import React, { useEffect } from "react";
import { useHistory, Redirect } from 'react-router-dom';
import CguCgv from '../../assets/documents/cgu-cgv.pdf';

export const CguvPage = () => {
  const history = useHistory();

  useEffect(() => {
    history.go(0);
  }, []);

  return (
    <Redirect to={CguCgv} />
  );
};

export default CguvPage;
