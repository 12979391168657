import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

export const Stepper = ({ step, isSoleTrader }) => {
  const { t } = useTranslation();
  const steps = [1, 2, 3, 4, 5];

  return (
    <nav aria-label="Progress" className={clsx(step === 1 ? "lg:pb-16" : "sm:pb-12 sm:pt-8")}>
      <p className={clsx("text-xms font-medium py-2 text-center", step === 1 ? "text-white" : "text-grey")}>
        {t("step")}
        {(isSoleTrader && step > 2) ? (step - 1) : step}
        /
        {isSoleTrader ? (steps.length - 1) : steps.length}
      </p>
      <ul className="flex items-center">
        {
          steps.map((stepIdx) => (
            <li key={stepIdx} className={clsx(isSoleTrader && stepIdx === 3 ? 'hidden' : stepIdx !== 5 ? 'pr-8 sm:pr-8' : '', 'relative')}>
              {
                (stepIdx < step)
                && (
                  <>
                    <div
                      className="relative w-3 md:w-3 h-3 md:h-3 flex items-center justify-center bg-orange-chocolate rounded-full hover:bg-orange-chocolate"
                    >
                      <span className="w-3 md:w-3 h-3 md:h-3 bg-orange-chocolate rounded-full" aria-hidden="true" />
                    </div>
                  </>
                )
              }
              {
                (stepIdx === step)
                && (
                  <>
                    <div
                      className="relative w-5 md:w-5 h-5 md:h-5 flex items-center justify-center bg-orange-dark rounded-full"
                      aria-current="step"
                    >
                      <span className="w-3 md:w-3 h-3 md:h-3 bg-orange-chocolate rounded-full" aria-hidden="true" />
                    </div>
                  </>
                )
              }
              {
                (stepIdx > step)
                && (
                  <>
                    <div
                      className="group relative w-5 md:w-5 h-5 md:h-5 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full hover:border-gray-400"
                    >
                      <span
                        className="w-3 md:w-3 h-3 md:h-3 bg-transparent rounded-full group-hover:bg-gray-300"
                        aria-hidden="true"
                      />
                      <span className="sr-only">{step.name}</span>
                    </div>
                  </>
                )
              }
            </li>
          ))
        }
      </ul>
    </nav>
  );
};

export default Stepper;
