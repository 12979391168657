import React, { useState } from 'react';
import useFetchSellerOrders from '../../hooks/useFetchSellerOrders';
import { OrdersLoader } from '../../components/OrdersLoader';
import { OrdersNavigation } from "../../components/OrdersNavigation";
import { Pagination } from "../../components/Pagination";

export const OrdersPage = () => {
  const currentInfosOrder = useFetchSellerOrders();
  const PageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <>
      <div className="md:w-3/4 md:px-4 md:mx-auto pt-2">
        {currentInfosOrder !== null
          && (
            <>
              <OrdersNavigation PageSize={PageSize} currentPage={currentPage} infosOrder={currentInfosOrder} incidentModal={false} incident={false} />
              {currentInfosOrder === undefined
                && (
                  <OrdersLoader />
                )}
              {currentInfosOrder !== undefined
                && (
                  <Pagination
                    currentPage={currentPage}
                    totalCount={currentInfosOrder?.length}
                    pageSize={PageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                )}
            </>
          )}
      </div>
    </>
  );
};

export default OrdersPage;
