import React from 'react';

export const OrdersLoader = () => (
  <div className="w-full animate-pulse space-y-10 px-6 mm:px-0">
    <div className="h-10 w-full bg-grey-light rounded" />
    <div className="h-10 w-full bg-grey-light rounded" />
    <div className="h-10 w-full bg-grey-light rounded" />
    <div className="h-10 w-full bg-grey-light rounded" />
  </div>
);

export default OrdersLoader;
