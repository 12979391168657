import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CheckIcon, ExclamationIcon } from '@heroicons/react/outline';
import { getImportsLaunched, getShippingConfigurationLaunched } from '../../store/reducer';
import FormDeposit from '../../components/FormDeposit';
import ImportStatus from '../../components/ImportStatus';
import DesktopImportStatus from '../../components/ImportStatus/DesktopImportStatus';
import usePagination from "../../hooks/usePagination";
import { Pagination } from "../../components/Pagination";
import { capitalizeFirst } from '../../utils/format';

const PageSize = 10;

export const ImportsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { imports, userDynamo, shippingLoading, shippingConfiguration, importSuccessState, getImportsLoading } = useSelector((state) => (
    {
      imports: state.getIn(['store', 'imports']),
      userDynamo: state.getIn(['app', 'userDynamo']),
      shippingLoading: state.getIn(['store', 'shippingLoading']),
      shippingConfiguration: state.getIn(['store', 'shippingConfiguration']),
      importSuccessState: state.getIn(['store', 'importSuccessState']),
      getImportsLoading: state.getIn(['store', 'getImportsLoading'])
    }
  ));
  useEffect(() => {
    dispatch(getImportsLaunched({ imports }));
    dispatch(getShippingConfigurationLaunched({ shippingConfiguration }));
  }, []);

  const [data, setData] = useState([]);
  const [tryToRefresh, setTryToRefresh] = useState(false);

  // Function when we need to refresh imports
  const dispatchAndRefresh = () => {
    dispatch(getImportsLaunched({ imports }));
    setTryToRefresh(!tryToRefresh);
  };

  // Set data and sort by date DESC for Desktop
  useEffect(() => {
    if (imports) {
      const dataDesktop = Array.from(imports);
      dataDesktop.sort((a, b) => {
        const dateA = new Date(a.metadata.createdAt);
        const dateB = new Date(b.metadata.createdAt);
        return dateB - dateA;
      });
      setData(dataDesktop);
    }
  }, [imports]);

  // On import success : we need to refresh imports
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return null; // return null at first mount
    }
    if (importSuccessState) {
      dispatchAndRefresh();
      return null;
    }
    return null;
  }, [importSuccessState]);

  // Fetch and set the last import
  const [newImport, setNewImport] = useState();
  const isInitialMount2 = useRef(true);
  useEffect(() => {
    if (isInitialMount2.current) {
      isInitialMount2.current = false;
      return null; // return null at first mount
    }
    if (imports && !getImportsLoading) {
      setNewImport(Array.from(imports).filter((dataImport) => (dataImport.importStatus === "NEW" || dataImport.importStatus === "ONGOING")));
    }
    return null;
  }, [tryToRefresh, getImportsLoading]);

  // While record treatment is not finished : we refresh import
  useEffect(() => {
    let timer;
    if (newImport && (newImport[0]?.importStatus === "NEW" || newImport[0]?.importStatus === "ONGOING")) {
      timer = setTimeout(() => dispatchAndRefresh(), 5000);
    }
    return () => { clearTimeout(timer); }; // Important
  }, [newImport]);

  // Set data and sort by date DESC and Pagination for mobile
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  useEffect(() => {
    if (imports) {
      const dataMobile = Array.from(imports);
      dataMobile.sort((a, b) => {
        const dateA = new Date(a.metadata.createdAt);
        const dateB = new Date(b.metadata.createdAt);
        return dateB - dateA;
      });
      setCurrentData(dataMobile);
    }
  }, [imports]);

  const currentTableData = usePagination({ currentData, PageSize, currentPage });

  // Import is authorized if seller's informations is defined
  const sellerInfo = userDynamo?.seller;
  const [sellerCompanyAbout, setSellerCompanyAbout] = useState();
  useEffect(() => {
    if (sellerInfo.companyAbout) {
      setSellerCompanyAbout(sellerInfo.companyAbout);
    }
  }, [userDynamo]);

  // Import is authorized if seller's shipping cost is defined
  const [sellerInfoShipping, setSellerInfoShipping] = useState(null);
  useEffect(() => {
    if (shippingConfiguration) {
      setSellerInfoShipping(shippingConfiguration);
    }
  }, [shippingConfiguration, shippingLoading]);

  if (sellerCompanyAbout && (sellerInfoShipping !== null && sellerInfoShipping.length !== 0)) {
    return (
      <>
        <div className="">
          <h2 className="text-s md:text-xms font-medium w-full md:w-3/4 px-8 md:px-4 md:mx-auto pt-8 pb-10">{t("importsPage.title")}</h2>
          <FormDeposit />
        </div>
        {/* Desktop */}
        <div className="hidden lg:block px-12">
          <DesktopImportStatus arrayContent={data} />
        </div>
        {/* Responsive Mobile */}
        {currentTableData.map((items) => (
          <div className="pt-8 pl-4 block lg:hidden" key={items?.id}>
            <ImportStatus
              name={items?.filename}
              date={items?.metadata?.createdAt}
              status={items?.importStatus}
              errors={items?.stats?.nbError}
              products={items?.stats?.nbDone}
              isError={items?.S3KeyErrorReport}
              statusReason={items?.statusReason}
            />
          </div>
        ))}
        {imports
          && (
            <div className="block lg:hidden">
              <Pagination
                currentPage={currentPage}
                totalCount={imports.length}
                pageSize={PageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          )}
      </>
    );
  } if (userDynamo && shippingConfiguration) {
    return (
      <div>
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-extrabold text-gray-900">Attention</h2>
            <p className="mt-4 text-base text-gray-500">
              {capitalizeFirst(t("importsPage.errorTitle"))}
            </p>
          </div>
          <dl className="mt-12 space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-6 md:gap-y-12">
            <div className="relative">
              <dt>
                {!sellerCompanyAbout
                  && (
                    <ExclamationIcon className="absolute h-6 w-6 text-red-500" aria-hidden="true" />
                  )}
                {sellerCompanyAbout
                  && (
                    <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                  )}
                <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{capitalizeFirst(t("importsPage.accountTitle"))}</p>
              </dt>
              <dd className="mt-2 ml-9 text-xss text-gray-500">{capitalizeFirst(t("importsPage.accountDetails"))}</dd>
              <Link to="/account" className="ml-9">
                <button
                  type="button"
                  className="w-60 mt-6 py-1 px-6 text-xss text-white font-normal rounded bg-orange-dark hover:bg-orange-chocolate"
                >
                  {capitalizeFirst(t("importsPage.accountAccess"))}
                </button>
              </Link>
            </div>
            <div className="relative">
              <dt>
                {(sellerInfoShipping && (sellerInfoShipping === null || sellerInfoShipping.length === 0))
                  && (
                    <ExclamationIcon className="absolute h-6 w-6 text-red-500" aria-hidden="true" />
                  )}
                {(sellerInfoShipping && (sellerInfoShipping !== null && sellerInfoShipping.length !== 0))
                  && (
                    <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                  )}
                <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{capitalizeFirst(t("importsPage.shippingTitle"))}</p>
              </dt>
              <dd className="mt-2 ml-9 text-xss text-gray-500">{capitalizeFirst(t("importsPage.shippingDetails"))}</dd>
              <Link to="/shipping-mode" className="ml-9">
                <button
                  type="button"
                  className="w-60 mt-6 py-1 px-6 text-xss text-white font-normal rounded bg-orange-dark hover:bg-orange-chocolate"
                >
                  {capitalizeFirst(t("importsPage.shippingAccess"))}
                </button>
              </Link>
            </div>
          </dl>
        </div>
      </div>
    );
  }
  return (<></>);
};

export default ImportsPage;
