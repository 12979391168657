const getStyles = (theme) => ({
  rowContainer: {
    display: 'flex'
  },
  productCell: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default getStyles;
