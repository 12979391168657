const getStyles = (theme) => ({
  rowContainer: {
    display: 'flex'
  },
  productCell: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    width: '12.5%',
  },
  imageTab: {
    height: "5rem",
  },
  widthCell0: {
    width: '12.5%',
  },
});

export default getStyles;
