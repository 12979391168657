import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes, { number } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useStyles } from 'react-styles-hook';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { formatDateToFrenchFormat } from '../../../utils/format';
import { updateOrderLineIncidentLaunched } from '../../../store/reducer';
import getStyles from '../styles';

export const ModalOrderRefund = ({ showModal, hideModal, infosOrder }) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  const classes = useStyles(getStyles());
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const refundModes = [t("modalRefundOrder.complete"), t("modalRefundOrder.partial")];
  const refundTitles = [t("modalRefundOrder.completeRefund"), t("modalRefundOrder.partialRefund")];

  const [label, setLabel] = useState(refundTitles[0]);
  const [selected, setSelected] = useState(refundModes[0]);

  const [validate, setValidate] = useState(false);

  // auto close modal after 3s ONLY if refund is validated
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return null;
    }
    const timer = setTimeout(() => hideModal(), 3000);
    return () => { clearTimeout(timer); };
  }, [validate]);

  // Form
  const [formValue, setFormValue] = useState({
    id: infosOrder.id,
    sellerId: infosOrder.sellerId,
    orderLines:
    {
      offerId: infosOrder.products[0].offerId,
      incident: [
        {
          hasIncident: infosOrder.products[0].incident[0].hasIncident,
          amountWithVAT: infosOrder.totalOfferPrice.toFixed(2),
          createdDate: infosOrder.products[0].incident[0].createdDate,
          status: infosOrder.products[0].incident[0].status,
          subject: infosOrder.products[0].incident[0].subject,
          message: infosOrder.products[0].incident[0].message
        }
      ]
    }
  });

  const initialValues = {
    id: formValue.id,
    sellerId: formValue.sellerId,
    orderLines:
    {
      offerId: formValue.orderLines.offerId,
      incident: [
        {
          hasIncident: formValue.orderLines.incident[0].hasIncident,
          amountWithVAT: parseFloat(infosOrder.totalOfferPrice.toFixed(2), 10),
          createdDate: formValue.orderLines.incident[0].createdDate,
          status: "REFUNDED",
          subject: formValue.orderLines.incident[0].subject,
          message: formValue.orderLines.incident[0].message,
        }
      ]
    }
  };

  useEffect(() => {
    if (selected === refundModes[0]) {
      setLabel(refundTitles[0]);
    } else if (selected === refundModes[1]) {
      setLabel(refundTitles[1]);
    }
    return null;
  }, [selected]);

  const onSubmit = (values) => {
    dispatch(updateOrderLineIncidentLaunched(values));
    setValidate(true);
  };

  return (
    showModal
    && (
      <button
        type="button"
        className="cursor-auto absolute w-screen h-screen top-0 pt-0 inset-x-0 flex justify-center items-center md:items-center"
        style={classes.modalBackdrop}
        // close modal if anything outside modal content is clicked
        onClick={hideModal}
      >
        <div
          aria-hidden="true"
          className="relative bg-white w-11/12 md:w-3/4 lg:w-4/6 xl:w-1/2 flex flex-col justify-center items-center px-4 md:px-12 py-3 md:py-8 h-96"
          onClick={(e) => {
            // don't close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}
        >
          <div className="w-full flex flex-col items-center justify-between">
            {
              (validate === false)
              && (
                <>
                  <h3 className="text-xxs md:text-xss text-center my-6 self-start space-x-1">
                    <span>{t("order")}</span>
                    <span className="font-semibold">{infosOrder.orderNumber}</span>
                    <span>{t("of")}</span>
                    <span>{formatDateToFrenchFormat(infosOrder.date)}</span>
                  </h3>
                  <Listbox value={selected} onChange={setSelected}>
                    {({ open }) => (
                      <>
                        <div className="flex flex-col md:flex-row md:items-center mt-6 mb-4 self-start">
                          <Listbox.Label className="block text-xxs md:text-xss font-medium text-black-title capitalize">{t("modalRefundOrder.refund")}</Listbox.Label>
                          <div className="relative md:w-36 md:ml-4">
                            <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-orange focus:border-orange text-xxs md:text-xss">
                              <span className="block truncate">{selected}</span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-xxs md:text-xss">
                                {refundModes.map((edges) => (
                                  <Listbox.Option
                                    key={edges}
                                    className={({ active }) => classNames(
                                      active ? 'text-gray-900 bg-orange' : 'text-gray-900',
                                      'cursor-default select-none relative py-2 pl-3 pr-9'
                                    )}
                                    value={edges}
                                  >
                                    {({ newSelected, active }) => (
                                      <>
                                        <span className={classNames(newSelected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                          {edges}
                                        </span>

                                        {newSelected ? (
                                          <span
                                            className={classNames(
                                              active ? 'text-white' : 'text-indigo-600',
                                              'absolute inset-y-0 right-0 flex items-center pr-4'
                                            )}
                                          >
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </div>
                      </>
                    )}
                  </Listbox>
                  <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => { onSubmit(values); }}
                  >
                    {({ values, setFieldValue, handleChange }) => (
                      <Form className="w-full flex flex-col">
                        <fieldset
                          key={label}
                          className="border px-4 py-2 bg-orange border-orange-dark"
                        >
                          <div className="relative flex items-start pt-2">
                            <div className="flex items-center h-5 pt-1 cursor-pointer">
                              <input
                                id={label}
                                aria-describedby={label}
                                name={label}
                                type="checkbox"
                                defaultChecked="true"
                                disabled
                                className="focus:ring-orange-chocolate w-5 h-5 rounded-full text-orange-chocolate"
                              />
                            </div>
                            <div className="ml-3 text-xxs md:text-xss">
                              <label htmlFor={label} className="text-gray-900 font-normal pl-2">
                                {label}
                              </label>
                            </div>
                          </div>
                          <div
                            className="ml-10 w-40 text-xxs md:text-xss"
                          >
                            <>
                              <label htmlFor={label} className="hidden">
                                Prix
                              </label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                  <span className="text-gray-500">€</span>
                                </div>
                                <Field
                                  id="orderLines.incident[0].amountWithVAT"
                                  name="orderLines.incident[0].amountWithVAT"
                                  type="number"
                                  value={values.orderLines.incident[0].amountWithVAT}
                                  aria-describedby="price-currency"
                                  className="focus:ring-orange focus:border-orange block w-full pl-10 pr-15 text-gray-simple font-normal border-gray-300 rounded-md"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                />
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                  <span className="text-gray-500" id="price-currency">
                                    Euros
                                  </span>
                                </div>
                              </div>
                            </>
                          </div>
                        </fieldset>
                        <button
                          type="submit"
                          className="w-full sm:w-64 text-xxs text-white bg-orange-chocolate self-center rounded-md mt-8 px-6 py-2"
                        >
                          {t("modalRefundOrder.refundOrder")}
                        </button>
                      </Form>
                    )}
                  </Formik>
                  <button className="text-xxs px-4 py-2 items-center" type="button" onClick={hideModal}>{t("orderPage.cancel")}</button>
                </>
              )
            }
            {
              (validate === true)
              && (
                <>
                  <h3 className="text-xxs md:text-xss text-center my-6 space-y-1">
                    <p>{t("modalRefundOrder.refund")}</p>
                    <p className="space-x-0.5">
                      <span>{t("modalRefundOrder.of")}</span>
                      <span>{t("modalRefundOrder.order")}</span>
                    </p>
                    <p className="font-semibold pl-2">{infosOrder.orderNumber}</p>
                    <p>{t("modalRefundOrder.isRefund")}</p>
                  </h3>
                </>
              )
            }
          </div>
        </div>
      </button>
    )
  );
};

export default ModalOrderRefund;

ModalOrderRefund.propTypes = {
  showModal: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  infosOrder: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
