import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSellerOrdersLaunched, getCustomOrdersLaunched, getOffersLaunched, getDeliverySimulationsLaunched, getUserLaunched, getProductsLaunched } from '../store/reducer';
import { dateComparison } from '../utils/format';

const useFetchSellerOrders = (orderStatus) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { sellerOrders, customOrders, offers, users, deliverySimulations, products, updateSellerOrderLoading, updateOrderLineIncidentLoading } = useSelector((state) => (
    {
      customOrders: state.getIn(['store', 'customOrders']),
      sellerOrders: state.getIn(['store', 'sellerOrders']),
      offers: state.getIn(['store', 'offers']),
      users: state.getIn(['store', 'user']),
      deliverySimulations: state.getIn(['store', 'deliverySimulations']),
      products: state.getIn(['store', 'products']),
      updateSellerOrderLoading: state.getIn(['store', 'updateSellerOrderLoading']),
      updateOrderLineIncidentLoading: state.getIn(['store', 'updateOrderLineIncidentLoading'])
    }
  ));

  useEffect(() => {
    dispatch(getCustomOrdersLaunched({ customerOrders: id }));
    dispatch(getOffersLaunched({ offers: id }));
    dispatch(getDeliverySimulationsLaunched({ deliverySimulations: id }));
    dispatch(getUserLaunched({ users: id }));
    dispatch(getProductsLaunched({ products }));
  }, []);

  // After status sellerOrder updated (or Incident updated) : get all sellerOrders updated
  useEffect(() => {
    dispatch(getSellerOrdersLaunched({ sellerOrders }));
  }, [updateSellerOrderLoading, updateOrderLineIncidentLoading]);

  let dataCustomOrders;
  let dataSellerOrders;
  let dataOffers;
  let dataDeliverySimulations;
  let dataUsers;
  let dataProducts;

  let currentInfosOrder;
  if (sellerOrders && customOrders && offers && deliverySimulations && users && products) {
    currentInfosOrder = [];
    dataCustomOrders = Array.from(customOrders);
    dataSellerOrders = Array.from(sellerOrders);
    dataOffers = Array.from(offers);
    dataDeliverySimulations = Array.from(deliverySimulations);
    dataUsers = Array.from(users);
    dataProducts = Array.from(products);

    // filter by orderStatus
    let currentSellerOrders = null;
    if (orderStatus && orderStatus !== "INCIDENT") {
      currentSellerOrders = dataSellerOrders.filter((order) => order.orderStatus === orderStatus);
    } else if (orderStatus && orderStatus === "INCIDENT") {
      // Check if any sellerOrder has an Incident Line
      currentSellerOrders = [];
      dataSellerOrders.forEach((sellerOrder) => {
        let thisSellerOrderHasIncident = null;
        sellerOrder.orderLines.some((orderLine) => {
          orderLine.incident.some((orderLineIncident) => {
            if (orderLineIncident.hasIncident && (orderLineIncident.status === "NEW" || orderLineIncident.status === "WAITING_FOR_REFUND")) {
              currentSellerOrders.push(sellerOrder);
              thisSellerOrderHasIncident = true;
            } return thisSellerOrderHasIncident === true;
          });
          return thisSellerOrderHasIncident === true;
        });
      });
    } else {
      currentSellerOrders = dataSellerOrders;
    }

    if (currentSellerOrders.length > 0) {
      currentSellerOrders.forEach((sellerOrder) => {
        // get order Id et delivery information
        let orderID = null;
        let orderDelivery = null;
        let deliveryAddress = {};

        dataCustomOrders.forEach((customerOrder) => {
          if (sellerOrder.customerOrderId === customerOrder.id) {
            orderID = customerOrder.orderNumber;
            orderDelivery = customerOrder.delivery;
            deliveryAddress = {};
            // relay Point case
            if (sellerOrder.delivery.deliveryModeId === "relais-colis" && (customerOrder.relayPointAddress !== undefined && customerOrder.relayPointAddress.id !== null)) {
              deliveryAddress = {
                firstName: orderDelivery.firstName,
                lastName: orderDelivery.lastName,
                company: customerOrder.relayPointAddress.name,
                relayPointId: customerOrder.relayPointAddress.id,
                telephone: orderDelivery.telephone,
                address: customerOrder.relayPointAddress.address,
                zipcode: null,
                city: customerOrder.relayPointAddress.zipCodeCity,
                country: customerOrder.relayPointAddress.country,
                deliveryDate: sellerOrder.delivery.date,
                deliveryPrice: sellerOrder.delivery.price,
                deliveryModeId: sellerOrder.delivery.deliveryModeId,
                personnalAddress: orderDelivery.address,
                personnalZipcode: orderDelivery.zipCode,
                personnalCity: orderDelivery.city,
                personnalCountry: orderDelivery.country,

              };
              // home delivery case
            } else {
              deliveryAddress = {
                firstName: orderDelivery.firstName,
                lastName: orderDelivery.lastName,
                company: orderDelivery.company,
                relayPointId: null,
                telephone: orderDelivery.telephone,
                address: orderDelivery.address,
                zipcode: orderDelivery.zipCode,
                city: orderDelivery.city,
                country: orderDelivery.country,
                deliveryDate: sellerOrder.delivery.date,
                deliveryPrice: sellerOrder.delivery.price,
                deliveryModeId: sellerOrder.delivery.deliveryModeId
              };
            }
          }
        });

        // get customer information
        const currentCustomer = dataUsers.filter((user) => user.id === sellerOrder.userId);

        // get and push products information
        const currentOffers = [];
        let productInfo;
        let totalOfferPriceWithVAT = 0;
        sellerOrder.orderLines.forEach((orderLine) => {
          const offer = dataOffers.filter((data) => data.id === orderLine.offerId);
          if (offer.length !== 0) {
            productInfo = dataProducts.filter((product) => product.id === offer[0].productId);
            if (productInfo.length === 0) {
              productInfo.push({ images: [] });
            }
            totalOfferPriceWithVAT += Number(orderLine.totalPrice);
            currentOffers.push({
              img: productInfo[0].images,
              offerId: orderLine.offerId,
              title: productInfo[0].title.fr,
              sku: offer[0].sellerSKU,
              quantity: orderLine.quantity,
              price: orderLine.unitPrice,
              incident: orderLine.incident,
            });
          }
        });

        // const totalOfferPriceWithVAT = sellerOrder.totalPrice; is now defined in the OrderLine loop
        const totalDeliveryPriceWithVAT = sellerOrder.delivery.price;

        // push order informations
        currentInfosOrder.push({
          id: sellerOrder.id,
          sellerId: sellerOrder.sellerId,
          orderNumber: orderID,
          date: sellerOrder.createdDate,
          status: sellerOrder.orderStatus,
          payoutStatus: sellerOrder.payoutStatus,
          customer: {
            firstname: currentCustomer[0].firstName,
            lastname: currentCustomer[0].lastName,
            email: currentCustomer[0].email
          },
          products: currentOffers,
          totalOfferPrice: totalOfferPriceWithVAT,
          totalDeliveryPrice: totalDeliveryPriceWithVAT,
          trackingUrl: sellerOrder.delivery.trackingUrl,
          delivery: deliveryAddress
        });
      });

      // sort by date desc
      currentInfosOrder.sort(dateComparison);
    }
  }

  return currentInfosOrder;
};

export default useFetchSellerOrders;
