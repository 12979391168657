import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { addProtocol, formatDateToFrenchFormat } from '../../utils/format';

const ImportStatus = ({ name, date, status, errors, products, isError, statusReason }) => {
  const { t } = useTranslation();
  const localizedDate = formatDateToFrenchFormat(date);
  const DATE = localizedDate || `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

  return (
    <div className="pl-4 border-grey-light border-b md:border-b-0">
      <div className="mb-4">
        <div>
          <p className="text-xxms font-semibold text-grey-lightDark">{t("fileName")}</p>
        </div>
        <div className="space-x-4">
          <div className="pt-2">
            <p className="text-xxms">{name}</p>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <div>
          <p className="text-xxms text-grey-lightDark font-semibold">Date</p>
        </div>
        <div className="pt-2">
          <p className="text-xxms space-y-2">{DATE}</p>
        </div>
      </div>
      <div className="mb-4">
        <div>
          <p className="text-xxms text-grey-lightDark font-semibold">{t("status")}</p>
        </div>
        <div className="pt-2">
          <p className="text-xxms">{t(`${status}`)}</p>
        </div>
      </div>
      <div>
        <div>
          <p className="text-xxms text-grey-lightDark font-semibold">{t("errors")}</p>
        </div>
        <div className="pt-2">
          <p className="text-xxms">
            {errors}
          </p>
        </div>
      </div>
      <div className="pt-4">
        <div className="mb-2">
          <p className="text-xxms text-grey-lightDark font-semibold">{t("numberProducts")}</p>
        </div>
        <div className="pt-2">
          <p className="text-xxms">{products}</p>
        </div>
      </div>
      <div className="pt-4 mb-8">
        <p className="text-xxms text-grey-lightDark font-semibold">{t("errorsReport")}</p>
        { errors > 0 && !statusReason
          && (
            <div className="pt-4 flex">
              <a
                href={`${addProtocol(process.env.REACT_APP_IMPORT_ERRORS_S3BUCKET)}/${isError}`}
                className="underline text-xxms"
                download={isError}
                target="_blank"
                rel="noopener noreferrer"
                edge="end"
                aria-label="download"
              >
                {t("recover")}
              </a>
              <a href={isError} download={isError} className="pl-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z" clipRule="evenodd" />
                </svg>
              </a>
            </div>
          )}
        { statusReason
          && (
            <p className="pt-2 text-black text-xxxs">{statusReason}</p>
          )}
      </div>
    </div>
  );
};

export default ImportStatus;
