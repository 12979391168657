import React, { useEffect } from 'react';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useStyles } from 'react-styles-hook';
import { useTranslation } from 'react-i18next';
import { getCurrentSessionLaunched, logoutLaunched } from '../App/reducer';
import Logo from '../../assets/img/logo.png';
import Zamewa from '../../assets/img/zawema.png';
import getStyles from './styles';
import BurgerMenu from '../BurgerMenu';

export const Header = ({ sellerInfos, to, text, ...props }) => {
  const classes = useStyles(getStyles());
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { isAuthenticated } = useSelector((state) => ({
    isAuthenticated: state.getIn(['app', 'isAuthenticated']),
  }));

  useEffect(() => {
    dispatch(getCurrentSessionLaunched({ fromPath: "/" }));
  }, [dispatch]);

  const logOut = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <div className="custom-ui bg-white p-5 rounded-lg">
          <div className="py-2 font-bold text-center text-lg">Confirmation</div>
          <p className="py-2 text-center font-medium">Voulez-vous vraiment vous déconnecter ?</p>
          <div className="flex justify-around pt-4">
            <button onClick={() => { dispatch(logoutLaunched()); history.push("/"); onClose(); }} type="submit" className="bg-orange-chocolate text-white py-2 px-4 rounded font-medium">Oui</button>
            <button onClick={onClose} type="button" className="bg-orange-chocolate text-white py-2 px-4 rounded font-medium">Non</button>
          </div>
        </div>
      )
    });
  };

  return (
    <div style={classes.mainContainer}>
      <div className="w-full lg:w-11/12 mx-auto flex lg:justify-between items-center px-4 lg:px-0 py-4 justify-start">
        <div className="lg:hidden">
          <BurgerMenu
            authenticated={isAuthenticated}
            sellerInfo={sellerInfos}
          />
        </div>
        <div className="flex pl-10 items-center">
          <div className="">
            <Link to="/">
              <h1 title="Zawema">
                <img
                  className="h-12 lg:h-20 w-auto"
                  src={Logo}
                  alt="Logo zawema"
                />
              </h1>
            </Link>
          </div>
          <div className="flex flex-col items-start ml-4 lg:ml-8">
            <Link to="/">
              <img
                className="h-5 lg:h-7 w-28 lg:w-36 mb-2"
                src={Zamewa}
                alt="Zawema brand"
              />
            </Link>
          </div>
        </div>
        { isAuthenticated
          && (
            <div className="hidden lg:block">
              <button onClick={logOut} type="submit" className="underline mr-12 text-sm">
                {t("logout")}
              </button>
            </div>
          )}
        { (isAuthenticated === false) && (location.pathname !== '/signup')
          && (
            <div className="hidden lg:block">
              <Link to="/login" className="underline mr-12 text-sm">
                {t("connect")}
              </Link>
              <button
                type="button"
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-orange-dark hover:bg-orange-chocolate focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <Link to="/signup" className="text-sm">
                  {t("subscribe")}
                </Link>
              </button>
            </div>
          )}
      </div>
    </div>
  );
};

export default Header;
