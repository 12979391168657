import React from 'react';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import { useStyles } from "react-styles-hook";
import getStyles from "./styles";
import { capitalizeFirst } from '../../../utils/format';

export const ModalProductDetails = ({ item, showModal, hideModal }) => {
  const { t } = useTranslation();
  const classes = useStyles(getStyles());
  const attributes = item?.attributes;
  const attribute = Object.entries(attributes);

  // Return string or object transformed in order to be displayable
  const format = (attributeToTest) => {
    if (typeof attributeToTest === "object") {
      const result = Object.keys(attributeToTest).map((key) => (
        <div key={key} className="flex flex-col space-y-1">
          <p>
            <span>{capitalizeFirst(key)}</span>
            <span> : </span>
            <span>{capitalizeFirst(attributeToTest[key])}</span>
          </p>
        </div>
      ));
      return result;
    }
    return capitalizeFirst(attributeToTest);
  };

  return (
    showModal
    && (
      <div
        key={`${item.gtin}-${item.title.fr}`}
        id={`${item.gtin}-${item.title.fr}`}
        tabIndex="-1"
        aria-hidden="true"
        style={classes.largeModalBackdrop}
        className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-full md:inset-0 h-modal"
        // close modal if anything outside modal content is clicked
        onClick={hideModal}
      >
        <div
          aria-hidden="true"
          className="relative mx-auto p-4 w-full max-w-7xl h-full md:h-auto"
          onClick={(e) => {
            // do not close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}
        >
          {/* <!-- Modal content --> */}
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            {/* <!-- Modal header --> */}
            <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
              <h3 className="text-base md:text-lg font-semibold text-gray-900 dark:text-white">
                {item.title.fr}
              </h3>
              <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={hideModal}>
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
              </button>
            </div>
            {/* <!-- Modal body --> */}
            <div className="p-6 space-y-6">
              <h4 className="font-bold text-s md:text-m text-left mb-4">Description</h4>
              <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400 whitespace-pre-line">
                {item?.description?.fr}
              </p>

              <div className="w-full flex flex-col items-start justify-center mb-10">
                <h4 className="font-bold text-s md:text-m text-left mb-4">
                  {t("specifications")}
                  {`  `}
                  <span className="text-orange-dark">{t("ofProduct")}</span>
                </h4>
                <table className="table-fixed w-full text-left" style={classes.tableBorder}>
                  <tbody>
                    {
                      attribute && attribute.map((attr) => (
                        <tr key={attr[0]}>
                          <td className="bg-orange-light w-1/3 md:w-1/4 text-xs md:text-s px-2 md:px-4 py-2" style={classes.tableBorder}>
                            {capitalizeFirst(attr[0])}
                          </td>
                          <td className="w-2/3 md:w-3/4 text-xs md:text-s px-2 md:px-4 py-2" style={classes.tableBorder}>
                            {format(attr[1])}
                          </td>
                        </tr>
                      ))
                    }
                    {
                      !attribute
                      && (
                        <p>{t("cataloguePage.noAttribut")}</p>
                      )
                    }
                  </tbody>
                </table>
              </div>
            </div>
            {/* <!-- Modal footer --> */}
            <div className="flex justify-center items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
              <button onClick={hideModal} type="button" className="capitalize text-white bg-orange-dark hover:bg-orange-chocolate focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-chocolate font-medium rounded-lg text-sm px-5 py-2.5 text-center">{t("close")}</button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ModalProductDetails;

ModalProductDetails.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object]).isRequired,
  showModal: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
};
