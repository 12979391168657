import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, Redirect } from "react-router";
import { useTranslation } from "react-i18next";
import PrivateRoute from "../PrivateRoute";
import PublicRoute from "../PublicRoute";
import { getCurrentSessionLaunched } from "./reducer";
// Pages imports
import { WelcomePage } from "../../pages/WelcomePage";
import { HomePage } from "../../pages/HomePage";
import { SignInPage } from "../../pages/SignIn";
import { SignUpPage } from "../../pages/SignUp";
import { ForgotPassword } from "../../pages/ForgotPassword";
import { ImportsPage } from "../../pages/ImportsPage";
import { ProductsPage } from "../../pages/ProductsPage";
import { ConstructionPage } from "../../pages/ConstructionPage";
import { NotFoundPage } from "../../pages/NotFoundPage";
import { OrdersPage } from "../../pages/OrdersPage";
import { PreparationOrdersPage } from "../../pages/PreparationOrdersPage";
import { IncidentPage } from "../../pages/IncidentPage";
import { NotShippedPage } from "../../pages/NotShippedPage";
import { DeliveryPage } from "../../pages/DeliveryPage";
import { ShippingModeConfigurationsPage } from "../../pages/ShippingModeConfigurationsPage";
import { LegalPage } from "../../pages/LegalPage";
import { PaidOrdersPage } from "../../pages/PaidOrdersPage";
import { ContactPage } from "../../pages/ContactPage";
import { AccountPage } from "../../pages/AccountPage";
import { CguvPage } from "../../pages/CguvPage";
import { WaitingDeliveryPage } from '../../pages/WaitingDeliveryPage';
// Components imports
import { NavBar } from "../NavBar";
import { Header } from "../Header";
import { Footer } from "../Footer";

function App() {
  const dispatch = useDispatch();
  const {
    isAuthenticating,
    isAuthenticated,
    userDynamo,
  } = useSelector((state) => ({
    isAuthenticating: state.getIn(["app", "isAuthenticating"]),
    isAuthenticated: state.getIn(["app", "isAuthenticated"]),
    userDynamo: state.getIn(['app', 'userDynamo'])
  }));
  const { i18n } = useTranslation();

  useEffect(() => {
    dispatch(getCurrentSessionLaunched());
  }, [dispatch]);

  const sellerCompanyName = userDynamo?.seller?.socialReason;
  const appSwitch = (
    <Switch>
      <PublicRoute exact path="/" fixed component={WelcomePage} />
      <PublicRoute exact path="/login" fixed component={SignInPage} />
      <PublicRoute exact path="/signup" fixed component={SignUpPage} />
      <PrivateRoute exact path="/imports" fixed component={ImportsPage} />
      {/* <PublicRoute exact path="/contact" fixed component={ContactPage} /> */}
      <PrivateRoute exact path="/home" fixed component={HomePage} />
      <PrivateRoute exact path="/products" fixed component={ProductsPage} />
      <PrivateRoute exact path="/shipping-mode" fixed component={ShippingModeConfigurationsPage} />
      <PrivateRoute exact path="/orders" fixed component={OrdersPage} />
      <PrivateRoute exact path="/paid-orders" fixed component={PaidOrdersPage} />
      <PrivateRoute exact path="/preparation-orders" fixed component={PreparationOrdersPage} />
      <PrivateRoute exact path="/not-shipped" fixed component={NotShippedPage} />
      <PrivateRoute exact path="/waiting-delivery" fixed component={WaitingDeliveryPage} />
      <PrivateRoute exact path="/delivery" fixed component={DeliveryPage} />
      <PrivateRoute exact path="/incident" fixed component={IncidentPage} />
      <PrivateRoute exact path="/account" fixed component={AccountPage} />
      <PublicRoute exact path="/password" fixed component={ForgotPassword} />
      <PublicRoute exact path="/cguv" fixed component={CguvPage} />
      {/* <PublicRoute exact path="/legal" fixed component={LegalPage} /> */}
      <Route component={NotFoundPage} />
    </Switch>
  );

  return (
    <div className="relative flex flex-col min-h-screen h-full">
      <Header sellerInfos={sellerCompanyName} />
      {isAuthenticated ? <NavBar /> : null}
      <div className="mb-auto">
        {isAuthenticating ? "Loading..." : appSwitch}
      </div>
      <Footer />
    </div>
  );
}

export default App;
