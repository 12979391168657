import React, { useState, useEffect, useRef } from 'react';
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { useStyles } from "react-styles-hook";
import { useDispatch } from 'react-redux';
import { updateSellerOrderLaunched, updateOrderLineIncidentLaunched } from '../../../store/reducer';
import getStyles from "../styles";

export const ModalOrderCancel = ({ showModal, hideModal, infosOrder }) => {
  const classes = useStyles(getStyles());
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [validate, setValidate] = useState(false);
  // auto close modal after 3s ONLY if order is refused or canceled
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return null;
    }
    const timer = setTimeout(() => hideModal(), 3000);
    return () => { clearTimeout(timer); };
  }, [validate]);

  // Form SellerOrder is refused
  const [formValue, setFormValue] = useState({
    id: infosOrder.id,
    sellerId: infosOrder.sellerId,
    orderStatus: "REFUSED",
    revenusWithVAT: 0,
    revenusWithoutVAT: 0,
    decisionDate: new Date(),
  });
  const submit = () => {
    dispatch(updateSellerOrderLaunched({ formValue }));
  };

  // Form Incident is refused
  const incidentValues = {
    id: infosOrder.id,
    sellerId: infosOrder.sellerId,
    orderLines:
    {
      offerId: infosOrder.products[0].offerId,
      incident: [
        {
          hasIncident: infosOrder.products[0].incident[0].hasIncident,
          amountWithVAT: 0,
          createdDate: infosOrder.products[0].incident[0].createdDate,
          status: "CLOSED",
          subject: infosOrder.products[0].incident[0].subject,
          message: infosOrder.products[0].incident[0].message
        }
      ]
    }
  };

  const refuseIncident = () => {
    dispatch(updateOrderLineIncidentLaunched(incidentValues));
    setValidate(true);
  };

  return (
    showModal
    && (
      <button
        type="button"
        className="cursor-auto absolute w-screen h-screen top-0 pt-0 inset-x-0 flex justify-center items-center md:items-center"
        style={classes.modalBackdrop}
        // close modal if anything outside modal content is clicked
        onClick={hideModal}
      >
        <div
          aria-hidden="true"
          className="relative bg-white w-11/12 md:w-3/4 lg:w-4/6 xl:w-1/2 flex flex-col justify-center items-center px-4 md:px-12 py-3 md:py-8"
          onClick={(e) => {
            // don't close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}
        >
          <div className="w-3/4 md:w-full flex flex-col items-center justify-center my-16 h-36 min-h-full">
            {/* MODAL CANCEL PAGE PREPARATION */}
            {
              (pathname === "/preparation-orders")
              && (
                (validate === false)
                && (
                  <>
                    <h3 className="text-s md:text-xss text-center my-6">
                      <p>{t("modalCancelOrder.doYouWantRefuse")}</p>
                      <p className="font-semibold pl-2">{infosOrder.orderNumber}</p>
                    </h3>
                    <form className="w-full flex flex-col items-center">
                      <button
                        type="button"
                        className="text-xxs text-white bg-orange-chocolate rounded-md px-6 py-2"
                        onClick={() => { submit(); setValidate(true); }}
                      >
                        {t("modalCancelOrder.confirmeRefuse")}
                      </button>
                    </form>
                    <button className="text-xxs px-4 py-2 capitalize" type="button" onClick={hideModal}>{t("modalCancelOrder.goBack")}</button>
                  </>
                )
              )
            }
            {
              (pathname === "/preparation-orders")
              && (
                (validate === true)
                && (
                  <>
                    <h3 className="text-s md:text-xss text-center my-6 space-y-0.5">
                      <p>{t("modalCancelOrder.order")}</p>
                      <p className="font-semibold pl-2">{infosOrder.orderNumber}</p>
                      <p>{t("modalCancelOrder.isRefused")}</p>
                    </h3>
                  </>
                )
              )
            }
            {/* MODAL PAGE NOT SHIPPED */}
            {
              (pathname === "/not-shipped")
              && (
                (validate === false)
                && (
                  <>
                    <h3 className="text-s md:text-xss text-center my-6">
                      <p>{t("modalCancelOrder.doYouWantCancel")}</p>
                      <p className="font-semibold pl-2">{infosOrder.orderNumber}</p>
                    </h3>
                    <form className="w-full flex flex-col items-center">
                      <button
                        type="button"
                        className="text-xxs text-white bg-orange-chocolate rounded-md px-6 py-2"
                        onClick={() => { submit(); setValidate(true); }}
                      >
                        {t("modalCancelOrder.confirmCancel")}
                      </button>
                    </form>
                    <button className="text-xxs px-4 py-2 capitalize" type="button" onClick={hideModal}>{t("modalCancelOrder.goBack")}</button>
                    <p className="pt-6 text-xxxs">{t("modalCancelOrder.alertNotification")}</p>

                  </>
                )
              )
            }
            {
              (pathname === "/not-shipped")
              && (
                (validate === true)
                && (
                  <>
                    <h3 className="text-s md:text-xss text-center my-6 space-y-0.5">
                      <p>{t("modalCancelOrder.order")}</p>
                      <p className="font-semibold pl-2">{infosOrder.orderNumber}</p>
                      <p>{t("modalCancelOrder.isCanceled")}</p>
                    </h3>
                  </>
                )
              )
            }
            {/* MODAL PAGE INCIDENT */}
            {
              (pathname === "/incident")
              && (
                (validate === false)
                && (
                  <>
                    <h3 className="text-s md:text-xss text-center my-6">
                      <p className="space-x-1">
                        <span>{t("modalCancelOrder.doYouWantDenyIncident")}</span>
                        <span>{t("modalCancelOrder.order")}</span>
                      </p>
                      <p className="font-semibold pl-2">{infosOrder.orderNumber}</p>
                    </h3>
                    <form className="w-full flex flex-col items-center">
                      <button
                        type="button"
                        className="text-xxs text-white bg-orange-chocolate rounded-md px-6 py-2"
                        onClick={() => { refuseIncident(); setValidate(true); }}
                      >
                        {t("modalCancelOrder.confirmDeny")}
                      </button>
                    </form>
                    <button className="text-xxs px-4 py-2 capitalize" type="button" onClick={hideModal}>{t("modalCancelOrder.goBack")}</button>
                  </>
                )
              )
            }
            {
              (pathname === "/incident")
              && (
                (validate === true)
                && (
                  <>
                    <h3 className="text-s md:text-xss text-center my-6 space-y-0.5">
                      <p className="space-x-1">
                        <span>{t("modalCancelOrder.incidentOf")}</span>
                        <span>{t("modalCancelOrder.order")}</span>
                      </p>
                      <p className="font-semibold pl-2">{infosOrder.orderNumber}</p>
                      <p>{t("modalCancelOrder.isDeny")}</p>
                    </h3>
                  </>
                )
              )
            }
          </div>
        </div>
      </button>
    )
  );
};

export default ModalOrderCancel;

ModalOrderCancel.propTypes = {
  showModal: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  infosOrder: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
