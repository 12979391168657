import React from 'react';
import '../../index.css';
import { useStyles } from "react-styles-hook";
import getStyles from "./styles";

export const CustomButton = ({ title, onclick, disabled, ...props }) => {
  const classes = useStyles(getStyles());
  let isDisabled = false;
  if (disabled !== undefined) {
    isDisabled = disabled;
  }
  return (
    <button
      type="submit" className="px-8 py-1 bg-orange-dark text-sm text-white font-sans rounded" onClick={onclick} disabled={isDisabled}
    >
      {title}
    </button>
  );
};

export default CustomButton;
