/**
 * @param {number} date - Timestamp with milliseconds
 * @returns the date formatted as "2021-05-05"
 */
export const addProtocol = (url, protocol) => {
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    url = `${protocol}://${url}`;
  }
  return url;
};

export const formatTimestampToDateWithDashes = (date) => new Date(date).toISOString().split('T')[0];

export const formatHour = (date) => {
  const hour = new Date(date).getHours();
  const minute = new Date(date).getMinutes();
  return (minute === 0) ? (`${hour}h00`) : (`${hour}h${minute}`);
};

/**
 * @param {string} date - date as "2021-05-13"
 * @returns Date as "13-05-201"
 */
export const formatDateToFrenchFormat = (date) => {
  const splittedEnglishDate = date?.split("-");
  const calendarDate = new Date(date).getDate();
  const day = calendarDate < 10 ? (`0${calendarDate}`) : calendarDate;
  return splittedEnglishDate ? `${day}-${splittedEnglishDate[1]}-${splittedEnglishDate[0]}` : null;
};

/**
 * @param {array} array - array [[date: yyyy-mm-dd, otherKey: otherPropertyX], [date: yyyy-mm-dd, otherKey: otherPropertyY]]
 * @returns array sort by date Desc
 */
export const dateComparison = (a, b) => {
  const date1 = new Date(a.date);
  const date2 = new Date(b.date);
  return date2 - date1;
};

/**
 * @param {string} string - string as "text to Transform"
 * @returns string as "Text to transform"
 */
export const capitalizeFirst = (string) => string.charAt(0).toUpperCase() + string.slice(1);

/**
 * @param {string} string - string as "Ce texte à des accents"
 * @returns string as "Ce texte a des accents"
 */
export const strNoAccent = (a) => {
  const b = "áàâäãåçéèêëíïîìñóòôöõúùûüýÁÀÂÄÃÅÇÉÈÊËÍÏÎÌÑÓÒÔÖÕÚÙÛÜÝ";
  const c = "aaaaaaceeeeiiiinooooouuuuyAAAAAACEEEEIIIINOOOOOUUUUY";
  let d = "";
  for (let i = 0, j = a.length; i < j; i += 1) {
    const e = a.substr(i, 1);
    d += (b.indexOf(e) !== -1) ? c.substr(b.indexOf(e), 1) : e;
  }
  return d;
};

/**
 * @param {string} string - string as "text with sp€cial ch&r !"
 * @returns string as "text with spcial chr"
 */
export const strNoSpecialChar = (string) => {
  string.replace(/[`~!@#€$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, '');
};

/**
 * @param {string} string - string as "Text with sp€cial ch&r ! and accènt and CAPITALIZE and space"
 * @returns string as "text-with-spcial-chr--and-accent-and-capitalize"
 */
export const strNoAccentNoSpecialChar = (string) => {
  let stringToReturn = "";

  // delete accent
  const b = "éáàâäãåçéèêëíïîìñóòôöõúùûüýÁÀÂÄÃÅÇÉÈÊËÍÏÎÌÑÓÒÔÖÕÚÙÛÜÝ";
  const c = "eaaaaaaceeeeiiiinooooouuuuyAAAAAACEEEEIIIINOOOOOUUUUY";
  for (let i = 0, j = string.length; i < j; i += 1) {
    const e = string.substr(i, 1);
    stringToReturn += (b.indexOf(e) !== -1) ? c.substr(b.indexOf(e), 1) : e;
  }
  // delete special char
  stringToReturn = stringToReturn.replace(/[`~!@#€$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, '');

  return stringToReturn.toLowerCase().replaceAll(' ', '-');
};
