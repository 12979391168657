import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { ToggleSwitch } from '../../ToggleSwitch';
import countriesJSON from '../../../utils/countries.json';
import { ModalFormBusiness } from '../../Modal/ModalFormBusiness';
import { strNoAccentNoSpecialChar } from '../../../utils/format';
import { UploadFile } from '../../UploadFile';
import { uploadFileOnboardingLaunched } from '../../../store/reducer';

const InfosForm = ({ step, setStep, formValue, setFormValue }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // toggles states
  const [enabledShareholder, setEnabledShareholder] = useState(false);
  const [enabledAdministrator, setEnabledAdministrator] = useState(false);

  // input value state to Reset
  const [eventToReset, setEventToReset] = useState(null);

  // informations modale :
  const [openModal, setOpenModal] = useState(false);
  const showModal = () => {
    setOpenModal(true);
  };
  const hideModal = () => {
    setOpenModal(false);
  };

  // Form data
  const initialValues = {
    lastName: formValue.lastName,
    firstName: formValue.firstName,
    birthday: formValue.birthday,
    nationality: formValue.nationality,
    birthCountry: formValue.birthCountry,
    birthCity: formValue.birthCity,
    emailLegalRepresentant: formValue.emailLegalRepresentant,
    adress1LegalRepresentant: formValue.adress1LegalRepresentant,
    zipCodeLegalRepresentant: formValue.zipCodeLegalRepresentant,
    cityLegalRepresentant: formValue.cityLegalRepresentant,
    countryLegalRepresentant: formValue.countryLegalRepresentant,
    adressFiles: formValue.adressFiles,
    representantIdFiles: formValue.representantIdFiles,
    enabledShareholder: formValue.enabledShareholder,
    enabledAdministrator: formValue.enabledAdministrator
  };

  // Form Submitting Function
  const signup = () => {
    if (formValue.isSoleTrader) {
      setStep(step + 2);
    } else {
      setStep(step + 1);
    }
    window.scrollTo(0, 0);
  };

  // Form Validation Schema
  const validationSchema = Yup.object().shape({
    lastName: Yup.string()
      .required(t("lastNameRequired"))
      .nullable(),
    firstName: Yup.string()
      .required(t("firstNameRequired"))
      .nullable(),
    birthday: Yup.date()
      .required(t("signUpInfos.dateOfBirthRequired"))
      .nullable(),
    birthCountry: Yup.string()
      .required(t("signUpInfos.birthCountryRequired"))
      .nullable(),
    birthCity: Yup.string()
      .required(t("signUpInfos.birthCityRequired"))
      .nullable(),
    nationality: Yup.string()
      .required(t("signUpInfos.nationalityRequired"))
      .nullable(),
    emailLegalRepresentant: Yup.string()
      .required(t("signUpForm.emailRequired"))
      .email(t("signUpForm.emailType"))
      .nullable(),
    adress1LegalRepresentant: Yup.string()
      .required(t("signUpInfos.adressRequired"))
      .nullable(),
    zipCodeLegalRepresentant: Yup.number()
      .required(t("signUpInfos.cpRequired"))
      .positive()
      .integer()
      .nullable(),
    cityLegalRepresentant: Yup.string()
      .required(t("signUpInfos.cityRequired"))
      .nullable(),
    countryLegalRepresentant: Yup.string()
      .required(t("signUpInfos.countryRequired"))
      .nullable(),
    adressFiles: Yup.array()
      .nullable(),
    enabledShareholder: Yup.bool()
      .nullable(),
    enabledAdministrator: Yup.bool()
      .nullable()
  });
  // const countriesMapped = countriesJSON.sort((a, b) => a.name.localeCompare(b.name));
  const countriesMapped = countriesJSON;

  // tailwind reusable css
  const sectionContainer = "w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-4 md:mb-6";
  const sectionContainerSpaced = "w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-6";

  // Upload file on S3 function
  const uploadFile = (file, identificator) => {
    dispatch(uploadFileOnboardingLaunched({ file, identificator }));
  };

  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-lg">
      <h2 className="text-sm md:text-s font-bold pb-2 w-full mx-auto lg:w-5/6">{t("signUpInfos.legalRepresentantInfo")}</h2>
      <p className="text-gray-500 text-xxxs pb-12 w-full mx-auto lg:w-5/6">{formValue.isSoleTrader ? t("signUpInfos.legalRepresentantIntroSoleTrader") : t("signUpInfos.legalRepresentantInfo")}</p>
      <div className="w-full mx-auto">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validate={(values) => {
            const errors = {};
            if (values.birthday && !/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/i.test(values.birthday)) {
              errors.birthdayFormat = "Erreur de format, format yyyy-mm-dd attentu";
              // Files input's validation constraints
            } else if (values.adressFiles === null || values.adressFiles.length === 0) {
              errors.adressFiles = t("signUpBusiness.fileRequired");
            } else if (values.representantIdFiles === null || values.representantIdFiles.length === 0) {
              errors.representantIdFiles = t("signUpBusiness.fileRequired");
            } else if (values.adressFiles.length > 1) {
              errors.adressFiles = t("signUpBusiness.numberOfFile");
            } else if (values.representantIdFiles.length > 1) {
              errors.representantIdFiles = t("signUpBusiness.numberOfFile");
            } else {
              values.adressFiles.forEach((value) => {
                if (value.size > 3000000) {
                  errors.adressFiles = (t("signUpBusiness.fileSize"));
                } else if (value.size < 32000) {
                  errors.adressFiles = (t("signUpBusiness.fileToSmall"));
                }
              });
              values.representantIdFiles.forEach((value) => {
                if (value.size > 3000000) {
                  errors.representantIdFiles = (t("signUpBusiness.fileSize"));
                } else if (value.size < 32000) {
                  errors.representantIdFiles = (t("signUpBusiness.fileToSmall"));
                }
              });
            }

            return errors;
          }}
          onSubmit={signup}
        >
          {({ errors, touched, values, setFieldValue, handleChange }) => (
            <Form className="mx-auto w-5/6 flex flex-col justify-center items-center">
              <div className={sectionContainerSpaced}>
                <div className="w-full">
                  <label className="block text-xxms font-medium">{`${t('signUpInfos.lastName')}  *`}</label>
                  <Field
                    id="lastName"
                    name="lastName"
                    type="text"
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value
                      }));
                    }}
                    placeholder={t('lastName')}
                    className="w-full border-grey-light rounded text-xxms text-gray-500"
                  />
                  {errors.lastName && touched.lastName && (
                    <p className="text-red-600 text-center md:text-left">{errors.lastName}</p>
                  )}
                </div>
              </div>

              <div className={sectionContainerSpaced}>
                <div className="w-full">
                  <label className="block text-xxms font-medium">{`${t('signUpInfos.firstName')}  *`}</label>
                  <Field
                    id="firstName"
                    name="firstName"
                    type="text"
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value
                      }));
                    }}
                    placeholder={t('firstName')}
                    className="w-full border-grey-light rounded text-xxms text-gray-500"
                  />
                  {errors.firstName && touched.firstName ? (
                    <p className="text-red-600 text-center md:text-left">{errors.firstName}</p>
                  ) : null}
                </div>
              </div>

              <div className={sectionContainerSpaced}>
                <div className="w-full">
                  <label className="block text-xxms font-medium">{`${t('signInForm.email')}  *`}</label>
                  <Field
                    id="emailLegalRepresentant"
                    name="emailLegalRepresentant"
                    type="email"
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value
                      }));
                    }}
                    placeholder="ex : antoine.dupont@company.com"
                    className="w-full border-grey-light rounded text-xxms text-gray-500"
                  />
                  {errors.emailLegalRepresentant && touched.emailLegalRepresentant ? (
                    <p className="text-red-600 text-center md:text-left">{errors.emailLegalRepresentant}</p>
                  ) : null}
                </div>
              </div>

              <div className={sectionContainer}>
                <div className="w-full md:flex">
                  {/* Birthdate field */}
                  <div className="w-full md:1/5 md:pr-4">
                    <label className="block text-xxms font-medium">{`${t('signUpInfos.dateOfBirth')}  *`}</label>
                    <Field
                      id="birthday"
                      name="birthday"
                      type="date"
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value
                        }));
                      }}
                      className="w-full border-grey-light rounded text-xxms text-gray-500"
                    />
                    {errors.birthday && touched.birthday ? (
                      <p className="text-red-600 text-center md:text-left">{errors.birthday}</p>
                    ) : null}
                    {errors.birthdayFormat ? (
                      <p className="text-red-600 text-center md:text-left">{errors.birthdayFormat}</p>
                    ) : null}
                  </div>
                  {/* Nationality field */}
                  <div className="w-full md:w-4/5 mb-4 md:mb-0 mt-4 md:mt-0">
                    <label className="block text-xxms font-medium">{`${t('signUpInfos.nationality')}  *`}</label>
                    <select
                      id="nationality"
                      name="nationality"
                      defaultValue=""
                      type={values.nationality}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value
                        }));
                      }}
                      className="w-full border-grey-light rounded text-xxms text-gray-500"
                    >
                      <option value="" disabled label={t("selectNationality")} />
                      {
                        countriesMapped.map((country) => (
                          <option key={country.alpha_2_code} value={country.alpha_2_code}>
                            {country.nationality}
                          </option>
                        ))
                      }
                    </select>
                    {errors.nationality && touched.nationality ? (
                      <p className="text-red-600 text-center md:text-left">{errors.nationality}</p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className={sectionContainer}>
                <div className="w-full md:flex">
                  {/* BirthCountry field */}
                  <div className="w-full md:1/5 md:pr-4">
                    <label className="block text-xxms font-medium">{`${t('signUpInfos.birthCountry')}  *`}</label>
                    <select
                      id="birthCountry"
                      name="birthCountry"
                      defaultValue=""
                      type={values.birthCountry}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value
                        }));
                      }}
                      className="w-full border-grey-light rounded text-xxms text-gray-500"
                    >
                      <option value="" disabled label={t("selectCountry")} />
                      {
                        countriesMapped.map((country) => (
                          <option key={country.num_code} value={country.alpha_2_code}>
                            {country.en_short_name}
                          </option>
                        ))
                      }
                    </select>
                    {errors.birthCountry && touched.birthCountry ? (
                      <p className="text-red-600 text-center md:text-left">{errors.birthCountry}</p>
                    ) : null}
                  </div>
                  {/* birthCity  field */}
                  <div className="w-full md:w-4/5 mb-4 md:mb-0 mt-4 md:mt-0">
                    <label className="block text-xxms font-medium">{`${t('signUpInfos.birthCity')}  *`}</label>
                    <Field
                      id="birthCity"
                      name="birthCity"
                      type="text"
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value
                        }));
                      }}
                      className="w-full border-grey-light rounded text-xxms text-gray-500"
                    />
                    {errors.birthCity && touched.birthCity ? (
                      <p className="text-red-600 text-center md:text-left">{errors.birthCity}</p>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className={sectionContainerSpaced}>
                <div className="w-full">
                  <label className="block text-xxms font-medium">{`${t('signUpInfos.adress')}  *`}</label>
                  <Field
                    id="adress1LegalRepresentant"
                    name="adress1LegalRepresentant"
                    type="text"
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value
                      }));
                    }}
                    placeholder={`ex : ${t("signUpInfos.adressPlaceholder")}`}
                    className="w-full border-grey-light rounded text-xxms text-gray-500"
                  />
                  {errors.adress1LegalRepresentant && touched.adress1LegalRepresentant ? (
                    <p className="text-red-600 text-center md:text-left">{errors.adress1LegalRepresentant}</p>
                  ) : null}
                </div>
              </div>

              <div className={sectionContainer}>
                <div className="w-full md:flex">
                  {/* ZipCode field */}
                  <div className="w-full md:1/5 md:pr-4">
                    <Field
                      id="zipCodeLegalRepresentant"
                      name="zipCodeLegalRepresentant"
                      type="text"
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: parseInt(e.target.value, 10)
                        }));
                      }}
                      placeholder="ex : 75000"
                      className="w-full border-grey-light rounded text-xxms text-gray-500"
                    />
                    {errors.zipCodeLegalRepresentant && touched.zipCodeLegalRepresentant ? (
                      <p className="text-red-600 text-center md:text-left">{errors.zipCodeLegalRepresentant}</p>
                    ) : null}
                  </div>
                  {/* City field */}
                  <div className="w-full md:w-4/5 mb-4 md:mb-0 mt-4 md:mt-0">
                    <Field
                      id="cityLegalRepresentant"
                      name="cityLegalRepresentant"
                      type="text"
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value
                        }));
                      }}
                      placeholder="ex : Paris"
                      className="w-full border-grey-light rounded text-xxms text-gray-500"
                    />
                    {errors.cityLegalRepresentant && touched.cityLegalRepresentant ? (
                      <p className="text-red-600 text-center md:text-left">{errors.cityLegalRepresentant}</p>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className={sectionContainer}>
                <div className="w-full">
                  <select
                    id="countryLegalRepresentant"
                    name="countryLegalRepresentant"
                    defaultValue=""
                    type={values.countryLegalRepresentant}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value
                      }));
                    }}
                    className="w-full border-grey-light rounded text-xxs text-gray-500"
                  >
                    <option value="" disabled label={t("selectCountry")} />
                    {
                      countriesMapped.map((country) => (
                        <option key={country.alpha_3_code} value={country.alpha_2_code}>
                          {country.en_short_name}
                        </option>
                      ))
                    }
                  </select>
                  {errors.countryLegalRepresentant && touched.countryLegalRepresentant ? (
                    <p className="text-red-600 text-center md:text-left">{errors.countryLegalRepresentant}</p>
                  ) : null}
                </div>
              </div>

              {/* Required documents section */}
              <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-2">
                <div className="w-full md:w-4/6 lg:w-3/4">
                  <div>
                    <span className="text-xxms font-medium">
                      {t("signUpInfos.obligatory")}
                    </span>
                    <button
                      type="button"
                      className="pt-2"
                      onClick={() => {
                        setOpenModal(!openModal);
                      }}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-4" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="w-full mb-2 text-xxs italic flex flex-col space-y-1">
                <span>{t("signUpInfos.infoFile")}</span>
                <span>{t("signUpInfos.tipFile")}</span>
              </div>
              {/* Upload ID */}
              {/* <UploadFile
                identificator="identityFiles"
                label={t('uploadFileID')}
                onChange={(event) => {
                  if (!values.identityFiles) {
                    values.identityFiles = [];
                  }
                  const identityFilesArray = values.identityFiles;
                  if (event.target?.files[0]) {
                    const fileName = event.target?.files[0].name;
                    const fileType = event.target?.files[0].type;
                    const fileSize = event.target?.files[0].size;
                    const file = event.target?.files[0];
                    const reader = new FileReader();
                    reader.onload = () => {
                      setFieldValue("identityFiles", identityFilesArray.concat({ name: fileName, type: fileType, fileData: reader.result, size: fileSize }));
                      setFormValue((prevState) => ({
                        ...prevState,
                        [event.target.name]: values.identityFiles.concat({ docType: 'cni', name: fileName, type: fileType, fileData: reader.result, size: fileSize })
                      }));
                    };
                    reader.readAsDataURL(file);
                  }
                }}
                arrayValues={values.identityFiles}
                errors={errors.identityFiles}
                touched={touched.identityFiles}
                setFormValue={setFormValue}
                formValue={formValue}
                setFieldValue={setFieldValue}
              /> */}
              {/* Upload adress proof */}
              <UploadFile
                identificator="adressFiles"
                label={t('adressFiles')}
                onInput={(event) => {
                  setEventToReset(event.target);
                  if (!values.adressFiles) {
                    values.adressFiles = [];
                  }
                  if (values.adressFiles.length >= 1) {
                    values.adressFiles = [];
                  } // reset array after wrong upload (because only 1 file per input is allowed)
                  if (formValue.adressFiles.length >= 1) {
                    formValue.adressFiles.length = [];
                  } // reset array after wrong upload (because only 1 file per input is allowed)
                  const adressFilesArray = values.adressFiles;
                  if (event.target?.files[0]) {
                    const fileName = strNoAccentNoSpecialChar(event.target?.files[0].name);
                    const fileType = event.target?.files[0].type.slice(event.target?.files[0].type.indexOf("/") + 1, event.target?.files[0].type.length); // if file.type = img/png -> fileType = png
                    const fileSize = event.target?.files[0].size;
                    const file = event.target?.files[0];
                    const reader = new FileReader();
                    reader.onload = () => {
                      setFieldValue("adressFiles", adressFilesArray.concat({ name: fileName, type: fileType, fileData: `${fileName}_${fileSize}_adressFiles.${fileType}`, size: fileSize }));
                      setFormValue((prevState) => ({
                        ...prevState,
                        [event.target.name]: values.adressFiles.concat({ docType: 'addressProof', name: fileName, type: fileType, fileData: `${fileName}_${fileSize}_adressFiles.${fileType}`, size: fileSize })
                      }));
                    };
                    reader.readAsDataURL(file);
                    uploadFile(file, "adressFiles");
                  }
                }}
                arrayValues={values.adressFiles}
                errors={errors.adressFiles}
                touched={touched.adressFiles}
                setFormValue={setFormValue}
                formValue={formValue}
                setFieldValue={setFieldValue}
                eventToReset={eventToReset}
              />

              {/* Upload ID Legal Representant */}
              <UploadFile
                identificator="representantIdFiles"
                label={t('signUpBusiness.uploadID')}
                onInput={(event) => {
                  setEventToReset(event.target);
                  if (!values.representantIdFiles) {
                    values.representantIdFiles = [];
                  }
                  if (values.representantIdFiles.length >= 1) {
                    values.representantIdFiles = [];
                  } // reset array after wrong upload
                  if (formValue.representantIdFiles.length >= 1) {
                    formValue.representantIdFiles.length = [];
                  } // reset array after wrong upload
                  const representantIdFilesArray = values.representantIdFiles;
                  if (event.target?.files[0]) {
                    const fileName = strNoAccentNoSpecialChar(event.target?.files[0].name);
                    const fileType = event.target?.files[0].type.slice(event.target?.files[0].type.indexOf("/") + 1, event.target?.files[0].type.length); // if file.type = img/png -> fileType = png
                    const fileSize = event.target?.files[0].size;
                    const file = event.target?.files[0];
                    const reader = new FileReader();
                    reader.onload = () => {
                      setFieldValue("representantIdFiles", representantIdFilesArray.concat({ name: fileName, type: fileType, fileData: `${fileName}_${fileSize}_representantIdFiles.${fileType}`, size: fileSize }));
                      setFormValue((prevState) => ({
                        ...prevState,
                        [event.target.name]: values.representantIdFiles.concat({ docType: 'cni', name: fileName, type: fileType, fileData: `${fileName}_${fileSize}_representantIdFiles.${fileType}`, size: fileSize })
                      }));
                    };
                    reader.readAsDataURL(file);
                    uploadFile(file, "representantIdFiles");
                  }
                }}
                arrayValues={values.representantIdFiles}
                errors={errors.representantIdFiles}
                touched={touched.representantIdFiles}
                setFormValue={setFormValue}
                formValue={formValue}
                setFieldValue={setFieldValue}
                eventToReset={eventToReset}
              />

              {/* Toggle buttons group */}
              {formValue.isSoleTrader ? null
                : (
                  <>
                    <ToggleSwitch
                      id="enabledShareholder"
                      nameSwitch="enabledShareholder"
                      checked={enabledShareholder}
                      label={t("signUpInfos.shareholder")}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.checked
                        }));
                        setEnabledShareholder(e.target.checked);
                      }}
                    />

                    <ToggleSwitch
                      id="enabledAdministrator"
                      nameSwitch="enabledAdministrator"
                      checked={enabledAdministrator}
                      label={t("signUpInfos.administrator")}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.checked
                        }));
                        setEnabledAdministrator(e.target.checked);
                      }}
                    />
                  </>
                )}

              <div className="w-full flex flex-col-reverse md:flex-row justify-center items-center my-6">
                <button
                  type="button"
                  className="border border-grey-light mt-4 md:mt-0 bg-white py-2 px-4 rounded-md shadow-sm text-xxms font-medium text-black hover:bg-orange-dark hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange"
                  onClick={() => {
                    setStep(step - 1);
                    window.scrollTo(0, 0);
                  }}
                >
                  {t("previous")}
                </button>
                <button
                  type="submit"
                  className="border border-grey-light md:ml-3 inline-flex justify-center py-2 px-4 shadow-sm text-xxms font-medium rounded-md text-black bg-white hover:bg-orange-dark hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange"
                >
                  {t("next")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <ModalFormBusiness showModal={openModal} hideModal={hideModal} simple />
    </div>
  );
};

export default InfosForm;
