import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { Formik, Form } from 'formik';
import * as Yup from "yup";
import LoginForm from '../../components/LoginForm';
import { getSellersEmailLaunched } from '../../store/reducer';
import { loginLaunched } from '../../components/App/reducer';

export const SignInPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // Form data
  const initialValues = {
    email: '',
    password: '',
  };

  const [formValue, setFormValue] = useState(
    {
      email: initialValues.email,
      password: initialValues.password
    }
  );

  // Form Validation Schema
  const ValidationSchema = Yup.object({
    email: Yup.string()
      .required(t("signInForm.emailRequired"))
      .email(t("signInForm.emailType")),
    password: Yup.string()
      .required(t("signInForm.passwordRequired"))
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-])[A-Za-z\d$&+,:;=?@#|'<>.^*()%!-]{8,}$/,
        t("signInForm.passwordType")
      ),
  });

  // Form Submitting Function to check if seller is confirmed by Operator
  const login = () => {
    dispatch(getSellersEmailLaunched({ formEmail }));
  };

  // Check if seller is confirmed by Operator
  const [formEmail, setFormEmail] = useState();
  const [errorMessage, setErrorMessage] = useState(null);

  const { sellersEmail, getSellersEmailLoading } = useSelector((state) => (
    {
      sellersEmail: state.getIn(['store', 'sellersEmail']),
      getSellersEmailLoading: state.getIn(['store', 'getSellersEmailLoading'])
    }
  ));

  const isInitialMount = useRef(true); // Important , see UseEffect
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return null;
    } // At first mount : no effect.
    if (sellersEmail !== null && getSellersEmailLoading === false) {
      if (sellersEmail[0]?.isConfirmedByOperator === true) {
        dispatch(loginLaunched({ formValue }));
      } else {
        setErrorMessage(t("signInForm.isNotConfirmed"));
      }
    }
    return null;
  }, [getSellersEmailLoading]);

  return (
    <div className="bg-signin bg-cover h-screen w-full">
      <div className="min-h-full flex flex-col justify-center md:py-12 sm:px-6 lg:px-8">
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema}
          onSubmit={login}
        >
          {({ errors, touched, values, handleChange }) => (
            <Form className="bg-white sm:mx-auto sm:w-full sm:max-w-lg shadow">
              <LoginForm
                values={values}
                setFormValue={setFormValue}
                errors={errors}
                handleChange={handleChange}
                setFormEmail={setFormEmail}
                setErrorMessage={setErrorMessage}
                errorMessage={errorMessage}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default SignInPage;
