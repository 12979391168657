import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CheckIcon } from '@heroicons/react/outline';

const SignUpInfo = ({ setShowInfo }) => {
  const { t } = useTranslation();

  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-lg">
      <h2 className="text-white text-sm font-sans md:text-m font-semibold text-center py-4 md:py-8">{t("signUpForm.title")}</h2>
      <div className="w-full mx-auto">
        <div className="mx-auto w-5/6 flex flex-col justify-center items-center">
          <div className="bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
            <div>
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
              </div>
              <div className="mt-3 sm:mt-5 space-y-1">
                <p className="text-center text-s font-medium text-black">
                  {t("signUpIntro.information")}
                </p>
                <div className="mt-2">
                  <p className="text-xss text-black-light leading-tight">
                    {t("signUpIntro.subTitle")}
                  </p>
                  <div className="mt-2 text-xs text-black-light">
                    <ul className="list-disc pl-5 space-y-1">
                      <li>{t("signUpIntro.fileAdressProof")}</li>
                      <li>{t("signUpIntro.fileIDCard")}</li>
                      <li>{t("signUpIntro.fileCompAdressProof")}</li>
                      <li>{t("signUpIntro.fileRegistery")}</li>
                      <li>{t("signUpIntro.fileStatute")}</li>
                    </ul>
                    <p className="font-medium pt-2">{t("signUpIntro.soleTraderInfo")}</p>
                  </div>
                  <p className="mt-2 text-xss text-black-light">
                    {t("signUpIntro.formats")}
                  </p>
                  <p className="text-xss text-black-light">
                    {t("signUpIntro.size")}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <p className="text-xxms text-black-light">
                {t("signUpIntro.needMoreInfo")}
              </p>
              <p className="text-xxms text-black-light space-x-1">
                <span>{t("signUpIntro.contactUs")}</span>
                <span>{process.env.REACT_APP_OPERATOR_MAIL}</span>
              </p>
            </div>
            <div className="mt-5 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4">
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-xxms font-medium text-grey-lightDark hover:bg-grey hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-grey-extraDark"
              >
                <Link to="/">
                  {t("signUpIntro.cancel")}
                </Link>
              </button>
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent py-2 px-4 border border-transparent shadow-sm text-xxms font-medium rounded-md text-white bg-orange-dark hover:bg-orange-chocolate focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange"
                onClick={() => setShowInfo(false)}
              >
                {t("signUpIntro.go")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpInfo;
