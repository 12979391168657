import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { ToggleSwitch } from '../../ToggleSwitch';
import countriesJSON from '../../../utils/countries.json';
import { ModalFormBusiness } from '../../Modal/ModalFormBusiness';
import { strNoAccentNoSpecialChar } from '../../../utils/format';
import { UploadFile } from '../../UploadFile';
import { uploadFileOnboardingLaunched } from '../../../store/reducer';

const ShareHolderForm3 = ({ formValue, setFormValue, validate }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // toggles states
  const [enabledShareholder3, setEnabledShareholder3] = useState(false);
  const [enabledAdministrator3, setEnabledAdministrator3] = useState(false);

  // informations modale :
  const [openModal, setOpenModal] = useState(false);

  // input value state to Reset
  const [eventToReset, setEventToReset] = useState(null);

  const hideModal = () => {
    setOpenModal(false);
  };

  // Form data
  const initialValues = {
    shareholder3lastName: formValue.shareholder3lastName,
    shareholder3firstName: formValue.shareholder3firstName,
    shareholder3birthday: formValue.shareholder3birthday,
    shareholder3nationality: formValue.shareholder3nationality,
    shareholder3birthCountry: formValue.shareholder3birthCountry,
    shareholder3birthCity: formValue.shareholder3birthCity,
    shareholder3email: formValue.shareholder3email,
    shareholder3adress: formValue.shareholder3adress,
    shareholder3zipCode: formValue.shareholder3zipCode,
    shareholder3city: formValue.shareholder3city,
    shareholder3country: formValue.shareholder3country,
    // shareholder3identityFiles: formValue.shareholder3identityFiles,
    shareholder3adressFiles: formValue.shareholder3adressFiles,
    shareholder3declaration: formValue.shareholder3declaration,
    enabledShareholder3: formValue.enabledShareholder3,
    enabledAdministrator3: formValue.enabledAdministrator3
  };

  // Form Submitting Function
  const signup = () => {
    window.scrollTo(0, 0);
    // set que ça refermer le formulaire et faire deux autres fonctions identiques avec des states différents
  };

  // Form Validation Schema
  const validationSchema = Yup.object().shape({
    shareholder3lastName: Yup.string()
      .required(t("firstNameRequired"))
      .nullable(),
    shareholder3firstName: Yup.string()
      .required(t("lastNameRequired"))
      .nullable(),
    shareholder3birthday: Yup.date()
      .required(t("signUpInfos.dateOfBirthRequired"))
      .nullable(),
    shareholder3nationality: Yup.string()
      .required(t("signUpInfos.nationalityRequired"))
      .nullable(),
    shareholder3birthCountry: Yup.string()
      .required(t("signUpInfos.birthCountryRequired"))
      .nullable(),
    shareholder3birthCity: Yup.string()
      .required(t("signUpInfos.birthCityRequired"))
      .nullable(),
    shareholder3email: Yup.string()
      .required(t("signUpForm.emailRequired"))
      .email(t("signUpForm.emailType"))
      .nullable(),
    shareholder3adress: Yup.string()
      .required(t("signUpInfos.adressRequired"))
      .nullable(),
    shareholder3zipCode: Yup.number()
      .required(t("signUpInfos.cpRequired"))
      .positive()
      .integer()
      .nullable(),
    shareholder3city: Yup.string()
      .required(t("signUpInfos.cityRequired"))
      .nullable(),
    shareholder3country: Yup.string()
      .required(t("signUpInfos.countryRequired"))
      .nullable(),
    // shareholder3identityFiles: Yup.array()
    //   .nullable(),
    shareholder3adressFiles: Yup.array()
      .nullable(),
    shareholder3declaration: Yup.array()
      .nullable(),
    enabledShareholder3: Yup.bool()
      .nullable(),
    enabledAdministrator3: Yup.bool()
      .nullable()
  });
  const countriesMapped = countriesJSON;

  // tailwind reusable css
  const sectionContainer = "w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-4 md:mb-6";
  const sectionContainerSpaced = "w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-6";

  // Upload file on S3 function
  const uploadFile = (file, identificator) => {
    dispatch(uploadFileOnboardingLaunched({ file, identificator }));
  };
  return (
    <>
      <h2 className="text-sm md:text-s font-bold pb-2 w-full mx-auto lg:w-5/6">{t("shareholderForm.addShareholderForm")}</h2>
      <div className="w-full mx-auto">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validate={(values) => {
            const errors = {};
            // For validate file's input
            if (values.shareholder3adressFiles === null || values.shareholder3adressFiles.length === 0) {
              errors.shareholder1adressFiles = t("signUpBusiness.fileRequired");
            } else if (values.shareholder3declaration === null || values.shareholder3declaration.length === 0) {
              errors.shareholder3declaration = t("signUpBusiness.fileRequired");
            } else if (values.shareholder3adressFiles.length > 1) {
              errors.shareholder3adressFiles = t("signUpBusiness.numberOfFile");
            } else if (values.shareholder3declaration.length > 1) {
              errors.shareholder3declaration = t("signUpBusiness.numberOfFile");
            } else {
              values.shareholder3adressFiles.forEach((value) => {
                if (value.size > 3000000) {
                  errors.shareholder3adressFiles = (t("signUpBusiness.fileSize"));
                } else if (value.size < 32000) {
                  errors.shareholder3adressFiles = (t("signUpBusiness.fileToSmall"));
                }
              });
              values.shareholder3declaration.forEach((value) => {
                if (value.size > 3000000) {
                  errors.shareholder3declaration = (t("signUpBusiness.fileSize"));
                } else if (value.size < 32000) {
                  errors.shareholder3declaration = (t("signUpBusiness.fileToSmall"));
                }
              });
            }
            return errors;
          }}
          onSubmit={signup}
        >
          {({ errors, touched, values, setFieldValue, handleChange }) => (
            <Form className="mx-auto w-5/6 flex flex-col justify-center items-center">
              <div className={sectionContainerSpaced}>
                <div className="w-full">
                  <label className="block text-xxms font-medium">{`${t('lastName')}  *`}</label>
                  <Field
                    id="shareholder3lastName"
                    name="shareholder3lastName"
                    type="text"
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value
                      }));
                    }}
                    placeholder={t('lastName')}
                    className="w-full border-grey-light rounded text-xxms text-gray-500"
                  />
                  {errors.shareholder3lastName && touched.shareholder3lastName ? (
                    <p className="text-red-600 text-center md:text-left">{errors.shareholder3lastName}</p>
                  ) : null}
                </div>
              </div>

              <div className={sectionContainerSpaced}>
                <div className="w-full">
                  <label className="block text-xxms font-medium">{`${t('firstName')}  *`}</label>
                  <Field
                    id="shareholder3firstName"
                    name="shareholder3firstName"
                    type="text"
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value
                      }));
                    }}
                    placeholder={t('firstName')}
                    className="w-full border-grey-light rounded text-xxms text-gray-500"
                  />
                  {errors.shareholder3firstName && touched.shareholder3firstName ? (
                    <p className="text-red-600 text-center md:text-left">{errors.shareholder3firstName}</p>
                  ) : null}
                </div>
              </div>

              <div className={sectionContainerSpaced}>
                <div className="w-full">
                  <label className="block text-xxms font-medium">{`${t('signInForm.email')}  *`}</label>
                  <Field
                    id="shareholder3email"
                    name="shareholder3email"
                    type="email"
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value
                      }));
                    }}
                    placeholder="ex : antoine.dupont@company.com"
                    className="w-full border-grey-light rounded text-xxms text-gray-500"
                  />
                  {errors.shareholder3email && touched.shareholder3email ? (
                    <p className="text-red-600 text-center md:text-left">{errors.shareholder3email}</p>
                  ) : null}
                </div>
              </div>

              <div className={sectionContainer}>
                <div className="w-full md:flex">
                  {/* Birthdate field */}
                  <div className="w-full md:1/5 md:pr-4">
                    <label className="block text-xxms font-medium">{`${t('signUpInfos.dateOfBirth')}  *`}</label>
                    <Field
                      id="shareholder3birthday"
                      name="shareholder3birthday"
                      type="date"
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value
                        }));
                      }}
                      className="w-full border-grey-light rounded text-xxms text-gray-500"
                    />
                    {errors.shareholder3birthday && touched.shareholder3birthday ? (
                      <p className="text-red-600 text-center md:text-left">{errors.shareholder3birthday}</p>
                    ) : null}
                  </div>
                  {/* Nationality field */}
                  <div className="w-full md:w-4/5 mb-4 md:mb-0 mt-4 md:mt-0">
                    <label className="block text-xxms font-medium">{`${t('signUpInfos.nationality')}  *`}</label>
                    <select
                      id="shareholder3nationality"
                      name="shareholder3nationality"
                      defaultValue=""
                      type={values.shareholder3nationality}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value
                        }));
                      }}
                      className="w-full border-grey-light rounded text-xxms text-gray-500"
                    >
                      <option value="" disabled label={t("selectNationality")} />
                      {
                        countriesMapped.map((country) => (
                          <option key={country.num_code} value={country.alpha_2_code}>
                            {country.nationality}
                          </option>
                        ))
                      }
                    </select>
                    {errors.shareholder3nationality && touched.shareholder3nationality ? (
                      <p className="text-red-600 text-center md:text-left">{errors.shareholder3nationality}</p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className={sectionContainer}>
                <div className="w-full md:flex">
                  {/* BirthCountry field */}
                  <div className="w-full md:1/5 md:pr-4">
                    <label className="block text-xxms font-medium">{`${t('signUpInfos.birthCountry')}  *`}</label>
                    <select
                      id="shareholder3birthCountry"
                      name="shareholder3birthCountry"
                      defaultValue=""
                      type={values.shareholder3birthCountry}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value
                        }));
                      }}
                      className="w-full border-grey-light rounded text-xxms text-gray-500"
                    >
                      <option value="" disabled label={t("selectCountry")} />
                      {
                        countriesMapped.map((country) => (
                          <option key={country.alpha_2_code} value={country.alpha_2_code}>
                            {country.en_short_name}
                          </option>
                        ))
                      }
                    </select>
                    {errors.shareholder3birthCountry && touched.shareholder3birthCountry ? (
                      <p className="text-red-600 text-center md:text-left">{errors.shareholder3birthCountry}</p>
                    ) : null}
                  </div>
                  {/* birthCity  field */}
                  <div className="w-full md:w-4/5 mb-4 md:mb-0 mt-4 md:mt-0">
                    <label className="block text-xxms font-medium">{`${t('signUpInfos.birthCity')}  *`}</label>
                    <Field
                      id="shareholder3birthCity"
                      name="shareholder3birthCity"
                      type="text"
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value
                        }));
                      }}
                      className="w-full border-grey-light rounded text-xxms text-gray-500"
                    />
                    {errors.shareholder3birthCity && touched.shareholder3birthCity ? (
                      <p className="text-red-600 text-center md:text-left">{errors.shareholder3birthCity}</p>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className={sectionContainerSpaced}>
                <div className="w-full">
                  <label className="block text-xxms font-medium">{`${t('signUpInfos.adress')}  *`}</label>
                  <Field
                    id="shareholder3adress"
                    name="shareholder3adress"
                    type="text"
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value
                      }));
                    }}
                    placeholder={`ex : ${t("signUpInfos.adressPlaceholder")}`}
                    className="w-full border-grey-light rounded text-xxms text-gray-500"
                  />
                  {errors.shareholder3adress && touched.shareholder3adress ? (
                    <p className="text-red-600 text-center md:text-left">{errors.shareholder3adress}</p>
                  ) : null}
                </div>
              </div>

              <div className={sectionContainer}>
                <div className="w-full">
                  <select
                    id="shareholder3country"
                    name="shareholder3country"
                    defaultValue=""
                    value={values.shareholder3country}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value
                      }));
                    }}
                    className="w-full border-grey-light rounded text-xxs text-gray-500"
                  >
                    <option value="" disabled label={t("selectCountry")} />
                    {
                      countriesMapped.map((country) => (
                        <option key={country.alpha_3_code} value={country.alpha_2_code}>
                          {country.en_short_name}
                        </option>
                      ))
                    }
                  </select>
                  {errors.shareholder3country && touched.shareholder3country ? (
                    <p className="text-red-600 text-center md:text-left">{errors.shareholder3country}</p>
                  ) : null}
                </div>
              </div>

              <div className={sectionContainer}>
                <div className="w-full md:flex">
                  {/* ZipCode field */}
                  <div className="w-full md:1/5 md:pr-4">
                    <Field
                      id="shareholder3zipCode"
                      name="shareholder3zipCode"
                      type="text"
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: parseInt(e.target.value, 10)
                        }));
                      }}
                      placeholder="ex : 75000"
                      className="w-full border-grey-light rounded text-xxms text-gray-500"
                    />
                    {errors.shareholder3zipCode && touched.shareholder3zipCode ? (
                      <p className="text-red-600 text-center md:text-left">{errors.shareholder3zipCode}</p>
                    ) : null}
                  </div>
                  {/* City field */}
                  <div className="w-full md:w-4/5 mb-4 md:mb-0 mt-4 md:mt-0">
                    <Field
                      id="shareholder3city"
                      name="shareholder3city"
                      type="text"
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value
                        }));
                      }}
                      placeholder="ex : Paris"
                      className="w-full border-grey-light rounded text-xxms text-gray-500"
                    />
                    {errors.shareholder3city && touched.shareholder3city ? (
                      <p className="text-red-600 text-center md:text-left">{errors.shareholder3city}</p>
                    ) : null}
                  </div>
                </div>
              </div>

              {/* Required documents section */}
              <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-2">
                <div className="w-full md:w-4/6 lg:w-3/4">
                  <div>
                    <span className="text-xxms font-medium">
                      {t("signUpInfos.obligatory")}
                    </span>
                    <button
                      type="button"
                      className="pt-2"
                      onClick={() => {
                        setOpenModal(!openModal);
                      }}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-4" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="w-full mb-2 text-xxs italic flex flex-col space-y-1">
                <span>{t("signUpInfos.infoFile")}</span>
                <span>{t("signUpInfos.tipFile")}</span>
              </div>
              {/* Upload ID */}
              {/* <UploadFile
                identificator="shareholder3identityFiles"
                label={t('uploadFileID')}
                onChange={(event) => {
                  if (!values.shareholder3identityFiles) {
                    values.shareholder3identityFiles = [];
                  }
                  const shareholder3identityFilesArray = values.shareholder3identityFiles;
                  if (event.target?.files[0]) {
                    const fileName = event.target?.files[0].name;
                    const fileType = event.target?.files[0].type;
                    const fileSize = event.target?.files[0].size;
                    const file = event.target?.files[0];
                    const reader = new FileReader();
                    reader.onload = () => {
                      setFieldValue("shareholder3identityFiles", shareholder3identityFilesArray.concat({ name: fileName, type: fileType, fileData: reader.result, size: fileSize }));
                      setFormValue((prevState) => ({
                        ...prevState,
                        [event.target.name]: values.shareholder3identityFiles.concat({ docType: 'cni', name: fileName, type: fileType, fileData: reader.result, size: fileSize })
                      }));
                    };
                    reader.readAsDataURL(file);
                  }
                }}
                arrayValues={values.shareholder3identityFiles}
                errors={errors.shareholder3identityFiles}
                touched={touched.shareholder3identityFiles}
                setFormValue={setFormValue}
                formValue={formValue}
                setFieldValue={setFieldValue}
              /> */}
              {/* Upload adress proof */}
              <UploadFile
                identificator="shareholder3adressFiles"
                label={t('adressFiles')}
                onInput={(event) => {
                  setEventToReset(event.target);
                  if (!values.shareholder3adressFiles) {
                    values.shareholder3adressFiles = [];
                  }
                  if (values.shareholder3adressFiles.length >= 1) {
                    values.shareholder3adressFiles = [];
                  } // reset array after wrong upload
                  if (formValue.shareholder3adressFiles.length >= 1) {
                    formValue.shareholder3adressFiles.length = [];
                  } // reset array after wrong upload
                  const shareholder3adressFilesArray = values.shareholder3adressFiles;
                  if (event.target?.files[0]) {
                    const fileName = strNoAccentNoSpecialChar(event.target?.files[0].name);
                    const fileType = event.target?.files[0].type.slice(event.target?.files[0].type.indexOf("/") + 1, event.target?.files[0].type.length); // if file.type = img/png -> fileType = png
                    const fileSize = event.target?.files[0].size;
                    const file = event.target?.files[0];
                    const reader = new FileReader();
                    reader.onload = () => {
                      setFieldValue("shareholder3adressFiles", shareholder3adressFilesArray.concat({ name: fileName, type: fileType, fileData: `${fileName}_${fileSize}_shareholder3adressFiles.${fileType}`, size: fileSize }));
                      setFormValue((prevState) => ({
                        ...prevState,
                        [event.target.name]: values.shareholder3adressFiles.concat({ docType: 'addressProof', name: fileName, type: fileType, fileData: `${fileName}_${fileSize}_shareholder3adressFiles.${fileType}`, size: fileSize })
                      }));
                    };
                    reader.readAsDataURL(file);
                    uploadFile(file, "shareholder3adressFiles");
                  }
                }}
                arrayValues={values.shareholder3adressFiles}
                errors={errors.shareholder3adressFiles}
                touched={touched.shareholder3adressFiles}
                setFormValue={setFormValue}
                formValue={formValue}
                setFieldValue={setFieldValue}
                eventToReset={eventToReset}
              />

              {/* Upload shareholder declaration */}
              <UploadFile
                identificator="shareholder3declaration"
                label={t('shareholderForm.shareholderDeclaration')}
                onInput={(event) => {
                  setEventToReset(event.target);
                  if (!values.shareholder3declaration) {
                    values.shareholder3declaration = [];
                  }
                  if (values.shareholder3declaration.length >= 1) {
                    values.shareholder3declaration = [];
                  } // reset array after wrong upload
                  if (formValue.shareholder3declaration.length >= 1) {
                    formValue.shareholder3declaration.length = [];
                  } // reset array after wrong upload
                  const shareholder3declarationArray = values.shareholder3declaration;
                  if (event.target?.files[0]) {
                    const fileName = strNoAccentNoSpecialChar(event.target?.files[0].name);
                    const fileType = event.target?.files[0].type.slice(event.target?.files[0].type.indexOf("/") + 1, event.target?.files[0].type.length); // if file.type = img/png -> fileType = png
                    const fileSize = event.target?.files[0].size;
                    const file = event.target?.files[0];
                    const reader = new FileReader();
                    reader.onload = () => {
                      setFieldValue("shareholder3declaration", shareholder3declarationArray.concat({ name: fileName, type: fileType, fileData: `${fileName}_${fileSize}_shareholder3declaration.${fileType}`, size: fileSize }));
                      setFormValue((prevState) => ({
                        ...prevState,
                        [event.target.name]: values.shareholder3declaration.concat({ docType: 'shareholder', name: fileName, type: fileType, fileData: `${fileName}_${fileSize}_shareholder3declaration.${fileType}`, size: fileSize })
                      }));
                    };
                    reader.readAsDataURL(file);
                    uploadFile(file, "shareholder3declaration");
                  }
                }}
                arrayValues={values.shareholder3declaration}
                errors={errors.shareholder3declaration}
                touched={touched.shareholder3declaration}
                setFormValue={setFormValue}
                formValue={formValue}
                setFieldValue={setFieldValue}
                eventToReset={eventToReset}
              />

              {/* Toggle buttons group */}
              <ToggleSwitch
                id="enabledShareholder3"
                nameSwitch="enabledShareholder3"
                checked={enabledShareholder3}
                label={t("signUpInfos.shareholder")}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.checked
                  }));
                  setEnabledShareholder3(e.target.checked);
                }}
              />

              <ToggleSwitch
                id="enabledAdministrator3"
                nameSwitch="enabledAdministrator3"
                checked={enabledAdministrator3}
                label={t("signUpInfos.administrator")}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.checked
                  }));
                  setEnabledAdministrator3(e.target.checked);
                }}
              />

              <div className="w-full flex flex-col-reverse md:flex-row justify-center items-center my-6">
                <button
                  type="button"
                  className="border border-grey-light bg-white mt-4 md:mt-0 py-2 px-4 rounded-md shadow-sm text-xxms font-medium text-black hover:bg-orange-dark hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange"
                  onClick={() => validate(false)}
                >
                  {t("shareholderForm.goBack")}
                </button>
                <button
                  type="submit"
                  onClick={() => validate(false)}
                  disabled={(Object.keys(errors).length !== 0) ? "disabled" : ""}
                  className="border border-grey-light md:ml-3 inline-flex justify-center py-2 px-4 shadow-sm text-xxms font-medium rounded-md text-black bg-white hover:bg-orange-dark hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange"
                >
                  {t("shareholderForm.add")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <ModalFormBusiness showModal={openModal} hideModal={hideModal} simple shareholder />
    </>
  );
};

export default ShareHolderForm3;
