import React, { Fragment, useState } from "react";
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronUpIcon, ChevronDownIcon, MenuIcon, XIcon } from '@heroicons/react/outline';
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { logoutLaunched } from '../App/reducer';

const BurgerMenu = ({ authenticated, sellerInfo }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isShowing, setIsShowing] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const resetShows = () => {
    setIsOpen(false);
    setIsShowing(false);
  };

  const logOut = () => {
    dispatch(logoutLaunched());
    history.push('/');
  };

  return (
    <Disclosure as="nav">
      <div className={`mr-8 ${isOpen ? "hidden" : "block"}`}>
        <MenuIcon
          className="block h-6 w-6 text-grey-light lg:hidden"
          aria-hidden="true"
          onClick={() => setIsOpen(true)}
        />
      </div>
      <Transition
        as={Fragment}
        show={isOpen}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 transform translate-x-0"
        enterTo="opacity-100 transform translate-x-1"
        leave="transition ease-in duration-200"
        leaveFrom="opacity-100 transform translate-x-0"
        leaveTo="opacity-0 transform translate-x-1"
      >
        <div className="w-screen h-screen m-0 p-4 bg-white top-0 left-0">
          <nav className="bg-white">
            <div className="flex justify-end align-start pr-8">
              <button
                type="button"
                onClick={() => setIsOpen(false)}
                className="text-gray-500 w-4 h-4"
              >
                <XIcon className="block h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="w-full">
              {authenticated
                ? (
                  <Disclosure.Panel as="ul" className="w-11/12 mt-4 bg-white">
                    <Disclosure.Button
                      as="a"
                      href="/home"
                      className={`appearance-none py-2 text-xms ${(location.pathname === "/home") ? "bg-orange-dark border-orange-chocolate text-white block pl-3 pr-4 border-l-4 font-medium" : "text-s text-gray-500"}`}
                    >
                      Dashboard
                    </Disclosure.Button>
                    <li className="flex flex-col">
                      <Disclosure.Button
                        as="a"
                        onClick={() => setIsShowing(!isShowing)}
                        className={`appearance-none py-2 text-xms flex items-center justify-between ${(isShowing || location.pathname === "/orders" || location.pathname === "/preparation-orders" || location.pathname === "/incident" || location.pathname === "/paid-orders") ? "bg-orange-dark border-orange-chocolate text-white block pl-3 pr-4 border-l-4 font-medium" : "text-s text-gray-500"}`}
                      >
                        {t("orders")}
                        {(isShowing)
                          ? (
                            <button
                              type="button"
                              onClick={() => setIsShowing(false)}
                            >
                              <ChevronUpIcon
                                className="h-5 w-5 md:hidden text-white"
                              />
                            </button>
                          )
                          : (
                            <button
                              type="button"
                              onClick={() => setIsShowing(true)}
                            >
                              <ChevronDownIcon
                                className="h-5 w-5 md:hidden text-grey flex-end"
                              />
                            </button>
                          )}
                      </Disclosure.Button>
                      <Transition
                        as={Fragment}
                        show={isShowing}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Disclosure.Panel as="li" className="w-11/12 mt-1 flex flex-col">
                          <Disclosure.Button
                            as="a"
                            href="/orders"
                            onClick={resetShows}
                            className={`appearance-none py-2 text-xms ${(location.pathname === "/orders") ? "bg-orange-dark border-orange-chocolate text-white block pl-3 pr-4 border-l-4 font-medium" : "text-s text-gray-500"}`}
                          >
                            {t("orderPage.newOrders")}
                          </Disclosure.Button>
                          <Disclosure.Button
                            as="a"
                            href="/preparation-orders"
                            onClick={resetShows}
                            className={`appearance-none py-2 text-xms ${(location.pathname === "/preparation-orders") ? "bg-orange-dark border-orange-chocolate text-white block pl-3 pr-4 border-l-4 font-medium" : "text-s text-gray-500"}`}
                          >
                            {t("orderPage.preparation")}
                          </Disclosure.Button>
                          <Disclosure.Button
                            as="a"
                            href="/not-shipped"
                            onClick={resetShows}
                            className={`appearance-none py-2 text-xms ${(location.pathname === "/not-shipped") ? "bg-orange-dark border-orange-chocolate text-white block pl-3 pr-4 border-l-4 font-medium" : "text-s text-gray-500"}`}
                          >
                            {t("orderPage.notShipped")}
                          </Disclosure.Button>
                          <Disclosure.Button
                            as="a"
                            href="/waiting-delivery"
                            onClick={resetShows}
                            className={`appearance-none py-2 text-xms ${(location.pathname === "/waiting-delivery") ? "bg-orange-dark border-orange-chocolate text-white block pl-3 pr-4 border-l-4 font-medium" : "text-s text-gray-500"}`}
                          >
                            {t("orderPage.waitingDelivery")}
                          </Disclosure.Button>
                          <Disclosure.Button
                            as="a"
                            href="/delivery"
                            onClick={resetShows}
                            className={`appearance-none py-2 text-xms ${(location.pathname === "/delivery") ? "bg-orange-dark border-orange-chocolate text-white block pl-3 pr-4 border-l-4 font-medium" : "text-s text-gray-500"}`}
                          >
                            {t("orderPage.delivered")}
                          </Disclosure.Button>
                          <Disclosure.Button
                            as="a"
                            href="/incident"
                            onClick={resetShows}
                            className={`appearance-none py-2 text-xms capitalize ${(location.pathname === "/incident") ? "bg-orange-dark border-orange-chocolate text-white block pl-3 pr-4 border-l-4 font-medium" : "text-s text-gray-500"}`}
                          >
                            {t("orderPage.incident")}
                          </Disclosure.Button>
                        </Disclosure.Panel>
                      </Transition>
                    </li>
                    <div className="flex flex-col">
                      <Disclosure.Button
                        as="a"
                        href="/products"
                        onClick={() => setIsOpen(false)}
                        className={`appearance-none py-2 text-xms ${(location.pathname === "/products") ? "bg-orange-dark border-orange-chocolate text-white block pl-3 pr-4 border-l-4 font-medium" : "text-s text-gray-500"}`}
                      >
                        {t("catalog")}
                      </Disclosure.Button>
                      <Disclosure.Button
                        as="a"
                        href="/imports"
                        onClick={() => setIsOpen(false)}
                        className={`appearance-none py-2 text-xms ${(location.pathname === "/imports") ? "bg-orange-dark border-orange-chocolate text-white block pl-3 pr-4 border-l-4 font-medium" : "text-s text-gray-500"}`}
                      >
                        Imports
                      </Disclosure.Button>
                      <Disclosure.Button
                        as="a"
                        href="/shipping-mode"
                        onClick={() => setIsOpen(false)}
                        className={`appearance-none py-2 text-xms capitalize ${(location.pathname === "/shipping-mode") ? "bg-orange-dark border-orange-chocolate text-white block pl-3 pr-4 border-l-4 font-medium" : "text-s text-gray-500"}`}
                      >
                        {t("logistics")}
                      </Disclosure.Button>
                      <Disclosure.Button
                        as="a"
                        href="/account"
                        onClick={() => setIsOpen(false)}
                        className={`appearance-none py-2 text-xms capitalize ${(location.pathname === "/account") ? "bg-orange-dark border-orange-chocolate text-white block pl-3 pr-4 border-l-4 font-medium" : "text-s text-gray-500"}`}
                      >
                        {t("myAccount")}
                      </Disclosure.Button>
                      <Disclosure.Button
                        as="a"
                        className="appearance-none py-2 text-xms capitalize text-xms font-medium border-gray-200 border-opacity-25 border-t"
                      >
                        {sellerInfo}
                      </Disclosure.Button>
                      <button
                        type="button"
                        onClick={() => { logOut(); setIsOpen(false); }}
                        className="appearance-none py-2 flex flex-start text-xms capitalize text-xms text-gray-500 font-medium"
                      >
                        {t("logout")}
                      </button>
                    </div>
                  </Disclosure.Panel>
                )
                : (
                  <Disclosure.Panel as="ul" className="w-11/12 mt-4 flex flex-col pt-24 w-11/12 mt-4 bg-white">
                    <Disclosure.Button
                      as="a"
                      href="/login"
                      onClick={() => { setIsOpen(false); }}
                      className={`appearance-none py-2 text-xms text-center ${(location.pathname === "/login") ? "bg-orange-dark border-orange-chocolate text-white block pl-3 pr-4 border-l-4 font-medium" : "text-s text-gray-500"}`}
                    >
                      {t("connect")}
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      href="/signup"
                      onClick={() => setIsOpen(false)}
                      className={`appearance-none pb-28 py-2 text-xms text-center ${(location.pathname === "/signup") ? "bg-orange-dark border-orange-chocolate text-white block pl-3 pr-4 border-l-4 font-medium" : "text-s text-gray-500"}`}
                    >
                      {t("subscribe")}
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      href={`${process.env.REACT_APP_ZAWEMA_MARKETPLACE}`}
                      onClick={() => setIsOpen(false)}
                      className="appearance-none capitalize text-center py-2 text-xms text-s text-gray-500 border-gray-200 border-opacity-25 border-t"
                    >
                      {t("goBackMarketplace")}
                    </Disclosure.Button>
                  </Disclosure.Panel>
                )}
            </div>
          </nav>
        </div>
      </Transition>
    </Disclosure>
  );
};

export default BurgerMenu;
