import React from 'react';
import { NavLink } from 'react-router-dom';

export const CustomNavLink = ({ icon, to, text, isActive }) => (
  <NavLink
    className="text-grey-middle pl-2 flex capitalize"
    to={to}
    isActive={isActive}
    activeStyle={{
      fontWeight: "bold",
      color: "#F5B091",
    }}
  >
    {icon}
    {text}
  </NavLink>
);

export default CustomNavLink;
