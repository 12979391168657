import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { Link, useLocation } from 'react-router-dom';
import { FilterResult } from './FilterResult/index';
import { StatusTabs } from './StatusTabs/index';
import { FilterResultMobile } from './FilterResult/FilterResultMobile';
import usePagination from "../../hooks/usePagination";
import { capitalizeFirst } from '../../utils/format';

export const OrdersNavigation = ({ PageSize, currentPage, infosOrder, validationModal, validation, shippingModal, cancelModal, refundModal, incidentModal, incident }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  // Pagination
  const [currentData, setCurrentData] = useState([]);
  useEffect(() => {
    if (infosOrder) {
      setCurrentData(infosOrder);
    }
  }, [infosOrder]);

  const currentTableData = usePagination({ currentData, PageSize, currentPage });

  return (
    <>
      <div className="md:pt-6 lg:pt-0 hidden md:block w-full">
        <StatusTabs />
        {
          (infosOrder?.length === 0)
          && (
            <div className="text-grey-middle text-xxms font-normal mt-5 pl-5">
              {`${(pathname === "/orders") ? `${capitalizeFirst(t("orderPage.noOrderToDisplay"))}` : ''}`}
              {`${(pathname === "/preparation-orders") ? `${capitalizeFirst(t("orderPage.noOrderToPrepare"))}` : ''}`}
              {`${(pathname === "/not-shipped") ? `${capitalizeFirst(t("orderPage.noOrderToShip"))}` : ''}`}
              {`${(pathname === "/delivery") ? `${capitalizeFirst(t("orderPage.noOrderDelivered"))}` : ''}`}
              {`${(pathname === "/incident") ? `${capitalizeFirst(t("orderPage.noOrderIncident"))}` : ''}`}
              {`${(pathname === "/waiting-delivery") ? `${capitalizeFirst(t("orderPage.noOrderWaitingDelivery"))}` : ''}`}
            </div>
          )
        }
      </div>
      {
        (infosOrder?.length > 0)
        && (
          <div className="hidden md:flex md:flex-col md:my-10">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden border-b">
                  <table className="min-w-full divide-y divide-gray-200 table-auto">
                    <thead className="border-t">
                      <tr>
                        <th
                          scope="col"
                          className="px-2 py-4 text-left text-xxms font-medium text-black-primary tracking-wider"
                        >
                          {t("orderPage.orderNumber")}
                        </th>
                        <th
                          scope="col"
                          className="px-2 py-4 text-left text-xxms font-medium text-black-primary tracking-wider"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          className="px-2 py-4 text-left text-xxms font-medium text-black-primary tracking-wider"
                        >
                          {t("orderPage.products")}
                        </th>
                        <th
                          scope="col"
                          className="px-2 py-4 text-left text-xxms font-medium text-black-primary tracking-wider"
                        >
                          {t("orderPage.quantity")}
                        </th>
                        <th
                          scope="col"
                          className="px-2 py-4 text-left text-xxms font-medium text-black-primary tracking-wider"
                        >
                          {t("orderPage.amountOrder")}
                        </th>
                        <th
                          scope="col"
                          className="px-2 py-4 text-left text-xxms font-medium text-black-primary tracking-wider"
                        >
                          {t("orderPage.customer")}
                        </th>
                        <th
                          scope="col"
                          className="px-2 py-4 text-left text-xxms font-medium text-black-primary tracking-wider"
                        >
                          {t("email")}
                        </th>
                        <th
                          scope="col"
                          className={`px-2 py-4 text-left text-xxms font-medium text-black-primary tracking-wider ${(pathname === "/incident" || pathname === "/delivery" || pathname === "/waiting-delivery") ? "" : "hidden"}`}
                        >
                          {t("orderPage.trackingNumber")}
                        </th>
                        <th
                          scope="col"
                          className="px-2 py-4 text-left text-xxms font-medium text-black-primary tracking-wider"
                        >
                          {t("orderPage.status")}
                        </th>
                        <th
                          scope="col"
                          className="px-2 py-4 text-left text-xxms font-medium text-black-primary tracking-wider block print:hidden"
                        >
                          {t("orderPage.actions")}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {
                        currentTableData.map((info) => (
                          <FilterResult key={`${info.id}-${info.orderNumber}-${info.totalOfferPrice}`} infoOrder={info} validationModal={validationModal} validation={validation} shippingModal={shippingModal} cancelModal={cancelModal} refundModal={refundModal} incidentModal={incidentModal} incident={incident} />
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )
      }
      <div className="md:hidden">
        <div className="ml-4 mt-5 mb-2 text-s font-medium capitalize">
          {`${(pathname === "/orders") ? ` ${t("orders")}` : ''}`}
          {`${(pathname === "/preparation-orders") ? ` ${t("orderPage.preparation")}` : ''}`}
          {`${(pathname === "/not-shipped") ? ` ${t("orderPage.notShipped")}` : ''}`}
          {`${(pathname === "/delivery") ? ` ${t("orderPage.delivered")}` : ''}`}
          {`${(pathname === "/incident") ? ` ${t("orderPage.incident")}` : ''}`}
          {`${(pathname === "/waiting-delivery") ? ` ${t("orderPage.waitingDelivery")}` : ''}`}
        </div>
        {
          (infosOrder?.length === 0)
          && (
            <div className="text-grey-middle text-xxms font-normal mt-2 pl-5 capitalizeFirst">
              {`${(pathname === "/orders") ? ` ${capitalizeFirst(t("orderPage.noOrderToDisplay"))}` : ''}`}
              {`${(pathname === "/preparation-orders") ? ` ${capitalizeFirst(t("orderPage.noOrderToPrepare"))}` : ''}`}
              {`${(pathname === "/not-shipped") ? ` ${capitalizeFirst(t("orderPage.noOrderToShip"))}` : ''}`}
              {`${(pathname === "/delivery") ? ` ${capitalizeFirst(t("orderPage.noOrderDelivered"))}` : ''}`}
              {`${(pathname === "/incident") ? ` ${capitalizeFirst(t("orderPage.noOrderIncident"))}` : ''}`}
              {`${(pathname === "/waiting-delivery") ? ` ${capitalizeFirst(t("orderPage.noOrderWaitingDelivery"))}` : ''}`}
            </div>
          )
        }
        {
          (infosOrder?.length > 0)
          && currentTableData.map((info) => (
            <div key={`${info.orderNumber}-${info.id}-${info.totalOfferPrice}`} className="flex flex-col w-full text-xxms">
              <FilterResultMobile infoOrder={info} validationModal={validationModal} validation={validation} shippingModal={shippingModal} cancelModal={cancelModal} refundModal={refundModal} incidentModal={incidentModal} incident={incident} />
            </div>
          ))
        }
      </div>
    </>
  );
};

export default OrdersNavigation;
