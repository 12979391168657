import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { ToggleSwitch } from '../../ToggleSwitch';
import countriesJSON from '../../../utils/countries.json';
import { ModalFormBusiness } from '../../Modal/ModalFormBusiness';
import { strNoAccentNoSpecialChar } from '../../../utils/format';
import { UploadFile } from '../../UploadFile';
import { uploadFileOnboardingLaunched } from '../../../store/reducer';

const ShareHolderForm1 = ({ formValue, setFormValue, validate }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // toggles states
  const [enabledShareholder1, setEnabledShareholder1] = useState(false);
  const [enabledAdministrator1, setEnabledAdministrator1] = useState(false);

  // input value state to Reset
  const [eventToReset, setEventToReset] = useState(null);

  // informations modale :
  const [openModal, setOpenModal] = useState(false);

  const hideModal = () => {
    setOpenModal(false);
  };

  // Form data
  const initialValues = {
    shareholder1lastName: formValue.shareholder1lastName,
    shareholder1firstName: formValue.shareholder1firstName,
    shareholder1birthday: formValue.shareholder1birthday,
    shareholder1nationality: formValue.shareholder1nationality,
    shareholder1birthCountry: formValue.shareholder1birthCountry,
    shareholder1birthCity: formValue.shareholder1birthCity,
    shareholder1email: formValue.shareholder1email,
    shareholder1adress: formValue.shareholder1adress,
    shareholder1zipCode: formValue.shareholder1zipCode,
    shareholder1city: formValue.shareholder1city,
    shareholder1country: formValue.shareholder1country,
    // shareholder1identityFiles: formValue.shareholder1identityFiles,
    shareholder1adressFiles: formValue.shareholder1adressFiles,
    shareholder1declaration: formValue.shareholder1declaration,
    enabledShareholder1: formValue.enabledShareholder1,
    enabledAdministrator1: formValue.enabledAdministrator1
  };

  // Form Submitting Function
  const signup = () => {
    window.scrollTo(0, 0);
  };

  // Form Validation Schema
  const validationSchema = Yup.object().shape({
    shareholder1lastName: Yup.string()
      .required(t("firstNameRequired"))
      .nullable(),
    shareholder1firstName: Yup.string()
      .required(t("lastNameRequired"))
      .nullable(),
    shareholder1birthday: Yup.date()
      .required(t("signUpInfos.dateOfBirthRequired"))
      .nullable(),
    shareholder1nationality: Yup.string()
      .required(t("signUpInfos.nationalityRequired"))
      .nullable(),
    shareholder1birthCountry: Yup.string()
      .required(t("signUpInfos.birthCountryRequired"))
      .nullable(),
    shareholder1birthCity: Yup.string()
      .required(t("signUpInfos.birthCityRequired"))
      .nullable(),
    shareholder1email: Yup.string()
      .required(t("signUpForm.emailRequired"))
      .email(t("signUpForm.emailType"))
      .nullable(),
    shareholder1adress: Yup.string()
      .required(t("signUpInfos.adressRequired"))
      .nullable(),
    shareholder1zipCode: Yup.number()
      .required(t("signUpInfos.cpRequired"))
      .positive()
      .integer()
      .nullable(),
    shareholder1city: Yup.string()
      .required(t("signUpInfos.cityRequired"))
      .nullable(),
    shareholder1country: Yup.string()
      .required(t("signUpInfos.countryRequired"))
      .nullable(),
    // shareholder1identityFiles: Yup.array()
    //   .nullable(),
    shareholder1adressFiles: Yup.array()
      .nullable(),
    shareholder1declaration: Yup.array()
      .nullable(),
    enabledShareholder1: Yup.bool()
      .nullable(),
    enabledAdministrator1: Yup.bool()
      .nullable()
  });
  const countriesMapped = countriesJSON;

  // tailwind reusable css
  const sectionContainer = "w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-4 md:mb-6";
  const sectionContainerSpaced = "w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-6";

  // Upload file on S3 function
  const uploadFile = (file, identificator) => {
    dispatch(uploadFileOnboardingLaunched({ file, identificator }));
  };
  return (
    <>
      <h2 className="text-sm md:text-s font-bold w-full mx-auto lg:w-5/6 pb-2">{t("shareholderForm.addShareholderForm")}</h2>
      <div className="w-full mx-auto">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validate={(values) => {
            const errors = {};
            // For validate file's input
            if (values.shareholder1adressFiles === null || values.shareholder1adressFiles.length === 0) {
              errors.shareholder1adressFiles = t("signUpBusiness.fileRequired");
            } else if (values.shareholder1declaration === null || values.shareholder1declaration.length === 0) {
              errors.shareholder1declaration = t("signUpBusiness.fileRequired");
            } else if (values.shareholder1adressFiles.length > 1) {
              errors.shareholder1adressFiles = t("signUpBusiness.numberOfFile");
            } else if (values.shareholder1declaration.length > 1) {
              errors.shareholder1declaration = t("signUpBusiness.numberOfFile");
            } else {
              values.shareholder1adressFiles.forEach((value) => {
                if (value.size > 3000000) {
                  errors.shareholder1adressFiles = (t("signUpBusiness.fileSize"));
                } else if (value.size < 32000) {
                  errors.shareholder1adressFiles = (t("signUpBusiness.fileToSmall"));
                }
              });
              values.shareholder1declaration.forEach((value) => {
                if (value.size > 3000000) {
                  errors.shareholder1declaration = (t("signUpBusiness.fileSize"));
                } else if (value.size < 32000) {
                  errors.shareholder1declaration = (t("signUpBusiness.fileToSmall"));
                }
              });
            }
            return errors;
          }}
          onSubmit={signup}
        >
          {({ errors, touched, values, setFieldValue, handleChange }) => (
            <Form className="mx-auto w-5/6 flex flex-col justify-center items-center">
              <div className={sectionContainerSpaced}>
                <div className="w-full">
                  <label className="block text-xxms font-medium">{`${t('lastName')}  *`}</label>
                  <Field
                    id="shareholder1lastName"
                    name="shareholder1lastName"
                    type="text"
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value
                      }));
                    }}
                    placeholder={t('lastName')}
                    className="w-full border-grey-light rounded text-xxms text-gray-500"
                  />
                  {errors.shareholder1lastName && touched.shareholder1lastName ? (
                    <p className="text-red-600 text-center md:text-left">{errors.shareholder1lastName}</p>
                  ) : null}
                </div>
              </div>

              <div className={sectionContainerSpaced}>
                <div className="w-full">
                  <label className="block text-xxms font-medium">{`${t('firstName')}  *`}</label>
                  <Field
                    id="shareholder1firstName"
                    name="shareholder1firstName"
                    type="text"
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value
                      }));
                    }}
                    placeholder={t('firstName')}
                    className="w-full border-grey-light rounded text-xxms text-gray-500"
                  />
                  {errors.shareholder1firstName && touched.shareholder1firstName ? (
                    <p className="text-red-600 text-center md:text-left">{errors.shareholder1firstName}</p>
                  ) : null}
                </div>
              </div>

              <div className={sectionContainerSpaced}>
                <div className="w-full">
                  <label className="block text-xxms font-medium">{`${t('signInForm.email')}  *`}</label>
                  <Field
                    id="shareholder1email"
                    name="shareholder1email"
                    type="email"
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value
                      }));
                    }}
                    placeholder="ex : antoine.dupont@company.com"
                    className="w-full border-grey-light rounded text-xxms text-gray-500"
                  />
                  {errors.shareholder1email && touched.shareholder1email ? (
                    <p className="text-red-600 text-center md:text-left">{errors.shareholder1email}</p>
                  ) : null}
                </div>
              </div>

              <div className={sectionContainer}>
                <div className="w-full md:flex">
                  {/* Birthdate field */}
                  <div className="w-full md:1/5 md:pr-4">
                    <label className="block text-xxms font-medium">{`${t('signUpInfos.dateOfBirth')}  *`}</label>
                    <Field
                      id="shareholder1birthday"
                      name="shareholder1birthday"
                      type="date"
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value
                        }));
                      }}
                      className="w-full border-grey-light rounded text-xxms text-gray-500"
                    />
                    {errors.shareholder1birthday && touched.shareholder1birthday ? (
                      <p className="text-red-600 text-center md:text-left">{errors.shareholder1birthday}</p>
                    ) : null}
                  </div>
                  {/* Nationality field */}
                  <div className="w-full md:w-4/5 mb-4 md:mb-0 mt-4 md:mt-0">
                    <label className="block text-xxms font-medium">{`${t('signUpInfos.nationality')}  *`}</label>
                    <select
                      id="shareholder1nationality"
                      name="shareholder1nationality"
                      defaultValue=""
                      type={values.shareholder1nationality}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value
                        }));
                      }}
                      className="w-full border-grey-light rounded text-xxms text-gray-500"
                    >
                      <option value="" disabled label={t("selectNationality")} />
                      {
                        countriesMapped.map((country) => (
                          <option key={country.num_code} value={country.alpha_2_code}>
                            {country.nationality}
                          </option>
                        ))
                      }
                    </select>
                    {errors.shareholder1nationality && touched.shareholder1nationality ? (
                      <p className="text-red-600 text-center md:text-left">{errors.shareholder1nationality}</p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className={sectionContainer}>
                <div className="w-full md:flex">
                  {/* BirthCountry field */}
                  <div className="w-full md:1/5 md:pr-4">
                    <label className="block text-xxms font-medium">{`${t('signUpInfos.birthCountry')}  *`}</label>
                    <select
                      id="shareholder1birthCountry"
                      name="shareholder1birthCountry"
                      defaultValue=""
                      type={values.shareholder1birthCountry}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value
                        }));
                      }}
                      className="w-full border-grey-light rounded text-xxms text-gray-500"
                    >
                      <option value="" disabled label={t("selectCountry")} />
                      {
                        countriesMapped.map((country) => (
                          <option key={country.alpha_2_code} value={country.alpha_2_code}>
                            {country.en_short_name}
                          </option>
                        ))
                      }
                    </select>
                    {errors.shareholder1birthCountry && touched.shareholder1birthCountry ? (
                      <p className="text-red-600 text-center md:text-left">{errors.shareholder1birthCountry}</p>
                    ) : null}
                  </div>
                  {/* birthCity  field */}
                  <div className="w-full md:w-4/5 mb-4 md:mb-0 mt-4 md:mt-0">
                    <label className="block text-xxms font-medium">{`${t('signUpInfos.birthCity')}  *`}</label>
                    <Field
                      id="shareholder1birthCity"
                      name="shareholder1birthCity"
                      type="text"
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value
                        }));
                      }}
                      className="w-full border-grey-light rounded text-xxms text-gray-500"
                    />
                    {errors.shareholder1birthCity && touched.shareholder1birthCity ? (
                      <p className="text-red-600 text-center md:text-left">{errors.shareholder1birthCity}</p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className={sectionContainerSpaced}>
                <div className="w-full">
                  <label className="block text-xxms font-medium">{`${t('signUpInfos.adress')}  *`}</label>
                  <Field
                    id="shareholder1adress"
                    name="shareholder1adress"
                    type="text"
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value
                      }));
                    }}
                    placeholder={`ex : ${t("signUpInfos.adressPlaceholder")}`}
                    className="w-full border-grey-light rounded text-xxms text-gray-500"
                  />
                  {errors.shareholder1adress && touched.shareholder1adress ? (
                    <p className="text-red-600 text-center md:text-left">{errors.shareholder1adress}</p>
                  ) : null}
                </div>
              </div>

              <div className={sectionContainer}>
                <div className="w-full">
                  <select
                    id="shareholder1country"
                    name="shareholder1country"
                    defaultValue=""
                    type={values.shareholder1country}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value
                      }));
                    }}
                    className="w-full border-grey-light rounded text-xxs text-gray-500"
                  >
                    <option value="" disabled label={t("selectCountry")} />
                    {
                      countriesMapped.map((country) => (
                        <option key={country.alpha_3_code} value={country.alpha_2_code}>
                          {country.en_short_name}
                        </option>
                      ))
                    }
                  </select>
                  {errors.shareholder1country && touched.shareholder1country ? (
                    <p className="text-red-600 text-center md:text-left">{errors.shareholder1country}</p>
                  ) : null}
                </div>
              </div>

              <div className={sectionContainer}>
                <div className="w-full md:flex">
                  {/* ZipCode field */}
                  <div className="w-full md:1/5 md:pr-4">
                    <Field
                      id="shareholder1zipCode"
                      name="shareholder1zipCode"
                      type="text"
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: parseInt(e.target.value, 10)
                        }));
                      }}
                      placeholder="ex : 75000"
                      className="w-full border-grey-light rounded text-xxms text-gray-500"
                    />
                    {errors.shareholder1zipCode && touched.shareholder1zipCode ? (
                      <p className="text-red-600 text-center md:text-left">{errors.shareholder1zipCode}</p>
                    ) : null}
                  </div>
                  {/* City field */}
                  <div className="w-full md:w-4/5 mb-4 md:mb-0 mt-4 md:mt-0">
                    <Field
                      id="shareholder1city"
                      name="shareholder1city"
                      type="text"
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value
                        }));
                      }}
                      placeholder="ex : Paris"
                      className="w-full border-grey-light rounded text-xxms text-gray-500"
                    />
                    {errors.shareholder1city && touched.shareholder1city ? (
                      <p className="text-red-600 text-center md:text-left">{errors.shareholder1city}</p>
                    ) : null}
                  </div>
                </div>
              </div>

              {/* Required documents section */}
              <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center">
                <div className="w-full md:w-4/6 lg:w-3/4">
                  <div>
                    <span className="text-xxms font-medium">
                      {t("signUpInfos.obligatory")}
                    </span>
                    <button
                      type="button"
                      className="pt-2"
                      onClick={() => {
                        setOpenModal(!openModal);
                      }}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-4" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="w-full mb-2 text-xxs italic flex flex-col space-y-1">
                <span>{t("signUpInfos.infoFile")}</span>
                <span>{t("signUpInfos.tipFile")}</span>
              </div>
              {/* Upload ID */}
              {/* <UploadFile
                identificator="shareholder1identityFiles"
                label={t('uploadFileID')}
                onChange={(event) => {
                  if (!values.shareholder1identityFiles) {
                    values.shareholder1identityFiles = [];
                  }
                  const shareholder1identityFilesArray = values.shareholder1identityFiles;
                  if (event.target?.files[0]) {
                    const fileName = event.target?.files[0].name;
                    const fileType = event.target?.files[0].type;
                    const fileSize = event.target?.files[0].size;
                    const file = event.target?.files[0];
                    const reader = new FileReader();
                    reader.onload = () => {
                      setFieldValue("shareholder1identityFiles", shareholder1identityFilesArray.concat({ name: fileName, type: fileType, fileData: reader.result, size: fileSize }));
                      setFormValue((prevState) => ({
                        ...prevState,
                        [event.target.name]: values.shareholder1identityFiles.concat({ docType: 'cni', name: fileName, type: fileType, fileData: reader.result, size: fileSize })
                      }));
                    };
                    reader.readAsDataURL(file);
                  }
                }}
                arrayValues={values.shareholder1identityFiles}
                errors={errors.shareholder1identityFiles}
                touched={touched.shareholder1identityFiles}
                setFormValue={setFormValue}
                formValue={formValue}
                setFieldValue={setFieldValue}
              /> */}
              {/* Upload adress proof */}
              <UploadFile
                identificator="shareholder1adressFiles"
                label={t('adressFiles')}
                onInput={(event) => {
                  setEventToReset(event.target);
                  if (!values.shareholder1adressFiles) {
                    values.shareholder1adressFiles = [];
                  }
                  if (values.shareholder1adressFiles.length >= 1) {
                    values.shareholder1adressFiles = [];
                  } // reset array after wrong upload
                  if (formValue.shareholder1adressFiles.length >= 1) {
                    formValue.shareholder1adressFiles.length = [];
                  } // reset array after wrong upload
                  const shareholder1adressFilesArray = values.shareholder1adressFiles;
                  if (event.target?.files[0]) {
                    const fileName = strNoAccentNoSpecialChar(event.target?.files[0].name);
                    const fileType = event.target?.files[0].type.slice(event.target?.files[0].type.indexOf("/") + 1, event.target?.files[0].type.length); // if file.type = img/png -> fileType = png
                    const fileSize = event.target?.files[0].size;
                    const file = event.target?.files[0];
                    const reader = new FileReader();
                    reader.onload = () => {
                      setFieldValue("shareholder1adressFiles", shareholder1adressFilesArray.concat({ name: fileName, type: fileType, fileData: `${fileName}_${fileSize}_shareholder1adressFiles.${fileType}`, size: fileSize }));
                      setFormValue((prevState) => ({
                        ...prevState,
                        [event.target.name]: values.shareholder1adressFiles.concat({ docType: 'addressProof', name: fileName, type: fileType, fileData: `${fileName}_${fileSize}_shareholder1adressFiles.${fileType}`, size: fileSize })
                      }));
                    };
                    reader.readAsDataURL(file);
                    uploadFile(file, "shareholder1adressFiles");
                  }
                }}
                arrayValues={values.shareholder1adressFiles}
                errors={errors.shareholder1adressFiles}
                touched={touched.shareholder1adressFiles}
                setFormValue={setFormValue}
                formValue={formValue}
                setFieldValue={setFieldValue}
                eventToReset={eventToReset}
              />

              {/* Upload shareholder declaration */}
              <UploadFile
                identificator="shareholder1declaration"
                label={t('shareholderForm.shareholderDeclaration')}
                onInput={(event) => {
                  setEventToReset(event.target);
                  if (!values.shareholder1declaration) {
                    values.shareholder1declaration = [];
                  }
                  if (values.shareholder1declaration.length >= 1) {
                    values.shareholder1declaration = [];
                  } // reset array after wrong upload
                  if (formValue.shareholder1declaration.length >= 1) {
                    formValue.shareholder1declaration.length = [];
                  } // reset array after wrong upload
                  const shareholder1declarationArray = values.shareholder1declaration;
                  if (event.target?.files[0]) {
                    const fileName = strNoAccentNoSpecialChar(event.target?.files[0].name);
                    const fileType = event.target?.files[0].type.slice(event.target?.files[0].type.indexOf("/") + 1, event.target?.files[0].type.length); // if file.type = img/png -> fileType = png
                    const fileSize = event.target?.files[0].size;
                    const file = event.target?.files[0];
                    const reader = new FileReader();
                    reader.onload = () => {
                      setFieldValue("shareholder1declaration", shareholder1declarationArray.concat({ name: fileName, type: fileType, fileData: `${fileName}_${fileSize}_shareholder1declaration.${fileType}`, size: fileSize }));
                      setFormValue((prevState) => ({
                        ...prevState,
                        [event.target.name]: values.shareholder1declaration.concat({ docType: 'shareholder', name: fileName, type: fileType, fileData: `${fileName}_${fileSize}_shareholder1declaration.${fileType}`, size: fileSize })
                      }));
                    };
                    reader.readAsDataURL(file);
                    uploadFile(file, "shareholder1declaration");
                  }
                }}
                arrayValues={values.shareholder1declaration}
                errors={errors.shareholder1declaration}
                touched={touched.shareholder1declaration}
                setFormValue={setFormValue}
                formValue={formValue}
                setFieldValue={setFieldValue}
                eventToReset={eventToReset}
              />

              {/* Toggle buttons group */}
              <ToggleSwitch
                id="enabledShareholder1"
                nameSwitch="enabledShareholder1"
                checked={enabledShareholder1}
                label={t("signUpInfos.shareholder")}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.checked
                  }));
                  setEnabledShareholder1(e.target.checked);
                }}
              />

              <ToggleSwitch
                id="enabledAdministrator1"
                nameSwitch="enabledAdministrator1"
                checked={enabledAdministrator1}
                label={t("signUpInfos.administrator")}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.checked
                  }));
                  setEnabledAdministrator1(e.target.checked);
                }}
              />

              <div className="w-full flex flex-col-reverse md:flex-row justify-center items-center my-6">
                <button
                  type="button"
                  className="border border-grey-light bg-white mt-4 md:mt-0 py-2 px-4 rounded-md shadow-sm text-xxms font-medium text-black hover:bg-orange-dark hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange"
                  onClick={() => validate(false)}
                >
                  {t("shareholderForm.goBack")}
                </button>
                <button
                  type="submit"
                  disabled={(Object.keys(errors).length !== 0) ? "disabled" : ""}
                  onClick={() => validate(false)}
                  className="border border-grey-light md:ml-3 inline-flex justify-center py-2 px-4 shadow-sm text-xxms font-medium rounded-md text-black bg-white hover:bg-orange-dark hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange"
                >
                  {t("shareholderForm.add")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <ModalFormBusiness showModal={openModal} hideModal={hideModal} simple shareholder />
    </>
  );
};

export default ShareHolderForm1;
