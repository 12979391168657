import { all, put, takeLatest, select } from 'redux-saga/effects';
import FormData from 'form-data';
import { API } from 'aws-amplify';
import { config } from '../conf/amplify';
import customerOrdersMock from '../mocks/customerOrders.json';
import deliverySimulationsMock from '../mocks/deliverySimulationsMock.json';
import usersMock from '../mocks/users.json';
import { addFile } from '../utils/awsLib';

import {
  getOffersSuccess,
  getOffersFailure,
  getCustomOrdersSuccess,
  getCustomOrderssFailure,
  getDeliverySimulationsSuccess,
  getDeliverySimulationssFailure,
  getUserSuccess,
  getUsersFailure,
  getSellersEmailSuccess,
  getSellersEmailFailure,
  getSellerOrdersSuccess,
  getSellerOrdersFailure,
  getImportsSuccess,
  getImportsFailure,
  getProductSheetsSuccess,
  getProductSheetsFailure,
  contactSuccess,
  contactFailure,
  sellerCompanyInfoSuccess,
  sellerCompanyInfoFailure,
  importSuccess,
  importFailure,
  getDeliveryZonesSuccess,
  getDeliveryZonesFailure,
  getDeliveryModesSuccess,
  getDeliveryModesFailure,
  shippingConfigurationSuccess,
  shippingConfigurationFailure,
  getShippingConfigurationSuccess,
  getShippingConfigurationFailure,
  getProductsSuccess,
  getProductsFailure,
  updateSellerOrderSuccess,
  updateSellerOrderFailure,
  updateOrderLineIncidentSuccess,
  updateOrderLineIncidentFailure,
  uploadFileOnboardingSuccess,
  uploadFileOnboardingFailure,
} from './reducer';

import { getCurrentSessionLaunched } from '../components/App/reducer';

// CONFIG
const customHeader = {
  'x-api-key': config.apiKey
};

function* getSellersEmail(action) {
  const email = action.payload.formEmail;
  try {
    const sellersEmail = yield API.get(config.apiGateway.NAME, `/sellers?email=${email}`, {
      headers: {
        'x-api-key': config.apiKey,
      },
    });
    yield put(getSellersEmailSuccess(sellersEmail));
  } catch (error) {
    yield put(getSellersEmailFailure(error));
  }
}

function* getOffers(action) {
  const { seller } = yield select((state) => state.getIn(['app', 'userDynamo']));
  const sellerId = seller.id;
  try {
    // COMMENT TO USE MOCK
    const offers = yield API.get(config.apiGateway.NAME, `/offers?sellerId=${sellerId}`, {
      headers: customHeader
    });
    // UNCOMMENT TO USE MOCK
    // const offers = offerMock;
    yield put(getOffersSuccess(offers));
  } catch (error) {
    console.log(error);
    yield put(getOffersFailure());
  }
}

function* getCustomOrders() {
  try {
    // COMMENT TO USE MOCK
    const customerOrder = yield API.get(config.apiGateway.NAME, `/allCustomerOrders`, {
      headers: {
        'x-api-key': config.apiKey,
      },
    });
    // UNCOMMENT TO USE MOCK
    // const customerOrder = customerOrdersMock;
    yield put(getCustomOrdersSuccess(customerOrder));
  } catch (error) {
    console.log(error);
    yield put(getCustomOrderssFailure());
  }
}

function* getDeliverySimulations(action) {
  const { deliverySimulations } = action.payload;
  try {
    // COMMENT TO USE MOCK
    // const deliverySimulations = yield API.get(config.apiGateway.NAME, `/deliverySimulations/${deliverySimulations}`, {
    //   headers: customHeader
    // });
    // UNCOMMENT TO USE MOCK
    const deliverySimulation = deliverySimulationsMock;
    yield put(getDeliverySimulationsSuccess(deliverySimulation));
  } catch (error) {
    console.log(error);
    yield put(getDeliverySimulationssFailure());
  }
}

function* getUser() {
  try {
    // COMMENT TO USE MOCK
    const user = yield API.get(config.apiGateway.NAME, `/allUsers`, {
      headers: {
        'x-api-key': config.apiKey,
      },
    });
    // const user = usersMock;
    yield put(getUserSuccess(user));
  } catch (error) {
    console.log(error);
    yield put(getUsersFailure());
  }
}

function* getSellerOrders(action) {
  const { seller } = yield select((state) => state.getIn(['app', 'userDynamo']));
  const sellerId = seller.id;
  try {
    // COMMENT TO USE MOCK
    const sellerOrder = yield API.get(config.apiGateway.NAME, `/sellerOrders?sellerId=${sellerId}`, {
      headers: {
        'x-api-key': config.apiKey,
      },
    });
    // UNCOMMENT TO USE MOCK
    // const sellerOrder = sellerOrdersMock;
    yield put(getSellerOrdersSuccess(sellerOrder));
  } catch (error) {
    console.log(error);
    yield put(getSellerOrdersFailure());
  }
}

function* getImports(action) {
  const { seller } = yield select((state) => state.getIn(['app', 'userDynamo']));
  const sellerId = seller.id;
  try {
    const imported = yield API.get(config.apiGateway.NAME, `/imports?sellerId=${sellerId}`, {
      headers: {
        'x-api-key': config.apiKey,
      },
    });
    yield put(getImportsSuccess(imported));
  } catch (error) {
    console.log(error);
    yield put(getImportsFailure(error));
  }
}

function* doImport(action) {
  const { seller } = yield select((state) => state.getIn(['app', 'userDynamo']));
  const form = new FormData();
  form.append("sellerId", seller.id);
  form.append("filename", action.payload?.file[0].name);
  form.append("file", action.payload?.file[0]);

  try {
    const result = yield API.post(config.apiGateway.NAME, `/imports`, {
      headers: {
        'x-api-key': config.apiKey,
        'content-type': 'multipart/form-data'
      },
      body: form
    });
    yield put(importSuccess(result));
  } catch (error) {
    console.log(error);
    yield put(importFailure(error));
  }
}

function* getProductSheets(action) {
  const { seller } = yield select((state) => state.getIn(['app', 'userDynamo']));
  const sellerId = seller.id;
  try {
    const product = yield API.get(config.apiGateway.NAME, `/productSheets?sellerId=${sellerId}`, {
      headers: {
        'x-api-key': config.apiKey,
      },
    });
    yield put(getProductSheetsSuccess(product));
  } catch (error) {
    yield put(getProductSheetsFailure(error));
  }
}

function* contact(action) {
  const { seller } = yield select((state) => state.getIn(['app', 'userDynamo']));
  const sellerId = seller.id;
  const body = action.payload.formValue;
  try {
    const contents = yield API.post(config.apiGateway.NAME, `/seller/${sellerId}/messages`, {
      headers: customHeader,
      body
    });
    yield put(contactSuccess(contents));
  } catch (error) {
    console.log(error);
    yield put(contactFailure());
  }
}

function* sellerCompanyInfo(action) {
  const { seller } = yield select((state) => state.getIn(['app', 'userDynamo']));
  const sellerId = seller.id;
  const body = action.payload.formValue;
  try {
    const contents = yield API.put(config.apiGateway.NAME, `/sellers/${sellerId}`, {
      headers: customHeader,
      body
    });
    yield put(sellerCompanyInfoSuccess(contents));
    yield put(getCurrentSessionLaunched('/account'));
  } catch (error) {
    console.log(error);
    yield put(sellerCompanyInfoFailure());
  }
}

function* getDeliveryZones(action) {
  try {
    const deliveryZones = yield API.get(config.apiGateway.NAME, `/deliveryZones`, {
      headers: {
        'x-api-key': config.apiKey,
      },
    });
    yield put(getDeliveryZonesSuccess(deliveryZones));
  } catch (error) {
    console.log(error);
    yield put(getDeliveryZonesFailure());
  }
}

function* getDeliveryModes(action) {
  try {
    const deliveryModes = yield API.get(config.apiGateway.NAME, `/deliveryModes`, {
      headers: {
        'x-api-key': config.apiKey,
      },
    });
    yield put(getDeliveryModesSuccess(deliveryModes));
  } catch (error) {
    console.log(error);
    yield put(getDeliveryModesFailure());
  }
}

function* doShippingConfiguration(action) {
  const body = action.payload;
  const { seller } = yield select((state) => state.getIn(['app', 'userDynamo']));
  const sellerId = seller.id;
  try {
    const contents = yield API.post(config.apiGateway.NAME, `/deliveryModeConfigurations/${sellerId}`, {
      headers: customHeader,
      body
    });
    yield put(shippingConfigurationSuccess(contents));
  } catch (error) {
    console.log(error);
    yield put(shippingConfigurationFailure());
  }
}

function* getShippingConfiguration(action) {
  const { seller } = yield select((state) => state.getIn(['app', 'userDynamo']));
  const sellerId = seller.id;
  try {
    const shippingConfiguration = yield API.get(config.apiGateway.NAME, `/deliveryModeConfigurations?sellerId=${sellerId}`, {
      headers: {
        'x-api-key': config.apiKey,
      },
    });
    yield put(getShippingConfigurationSuccess(shippingConfiguration));
  } catch (error) {
    console.log(error);
    yield put(getShippingConfigurationFailure(error));
  }
}

function* getProducts(action) {
  const { seller } = yield select((state) => state.getIn(['app', 'userDynamo']));
  const sellerId = seller.id;
  try {
    // COMMENT TO USE MOCK
    const products = yield API.get(config.apiGateway.NAME, `/products?sellerId=${sellerId}`, {
      headers: {
        'x-api-key': config.apiKey,
      },
    });
    // UNCOMMENT TO USE MOCK
    // const products = productsMock;
    yield put(getProductsSuccess(products));
  } catch (error) {
    yield put(getProductsFailure(error));
  }
}

function* updateSellerOrder(action) {
  const body = action.payload.formValue;
  const sellerOrderId = body.id;
  try {
    const contents = yield API.put(config.apiGateway.NAME, `/sellerOrders/${sellerOrderId}`, {
      headers: customHeader,
      body
    });
    yield put(updateSellerOrderSuccess(contents));
  } catch (error) {
    console.log(error);
    yield put(updateSellerOrderFailure());
  }
}

function* updateOrderLineIncident(action) {
  const body = action.payload;
  const sellerOrderId = body.id;
  try {
    const contents = yield API.put(config.apiGateway.NAME, `/sellerOrders/incident/${sellerOrderId}`, {
      headers: customHeader,
      body
    });
    yield put(updateOrderLineIncidentSuccess(contents));
  } catch (error) {
    console.log(error);
    yield put(updateOrderLineIncidentFailure());
  }
}

function* doUploadFileOnboarding(action) {
  const { file, identificator } = action.payload;
  try {
    const storedKey = yield addFile(file, identificator);
    yield put(uploadFileOnboardingSuccess(storedKey));
  } catch (error) {
    console.log(error);
    yield put(uploadFileOnboardingFailure(error));
  }
}

export default function* saga() {
  yield all([
    takeLatest('store/getOffersLaunched', getOffers),
    takeLatest('store/getCustomOrdersLaunched', getCustomOrders),
    takeLatest('store/getDeliverySimulationsLaunched', getDeliverySimulations),
    takeLatest('store/getUserLaunched', getUser),
    takeLatest('store/getSellerOrdersLaunched', getSellerOrders),
    takeLatest('store/getImportsLaunched', getImports),
    takeLatest('store/importLaunched', doImport),
    takeLatest('store/getProductSheetsLaunched', getProductSheets),
    takeLatest('store/contactLaunched', contact),
    takeLatest('store/sellerCompanyInfoLaunched', sellerCompanyInfo),
    takeLatest('store/getDeliveryZonesLaunched', getDeliveryZones),
    takeLatest('store/getDeliveryModesLaunched', getDeliveryModes),
    takeLatest('store/shippingConfigurationLaunched', doShippingConfiguration),
    takeLatest('store/getShippingConfigurationLaunched', getShippingConfiguration),
    takeLatest('store/getProductsLaunched', getProducts),
    takeLatest('store/getSellersEmailLaunched', getSellersEmail),
    takeLatest('store/updateSellerOrderLaunched', updateSellerOrder),
    takeLatest('store/updateOrderLineIncidentLaunched', updateOrderLineIncident),
    takeLatest('store/uploadFileOnboardingLaunched', doUploadFileOnboarding),
  ]);
}
