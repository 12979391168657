import React from 'react';
import PropTypes from "prop-types";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const ToggleSwitch = ({ id, checked, nameSwitch, onChange, label, labelStyle }) => (

  <div className="flex items-center w-full flex flex-col md:flex-row items-start md:items-center mb-4 md:mb-6">
    <div
      className={classNames(
        checked ? 'bg-orange-dark' : 'bg-gray-200',
        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dark'
      )}
    >
      <span
        aria-hidden="true"
        className={classNames(
          checked ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
        )}
      />
      <input
        type="checkbox"
        name={nameSwitch}
        id={id}
        checked={checked}
        onChange={onChange}
        className="absolute w-10 h-6 opacity-0"
      />
    </div>
    {
      label ? (
        <span className="ml-3">
          <span className={labelStyle !== null ? labelStyle : 'text-xxxs font-normal text-gray-500'}>{label}</span>
        </span>
      ) : null
    }
  </div>

);

export default ToggleSwitch;

ToggleSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  // nameSwitch: PropTypes.string,
  // disabled: PropTypes.bool
};
