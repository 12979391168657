import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { addProtocol } from '../../../utils/format';

const SigUpConfirmation = () => {
  const { t } = useTranslation();

  return (
    <div className="text-center mx-auto md:w-1/2">
      <p className="md:text-sm text-xs pb-8 pt-24">{t("signUpConfirmation.thanks")}</p>
      <p className="md:text-sm text-xs pb-8">{t("signUpConfirmation.formTransfered")}</p>
      <p className="md:text-sm text-xs pb-8">{t("signUpConfirmation.confirmationEmail")}</p>
      <p className="md:text-sm text-xs pb-8">{t("signUpConfirmation.signupQuestions")}</p>
      <p className="md:text-sm text-xs pb-12 font-bold">{process.env.REACT_APP_OPERATOR_MAIL}</p>
      <Link to="/" className="md:text-sm text-xs underline pb-8">{t("signUpConfirmation.goHome")}</Link>
    </div>
  );
};

export default SigUpConfirmation;
