import Immutable from 'immutable';
import { createSlice } from '@reduxjs/toolkit';

const initialState = Immutable.Map({
  getOffersLoading: false,
  getOffersError: null,
  offers: null,
  getCustomOrdersLoading: false,
  getCustomOrdersError: null,
  contact: null,
  contactLoading: false,
  contactSuccessState: false,
  contactErrorMessage: null,
  customOrders: null,
  getDeliverySimulationsLoading: false,
  getDeliverySimulationsError: null,
  deliverySimulations: null,
  getUserLoading: false,
  getUserError: null,
  user: null,
  userDynamo: null,
  userInfo: null,
  getSellerLoading: false,
  getSellerError: null,
  seller: null,
  getSellersEmailLoading: false,
  getSellersEmailError: null,
  sellersEmail: null,
  signupEmail: null,
  getSellerOrdersLoading: false,
  getSellerOrdersError: null,
  sellerOrders: null,
  getImportsLoading: false,
  getImportsError: null,
  imports: false,
  importLoading: false,
  importSuccessState: false,
  importErrorMessage: null,
  getProductSheetsLoading: false,
  getProductSheetsError: null,
  productSheets: null,
  getDeliveryZonesLoading: false,
  getDeliveryZonesError: null,
  deliveryZones: null,
  getDeliveryModesLoading: false,
  getDeliveryModesError: null,
  deliveryModes: null,
  shippingLoading: false,
  shippingSuccess: null,
  shippingError: null,
  getShippingConfigurationLoading: false,
  getShippingConfigurationError: null,
  shippingConfiguration: null,
  getProductsLoading: false,
  getProductsError: null,
  products: null,
  updateSellerOrder: null,
  updateSellerOrderLoading: false,
  updateSellerOrderSuccess: false,
  updateSellerOrderError: null,
  updateOrderLineIncident: null,
  updateOrderLineIncidentLoading: false,
  updateOrderLineIncidentSuccess: false,
  updateOrderLineIncidentError: null,
  uploadFileOnboardingLoading: false,
  uploadFileOnboardingSuccessState: false,
  uploadFileOnboardingErrorMessage: null,
});

const { actions, reducer } = createSlice({
  slice: 'store',
  name: 'store',
  initialState,
  reducers: {
    // GETOFFERS
    getOffersLaunched: (state) => state
      .set('getOffersLoading', true)
      .set('offers', null)
      .set('getOffersError', null),
    getOffersSuccess: (state, action) => state
      .set('getOffersLoading', false)
      .set('offers', action.payload)
      .set('getOffersError', null),
    getOffersFailure: (state, action) => state
      .set('getOffersLoading', false)
      .set('offers', null)
      .set('getOffersError', action.payload),

    // GetCustomOrders
    getCustomOrdersLaunched: (state, action) => state
      .set('getCustomOrdersLoading', true)
      .set('customOrders', null)
      .set('getCustomOrdersError', null),
    getCustomOrdersSuccess: (state, action) => state
      .set('getCustomOrdersLoading', false)
      .set('customOrders', action.payload)
      .set('getCustomOrdersError', null),
    getCustomOrderssFailure: (state, action) => state
      .set('getCustomOrdersLoading', false)
      .set('customOrders', null)
      .set('getCustomOrdersError', action.payload),

    // getDeliverySimulations
    getDeliverySimulationsLaunched: (state, action) => state
      .set('getDeliverySimulationsLoading', true)
      .set('deliverySimulations', null)
      .set('getDeliverySimulationsError', null),
    getDeliverySimulationsSuccess: (state, action) => state
      .set('getDeliverySimulationsLoading', false)
      .set('deliverySimulations', action.payload)
      .set('getDeliverySimulationsError', null),
    getDeliverySimulationssFailure: (state, action) => state
      .set('getDeliverySimulationsLoading', false)
      .set('deliverySimulations', null)
      .set('getDeliverySimulationsError', action.payload),

    // getUser
    getUserLaunched: (state, action) => state
      .set('getUserLoading', true)
      .set('user', null)
      .set('getUserError', null),
    getUserSuccess: (state, action) => state
      .set('getUserLoading', false)
      .set('user', action.payload)
      .set('getUserError', null),
    getUsersFailure: (state, action) => state
      .set('getUserLoading', false)
      .set('user', null)
      .set('getUserError', action.payload),

    // getProducts
    getProductsLaunched: (state, action) => state
      .set('getProductsLoading', true)
      .set('products', null)
      .set('getProductsError', null),
    getProductsSuccess: (state, action) => state
      .set('getProductsLoading', false)
      .set('products', action.payload)
      .set('getProductsError', null),
    getProductsFailure: (state, action) => state
      .set('getProductsLoading', false)
      .set('products', null)
      .set('getProductsError', action.payload),

    // GetSellerOrders
    getSellerOrdersLaunched: (state, action) => state
      .set('getSellerOrdersLoading', true)
      .set('sellerOrders', null)
      .set('getSellerOrdersError', null),
    getSellerOrdersSuccess: (state, action) => state
      .set('getSellerOrdersLoading', false)
      .set('sellerOrders', action.payload)
      .set('getSellerOrdersError', null),
    getSellerOrdersFailure: (state, action) => state
      .set('getSellerOrdersLoading', false)
      .set('sellerOrders', null)
      .set('getSellerOrdersError', action.payload),

    // GetSellersEmail
    getSellersEmailLaunched: (state, action) => state
      .set('getSellersEmailLoading', true)
      .set('sellersEmail', null)
      .set('getSellersEmailError', null),
    // .set('signupEmail', action.payload),
    getSellersEmailSuccess: (state, action) => state
      .set('getSellersEmailLoading', false)
      .set('sellersEmail', action.payload)
      .set('getSellersEmailError', null),
    // .set('signupEmail', action.payload),
    getSellersEmailFailure: (state, action) => state
      .set('getSellersEmailLoading', false)
      .set('sellersEmail', null)
      .set('getSellersEmailError', action.payload),

    // IMPORTS
    getImportsLaunched: (state, action) => state
      .set('getImportsLoading', true)
      .set('imports', false)
      .set('getImportsError', null),
    getImportsSuccess: (state, action) => state
      .set('getImportsLoading', false)
      .set('imports', action.payload)
      .set('getImportsError', null),
    getImportsFailure: (state, action) => state
      .set('getImportsLoading', false)
      .set('imports', false)
      .set('getImportsError', action.payload),
    importLaunched: (state, action) => state
      .set('importLoading', true)
      .set('importSuccessState', false)
      .set('importErrorMessage', null),
    importSuccess: (state, action) => state
      .set('importLoading', false)
      .set('importSuccessState', true)
      .set('importErrorMessage', null),
    importFailure: (state, action) => state
      .set('importLoading', false)
      .set('importSuccessState', false)
      .set('importErrorMessage', action.payload),

    // GET PRODUCTSHEETS FOR CATALOG
    getProductSheetsLaunched: (state) => state
      .set('getProductSheetsLoading', true)
      .set('productSheets', null)
      .set('getProductSheetsError', null),
    getProductSheetsSuccess: (state, action) => state
      .set('getProductSheetsLoading', false)
      .set('productSheets', action.payload)
      .set('getProductSheetsError', null),
    getProductSheetsFailure: (state, action) => state
      .set('getProductSheetsLoading', false)
      .set('productSheets', null)
      .set('getProductSheetsError', action.payload),

    // CONTACT
    contactLaunched: (state, action) => state
      .set('contactLoading', true)
      .set('contact', false)
      .set('contactError', null),
    contactSuccess: (state, action) => state
      .set('contactLoading', false)
      .set('contact', action.payload)
      .set('contactError', null),
    contactFailure: (state, action) => state
      .set('contactLoading', false)
      .set('contactError', action.payload),

    // UPDATE SELLER COMPANY INFO
    sellerCompanyInfoLaunched: (state, action) => state
      .set('sellerCompanyInfoLoading', true)
      .set('sellerCompanyInfoError', null),
    sellerCompanyInfoSuccess: (state, action) => state
      .set('sellerCompanyInfoLoading', false)
      .set('sellerCompanyInfoError', null),
    sellerCompanyInfoFailure: (state, action) => state
      .set('sellerCompanyInfoLoading', false)
      .set('sellerCompanyInfoError', action.payload),

    // GET DELIVERY ZONES
    getDeliveryZonesLaunched: (state, action) => state
      .set('getDeliveryZonesLoading', true)
      .set('deliveryZones', null)
      .set('getDeliveryZonesError', null),
    getDeliveryZonesSuccess: (state, action) => state
      .set('getDeliveryZonesLoading', false)
      .set('deliveryZones', action.payload)
      .set('getDeliveryZonesError', null),
    getDeliveryZonesFailure: (state, action) => state
      .set('getDeliveryZonesLoading', false)
      .set('deliveryZones', null)
      .set('getDeliveryZonesError', action.payload),

    // GET DELIVERY MODES
    getDeliveryModesLaunched: (state, action) => state
      .set('getDeliveryModesLoading', true)
      .set('deliveryModes', null)
      .set('getDeliveryModesError', null),
    getDeliveryModesSuccess: (state, action) => state
      .set('getDeliveryModesLoading', false)
      .set('deliveryModes', action.payload)
      .set('getDeliveryModesError', null),
    getDeliveryModesFailure: (state, action) => state
      .set('getDeliveryModesLoading', false)
      .set('deliveryModes', null)
      .set('getDeliveryModesError', action.payload),

    // DO SHIPPING CONFIGURATION
    shippingConfigurationLaunched: (state, action) => state
      .set('shippingLoading', true)
      .set('shippingSuccess', null)
      .set('shippingError', null),
    shippingConfigurationSuccess: (state, action) => state
      .set('shippingLoading', false)
      .set('shippingSuccess', true)
      .set('shippingError', null),
    shippingConfigurationFailure: (state, action) => state
      .set('shippingLoading', false)
      .set('shippingSuccess', false)
      .set('shippingError', action.payload),

    // GET SHIPPING CONFIGURATION
    getShippingConfigurationLaunched: (state, action) => state
      .set('getShippingConfigurationLoading', true)
      .set('shippingConfiguration', null)
      .set('getShippingConfigurationError', null),
    getShippingConfigurationSuccess: (state, action) => state
      .set('getShippingConfigurationLoading', false)
      .set('shippingConfiguration', action.payload)
      .set('getShippingConfigurationError', null),
    getShippingConfigurationFailure: (state, action) => state
      .set('getShippingConfigurationLoading', false)
      .set('shippingConfiguration', null)
      .set('getShippingConfigurationError', action.payload),

    // UPDATE SELLERORDER
    updateSellerOrderLaunched: (state, action) => state
      .set('updateSellerOrderLoading', true)
      .set('updateSellerOrder', false)
      .set('updateSellerOrderError', null),
    updateSellerOrderSuccess: (state, action) => state
      .set('updateSellerOrderLoading', false)
      .set('updateSellerOrder', action.payload)
      .set('updateSellerOrderError', null),
    updateSellerOrderFailure: (state, action) => state
      .set('updateSellerOrderLoading', false)
      .set('updateSellerOrderError', action.payload),

    // UPDATE ORDERLINE INCIDENT
    updateOrderLineIncidentLaunched: (state, action) => state
      .set('updateOrderLineIncidentLoading', true)
      .set('updateOrderLineIncident', false)
      .set('updateOrderLineIncidentError', null),
    updateOrderLineIncidentSuccess: (state, action) => state
      .set('updateOrderLineIncidentLoading', false)
      .set('updateOrderLineIncident', action.payload)
      .set('updateOrderLineIncidentError', null),
    updateOrderLineIncidentFailure: (state, action) => state
      .set('updateOrderLineIncidentLoading', false)
      .set('updateOrderLineIncidentError', action.payload),

    // UPLOAD FILE ONBOARDING
    uploadFileOnboardingLaunched: (state, action) => state
      .set('uploadFileOnboardingLoading', true)
      .set('uploadFileOnboardingSuccessState', false)
      .set('uploadFileOnboardingErrorMessage', null),
    uploadFileOnboardingSuccess: (state, action) => state
      .set('uploadFileOnboardingLoading', false)
      .set('uploadFileOnboardingSuccessState', true)
      .set('uploadFileOnboardingErrorMessage', null),
    uploadFileOnboardingFailure: (state, action) => state
      .set('uploadFileOnboardingLoading', false)
      .set('uploadFileOnboardingSuccessState', false)
      .set('uploadFileOnboardingErrorMessage', action.payload),
  }
});

export const {
  getOffersLaunched,
  getOffersSuccess,
  getOffersFailure,
  getCustomOrdersLaunched,
  getCustomOrdersSuccess,
  getCustomOrderssFailure,
  getDeliverySimulationsLaunched,
  getDeliverySimulationsSuccess,
  getDeliverySimulationssFailure,
  getUserLaunched,
  getUserSuccess,
  getUsersFailure,
  getSellersEmailLaunched,
  getSellersEmailSuccess,
  getSellersEmailFailure,
  getSellerLaunched,
  getSellerSuccess,
  getSellerFailure,
  getSellerOrdersLaunched,
  getSellerOrdersSuccess,
  getSellerOrdersFailure,
  getImportsLaunched,
  getImportsSuccess,
  getImportsFailure,
  importLaunched,
  importSuccess,
  importFailure,
  getProductSheetsLaunched,
  getProductSheetsSuccess,
  getProductSheetsFailure,
  contactLaunched,
  contactSuccess,
  contactFailure,
  sellerCompanyInfoLaunched,
  sellerCompanyInfoSuccess,
  sellerCompanyInfoFailure,
  getDeliveryZonesLaunched,
  getDeliveryZonesSuccess,
  getDeliveryZonesFailure,
  getDeliveryModesLaunched,
  getDeliveryModesSuccess,
  getDeliveryModesFailure,
  shippingConfigurationLaunched,
  shippingConfigurationSuccess,
  shippingConfigurationFailure,
  getShippingConfigurationLaunched,
  getShippingConfigurationSuccess,
  getShippingConfigurationFailure,
  getProductsLaunched,
  getProductsSuccess,
  getProductsFailure,
  updateSellerOrderLaunched,
  updateSellerOrderSuccess,
  updateSellerOrderFailure,
  updateOrderLineIncidentLaunched,
  updateOrderLineIncidentSuccess,
  updateOrderLineIncidentFailure,
  uploadFileOnboardingLaunched,
  uploadFileOnboardingSuccess,
  uploadFileOnboardingFailure,
} = actions;

export default reducer;
