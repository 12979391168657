import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountInfo } from '../../components/AccountInfo';
import { capitalizeFirst } from '../../utils/format';

export const AccountPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="w-full md:w-3/4 px-4 md:mx-auto pt-8 mb-10 max-w-screen-lg">
        <AccountInfo />
      </div>
    </>
  );
};

export default AccountPage;
