import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useStyles } from "react-styles-hook";
import { Pagination } from "../../Pagination";
import usePagination from "../../../hooks/usePagination";
import { addProtocol, formatDateToFrenchFormat, formatTimestampToDateWithDashes } from '../../../utils/format';
import getStyles from "./styles";

const DesktopImportStatus = ({ arrayContent }) => {
  const { t } = useTranslation();
  const classes = useStyles(getStyles());

  // Pagination
  const PageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  useEffect(() => {
    if (arrayContent) {
      setCurrentData(arrayContent);
    }
  }, [arrayContent]);
  const currentTableData = usePagination({ currentData, PageSize, currentPage });

  return (
    <>
      <div className="table w-full pt-6">
        <div className="table-row-group">
          <div className="table-row h-24" style={classes.rowContainer}>
            <div className="table-cell w-2/12 text-xxms px-2 pt-8 pb-2 font-medium border-grey-light border-b">{t("fileName")}</div>
            <div className="table-cell w-2/12 text-xxms px-2 pt-8 pb-2 font-medium border-grey-light border-b">Date</div>
            <div className="table-cell w-2/12 text-xxms px-2 pt-8 pb-2 font-medium  border-grey-light border-b">{t("status")}</div>
            <div className="table-cell w-2/12 text-xxms px-2 pt-8 pb-2 font-medium border-grey-light border-b">{t("errors")}</div>
            <div className="table-cell w-2/12 text-xxms px-2 pt-8 pb-2 font-medium border-grey-light border-b">{t("numberProducts")}</div>
            <div className="table-cell w-2/12 text-xxms px-2 pt-8 pb-2 font-medium border-grey-light border-b">{t("errorsReport")}</div>
          </div>
          {
            currentTableData.map((items) => (
              <div key={items?.id} className="table-row h-24" style={classes.rowContainer}>
                <div className="table-cell border-grey-light border-b w-2/12" style={classes.productCell}>
                  <p className="text-xxms w-full px-2  break-words">{items?.filename}</p>
                </div>
                <div className="table-cell w-2/12 border-grey-light border-b" style={classes.productCell}>
                  <p className="text-xxms w-full px-2">{formatDateToFrenchFormat(formatTimestampToDateWithDashes(items?.metadata?.createdAt))}</p>
                </div>
                <div className="table-cell w-2/12 border-grey-light border-b" style={classes.productCell}>
                  <p className="text-xxms w-full px-2 break-words">{t(`${items?.importStatus}`)}</p>
                </div>
                <div className="table-cell w-2/12 border-grey-light border-b" style={classes.productCell}>
                  <p className="text-xxms w-full px-2">
                    {items?.stats?.nbError}
                  </p>
                </div>
                <div className="table-cell w-2/12 border-grey-light border-b" style={classes.productCell}>
                  <p className="text-xxms w-full px-2">{items?.stats?.nbDone}</p>
                </div>
                {items?.stats?.nbError > 0
                  && (
                    <div style={classes.productCell} className="table-cell w-2/12 border-grey-light border-b pl-3">
                      <a
                        style={classes.productCell}
                        href={`${addProtocol(process.env.REACT_APP_IMPORT_ERRORS_S3BUCKET)}/${items.S3KeyErrorReport}`}
                        download={items.S3KeyErrorReport}
                        target="_blank"
                        rel="noopener noreferrer"
                        edge="end"
                        aria-label="download"
                      >
                        <span className="text-xxms mr-2">{t("recover")}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z" clipRule="evenodd" />
                        </svg>
                      </a>
                    </div>
                  )}
                {items.statusReason
                  && (
                    <div className="table-cell w-2/12 border-grey-light border-b" style={classes.productCell}>
                      <p className="text-xxxs px-2 w-full break-words">{items?.statusReason}</p>
                    </div>
                  )}
                {items?.stats?.nbError === 0 && !items.statusReason
                  && (
                    <div className="table-cell w-2/12 border-grey-light border-b" />
                  )}
              </div>
            ))
          }
        </div>
      </div>
      <Pagination
        currentPage={currentPage}
        totalCount={arrayContent.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </>
  );
};

export default DesktopImportStatus;

DesktopImportStatus.propTypes = {
  arrayContent: PropTypes.oneOfType([PropTypes.array]).isRequired
};
