import React, { useState } from "react";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { useStyles } from "react-styles-hook";
import getStyles from "../styles";
import { ModalProductDetails } from "../../../Modal/ModalProductDetails";

const FilterProductSheet = ({ item }) => {
  const { t } = useTranslation();
  const classes = useStyles(getStyles());
  const [openModal, setOpenModal] = useState(false);
  const showModal = () => {
    setOpenModal(true);
  };
  const hideModal = () => {
    setOpenModal(false);
  };

  // refactoring tailwind classes
  const tableCell = "table-cell border-grey-ghost border-b";

  return (
    <>
      <div key={`${item.id}-${item.title.fr}`} className="table-row h-24" style={classes.rowContainer}>
        <div className={tableCell} style={classes.productCell}>
          <p className="text-xxms text-black-primary w-full px-2 text-left break-words">{item.productId}</p>
        </div>
        <div className="table-cell h-min border-grey-ghost border-b" style={classes.productCell}>
          <img src={item.images[0].url} alt={item.title.fr} style={classes.imageTab} />
        </div>
        <div className={tableCell} style={classes.productCell}>
          <p className="text-xxs text-black-primary w-full px-2">{item.title.fr}</p>
        </div>
        <div className={tableCell} style={classes.widthCell0}>
          <p className="text-xxs text-black-primary pt-10">{item.gtin}</p>
        </div>
        <div className={tableCell} style={classes.productCell}>
          <p className="text-xxs text-black-primary w-full px-2 break-words">
            {item.sellerSKU}
          </p>
        </div>
        <div className={tableCell} style={classes.productCell}>
          <p className="text-xxs text-black-primary w-full px-2">
            {item.priceWithVAT ? item.priceWithVAT.toFixed(2) : ""}
            <span> €</span>
          </p>
        </div>
        <div className={tableCell} style={classes.productCell}>
          <p className="text-sm  w-full px-2">
            {
              item.isAvailable
                ? (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-green-apple" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                )
                : (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                )
            }
          </p>
        </div>
        <div className={tableCell} style={classes.widthCell0}>
          <button
            type="button"
            id="details"
            onClick={showModal}
            data-modal-toggle={`${item.gtin}-${item.title.fr}`}
            className="text-xxs text-black-medium font-semibold pt-10"
          >
            {t("seeDetail")}
          </button>
        </div>
      </div>
      <ModalProductDetails
        showModal={openModal}
        hideModal={hideModal}
        item={item}
      />
    </>
  );
};

export default FilterProductSheet;

FilterProductSheet.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object]).isRequired
};
