import React from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { GlobeAltIcon, LightningBoltIcon, CurrencyEuroIcon, UserGroupIcon } from '@heroicons/react/outline';
import CguCgv from '../../assets/documents/cgu-cgv.pdf';
import Chart from '../../assets/documents/zawema - Charte déontologique des vendeurs.pdf';

export const WelcomePage = (props) => {
  const { t } = useTranslation();
  const features = [
    {
      name: t("welcome.featureName1"),
      description: t("welcome.featureDescription1"),
      icon: GlobeAltIcon,
    },
    {
      name: t("welcome.featureName2"),
      description: t("welcome.featureDescription2"),
      icon: CurrencyEuroIcon,
    },
    {
      name: t("welcome.featureName3"),
      description: t("welcome.featureDescription3"),
      icon: LightningBoltIcon,
    },
    {
      name: t("welcome.featureName4"),
      description: t("welcome.featureDescription4"),
      icon: UserGroupIcon,
    },
  ];

  const documents = [
    {
      name: t("welcome.docTitle1"),
      description: t("welcome.docDescription1"),
    },
    {
      name: t("welcome.docTitle2"),
      description: t("welcome.docDescription2"),
    },
    {
      name: t("welcome.docTitle3"),
      description: t("welcome.docDescription3"),
    },
    {
      name: t("welcome.docTitle4"),
      description: t("welcome.docDescription4"),
    },
    {
      name: "  ",
      description: "  ",
    },
    {
      name: t("welcome.docTitle5"),
      description: t("welcome.docDescription5"),
    },
    {
      name: t("welcome.docTitle6"),
      description: t("welcome.docDescription6"),
    },
  ];

  return (
    <div className="relative overflow-hidden shadow-sm">
      <div className="mt-16 mb-24 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
            <h2>
              <span className="mt-1 block text-xl tracking-tight font-bold sm:text-xxxl">
                <span className="block">{t("welcome.seller")}</span>
                <span className="block text-orange-chocolate">{t("welcome.onOperator")}</span>
              </span>
            </h2>
            <p className="mt-3 sm:mt-5 text-grey-middle text-xss">
              {t("welcome.introduction1")}
              <span className="font-bold">
                {t("welcome.introduction2")}
              </span>
            </p>
            <p className="text-grey-middle text-xss">
              {t("welcome.introduction3")}
            </p>
            <p className="text-grey-middle text-xss pt-6">
              {t("welcome.introduction4")}
            </p>
            <p className="pl-2 text-grey-middle text-xss pt-6 font-bold">
              <span className="font-normal">
                •&nbsp;
                {t("welcome.introduction5")}
              </span>
              {t("welcome.introduction5bold")}
            </p>
            <p className="pl-2 text-grey-middle text-xss font-bold">
              <span className="font-normal">
                •&nbsp;
                {t("welcome.introduction6")}
              </span>
              {t("welcome.introduction6bold")}
            </p>
            <Link to="/signup">
              <button
                className="p-3 mt-8 rounded-lg bg-orange-chocolate text-white border border-orange-dark font-normal hover:font-bold"
                type="button"
              >
                {t("welcome.createAccount")}
              </button>
            </Link>
            <button type="button" className="pl-2 pt-8 text-xs text-grey-middle text-xxss">
              {t("welcome.see")}
              <a href={CguCgv} target="_blank" rel="noopener noreferrer" className="font-bold uppercase">
                {t("welcome.legalConditions")}
              </a>
            </button>
            <div className="flex sm:justify-center lg:justify-start mt-3 sm:mt-5 text-grey-middle">
              <p className="text-xss">{t("welcome.alreadySignup")}</p>
              <Link to="/signup">
                <button type="button" className="text-xss underline pl-4">{t("connect")}</button>
              </Link>
            </div>
          </div>
          <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
            <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
              <button
                type="button"
                className="relative block w-full bg-white rounded-lg overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <iframe
                  width="464"
                  height="383"
                  className="w-full"
                  src="https://www.youtube.com/embed/RcaRT2-9dXY"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <h2 className="text-xms text-orange-dark font-semibold tracking-wide uppercase">{t("welcome.marketplace")}</h2>
            <h3 className="mt-2 text-semilg leading-8 font-bold tracking-tight text-gray-900">
              {t("welcome.why")}
            </h3>
            <p className="mt-4 max-w-2xl text-gray-500 lg:mx-auto text-s">
              {t("welcome.whyIntro")}
            </p>
          </div>

          <div className="mt-10 mb-10 sm:mb-28">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              {features.map((feature) => (
                <div key={feature.name} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-orange-dark text-white">
                      <feature.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 leading-6 font-medium text-gray-900 text-s">{feature.name}</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-gray-500 text-xms">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
      <div className="bg-orange pt-4 sm:pt-20 pb-20">
        <div className="px-2 sm:px-0">
          <h3 className="text-center font-semibold text-semilg">{t("welcome.subtitle")}</h3>
          <p className="text-center pt-4 text-s">{t("welcome.docIntro")}</p>
        </div>
        <dl className="bg-orange mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8 container mx-auto lg:px-24">
          {documents.map((document) => (
            <div key={document.name} className="relative">
              <dt>
                <p className="ml-9 text-s h-12 leading-6 font-medium text-orange-chocolate ">{document.name}</p>
              </dt>
              <dd className="mt-2 lg:mt-20 2xl:mt-1 ml-9 text-xms">{document.description}</dd>
            </div>
          ))}
        </dl>
      </div>
      <div className="py-20">
        <h2 className="text-xms text-center text-orange-dark font-semibold tracking-wide uppercase pb-2">{t("welcome.legalTitle")}</h2>
        <h3 className="text-center font-bold text-semilg">
          <span>{t("welcome.discover")}</span>
          <Link to="/cguv" target="_blank" rel="noopener noreferrer" className="underline uppercase">{t("welcome.legalConditions")}</Link>
          <span>{t("welcome.download")}</span>
          <a className="underline" href={Chart} download>{t("welcome.chart")}</a>
        </h3>
        <Link to="/signup" className="flex justify-center">
          <button
            className="p-4 px-5 mt-8 rounded-lg bg-orange-chocolate text-white border border-orange-dark font-normal hover:font-bold"
            type="button"
          >
            {t("welcome.createAccount")}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default WelcomePage;
