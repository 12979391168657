import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'formik';
import { CustomButton } from '../Button';

const SubmitForm = (props) => {
  const { t } = useTranslation();
  const { submitPasswordErrorMessage, submitPasswordLoading, submitPasswordSuccessMessage } = useSelector((state) => ({
    submitPasswordErrorMessage: state.getIn(['app', 'submitPasswordErrorMessage']),
    submitPasswordLoading: state.getIn(['app', 'submitPasswordLoading']),
    submitPasswordSuccessMessage: state.getIn(['app', 'submitPasswordSuccessMessage'])
  }));
  const {
    values,
    errors,
    onSubmit,
    handleBlur,
    handleChange,
    handleSubmit
  } = props;
  const { code, password, confirmPassword } = values;

  return (
    <Form className="container flex flex-col justify-center items-center w-full">
      {/* verification code section */}
      <div className="w-full px-4 flex flex-col justify-center items-center">
        <p className="text-xs py-4">
          {t("forgotPassword.explanation")}
        </p>
        <div className="w-full flex flex-col items-center mb-6">
          <label className="text-xs font-sans mb-2 md:mb-0 w-full pl-0 md:pl-8">{t('code')}</label>
          <div>
            <Field
              id="code"
              name="code"
              type="text"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-white focus:outline-none focus:ring-orange-light focus:border-orange-dark sm:text-xxs"
            />
            {errors.code ? (
              <p className="text-red-600 text-center md:text-left">{errors.code}</p>
            ) : null }
          </div>
        </div>
      </div>
      {/* Add new password */}
      <div className="w-full px-4 flex flex-col justify-center items-center">
        <div className="w-full flex flex-col items-center mb-6">
          <label className="text-xs font-sans mb-2 md:mb-0 w-full pl-0 md:pl-8">{t('password')}</label>
          <div>
            <Field
              id="password"
              name="password"
              type="password"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-white focus:outline-none focus:ring-orange-light focus:border-orange-dark sm:text-xxs"
            />
            {errors.password ? (
              <p className="text-red-600 text-center md:text-left">{errors.password}</p>
            ) : null }
          </div>
        </div>
      </div>
      {/* confirm new password */}
      <div className="w-full px-4 flex flex-col justify-center items-center">
        <div className="w-full flex flex-col items-center mb-6">
          <label className="text-xs font-sans mb-2 md:mb-0 w-full pl-0 md:pl-8">{t('confirmPassword')}</label>
          <div>
            <Field
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-white focus:outline-none focus:ring-orange-light focus:border-orange-dark sm:text-xxs"
            />
            {errors.confirmPassword ? (
              <p className="text-red-600 text-center md:text-left">{errors.confirmPassword}</p>
            ) : null }
          </div>
        </div>
      </div>
      <CustomButton
        type="submit"
        onclick={() => onSubmit}
        title={t('submitNewPasswordButton')}
      />
      <p className="pt-4 text-red text-sm text-center">{submitPasswordErrorMessage}</p>
      <p className="pt-4 text-green-500 text-center text-sm">{submitPasswordSuccessMessage}</p>
    </Form>
  );
};

export default SubmitForm;
