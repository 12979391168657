import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BadgeCheckIcon, ChartPieIcon, ShoppingCartIcon, TagIcon, ExclamationIcon } from '@heroicons/react/solid';
import useFetchSellerOrders from '../../hooks/useFetchSellerOrders';
import { logoutLaunched } from "../../components/App/reducer";
import { getOffersLaunched, getProductSheetsLaunched, getSellerLaunched, getSellerOrdersLaunched } from '../../store/reducer';
import { StatArray } from "../../components/StatArray";
import { ContactForm } from '../../components/ContactForm';

export const HomePage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const logout = () => {
    dispatch(logoutLaunched());
  };

  const [openForm, setOpenForm] = useState(false);

  const { offers, productSheets, sellerOrders } = useSelector((state) => (
    {
      offers: state.getIn(['store', 'offers']),
      productSheets: state.getIn(['store', 'productSheets']),
      sellerOrders: state.getIn(['store', 'sellerOrders']),
    }
  ));

  useEffect(() => {
    dispatch(getOffersLaunched({ offers: id }));
    dispatch(getProductSheetsLaunched({ productSheets }));
    dispatch(getSellerOrdersLaunched({ sellerOrders }));
  }, [dispatch]);

  const ordersWaitingAcceptance = useFetchSellerOrders("WAITING_ACCEPTANCE");
  const ordersIncident = useFetchSellerOrders("INCIDENT");

  let dataProductsAvailable;
  let dataOffersAvailable;

  let numberOfProductsAvailable = "...";
  let numberOfOffersAvailable = "...";
  let revenues = 0;
  const currencyEuro = "€";

  if (productSheets && offers) {
    dataProductsAvailable = productSheets.filter((product) => product.isAvailable === true);
    numberOfProductsAvailable = dataProductsAvailable.length;

    dataOffersAvailable = offers.filter((offer) => offer.isAvailable === true);
    numberOfOffersAvailable = dataOffersAvailable.length;
  }

  if (sellerOrders?.length > 0) {
    sellerOrders.forEach((sellerOrder) => {
      revenues += Number(sellerOrder.revenusWithoutVAT);
    });
    revenues = revenues.toFixed(2);
  } else {
    revenues = 0;
  }

  const stats = [
    {
      id: 1,
      name: t("homePage.statItem1"),
      stat: numberOfProductsAvailable,
      icon: BadgeCheckIcon,
    },
    {
      id: 2,
      name: t("homePage.statItem2"),
      stat: revenues + currencyEuro,
      icon: ChartPieIcon,
    },
    {
      id: 3,
      name: t("homePage.statItem3"),
      stat: ordersWaitingAcceptance ? ordersWaitingAcceptance?.length + t("homePage.waiting") : "0",
      icon: ShoppingCartIcon,
    },
    {
      id: 4,
      name: t("homePage.statItem4"),
      stat: numberOfOffersAvailable,
      icon: TagIcon,
    },
    {
      id: 5,
      name: t("homePage.statItem5"),
      stat: ordersIncident ? 0 : ordersIncident?.length,
      icon: ExclamationIcon,
    },
  ];

  const subjectList = [t("contactForm.order"), t("contactForm.catalog"), t("contactForm.imports"), t("contactForm.logistic"), t("contactForm.account"), t("contactForm.other")];

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <>
      <div className="w-full md:w-3/4 px-4 md:mx-auto pt-8">
        <h2 className="text-s leading-6 font-normal text-gray-900 pl-5 md:pl-0">{t("homePage.title")}</h2>
        <StatArray stats={stats} />
        <div className="flex flex-col mt-10 md:mt-20">
          {
            (!openForm)
            && (
              <>
                <span className="self-center mt-10 py-1 px-6 text-xxs text-white font-normal rounded bg-orange-dark hover:bg-orange-chocolate">
                  {t("homePage.btnDownloadGuide")}
                </span>
                {/* TODO : intégrer le guide vendeur, supprimer la ligne 114 a 116 et décommenter la balise <a> */}
                {/* <a
                  href="/"
                  className="self-center mt-10 py-1 px-6 text-xxs text-white font-normal rounded bg-orange-dark hover:bg-orange-chocolate"
                >
                  {t("homePage.btnDownloadGuide")}
                </a> */}
                <p
                  className="self-center mt-6 font-normal text-xxms"
                >
                  {t("homePage.problemQuestion")}
                </p>
                <button
                  type="button"
                  className="font-medium text-xxms"
                  onClick={setOpenForm}
                >
                  {t("homePage.contact")}
                </button>
              </>
            )
          }
        </div>
        <div className="my-2">
          <ContactForm subjectList={subjectList} openForm={openForm} setOpenForm={setOpenForm} />
        </div>
      </div>
    </>
  );
};

export default HomePage;
