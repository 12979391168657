import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { ModalFormBusiness } from '../../Modal/ModalFormBusiness';
import { strNoAccentNoSpecialChar } from '../../../utils/format';
import { UploadFile } from '../../UploadFile';
import { uploadFileOnboardingLaunched } from '../../../store/reducer';
import countriesJSON from "../../../utils/countries.json";

const BusinessInfosForm = ({ step, setStep, formValue, setFormValue }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  // input value state to Reset
  const [eventToReset, setEventToReset] = useState(null);

  const showModal = () => {
    setOpenModal(true);
  };

  const hideModal = () => {
    setOpenModal(false);
  };

  const countriesMapped = countriesJSON;

  const [companyTelephone, setCompanyTelephone] = useState(formValue.companyTelephone);
  if (companyTelephone === undefined) {
    return (
      setCompanyTelephone('')
    );
  }

  // Form data
  const initialValues = {
    socialReason: formValue.socialReason,
    companyEmail: formValue.companyEmail,
    companyTelephone: formValue.companyTelephone,
    companyAdress: formValue.companyAdress,
    companyZipCode: formValue.companyZipCode,
    companyCity: formValue.companyCity,
    companyCountry: formValue.companyCountry,
    companyId: formValue.companyId,
    companyWebsite: formValue.companyWebsite,
    files: formValue.files,
    businessAdressFiles: formValue.businessAdressFiles,
    registrationFiles: formValue.registrationFiles,
    statutesFiles: formValue.statutesFiles
  };

  // Form Submitting Function
  const signup = () => {
    setStep(step + 1);
    window.scrollTo(0, 0);
  };

  // Upload file on S3 function
  const uploadFile = (file, identificator) => {
    dispatch(uploadFileOnboardingLaunched({ file, identificator }));
  };

  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-lg">
      <h2 className="text-sm md:text-s font-bold pb-2 w-full mx-auto lg:w-5/6">{t("signUpBusiness.companyInfo")}</h2>
      <div className="w-full mx-auto">
        <Formik
          initialValues={initialValues}
          validate={(values) => {
            const errors = {};
            // For validate Social Reason
            if (!values.socialReason) {
              if (formValue.isSoleTrader) {
                errors.socialReason = t("signUpBusiness.shopNameRequired");
              } else {
                errors.socialReason = t("signUpBusiness.socialReasonRequired");
              }
            }

            // For validate company's email
            if (!values.companyEmail) {
              errors.companyEmail = t("signUpBusiness.emailRequired");
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.companyEmail)
            ) {
              errors.companyEmail = t("signUpForm.emailType");
            }

            // For validate company's adress (first input)
            if (!values.companyAdress) {
              errors.companyAdress = t("signUpBusiness.adressRequired");
            }

            // For validate company's Phone number
            if (isValidPhoneNumber(companyTelephone) === false) {
              errors.companyTelephone = t("signUpBusiness.telephoneRequired");
            }

            // For validate company's CP
            if (!values.companyZipCode) {
              errors.companyZipCode = t("signUpBusiness.cpRequired");
            } else if (!Number.isInteger(values.companyZipCode) || values.companyZipCode <= 0) {
              errors.companyZipCode = t("signUpBusiness.cpFormat");
            }

            // For validate company's city
            if (!values.companyCity) {
              errors.companyCity = t("signUpBusiness.cityRequired");
            }

            // For validate company's country
            if (!values.companyCountry) {
              errors.companyCountry = t("signUpBusiness.countryRequired");
            }

            // For validate identification number (only for Business user)
            if (!formValue.isSoleTrader) {
              if (!values.companyId) {
                errors.companyId = t("signUpBusiness.identificationNumberRequired");
              } else if (!Number.isInteger(values.companyId) || values.companyId <= 0) {
                errors.companyId = t("signUpBusiness.identificationNumberFormat");
              }
            }

            // For validate URL in company's website
            if ((!new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_])?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(values.companyWebsite)) && values.companyWebsite !== null && values.companyWebsite !== "") {
              errors.companyWebsite = t("signUpBusiness.websiteUrl");
            }

            // For validate file's input
            if (!formValue.isSoleTrader && (values.businessAdressFiles === null || values.businessAdressFiles.length === 0)) {
              errors.businessAdressFiles = t("signUpBusiness.fileRequired");
            } else if (values.registrationFiles === null || values.registrationFiles.length === 0) {
              errors.registrationFiles = t("signUpBusiness.fileRequired");
            } else if (!formValue.isSoleTrader && (values.statutesFiles === null || values.statutesFiles.length === 0)) {
              errors.statutesFiles = t("signUpBusiness.fileRequired");
            } else if (!formValue.isSoleTrader && values.businessAdressFiles.length > 1) {
              errors.businessAdressFiles = t("signUpBusiness.numberOfFile");
            } else if (values.registrationFiles.length > 1) {
              errors.registrationFiles = t("signUpBusiness.numberOfFile");
            } else if (!formValue.isSoleTrader && values.statutesFiles.length > 1) {
              errors.statutesFiles = t("signUpBusiness.numberOfFile");
            } else {
              values.businessAdressFiles.forEach((value) => {
                if (value.size > 3000000) {
                  errors.businessAdressFiles = (t("signUpBusiness.fileSize"));
                } else if (value.size < 32000) {
                  errors.businessAdressFiles = (t("signUpBusiness.fileToSmall"));
                }
              });
              values.registrationFiles.forEach((value) => {
                if (value.size > 3000000) {
                  errors.registrationFiles = (t("signUpBusiness.fileSize"));
                } else if (value.size < 32000) {
                  errors.registrationFiles = (t("signUpBusiness.fileToSmall"));
                }
              });
              values.statutesFiles.forEach((value) => {
                if (value.size > 3000000) {
                  errors.statutesFiles = (t("signUpBusiness.fileSize"));
                } else if (value.size < 32000) {
                  errors.statutesFiles = (t("signUpBusiness.fileToSmall"));
                }
              });
            }

            return errors;
          }}
          onSubmit={signup}
        >
          {({ values, errors, touched, setFieldValue, handleChange }) => (
            <Form className="mx-auto w-5/6 flex flex-col justify-center items-center">
              <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
                <div className="w-full">
                  <label className="block text-xxms font-medium">{`${formValue.isSoleTrader ? t("signUpBusiness.shopName") : t("signUpBusiness.socialName")} *`}</label>
                  <Field
                    id="socialReason"
                    name="socialReason"
                    type="text"
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value
                      }));
                    }}
                    placeholder={formValue.isSoleTrader ? t("signUpBusiness.shopName") : t("signUpBusiness.socialName")}
                    className="w-full border-grey-light rounded text-xxms text-gray-500"
                  />
                  {errors.socialReason && touched.socialReason ? (
                    <p className="text-red-600 text-center md:text-left">{errors.socialReason}</p>
                  ) : null}
                </div>
              </div>

              {/* PhoneInput field : go to index css to customize it */}
              <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
                <div className="w-full">
                  <label className="block text-xxms font-medium">{`${t('signUpBusiness.email')} *`}</label>
                  <Field
                    id="companyEmail"
                    name="companyEmail"
                    type="email"
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value
                      }));
                    }}
                    placeholder={`ex: ${t("signUpBusiness.mailExample")}`}
                    className="w-full border-grey-light rounded text-xxms text-gray-500"
                  />
                  {errors.companyEmail && touched.companyEmail ? (
                    <p className="text-red-600 text-center md:text-left">{errors.companyEmail}</p>
                  ) : null}
                </div>
              </div>

              <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
                <div className="w-full">
                  <label className="block text-xxms font-medium">{`${t('signUpBusiness.telephone')} *`}</label>
                  <PhoneInput
                    international
                    defaultCountry="FR"
                    placeholder={t("signUpBusiness.phoneNumber")}
                    value={companyTelephone}
                    onChange={(e) => {
                      setCompanyTelephone(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        companyTelephone: e
                      }));
                    }}
                  />
                  {isValidPhoneNumber(companyTelephone) === false
                    ? (
                      <p className="text-red-500 pl-10">{t("signUpBusiness.telephoneFormat")}</p>
                    )
                    : null}
                </div>
              </div>

              <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-4 md:mb-6">
                <div className="w-full">
                  <label className="block text-xxms font-medium">{`${t('signUpBusiness.adress')} *`}</label>
                  <Field
                    id="companyAdress"
                    name="companyAdress"
                    type="text"
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value
                      }));
                    }}
                    className="w-full border-grey-light rounded text-xxms text-gray-500"
                    placeholder={`ex: ${t("signUpInfos.adressPlaceholder")}`}
                  />
                  {errors.companyAdress && touched.companyAdress ? (
                    <p className="text-red-600 text-center md:text-left">{errors.companyAdress}</p>
                  ) : null}
                </div>
              </div>

              <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-4 md:mb-6">
                <div className="w-full md:flex">
                  {/* ZipCode field */}
                  <div className="w-full md:1/5 md:pr-4">
                    <Field
                      id="companyZipCode"
                      name="companyZipCode"
                      type="number"
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: parseInt(e.target.value, 10)
                        }));
                      }}
                      placeholder="ex: 75000"
                      className="w-full border-grey-light rounded text-xxms text-gray-500"
                    />
                    {errors.companyZipCode && touched.companyZipCode ? (
                      <p className="text-red-600 text-center md:text-left">{errors.companyZipCode}</p>
                    ) : null}
                  </div>
                  {/* City field */}
                  <div className="w-full md:w-4/5 mb-4 md:mb-0 mt-4 md:mt-0">
                    <Field
                      id="companyCity"
                      name="companyCity"
                      type="text"
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value
                        }));
                      }}
                      placeholder="ex: Paris"
                      className="w-full border-grey-light rounded text-xxms text-gray-500"
                    />
                    {errors.companyCity && touched.companyCity ? (
                      <p className="text-red-600 text-center md:text-left">{errors.companyCity}</p>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-4 md:mb-6">
                <div className="w-full">
                  <select
                    id="companyCountry"
                    name="companyCountry"
                    defaultValue=""
                    type={values.companyCountry}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value
                      }));
                    }}
                    className="w-full border-grey-light rounded text-xxms text-gray-500"
                  >
                    <option value="" disabled label={t("selectCountry")} />
                    {
                      countriesMapped.map((country) => (
                        <option key={country.alpha_3_code} value={country.alpha_2_code}>
                          {country.en_short_name}
                        </option>
                      ))
                    }
                  </select>
                  {errors.companyCountry && touched.companyCountry ? (
                    <p className="text-red-600 text-center md:text-left">{errors.companyCountry}</p>
                  ) : null}
                </div>
              </div>

              {formValue.isSoleTrader
                ? null
                : (
                  <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
                    <div className="w-full">
                      <label className="block text-xxms font-medium">{`${t('signUpBusiness.identificationNumber')} *`}</label>
                      <Field
                        id="companyId"
                        name="companyId"
                        type="number"
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: parseInt(e.target.value, 10)
                          }));
                        }}
                        placeholder="ex: 111 222 333"
                        className="w-full border-grey-light rounded text-xxms text-gray-500"
                      />
                      {errors.companyId && touched.companyId ? (
                        <p className="text-red-600 text-center md:text-left">{errors.companyId}</p>
                      ) : null}
                    </div>
                  </div>
                )}

              <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
                <div className="w-full">
                  <label className="block text-xxms font-medium">{`${t('signUpBusiness.website')}`}</label>
                  <Field
                    id="companyWebsite"
                    name="companyWebsite"
                    type="text"
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value
                      }));
                    }}
                    placeholder={`${t('signUpBusiness.websiteUrl')}`}
                    className="w-full border-grey-light rounded text-xxms text-gray-500"
                  />
                  {errors.companyWebsite ? (
                    <p className="text-red-600 text-center md:text-left">{errors.companyWebsite}</p>
                  ) : null}
                </div>
              </div>

              {/* Upload section */}
              <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center">
                <div className="w-full md:w-4/6 lg:3/4">
                  <div>
                    <span className="text-xxms font-medium">
                      {t("signUpBusiness.obligatory")}
                    </span>
                    <button
                      type="button"
                      className="pt-2"
                      onClick={() => {
                        setOpenModal(!openModal);
                      }}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-4" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="w-full mb-2 text-xxs italic flex flex-col space-y-1">
                <span>{t("signUpInfos.infoFile")}</span>
                <span>{t("signUpInfos.tipFile")}</span>
              </div>
              {/* Upload company registrationFiles like kbis */}
              <UploadFile
                identificator="registrationFiles"
                label={formValue.isSoleTrader ? t('signUpBusiness.uploadRegisterySoleTrader') : t('signUpBusiness.uploadRegistery')}
                onInput={(event) => {
                  setEventToReset(event.target);
                  if (!values.registrationFiles) {
                    values.registrationFiles = [];
                  }
                  if (values.registrationFiles.length >= 1) {
                    values.registrationFiles = [];
                  } // reset array after wrong upload
                  if (formValue.registrationFiles.length >= 1) {
                    formValue.registrationFiles.length = [];
                  } // reset array after wrong upload
                  const registrationFilesArray = values.registrationFiles;
                  if (event.target?.files[0]) {
                    const fileName = strNoAccentNoSpecialChar(event.target?.files[0].name);
                    const fileType = event.target?.files[0].type.slice(event.target?.files[0].type.indexOf("/") + 1, event.target?.files[0].type.length); // if file.type = img/png -> fileType = png
                    const fileSize = event.target?.files[0].size;
                    const file = event.target?.files[0];
                    const reader = new FileReader();
                    reader.onload = () => {
                      setFieldValue("registrationFiles", registrationFilesArray.concat({ name: fileName, type: fileType, fileData: `${fileName}_${fileSize}_registrationFiles.${fileType}`, size: fileSize }));
                      setFormValue((prevState) => ({
                        ...prevState,
                        [event.target.name]: values.registrationFiles.concat({ docType: 'kbis', name: fileName, type: fileType, fileData: `${fileName}_${fileSize}_registrationFiles.${fileType}`, size: fileSize })
                      }));
                    };
                    reader.readAsDataURL(file);
                    uploadFile(file, "registrationFiles");
                  }
                }}
                arrayValues={values.registrationFiles}
                errors={errors.registrationFiles}
                touched={touched.registrationFiles}
                setFormValue={setFormValue}
                formValue={formValue}
                setFieldValue={setFieldValue}
                eventToReset={eventToReset}
              />

              {formValue.isSoleTrader ? null
                : (
                  <>
                    {/* Upload company adress file */}
                    <UploadFile
                      identificator="businessAdressFiles"
                      label={t('signUpBusiness.uploadAdressProof')}
                      onInput={(event) => {
                        setEventToReset(event.target);
                        if (!values.businessAdressFiles) {
                          values.businessAdressFiles = [];
                        }
                        if (values.businessAdressFiles.length >= 1) {
                          values.businessAdressFiles = [];
                        } // reset array after wrong upload
                        if (formValue.businessAdressFiles.length >= 1) {
                          formValue.businessAdressFiles.length = [];
                        } // reset array after wrong upload
                        const businessAdressFilesArray = values.businessAdressFiles;
                        if (event.target?.files[0]) {
                          const fileName = strNoAccentNoSpecialChar(event.target?.files[0].name);
                          const fileType = event.target?.files[0].type.slice(event.target?.files[0].type.indexOf("/") + 1, event.target?.files[0].type.length); // if file.type = img/png -> fileType = png
                          const fileSize = event.target?.files[0].size;
                          const file = event.target?.files[0];
                          const reader = new FileReader();
                          reader.onload = () => {
                            setFieldValue("businessAdressFiles", businessAdressFilesArray.concat({ name: fileName, type: fileType, fileData: `${fileName}_${fileSize}_businessAdressFiles.${fileType}`, size: fileSize }));
                            setFormValue((prevState) => ({
                              ...prevState,
                              [event.target.name]: values.businessAdressFiles.concat({ docType: 'addressProof', name: fileName, type: fileType, fileData: `${fileName}_${fileSize}_businessAdressFiles.${fileType}`, size: fileSize })
                            }));
                          };
                          reader.readAsDataURL(file);
                          uploadFile(file, "businessAdressFiles");
                        }
                      }}
                      arrayValues={values.businessAdressFiles}
                      errors={errors.businessAdressFiles}
                      touched={touched.businessAdressFiles}
                      setFormValue={setFormValue}
                      formValue={formValue}
                      setFieldValue={setFieldValue}
                      eventToReset={eventToReset}
                    />

                    {/* Upload company creation files like status */}
                    <UploadFile
                      identificator="statutesFiles"
                      label={t('signUpBusiness.uploadStatutes')}
                      onInput={(event) => {
                        setEventToReset(event.target);
                        if (!values.statutesFiles) {
                          values.statutesFiles = [];
                        }
                        if (values.statutesFiles.length >= 1) {
                          values.statutesFiles = [];
                        } // reset array after wrong upload
                        if (formValue.statutesFiles.length >= 1) {
                          formValue.statutesFiles.length = [];
                        } // reset array after wrong upload
                        const statutesFilesArray = values.statutesFiles;
                        if (event.target?.files[0]) {
                          const fileName = strNoAccentNoSpecialChar(event.target?.files[0].name);
                          const fileType = event.target?.files[0].type.slice(event.target?.files[0].type.indexOf("/") + 1, event.target?.files[0].type.length); // if file.type = img/png -> fileType = png
                          const fileSize = event.target?.files[0].size;
                          const file = event.target?.files[0];
                          const reader = new FileReader();
                          reader.onload = () => {
                            setFieldValue("statutesFiles", statutesFilesArray.concat({ name: fileName, type: fileType, fileData: `${fileName}_${fileSize}_statutesFiles.${fileType}`, size: fileSize }));
                            setFormValue((prevState) => ({
                              ...prevState,
                              [event.target.name]: values.statutesFiles.concat({ docType: 'statute', name: fileName, type: fileType, fileData: `${fileName}_${fileSize}_statutesFiles.${fileType}`, size: fileSize })
                            }));
                          };
                          reader.readAsDataURL(file);
                          uploadFile(file, "statutesFiles");
                        }
                      }}
                      arrayValues={values.statutesFiles}
                      errors={errors.statutesFiles}
                      touched={touched.statutesFiles}
                      setFormValue={setFormValue}
                      formValue={formValue}
                      setFieldValue={setFieldValue}
                      eventToReset={eventToReset}
                    />
                  </>
                )}

              <div className="w-full flex flex-col-reverse md:flex-row justify-center items-center my-6">
                <button
                  type="button"
                  className="border border-grey-light bg-white mt-4 md:mt-0 py-2 px-4 rounded-md shadow-sm text-xxms font-medium text-black hover:bg-orange-dark hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange"
                  onClick={() => {
                    if (formValue.isSoleTrader) {
                      setStep(step - 2);
                    } else {
                      setStep(step - 1);
                    }
                    window.scrollTo(0, 0);
                  }}
                >
                  {t("previous")}
                </button>
                <button
                  type="submit"
                  className="border border-grey-light md:ml-3 inline-flex justify-center py-2 px-4 shadow-sm text-xxms font-medium rounded-md text-black bg-white hover:bg-orange-dark hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange"
                >
                  {t("next")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <ModalFormBusiness showModal={openModal} hideModal={hideModal} isSoleTrader={formValue.isSoleTrader} />
    </div>
  );
};

export default BusinessInfosForm;
