import React from 'react';

export const Footer = () => (
  <footer className="bg-white inset-x-0 py-4 md:py-6 flex flex-col" aria-labelledby="footer-heading">
    <p className="text-grey-light text-center text-xs md:text-s font-sans">Powered by BeeForYou - 2022 -</p>
  </footer>

);

export default Footer;
