import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Formik, Field, Form } from 'formik';
import { ModalContact } from '../Modal/ModalContact';
import { capitalizeFirst } from '../../utils/format';
import { sellerCompanyInfoLaunched } from '../../store/reducer';

export const AccountInfo = () => {
  const { t } = useTranslation();
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const dispatch = useDispatch();

  const [validation, setValidation] = useState(null);

  const { userDynamo } = useSelector((state) => ({
    userDynamo: state.getIn(['app', 'userDynamo']),
  }));

  const [currSeller, setCurrSeller] = useState(userDynamo);

  // TODO get userDynamo updated after submit form
  // const isInitialMount = useRef(true);
  // useEffect(() => {
  //   if (isInitialMount.current) {
  //     isInitialMount.current = false;
  //     return null;
  //   }
  //   // return null;
  //   return () => dispatch(getCurrentSessionLaunched());
  // }, [userDynamo]);

  useEffect(() => {
    if (userDynamo !== null) {
      setCurrSeller(userDynamo);
    }
  }, [userDynamo]);

  const sellerInfo = currSeller?.seller;
  const sellerId = sellerInfo?.id;

  // Modale
  const [openModalContact, setOpenModalContact] = useState(false);
  const showModal = (e) => {
    if (e.target.id === "company"
      || e.target.id === "siret"
      || e.target.id === "adress"
      || e.target.id === "mailCompany"
      || e.target.id === "phoneCompany"
      || e.target.id === "fullName"
      || e.target.id === "sellerMail"
      || e.target.id === "iban"
      || e.target.id === "bic"
    ) {
      setOpenModalContact(true);
    }
  };
  const hideModal = () => {
    setOpenModalContact(false);
  };

  // Form Company Info
  let initialValues;
  if (sellerInfo
    && sellerInfo.companyAbout
    && sellerInfo.companyShipping
    && sellerInfo.companyReturn
    && sellerInfo.companyPersonalData
  ) {
    initialValues = {
      id: sellerId,
      companyAbout: sellerInfo.companyAbout,
      companyShipping: sellerInfo.companyShipping,
      companyReturn: sellerInfo.companyReturn,
      companyPersonalData: sellerInfo.companyPersonalData,
    };
  } else {
    initialValues = {
      id: sellerId,
      companyAbout: '',
      companyShipping: '',
      companyReturn: '',
      companyPersonalData: '',
    };
  }

  const [formValue, setFormValue] = useState({
    id: initialValues.id,
    companyAbout: initialValues.companyAbout,
    companyShipping: initialValues.companyShipping,
    companyReturn: initialValues.companyReturn,
    companyPersonalData: initialValues.companyPersonalData,
  });

  // Form submitting function to save
  const onSubmit = () => {
    dispatch(sellerCompanyInfoLaunched({ formValue }));
    setValidation(t("accountPage.succes"));
  };

  // Form validation schema
  const ValidationSchema = Yup.object({
    companyAbout: Yup.string()
      .min(5, 'Texte trop court')
      .max(5000, 'Texte trop long')
      .required('Merci de renseigner des informations'),
    companyShipping: Yup.string()
      .min(5, 'Texte trop court')
      .max(5000, 'Texte trop long')
      .required('Merci de renseigner des informations'),
    companyReturn: Yup.string()
      .min(5, 'Texte trop court')
      .max(5000, 'Texte trop long')
      .required('Merci de renseigner des informations'),
    companyPersonalData: Yup.string()
      .min(5, 'Texte trop court')
      .max(5000, 'Texte trop long')
      .required('Merci de renseigner des informations'),
  });

  const subjectList = [t("accountPage.editMyInformation")];

  return (
    <>
      <div className="flex flex-col lg:flex-row space-y-12 lg:space-y-0 lg:space-x-24">
        {/* Editable informations */}
        <div className="w-full lg:w-3/5 space-y-6">
          <h2 className="text-black-title font-medium text-xxms">{capitalizeFirst(t("accountPage.title2"))}</h2>
          <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={onSubmit}
          >
            {({ errors, touched, values, handleChange }) => (
              <Form>
                <div className="space-y-6">
                  <div>
                    <label htmlFor="companyAbout" className="block text-xxms font-normal text-title">
                      {capitalizeFirst(t("accountPage.aboutUs"))}
                    </label>
                    <div className="mt-1">
                      <Field
                        id="companyAbout"
                        name="companyAbout"
                        component="textarea"
                        rows="4"
                        value={values.companyAbout}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value
                          }));
                        }}
                        className="shadow-sm focus:ring-orange-chocolate focus:border-orange-chocolate mt-1 block w-full text-xxms border border-gray-300 rounded-md"
                      />
                      {errors.companyAbout && touched.companyAbout && (
                        <p className="px-2 text-red text-center md:text-left">{errors.companyAbout}</p>
                      )}
                    </div>
                    <p className="mt-2 text-xxms text-gray-500">
                      {capitalizeFirst(t("accountPage.aboutUsDesc"))}
                    </p>
                  </div>
                  <div>
                    <label htmlFor="companyShipping" className="block text-xxms font-normal text-title">
                      {capitalizeFirst(t("accountPage.shippingCondition"))}
                    </label>
                    <div className="mt-1">
                      <Field
                        id="companyShipping"
                        name="companyShipping"
                        component="textarea"
                        rows="4"
                        value={values.companyShipping}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value
                          }));
                        }}
                        className="shadow-sm focus:ring-orange-chocolate focus:border-orange-chocolate mt-1 block w-full text-xxms border border-gray-300 rounded-md"
                      />
                      {errors.companyShipping && touched.companyShipping && (
                        <p className="px-2 text-red text-center md:text-left">{errors.companyShipping}</p>
                      )}
                    </div>
                    <p className="mt-2 text-xxms text-gray-500">
                      {capitalizeFirst(t("accountPage.shippingConditionDesc"))}
                    </p>
                  </div>
                  <div>
                    <label htmlFor="companyReturn" className="block text-xxms font-normal text-title">
                      {capitalizeFirst(t("accountPage.returnCondition"))}
                    </label>
                    <div className="mt-1">
                      <Field
                        id="companyReturn"
                        name="companyReturn"
                        component="textarea"
                        rows="4"
                        value={values.companyReturn}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value
                          }));
                        }}
                        className="shadow-sm focus:ring-orange-chocolate focus:border-orange-chocolate mt-1 block w-full text-xxms border border-gray-300 rounded-md"
                      />
                      {errors.companyReturn && touched.companyReturn && (
                        <p className="px-2 text-red text-center md:text-left">{errors.companyReturn}</p>
                      )}
                    </div>
                    <p className="mt-2 text-xxms text-gray-500">
                      {capitalizeFirst(t("accountPage.returnConditionDesc"))}
                    </p>
                  </div>
                  <div>
                    <label htmlFor="companyPersonalData" className="block text-xxms font-normal text-title">
                      {capitalizeFirst(t("accountPage.personalDataCondition"))}
                    </label>
                    <div className="mt-1">
                      <Field
                        id="companyPersonalData"
                        name="companyPersonalData"
                        component="textarea"
                        rows="4"
                        value={values.companyPersonalData}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value
                          }));
                        }}
                        className="shadow-sm focus:ring-orange-chocolate focus:border-orange-chocolate mt-1 block w-full text-xxms border border-gray-300 rounded-md"
                      />
                      {errors.companyPersonalData && touched.companyPersonalData && (
                        <p className="px-2 text-red text-center md:text-left">{errors.companyPersonalData}</p>
                      )}
                    </div>
                    <p className="mt-2 text-xxms text-gray-500">
                      {capitalizeFirst(t("accountPage.personalDataDesc"))}
                    </p>
                  </div>
                </div>
                <button
                  type="submit"
                  className="mt-12 mb-8 px-14 py-1.5 border border-transparent rounded-md shadow-sm text-xxms font-medium text-white bg-orange-dark hover:bg-orange-chocolate focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dark capitalize"
                >
                  {capitalizeFirst(t("save"))}
                </button>
                {validation && <p className="text-center text-gray-500">{validation}</p>}
              </Form>
            )}
          </Formik>
        </div>

        {/* Uneditable informations */}
        <div className="w-full lg:w-2/5 space-y-6">
          <h1 className="text-black-title font-medium text-xxms">{capitalizeFirst(t("accountPage.title1"))}</h1>
          <div>
            <span className="block text-xxms font-normal text-gray-600">
              {capitalizeFirst(t("accountPage.companyName"))}
            </span>
            <div className="mt-1">
              <button
                type="button"
                name="company"
                id="company"
                className="py-2 pl-2 text-left text-gray-500 block w-full text-xxms border border-gray-300 rounded-md"
                onClick={showModal}
              >
                {sellerInfo.socialReason}
              </button>
            </div>
          </div>
          <div>
            <span className="block text-xxms font-normal text-gray-600">
              {capitalizeFirst(t("accountPage.companyNumber"))}
            </span>
            <div className="mt-1">
              <button
                type="button"
                name="siret"
                id="siret"
                className="py-2 pl-2 text-left text-gray-500 block w-full text-xxms border border-gray-300 rounded-md"
                onClick={showModal}
              >
                {sellerInfo.companyId}
              </button>
            </div>
          </div>
          <div>
            <span className="block text-xxms font-normal text-gray-600">
              {capitalizeFirst(t("accountPage.companyAdress"))}
            </span>
            <div className="mt-1">
              <button
                type="button"
                name="adress"
                id="adress"
                className="py-2 pl-2 text-left text-gray-500 block w-full text-xxms border border-gray-300 rounded-md"
                onClick={showModal}
              >
                <span className="pointer-events-none">{sellerInfo.companyAdress1}</span>
                <span className="pointer-events-none pl-1.5">{sellerInfo.companyZipCode}</span>
                <span className="pointer-events-none pl-1.5">{sellerInfo.companyCity}</span>
                <span className="pointer-events-none pl-1.5">{sellerInfo.companyCountry}</span>
              </button>
            </div>
          </div>
          <div>
            <span className="block text-xxms font-normal text-gray-600">
              {capitalizeFirst(t("accountPage.companyMail"))}
            </span>
            <div className="mt-1">
              <button
                type="button"
                name="mailCompany"
                id="mailCompany"
                className="py-2 pl-2 text-left text-gray-500 block w-full text-xxms border border-gray-300 rounded-md"
                onClick={showModal}
              >
                {sellerInfo.email}
              </button>
            </div>
          </div>
          <div>
            <span className="block text-xxms font-normal text-gray-600">
              {capitalizeFirst(t("phoneNumber"))}
            </span>
            <div className="mt-1">
              <button
                type="button"
                name="phoneCompany"
                id="phoneCompany"
                className="py-2 pl-2 text-left text-gray-500 block w-full text-xxms border border-gray-300 rounded-md"
                onClick={showModal}
              >
                {sellerInfo.companyTelephone}
              </button>
            </div>
          </div>

          <h2 className="text-black-title font-medium text-xxms">{capitalizeFirst(t("accountPage.title3"))}</h2>
          <div>
            <span className="block text-xxms font-normal text-gray-600">
              {capitalizeFirst(t("accountPage.sellerIdentity"))}
            </span>
            <div className="mt-1">
              <button
                type="button"
                name="fullName"
                id="fullName"
                className="py-2 pl-2 text-left text-gray-500 block w-full text-xxms border border-gray-300 rounded-md"
                onClick={showModal}
              >
                <span className="pointer-events-none">{sellerInfo.firstName}</span>
                <span className="pointer-events-none px-1.5">{sellerInfo.lastName}</span>
              </button>
            </div>
          </div>
          <div>
            <span className="block text-xxms font-normal text-gray-600">
              Mail
            </span>
            <div className="mt-1">
              <button
                type="button"
                name="sellerMail"
                id="sellerMail"
                className="py-2 pl-2 text-left text-gray-500 block w-full text-xxms border border-gray-300 rounded-md"
                onClick={showModal}
              >
                {sellerInfo.email}
              </button>
            </div>
          </div>
          <h2 className="text-black-title font-medium text-xxms">{capitalizeFirst(t("accountPage.title4"))}</h2>
          <div>
            <span className="block text-xxms font-normal text-gray-600">
              IBAN
            </span>
            <div className="mt-1">
              <button
                type="button"
                name="iban"
                id="iban"
                className="py-2 pl-2 text-left text-gray-500 block w-full text-xxms border border-gray-300 rounded-md"
                onClick={showModal}
              >
                {sellerInfo.iban}
              </button>
            </div>
          </div>
          <div>
            <span className="block text-xxms font-normal text-gray-600">
              BIC
            </span>
            <div className="mt-1">
              <button
                type="button"
                name="bic"
                id="bic"
                className="py-2 pl-2 text-left text-gray-500 block w-full text-xxms border border-gray-300 rounded-md"
                onClick={showModal}
              >
                {sellerInfo.bic}
              </button>
            </div>
          </div>

        </div>
      </div>
      <ModalContact showModal={openModalContact} hideModal={hideModal} subjectList={subjectList} />
    </>
  );
};

export default AccountInfo;
