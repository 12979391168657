const getStyles = (theme) => ({
  inputWithoutWebkit: {
    "input[type=number]::WebkitInnerSpinButton": {
      WebkitAppearance: "none",
      margin: "0"
    },
    "input[type=number]::WebkitOuterSpinButton": {
      WebkitAppearance: "none",
      margin: "0"
    }
  }
});

export default getStyles;
