const getStyles = (theme) => ({
  button: {
    height: 50,
    paddingLeft: 50,
    paddingRight: 50,
    border: '3px solid',
    textTransform: 'none',
    fontSize: 18,
    fontWeight: 'bold',
    margin: '1rem 0',
    '&:hover': {
      border: '3px solid',
    },
  }
});

export default getStyles;
