import {
  all, put, takeLatest, call, delay, select
} from 'redux-saga/effects';
import { API, Auth } from 'aws-amplify';
import { push } from 'connected-react-router';
import {
  clearSnackBar,
  closeSnackBar,
  getCurrentSessionSuccess,
  getCurrentSessionFailure,
  getCurrentSessionLaunched,
  loginSuccess,
  loginFailure,
  logoutSuccess,
  logoutFailure,
  openPopup,
  requestPasswordCodeFailure,
  requestPasswordCodeSuccess,
  signupSuccess,
  signupFailure,
  submitNewPasswordSuccess,
  submitNewPasswordFailure,
  updateUserFailure,
  updateUserSuccess
} from './reducer';
import {
  translateSignInError,
  translateSignUpError,
  translateConfirmForgotPassword,
  translateForgotPassword
} from '../../utils/cognito';
import { config } from '../../conf/amplify';

function* getCurrentSession(action) {
  const { fromPath } = action.payload || {};
  try {
    yield Auth.currentSession();
    const userInfo = yield Auth.currentUserInfo();
    const userDynamo = yield API.post(config.apiGateway.NAME, '/sessions', {
      headers: {
        'x-api-key': config.apiKey
      },
      body: {
        email: userInfo.attributes.email,
        role: 'SELLER'
      }
    });

    yield put(getCurrentSessionSuccess({ userInfo, userDynamo }));
    if (fromPath) {
      // yield delay(30000);
      yield put(push(fromPath));
    }
  } catch (error) {
    // console.log(error);
    yield put(getCurrentSessionFailure());
  }
}

function* doSignIn(action) {
  const { email, password, from } = action.payload.formValue;
  try {
    yield Auth.signIn(email, password);
    yield put(loginSuccess());
    yield put(getCurrentSessionLaunched({ fromPath: from || '/home' }));
  } catch (err) {
    yield put(loginFailure(translateSignInError(err.code)));
  }
}

function* doSignOut() {
  try {
    yield Auth.signOut();
    yield put(logoutSuccess());
  } catch (err) {
    yield put(logoutFailure());
  }
  yield put(getCurrentSessionLaunched('/login'));
}

function* doSignUp(action) {
  try {
    const result = yield API.post(config.apiGateway.NAME, `/sellers`, {
      headers: {
        'x-api-key': config.apiKey,
        'content-type': 'multipart/form-data'
      },
      body: action.payload
    });
    // We need operator validation -> user cannot autologged after Signup
    // yield call(doSignIn, { payload: { email: action.payload.email, password: action.payload.password } });
    // yield put(getCurrentSessionLaunched({ fromPath: '/login' }));
    yield put(signupSuccess(result));
  } catch (error) {
    // console.log(error);
    yield put(signupFailure(translateSignUpError(error.code)));
    yield put(openPopup({ open: true, message: translateSignUpError(error.code), error: true, popupId: "signupFailure" }));
  }
}

function* doRequestPasswordCode(action) {
  // console.log(action);
  const { email } = action.payload;

  try {
    yield Auth.forgotPassword(email);
    yield put(requestPasswordCodeSuccess());
  } catch (error) {
    yield put(requestPasswordCodeFailure(translateForgotPassword(error.code)));
  }
}

function* doSubmitNewPassword(action) {
  const {
    username, code, password
  } = action.payload;
  try {
    yield Auth.forgotPasswordSubmit(username, code, password);
    yield put(submitNewPasswordSuccess());
  } catch (error) {
    yield put(submitNewPasswordFailure(translateConfirmForgotPassword(error.code)));
  }
}

function* doUpdateUser(action) {
  const { firstName, lastName, userId } = action.payload;
  try {
    const apiURL = `/users/${userId}`;
    const params = {
      headers: {
        'x-api-key': config.apiKey
      },
      body: {
        lastName,
        firstName
      }
    };

    yield API.put(config.apiGateway.NAME, apiURL, params);
    yield put(updateUserSuccess());
    yield put(getCurrentSessionLaunched('/account'));
  } catch (error) {
    yield put(updateUserFailure());
  }
}

function* setSnackBar() {
  yield delay(5000);
  yield put(closeSnackBar());
  yield delay(200);
  yield put(clearSnackBar());
}

export default function* rootSaga() {
  yield all([
    takeLatest('App/getCurrentSessionLaunched', getCurrentSession),
    takeLatest('App/loginLaunched', doSignIn),
    takeLatest('App/logoutLaunched', doSignOut),
    takeLatest('App/signupLaunched', doSignUp),
    takeLatest('App/requestPasswordCodeLaunched', doRequestPasswordCode),
    takeLatest('App/submitNewPasswordLaunched', doSubmitNewPassword),
    takeLatest('App/updateUserLaunched', doUpdateUser),
  ]);
}
