import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Yup from "yup";
import { Link } from 'react-router-dom';
import { Field, Formik, Form } from 'formik';
import { getSellersEmailLaunched } from '../../store/reducer';
import { ToggleSwitch } from '../ToggleSwitch';

const SignUpForm = ({ step, setStep, formValue, setFormValue, previous }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // toggles states
  const [isSoleTrader, setIsSoleTrader] = useState(false);

  // Form data
  const initialValues = {
    email: formValue.email,
    password: formValue.password,
    confirmPassword: "",
    isSoleTrader: formValue.isSoleTrader
  };

  // check if email already exist in seller Table
  const [formEmail, setFormEmail] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const { sellersEmail, getSellersEmailLoading } = useSelector((state) => (
    {
      sellersEmail: state.getIn(['store', 'sellersEmail']),
      getSellersEmailLoading: state.getIn(['store', 'getSellersEmailLoading'])
    }
  ));

  useEffect(() => {
    if (sellersEmail && getSellersEmailLoading === false) {
      if (sellersEmail.length === 0) {
        setStep(step + 1);
        window.scrollTo(0, 0);
      } else {
        setErrorMessage(t("signUpForm.existingEmailAdress"));
        setStep(step);
      }
    }
  }, [getSellersEmailLoading]);

  const signup = () => {
    dispatch(getSellersEmailLaunched({ formEmail }));
  };

  // Form Validation Schema
  const ValidationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t("signInForm.emailRequired"))
      .email(t("signInForm.emailType"))
      .nullable(),
    password: Yup.string()
      .required(t("signInForm.passwordRequired"))
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-])[A-Za-z\d$&+,:;=?@#|'<>.^*()%!-]{8,}$/,
        t("signInForm.passwordType")
      )
      .nullable(),
    confirmPassword: Yup.string()
      .required(t("confirmPasswordRequired"))
      .oneOf([Yup.ref('password'), null], t("passwordMismatch"))
      .nullable(),
    isSoleTrader: Yup.bool(),
  });

  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-lg">
      <h2 className="text-white text-sm font-sans md:text-m font-semibold text-center md:py-8">{t("signUpForm.title")}</h2>
      <div className="w-full mx-auto">
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema}
          onSubmit={signup}
        >
          {({ errors, touched, values, handleChange }) => (
            <Form className="mx-auto w-5/6 flex flex-col justify-center items-center">
              <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
                <div className="w-full">
                  <label className="block text-xxs font-medium text-white">{t("signUpForm.email")}</label>
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    value={values.email ? values.email : ""}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value.toLowerCase()
                      }));
                      setFormEmail(e.target.value.toLowerCase());
                    }}
                    className={`w-full ${errors.email && touched.email ? 'border-red focus:border-red' : 'border-grey-light'} rounded`}
                  />
                  {errors.email && touched.email && (
                    <p className="px-2 text-white text-center md:text-left">{errors.email}</p>
                  )}
                </div>
              </div>

              <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-4 md:mb-6">
                <div className="w-full">
                  <label className="block text-xxs font-medium text-white">{t('signUpForm.password')}</label>
                  <Field
                    id="password"
                    name="password"
                    type="password"
                    value={values.password}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value
                      }));
                    }}
                    className={`w-full ${errors.password && touched.password ? 'border-red focus:border-red' : 'border-grey-light'} rounded`}
                  />
                  {errors.password && touched.password && (
                    <p className="px-2 text-white text-center md:text-left">{errors.password}</p>
                  )}
                </div>
              </div>

              <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-2 md:mb-4">
                <div className="w-full">
                  <label className="block text-xxs font-medium text-white">{t('signUpForm.confirmPassword')}</label>
                  <Field
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    value={values.confirmPassword}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    className={`w-full ${errors.confirmPassword && touched.confirmPassword ? 'border-red focus:border-red' : 'border-grey-light'} rounded`}
                  />
                  {errors.confirmPassword && touched.confirmPassword && (
                    <p className="px-2 text-white text-center md:text-left">{errors.confirmPassword}</p>
                  )}
                </div>
              </div>
              <div className="flex justify-center pt-8">
                <ToggleSwitch
                  id="isSoleTrader"
                  nameSwitch="isSoleTrader"
                  checked={isSoleTrader}
                  label={t("signUpForm.isSoleTrader")}
                  labelStyle="text-xs font-bold text-white"
                  onChange={(e) => {
                    handleChange(e);
                    setFormValue((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.checked
                    }));
                    setIsSoleTrader(e.target.checked);
                  }}
                />
              </div>

              <div className="flex justify-end pt-8">
                <button
                  type="button"
                  className="border border-grey-light mt-4 md:mt-0 bg-white py-2 px-4 rounded-md shadow-sm text-xxms font-medium text-black hover:bg-orange-dark hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange"
                  onClick={() => previous()}
                >
                  {t("previous")}
                </button>
                <button
                  type="submit"
                  className="ml-3 mt-4 md:mt-0 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-xxms font-medium rounded-md text-black bg-white hover:bg-orange-dark hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange"
                >
                  {t("next")}
                </button>
              </div>
              <div>
                {(errorMessage === t("signUpForm.existingEmailAdress"))
                  && (
                    <p className="pt-2 text-white font-bold text-center md:text-left">{errorMessage}</p>
                  )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SignUpForm;
