import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { XIcon, CurrencyEuroIcon } from '@heroicons/react/solid';
import { Link, useLocation } from 'react-router-dom';
import { formatDateToFrenchFormat } from '../../../utils/format';
import { ModalOrderDetails } from '../../Modal/ModalOrderDetails';
import { ModalOrderIncident } from '../../Modal/ModalOrderIncident';
import { ModalOrderValidation } from '../../Modal/ModalOrderValidation';
import { ModalOrderShipping } from '../../Modal/ModalOrderShipping';
import { ModalOrderCancel } from '../../Modal/ModalOrderCancel';
import { ModalOrderRefund } from '../../Modal/ModalOrderRefund';
import blurEmail from "../../../assets/img/blur_email.png";
import blurCustomer from "../../../assets/img/blur_customer.png";

export const FilterResult = ({ infoOrder, incidentModal, validationModal, shippingModal, cancelModal, refundModal, incident }) => {
  const { t } = useTranslation();
  const [openModalDetails, setOpenModalDetails] = useState(false);
  const [openModalIncident, setOpenModalIncident] = useState(false);
  const [openModalValidation, setOpenModalValidation] = useState(false);
  const [openModalShipping, setOpenModalShipping] = useState(false);
  const [openModalCancel, setOpenModalCancel] = useState(false);
  const [openModalRefund, setOpenModalRefund] = useState(false);

  let body;
  if (typeof window !== "undefined") { // during build window is unavailable
    body = document.querySelector("body");
  }

  const showModal = (e) => {
    switch (e.target.id) {
      case "incident":
        setOpenModalIncident(true);
        if (typeof window !== "undefined") {
          body.style.overflow = "hidden"; // block scroll on body when modal is open
        }
        break;
      case "details":
        setOpenModalDetails(true);
        if (typeof window !== "undefined") {
          body.style.overflow = "hidden"; // block scroll on body when modal is open
        }
        break;
      case "validation":
        setOpenModalValidation(true);
        if (typeof window !== "undefined") {
          body.style.overflow = "hidden"; // block scroll on body when modal is open
        }
        break;
      case "shipping":
        setOpenModalShipping(true);
        if (typeof window !== "undefined") {
          body.style.overflow = "hidden"; // block scroll on body when modal is open
        }
        break;
      case "cancel":
        setOpenModalCancel(true);
        if (typeof window !== "undefined") {
          body.style.overflow = "hidden"; // block scroll on body when modal is open
        }
        break;
      case "refund":
        setOpenModalRefund(true);
        if (typeof window !== "undefined") {
          body.style.overflow = "hidden"; // block scroll on body when modal is open
        }
        break;
      default:
        break;
    }
  };

  const hideModal = () => {
    setOpenModalDetails(false);
    setOpenModalIncident(false);
    setOpenModalValidation(false);
    setOpenModalShipping(false);
    setOpenModalCancel(false);
    setOpenModalRefund(false);
    if (typeof window !== "undefined") {
      body.style.overflow = "auto";
    }
  };

  const { pathname } = useLocation();
  // incident dans ModalOrderIncident

  return (
    <>
      <tr key={infoOrder.orderNumber}>
        <td className="px-2 py-4 whitespace-nowrap text-xxs font-normal text-black-primary">{infoOrder.orderNumber}</td>
        <td className="px-2 py-4 whitespace-nowrap text-xxxs font-normal text-black-primary">{formatDateToFrenchFormat(infoOrder.date)}</td>
        <td className="px-2 py-4 space-y-1.5 whitespace-nowrap text-xxxs font-normal text-black-primary">
          {infoOrder.products.map((product) => (
            <div key={product.sku} className="flex flex-col">
              <span className="capitalize">{product.title}</span>
              <span>{`SKU: ${product.sku}`}</span>
            </div>
          ))}
        </td>
        <td className="px-2 py-4 whitespace-nowrap text-xxxs font-normal text-black-primary">
          <div className="flex flex-col space-y-6">
            {infoOrder.products.map((product) => (
              <span key={product.sku}>{product.quantity}</span>
            ))}
          </div>
        </td>
        <td className="px-2 py-4 whitespace-nowrap text-xxxs font-normal">{`${infoOrder.totalOfferPrice.toFixed(2)} €`}</td>
        {
          (infoOrder.status === "WAITING_ACCEPTANCE")
          && (
            <>
              <td className="pr-6 py-4 whitespace-nowrap">
                <img className="md:w-16 xl:w-20" src={blurCustomer} alt="Customer" />
              </td>
              <td className="pr-6 py-4 whitespace-nowrap">
                <img className="md:w-28 xl:w-36" src={blurEmail} alt="Customer Email" />
              </td>
            </>
          )
        }
        {
          (infoOrder.status !== "WAITING_ACCEPTANCE")
          && (
            <>
              <td className="px-2 py-4 whitespace-nowrap text-xxxs font-normal text-black-primary">
                <span>
                  {infoOrder.customer.firstname}
                </span>
                <span className="pl-1">
                  {infoOrder.customer.lastname}
                </span>
              </td>
              <td className="px-2 py-4 whitespace-nowrap text-xxxs font-normal text-black-primary">
                {infoOrder.customer.email}
              </td>
            </>
          )
        }
        <td className={`px-2 py-4 whitespace-nowrap text-xxxs font-normal text-black-primary ${(pathname === "/incident" || pathname === "/delivery" || pathname === "/waiting-delivery") ? "" : "hidden"}`}>
          {(infoOrder.trackingUrl !== "" && infoOrder.trackingUrl !== null) ? <a href={infoOrder.trackingUrl} className="capitalize">{t("orderPage.track")}</a> : 'Aucun'}
        </td>
        <td className="px-2 py-4 whitespace-nowrap text-xxxs font-normal text-black-primary">
          {
            (infoOrder.status === "WAITING_ACCEPTANCE")
            && (
              <span className="inline-flex items-center px-3 py-0.5 rounded-full font-normal bg-grey-lightDark text-white capitalize">
                {t("orderPage.waitingAccept")}
              </span>
            )
          }
          {
            (infoOrder.status === "WAITING_PREPARATION")
            && (
              <span className="inline-flex items-center px-3 py-0.5 rounded-full font-normal bg-orange-lightDark text-white capitalize">
                {t("orderPage.toShip")}
              </span>
            )
          }
          {
            (infoOrder.status === "WAITING_DELIVERY")
            && (
              <span className="inline-flex items-center px-3 py-0.5 rounded-full font-normal bg-blue text-white capitalize">
                {t("orderPage.inDelivery")}
              </span>
            )
          }
          {
            (infoOrder.status === "DELIVERED")
            && (
              <span className="inline-flex items-center px-3 py-0.5 rounded-full font-normal bg-blue-aqua text-white capitalize">
                {t("orderPage.isDelivered")}
              </span>
            )
          }
          {
            (infoOrder.status === "INCIDENT")
            && (
              <span className="inline-flex items-center px-3 py-0.5 rounded-full font-normal bg-orange-chocolate text-white capitalize">
                Incident
              </span>
            )
          }
          {
            (infoOrder.status === "PAID")
            && (
              <span className="inline-flex items-center px-3 py-0.5 rounded-full font-normal bg-green-apple text-white capitalize">
                {t("orderPage.paid")}
              </span>
            )
          }
          {
            (infoOrder.status === "REFUSED")
            && (
              <span className="inline-flex items-center px-3 py-0.5 rounded-full font-normal bg-red-900 text-white capitalize">
                {t("orderPage.refused")}
              </span>
            )
          }
        </td>
        <td className="px-2 py-4 text-xxs lg:text-xxms whitespace-nowrap font-normal text-black-primary">
          {/* Actions conditionnelles */}
          <div className="flex flex-col items-start gap-y-2">
            {
              (pathname === "/preparation-orders")
              && (
                <>
                  {validationModal
                    && (
                      <button onClick={showModal} id="validation" type="button" className="w-28 text-green-apple border rounded border-green-apple py-1 px-4">{t("orderPage.accept")}</button>
                    )}
                  {cancelModal
                    && (
                      <button onClick={showModal} id="cancel" type="button" className="w-28 text-orange-chocolate border rounded border-orange-chocolate py-1 px-4">{t("orderPage.refuse")}</button>
                    )}
                </>
              )
            }
            {
              (pathname === "/not-shipped")
              && (
                <>
                  {shippingModal
                    && (
                      <button onClick={showModal} id="shipping" type="button" className="w-28 text-green-apple border rounded border-green-apple py-1 px-4">{t("orderPage.send")}</button>
                    )}
                  {cancelModal
                    && (
                      <button onClick={showModal} id="cancel" type="button" className="w-28 text-orange-chocolate border rounded border-orange-chocolate py-1 px-4">{t("orderPage.cancel")}</button>
                    )}
                </>
              )
            }
            {
              (pathname === "/incident")
              && (
                <>
                  {cancelModal
                    && (
                      <button onClick={showModal} id="cancel" type="button" className="flex text-orange-chocolate">
                        <XIcon className="w-5 h-5 mr-2" />
                        {t("orderPage.refuse")}
                      </button>
                    )}
                  {/* Fonctionnalité non disponible <button type="button" className="">{t("orderPage.windUp")}</button> */}
                  {cancelModal
                    && (
                      <button onClick={showModal} id="refund" type="button" className="flex text-yellow">
                        <CurrencyEuroIcon className="w-5 h-5 mr-2" />
                        {t("orderPage.refund")}
                      </button>
                    )}
                </>
              )
            }
            {/* Fonctionnalité non disponible {incidentModal && <button onClick={showModal} id="incident" type="button" className="">{t("orderPage.openIncident")}</button>} */}
            <button onClick={showModal} id="details" type="button" className="w-28 border rounded border-black-primary text-black-primary py-1 px-2">{t("seeDetail")}</button>
          </div>
        </td>
      </tr>
      <ModalOrderDetails showModal={openModalDetails} hideModal={hideModal} infosOrder={infoOrder} />
      <ModalOrderIncident showModal={openModalIncident} hideModal={hideModal} infosOrder={infoOrder} />
      <ModalOrderValidation showModal={openModalValidation} hideModal={hideModal} infosOrder={infoOrder} />
      <ModalOrderShipping showModal={openModalShipping} hideModal={hideModal} infosOrder={infoOrder} />
      <ModalOrderCancel showModal={openModalCancel} hideModal={hideModal} infosOrder={infoOrder} />
      <ModalOrderRefund showModal={openModalRefund} hideModal={hideModal} infosOrder={infoOrder} />
    </>
  );
};

export default FilterResult;
