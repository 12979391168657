import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { Formik, Form } from 'formik';
import DropFile from '../DropFile';
import importsTemplate from "../../assets/documents/templateImports.csv";
import { importLaunched, importSuccess } from '../../store/reducer';

const FormDeposit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initialValue = { file: null };
  const { importSuccessState } = useSelector((state) => (
    {
      importSuccessState: state.getIn(['store', 'importSuccessState']),
    }
  ));
  const [messageSend, setMessageSend] = useState(false);

  const submitFunc = (importData) => {
    dispatch(importLaunched(importData));
    if (importSuccessState) {
      setMessageSend(true);
    }
  };

  return (
    <>
      <div className="w-full lg:max-w-screen-2xl md:px-5 lg:px-10">
        <Formik
          initialValues={initialValue}
          // validationSchema={ValidationSchema}
          validate={(values) => {
            const errors = {};
            // For validate file's input
            if (values.file === null || values.file.length === 0) {
              errors.file = t("signUpBusiness.fileRequired");
            } else if (values.file.length > 1) {
              errors.file = t("signUpBusiness.numberOfFile");
            } else {
              values.file.forEach((value) => {
                if (value.size > 3000000) {
                  errors.file = (t("signUpBusiness.fileSize"));
                }
              });
            }
            return errors;
          }}
          onSubmit={submitFunc}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form>
              <div className="w-full flex flex-col md:flex-row items-start md:items-start mb-4 md:mb-6">
                <div className="container mx-auto w-full">
                  <div className="w-full md:w-auto flex flex-col md:flex-row pl-6 md:justify-center md:items-center md:pl-6">
                    {/* drag and drop section */}
                    <div>
                      <DropFile setFieldValue={setFieldValue} />
                      {
                        values.file && (messageSend === false || importSuccessState === false)
                          ? values.file.map((file) => (
                            <p key={file.name} className="flex items-center mt-2 break-all">
                              {`${file.name} - `}
                              {file.size > 999999 ? `${Math.round(file.size / 100000) / 10} Mo` : `${Math.round(file.size / 100) / 10} Ko`}
                            </p>
                          ))
                          : null
                      }
                      {/* Send button */}
                      <div className="md:flex justify-center text-center py-4 mr-8 ml-1 md:mr-0 md:ml-1 md:pt-8">
                        <button
                          type="submit"
                          className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium text-xxs rounded-md shadow-sm text-white bg-orange-dark hover:bg-orange-chocolate focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-chocolate"
                        >
                          {t("requestCodeButton")}
                        </button>
                      </div>
                      {values.files && (messageSend === true || importSuccessState === true)
                        ? <p className="text-green-600 text-center md:text-left mt-2">{t("importsPage.fileSent")}</p>
                        : null}
                      {(messageSend === true) || (importSuccessState === true)
                        ? <p className="text-green-600 text-center md:text-left mt-2">{t("importsPage.fileSent")}</p>
                        : null}
                      {errors.file && touched.file ? (
                        <p className="text-red-600 text-center md:text-left mt-2">{errors.file}</p>
                      ) : null}
                    </div>

                    {/* documentation section  */}
                    <div className="pt-2">
                      <button
                        type="button"
                        className="inline-flex items-center px-28 py-2 border border-transparent text-sm leading-4 font-normal text-xxms rounded-md shadow-sm text-white bg-black"
                      >
                        <span className="hidden md:block">{t("importsPage.importsGuide")}</span>
                        <span className="md:hidden">{t("importsPage.importsMobile")}</span>
                      </button>
                      <div className="pt-8 md:pt-8 pl-6 flex">
                        <a
                          href={importsTemplate}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="underline pr-2 text-orange-dark font-normal"
                          download
                        >
                          {t("templateRetrieval")}
                        </a>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-orange-dark" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v3.586L7.707 9.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 10.586V7z" clipRule="evenodd" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default FormDeposit;
