import React from "react";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { useStyles } from "react-styles-hook";
import getStyles from "./styles";
import FilterProductSheet from "./FilterProductSheet/index";

const DesktopCatalogArray = ({ arrayContent, setRefresh, refresh }) => {
  const { t } = useTranslation();
  const classes = useStyles(getStyles());

  return (
    <div>
      <p className="text-black-title text-sm font-semibold mr-20">
        {t("product")}
      </p>
      <div className="text-right">
        <button
          type="button"
          onClick={() => setRefresh(!refresh)}
          className="inline-flex items-center mr-20 px-6 mr-4 py-2 border border-transparent text-sm leading-4 font-medium text-xxs rounded-md shadow-sm text-white bg-orange-dark hover:bg-orange-chocolate focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-chocolate"
        >
          {t("updateButton")}
        </button>
      </div>
      <div className="table w-full pt-6">
        <div className="table-row-group">
          <div className="table-row h-24" style={classes.rowContainer}>
            {/* cellules de titres */}
            <div style={classes.widthCell0} className="table-cell  text-xxms px-2 pt-8 pb-2 font-medium border-grey-ghost border-b">ID</div>
            <div style={classes.widthCell0} className="table-cell text-xxms px-2 pt-8 pb-2 font-medium border-grey-ghost border-b">Image</div>
            <div style={classes.widthCell0} className="table-cell text-xxms px-2 pt-8 pb-2 font-medium border-grey-ghost border-b">{t("cataloguePage.productName")}</div>
            <div style={classes.widthCell0} className="table-cell text-xxms px-2 pt-8 pb-2 font-medium border-grey-ghost border-b">GTIN</div>
            <div style={classes.widthCell0} className="table-cell text-xxms px-2 pt-8 pb-2 font-medium border-grey-ghost border-b">SKU</div>
            <div style={classes.widthCell0} className="table-cell text-xxms px-2 pt-8 pb-2 font-medium border-grey-ghost border-b">{t("cataloguePage.price")}</div>
            <div style={classes.widthCell0} className="table-cell text-xxms px-2 pt-8 pb-2 font-medium border-grey-ghost border-b">{t("cataloguePage.disponibility")}</div>
            <div style={classes.widthCell0} className="table-cell text-xxms px-2 pt-8 pb-2 font-medium border-grey-ghost border-b">Actions</div>
          </div>
          {
            arrayContent && arrayContent.map((item) => (
              <div key={item.id}>
                <FilterProductSheet
                  item={item}
                />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default DesktopCatalogArray;

DesktopCatalogArray.propTypes = {
  arrayContent: PropTypes.oneOfType([PropTypes.array]).isRequired
};
