import React, { useState } from 'react';
import useFetchSellerOrders from '../../hooks/useFetchSellerOrders';
import { OrdersLoader } from '../../components/OrdersLoader';
import { OrdersNavigation } from "../../components/OrdersNavigation";
import { Pagination } from "../../components/Pagination";

export const IncidentPage = (props) => {
  const currentInfosOrder = useFetchSellerOrders("INCIDENT");
  const PageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  // Create an Array which will contain all the incident products
  const orderLineIncident = [];

  // Browse all the commands that contains at least one incident product
  currentInfosOrder?.forEach((sellerOrder) => {
    // Browse all the products that has an incident in every commands
    sellerOrder.products.forEach((product) => {
      const productWithIncident = [];
      if (product.incident[0].hasIncident) {
        productWithIncident.push({
          img: product.img,
          offerId: product.offerId,
          incident: product.incident,
          price: product.price,
          quantity: product.quantity,
          sku: product.sku,
          title: product.title,
        });
        orderLineIncident.push({
          id: sellerOrder.id,
          sellerId: sellerOrder.sellerId,
          orderNumber: sellerOrder.orderNumber,
          date: sellerOrder.date,
          status: sellerOrder.status,
          payoutStatus: sellerOrder.payoutStatus,
          customer: {
            firstname: sellerOrder.customer.firstname,
            lastname: sellerOrder.customer.lastname,
            email: sellerOrder.customer.email
          },
          products: productWithIncident,
          totalOfferPrice: product.price * product.quantity,
          totalDeliveryPrice: sellerOrder.totalDeliveryPrice,
          trackingUrl: sellerOrder.trackingUrl,
          delivery: {
            firstName: sellerOrder.delivery.firstName,
            lastName: sellerOrder.delivery.lastName,
            company: sellerOrder.delivery.company,
            telephone: sellerOrder.delivery.telephone,
            address: sellerOrder.delivery.address,
            zipcode: sellerOrder.delivery.zipCode,
            city: sellerOrder.delivery.city,
            country: sellerOrder.delivery.country,
            deliveryDate: sellerOrder.delivery.deliveryDate,
            deliveryPrice: sellerOrder.delivery.deliveryPrice,
            deliveryModeId: sellerOrder.delivery.deliveryModeId
          },
        });
      }
      return true;
    });
    return true;
  });

  return (
    <>
      <div className="md:w-3/4 md:px-4 md:mx-auto pt-2">
        {currentInfosOrder !== null
          && (
            <>
              <OrdersNavigation PageSize={PageSize} currentPage={currentPage} infosOrder={orderLineIncident} cancelModal refundModal incident />
              {currentInfosOrder === undefined
                && (
                  <OrdersLoader />
                )}
              {currentInfosOrder !== undefined
                && (
                  <Pagination
                    currentPage={currentPage}
                    totalCount={orderLineIncident?.length}
                    pageSize={PageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                )}
            </>
          )}
      </div>
    </>
  );
};

export default IncidentPage;
