import React from 'react';
import { useTranslation } from "react-i18next";

export const Pagination = (props) => {
  const { t } = useTranslation();

  const {
    onPageChange,
    totalCount,
    currentPage,
    pageSize,
  } = props;

  if (currentPage === 0 || (Math.ceil(totalCount / pageSize)) < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
    window.scrollTo(0, 0);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
    window.scrollTo(0, 0);
  };

  const lastPage = Math.ceil(totalCount / pageSize);

  const firstIndex = ((currentPage - 1) * pageSize) + 1;

  let lastIndex;
  if (currentPage === lastPage && (firstIndex + pageSize - 1) > totalCount) {
    lastIndex = totalCount;
  } else {
    lastIndex = firstIndex + pageSize - 1;
  }

  return (
    <div className="w-full md:flex md:justify-between md:items-center pt-5 px-4 border-t border-gray-200">
      <div className="hidden md:block text-grey-simple">
        <p className="space-x-1">
          <span>{t("pagination.showing")}</span>
          <span className="font-medium">{firstIndex}</span>
          <span>{t("pagination.to")}</span>
          <span className="font-medium">{lastIndex}</span>
          <span>{t("pagination.of")}</span>
          <span className="font-medium">{totalCount}</span>
          <span>{t("pagination.results")}</span>
        </p>
      </div>
      <div className="flex justify-between md:justify-end md:space-x-2">
        <button
          type="button"
          onClick={onPrevious}
          disabled={(currentPage === 1) ? "disabled" : ""}
          className={`px-4 py-2 border border-gray-400 rounded ${(currentPage === 1) ? "text-grey-light" : ""}`}
        >
          {t("previous")}
        </button>
        <button
          type="button"
          onClick={onNext}
          disabled={(currentPage === lastPage) ? "disabled" : ""}
          className={`px-4 py-2 border border-gray-400 rounded ${(currentPage === lastPage) ? "text-grey-light" : ""}`}
        >
          {t("next")}
        </button>
      </div>
    </div>
  );
};

export default Pagination;
