import { S3Client, AbortMultipartUploadCommand, PutObjectCommand, ListObjectsCommand, DeleteObjectCommand, DeleteObjectsCommand } from "@aws-sdk/client-s3";
import { config } from '../conf/amplify';
import { strNoAccentNoSpecialChar } from './format';

const credentials = {
  accessKeyId: config.s3.BUCKET_ACCESS_KEY,
  secretAccessKey: config.s3.BUCKET_SECRET_KEY,
};
const s3config = {
  region: config.s3.BUCKET_FILE_ONBOARDING_REGION,
  credentials,
};

export const addFile = async (fileToUpload, identificator) => {
  if (fileToUpload.size > 32000 && fileToUpload.size < 3000000) {
    try {
      const s3Client = new S3Client(s3config);

      const file = fileToUpload;
      const fileName = strNoAccentNoSpecialChar(file.name);
      const fileType = file.type.slice(file.type.indexOf("/") + 1, file.type.length); // if file.type = img/png -> fileType = png
      const fileSize = file.size;
      const fileKey = `${fileName}_${fileSize}_${identificator}.${fileType}`;
      const uploadParams = {
        Bucket: config.s3.BUCKET_FILE_ONBOARDING,
        Key: fileKey,
        Body: file
      };

      const data = await s3Client.send(new PutObjectCommand(uploadParams));
      return fileKey;
    } catch (err) {
      console.log(err.message);
      return err;
    }
  } else {
    return null;
  }
};

export const deleteFileS3 = async (fileKey) => {
  try {
    const s3Client = new S3Client(s3config);
    const deleteParams = {
      Bucket: config.s3.BUCKET_FILE_ONBOARDING,
      Key: fileKey
    };
    const command = new DeleteObjectCommand(deleteParams);
    const response = await s3Client.send(command);
    return response;
  } catch (err) {
    console.log(err.message);
    return (err.message);
  }
};
