import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'fr',
    debug: true,
    lng: 'fr',
    resources: {
      en: {
        translation: {
          // APP
          signInForm: {
            signIn: 'Connect to your seller account',
            nonsubscriber: 'Not registered yet ? ',
            becomeSeller: 'Become a Zawema member',
            email: 'Email adress',
            password: 'Password',
            rememberMe: 'Remember me',
            forgottenPassword: 'Forgotten password',
            connect: 'Connect',
            emailRequired: 'Please enter your email',
            emailType: 'Enter a valid email',
            passwordRequired: 'Please enter your password',
            passwordType: 'Please enter at least 8 characters, one letter, one number, one uppercase and one special character',
            isNotConfirmed: 'This account is being validated by the operator. You will be notified by email when it is active.',
          },
          forgotPassword: {
            title: "Password forgotten ?",
            text: "Don't panic ! \nComplete the field below and we will email you the information to reset your password.",
            submitSubtitle: "Enter the code received at the following address :",
            submitSubtitle2: "and choose your new password.",
            emailValidation: 'Enter a valid email',
            emailRequired: 'Enter your email address',
            explanation: 'You have received a confirmation code in your mailbox. Please enter this code in the field below.'
          },
          signUpForm: {
            title: 'Creation of login and password',
            email: 'Email',
            password: 'Password',
            confirmPassword: 'Confirm password',
            isSoleTrader: 'Are you soletrader ?',
            age: 'Age',
            genre: 'Genre',
            newsletter: 'Newsletter',
            emailRequired: 'Email is required field',
            emailType: 'Enter a valid email',
            passwordRequired: 'Password is required field',
            confirmPasswordRequired: 'Comfirm password is required field',
            matchPassword: 'Passwords must match',
            ageRequired: 'Age is required field',
            genreRequired: 'Genre is required field',
            existingEmailAdress: 'This email is already used'
          },
          signUpBusiness: {
            companyInfo: 'Company Info',
            socialReason: 'Social Reason',
            shopName: 'Commercial shop name of you company',
            socialReasonRequired: 'Please enter the name of your company',
            shopNameRequired: 'Please enter the name of your shop',
            email: 'Contact email',
            emailRequired: 'Please enter your contact email',
            telephone: 'Phone number',
            telephoneRequired: 'Please enter the phone number of your company',
            telephoneFormat: 'Please enter a valid phone number',
            adress: 'Company address',
            adressRequired: 'Please enter your business address',
            cp: 'ZIP / Postal',
            cpRequired: 'Please enter your company postal code',
            cpFormat: 'Please enter a valid postal code',
            city: 'City',
            cityRequired: 'Please enter the city of your company',
            country: 'Country',
            countryRequired: 'Please enter the country of your company',
            region: 'Region',
            regionRequired: 'Please enter the region of your company',
            identificationNumber: 'Company identification number',
            identificationNumberRequired: 'Please enter the company identification number of your company',
            identificationNumberFormat: 'Please enter a valid identification number',
            file: 'Filing of documents *',
            fileButton: 'Find a file',
            fileRequired: 'Please provide the requested attachments',
            numberOfFile: 'Please remove an attachment',
            fileSize: 'An attachment larger than 3MB',
            fileToSmall: 'The size of the folder must be greater than 32KB',
            activityType: 'Activity of your company',
            productsToSell: "Products you wan't to sell",
            website: 'Do you have a website ? (optionnal)',
            websiteUrl: 'https://mywebsite.com',
            phoneNumber: 'Enter a phone number',
            activityExample: 'Hare care products seller',
            shampoo: 'Shampoo',
            mailExample: 'compagnyname@example.com',
            activityRequired: 'Please enter an activity',
            productRequired: 'Please enter the products you want to sell',
            obligatory: 'Mandatory documents',
            socialName: "Name of compagny + corporate name (SAS, SARL, EIRL...)",
            uploadID: "Upload the identity document of the legal representative",
            uploadAdressProof: "Upload the proof of address of the legal company",
            uploadRegistery: "Upload the company register extract",
            uploadRegisterySoleTrader: "Upload your trade licence or register extract",
            uploadStatutes: "Upload your company's statutes"
          },
          signUpInfos: {
            legalRepresentantInfo: 'Legal representative information',
            firstName: 'Legal representative firstname',
            lastName: "Legal representative lastName",
            dateOfBirth: 'Date of birth',
            nationality: 'Nationality',
            birthCountry: 'Country of birth',
            birthCountryRequired: 'Please enter your country of birth',
            birthCity: 'City of birth',
            birthCityRequired: 'Please enter your city of birth',
            adress: 'Street adress',
            cp: 'ZIP / Postal',
            city: 'City',
            country: 'Country',
            region: 'Region',
            nationalityPlaceholder: 'French',
            adressPlaceholder: 'Market Street, 35',
            adress2Placeholder: 'Appartment 4',
            denomination: 'Last name and first name',
            legalRepresentantIntro: "This account must be activated by a responsible, senior manager or another person in a position of responsibility in the management of your business. If this is not your case, please ask the concerned person to complete this form.",
            legalRepresentantIntroSoleTrader: "This account must be activated by a sole trader himself. If this is not your case, please ask the concerned person to complete this form.",
            shareholder: "I own at least 25% of the compagny.",
            administrator: "I am a member of the compagny's board of directors.",
            uploadFile: "upload a file",
            obligatory: "Mandatory attachments",
            infoFile: 'Format PDF or JPG or PNG. Size min 32ko, max 3mo.',
            tipFile: 'Tip: if the size of your file < 32ko: print it and scan it to increase is size.'
          },
          signUpIntro: {
            information: 'Informations',
            subTitle: 'Before proceeding with your registration, please make sure you have the following documents:',
            fileAdressProof: 'Proof of address of the legal representative and the beneficial owners (+25% of the shares)',
            fileIDCard: 'Identity document of the legal representative and beneficial owners',
            fileCompAdressProof: 'Justificatif d\'adresse de l\'entreprise',
            fileRegistery: 'Proof of company address',
            fileStatute: 'Company statutes',
            soleTraderInfo: 'Sole trader ? Please provide only: 1 ID + 1 trade licence or equivalent + 1 proof of address',
            formats: 'Authorized formats: pdf, png, jpeg.',
            weight: 'Size min: 32ko, Size max: 3mo.',
            cancel: 'Cancel',
            go: 'Start',
            needMoreInfo: 'Need more informations ?',
            contactUs: 'Contact us at:'
          },
          shareholderForm: {
            title: "Compagny shareholders",
            intro: 'We are required to collect information on any beneficial owners owning 25% (or more) of your company\'s shares.',
            shareholdersList: 'Please indicate all beneficial owners of the shares',
            addShareholderButton: 'Add another beneficial owner',
            addShareholderForm: 'Add a beneficial owner',
            add: 'Add',
            goBack: 'Go back',
            shareholderDeclaration: 'Upload the declaration of beneficial owner',
            shareholderDeclarationDoc: 'A declaration of beneficial owner'
          },
          signUpBank: {
            title: 'Bank informations',
            owner: "Bank account owner's name",
            bankName: 'Bank name',
            adress: 'The address of the owner of the bank account',
            ownerNameRequired: "Please, enter the bank account owner's name",
            bankNameRequired: 'Please enter the name of your bank',
            BICisRequired: 'Please enter your Bank Identifier Code',
            IBANisRequired: 'Please enter your bank account number',
            adressRequired: 'Please enter the adress of the owner of the bank account',
            IBANcompletion: 'Please enter your account number exactly as it is in your bank documents',
            minIBAN: '7 digits minimum',
            maxIBAN: '27 digits maximum',
            BICcompletion: 'This code must contain only captal letters',
            cpRequired: "Please enter the zip code of the owner of the bank account",
            cityRequired: "City of the owner of the bank account is required",
            countryRequired: 'Country of the owner of the bank account is required',
            regionRequired: 'Region of the owner of the bank account is required',
            cgvCheckbox: 'I accept the ',
            cgv: 'general sales conditions',
            cgvRequired: 'Please check the box to continue',
            placeholderAdress: 'flowers street, 165',
          },
          welcome: {
            seller: 'Become a seller',
            onOperator: 'on Zawema',
            introduction1: 'Zawema is a ',
            introduction2: 'marketplace dedicated to curly, frizzy and frizzy hair.',
            introduction3: "We connect professionals and individuals to allow the purchase/sale of products and services adapted to textured hair.",
            introduction4: "By bringing together the entire dedicated offer on a digital platform, Zawema provides two solutions :",
            introduction5bold: "save time, money and have healthy hair",
            introduction6: 'Give sellers a ',
            introduction6bold: "better visibility and access to an ultra-targeted clientele.",
            createAccount: 'Create your account now',
            see: 'Consult our ',
            legalConditions: 't&c',
            alreadySignup: 'Already have an account?',
            marketplace: 'the marketplace',
            why: 'Why sell on Zawema ?',
            whyIntro: "The accelerator of online growth ",
            featureDescription1: 'Broaden the visibility of your business and benefit from the influence of the first Marketplace dedicated to textured hair. Whether you are already an E-merchant or not, you will necessarily find a good reason to join us.',
            featureName2: "Access to a targeted clientele",
            featureDescription2: "Dedicated entirely to textured hair, take advantage of an ultra-targeted clientele, in search of your products. All our visitors are potential buyers for your brand.",
            featureName3: 'Features designed for you',
            featureDescription3: "Because we are committed to providing you with a smooth sales experience, we have developed many features to allow you to earn money without wasting time unnecessarily.",
            featureName4: 'Secure payments',
            featureDescription4: "Zawema offers secure exchanges through a recognized monetician. For you it is the guarantee of having no bad surprises. Sell, deliver, get paid.",
            subtitle: 'Items needed to create your seller account',
            docIntro: 'Because legality prevails',
            docTitle1: 'List of beneficial owners',
            docDescription1: "Whether you are self-employed or a partner, access to the platform requires the information of people holding at least 25% of the company's shares.",
            docTitle2: "Certified status of the company",
            docDescription2: "To become a Zawema seller, you must have a \"professional status\", i.e. be registered.",
            docTitle3: 'Extract from the Companies Register less than 3 months old',
            docDescription3: 'Prepare and present a recent RCS extract (or equivalent)',
            docTitle4: 'Guarantee the delivery of your orders',
            docDescription4: 'Your company must be able to deliver the products to the end customer itself. You are free to select the provider of your choice',
            docTitle5: 'Billing and customer service',
            docDescription5: 'For each order received and shipped, you must provide an invoice to your customers. Your customer service can be managed in French.',
            docTitle6: 'Your banking informations',
            docDescription6: 'Your business must have a dedicated bank account and the details of this must be shared with our payment provider.',
            legalTitle: 'legal',
            discover: 'Discover our ',
            download: ' and download',
            chart: 'the seller\'s charter'
          },
          shipping: {
            shippingZone: 'Shipping zone',
            mode: {
              domicilestandard: 'Home delivery - Standard',
              domicilelong: 'Home delivery - Long',
              domicileexpress: 'Home delivery - Express',
              relaiscolis: 'Relay point delivery - Mondial Relay'
            },
            europe: 'Europe (except France)',
            metropolitanFrance: 'Metropolitan France',
            africa: 'Africa',
            usa: 'USA',
            latinAmerica: 'Latin America',
            free: 'Free',
            notice: 'To offer delivery costs on a delivery method: activate the mode and leave the price field empty.',
          },
          signUpConfirmation: {
            thanks: 'Thank You !',
            formTransfered: 'Your form has been correctly completed and your informations have been sent to Zawema.',
            confirmationEmail: 'You will receive an email after validation to be able to access our services.',
            signupQuestions: 'Got questions ? Contact us at the following email address :',
            goHome: 'Back to the home page'
          },
          modalBusinessForm: {
            documents: 'Mandatory documents to submit :',
            identity1: " Identity card, ",
            identity2: "passport, residence permit or driving license for the EU zone. Passport or driver's license for UK, US and Canada. For other nationalities, a passport is required. In the case of a legal user, this document must refer to the natural person duly authorized to act on behalf of the legal person. Identity card: Front AND Back (Valid) OR Passport (Valid)",
            status1: ' Certified statutes',
            status2: ' - formal act drawn up by the entrepreneurs, in which the following information is mentioned: company name, activity, registered office, shareholders, etc.',
            registerExtract1: ' Extract from the Companies Register ',
            registerExtract2: 'issued within the last three months In the case of an organization or a sole proprietorship, this may be proof of registration from the official authority',
            proofAdress1: ' Proof of address. ',
            proofAdress2: 'Confirmation of residence: Less than one year. Can be: Residential registration form, Water / electricity / gas / telephone bill, Tax certificate, Home insurance, Confirmation of real estate ownership',
            identificationCard: "Valid ID"
          },
          modalAcceptOrder: {
            doYouWantAccept: "Do you want to confirm your order",
            isAccept: "successfully validated",
          },
          modalShippingOrder: {
            toValidate: "To validate the dispatch and shipment of",
            order: "the order",
            pleaseFill: "please fill in the package tracking information",
            goBack: "go back",
            alertNotification: "A message will notify your customer that their order has been sent.",
            notificationConfirmation: "A message has been notified to your customer",
            shippingOf: "The shipment of",
            isValidate: "has been taken into account",
            sendWithoutTracking: "Send without tracking"
          },
          modalCancelOrder: {
            doYouWantRefuse: "Are you sure you want to decline the order",
            confirmeRefuse: "Yes I wish to refuse the order",
            order: "the order",
            isRefused: "has been refused",
            doYouWantCancel: "Are you sure you want to cancel the order",
            confirmCancel: "Yes I want to cancel the order",
            isCanceled: "has been successfully canceled",
            goBack: "go back",
            doYouWantDenyIncident: "Are you sure you want to deny the incident of",
            confirmDeny: "Yes I wish to refuse the incident",
            incidentOf: "The incident of",
            isDeny: "was successfully refused",
            alertNotification: "By canceling the order the customer will be fully refunded.",
          },
          modalRefundOrder: {
            complete: "integral",
            partial: "partiel",
            partialRefund: "Partial refund",
            completeRefund: "Complete refund",
            refund: "Repayment",
            of: "of",
            order: "the order",
            refundOrder: "Refund the order",
            isRefund: "successfully completed"
          },
          orderPage: {
            waitingDelivery: "Waiting Delivery",
            newOrders: "New orders",
            preparation: "Preparation",
            notShipped: "Not shipped",
            delivered: "Delivered",
            incident: "incidents",
            orderNumber: "Order ID",
            products: "Products",
            quantity: "Qty",
            amountOrder: "Amount",
            customer: "Customer",
            trackingNumber: "Tracking ID",
            track: "track",
            details: "Details",
            actions: "Actions",
            status: "Status",
            paidPluriel: "Paid",
            paid: "Paid",
            refused: "Refused",
            noActions: "no action available",
            accept: "Accept",
            refuse: "Refuse",
            send: "Send",
            cancel: "Cancel",
            finalizePreparation: "Finalize the preparation",
            windUp: "Wind up",
            refund: "Refund",
            openIncident: "Open an incident",
            shippingCost: "Shipping cost",
            totalOrder: "Total order",
            acceptCommandForSee: "Please accept the order so you can see the customer contact.",
            lateDelivery: "Late delivery",
            currentIncident: "Current incident",
            deliveryAddress: "Delivery Address",
            waitingAccept: "waiting for acceptance",
            toShip: "to send",
            inDelivery: "Out for delivery",
            rejected: "refused",
            isDelivered: "order delivered",
            noOrderToDisplay: "no commands to display",
            noOrderToPrepare: "you have no orders to process",
            noOrderToShip: "you have no orders to ship",
            noOrderDelivered: "you have no order delivered",
            noOrderIncident: "you have no incident",
            noOrderWaitingDelivery: "no order in transit",
            noProduct: "you have no registered product",
            importProduct: "import products",
            recapOfOrders: "summary of orders",
            deliveryInfo: "delivery informations",
            newIncident: "treat",
            waitingRefundIncident: "pending refund",
            refundIncident: "closed: refunded",
            closedIncident: "closed",
          },
          contactPage: {
            contactUs: 'Contact us',
            emailContact: 'same as your register mail',
            subject: 'Subject',
            subjectMissing: 'Tell us what your message is about',
            message: 'Message',
            messagePlaceholder: 'Your message here...',
            messageError: 'There is no message to be sent'
          },
          importsPage: {
            title: 'My imported products',
            dragDrop: 'or drag and drop the file',
            dropHere: 'drop here',
            fileSent: 'Your file is sent!',
            importsMobile: "Download the guide !",
            importsGuide: "Doubting ? Download the guide !",
            errorTitle: "In order to be able to import your products, please finish setting up your account.",
            accountTitle: "shop informations",
            accountDetails: "complete the information relating to your online store in the 'My Account' page.",
            accountAccess: "edit",
            shippingTitle: "shipping cost",
            shippingDetails: "choose your delivery zones and configure your shipping cost",
            shippingAccess: "setting"
          },
          cataloguePage: {
            productName: 'Name',
            disponibility: 'Availability',
            price: "Price(€)",
            noAttribut: 'No attribute specified'
          },
          homePage: {
            title: 'Dashboard',
            waiting: 'waiting',
            statItem1: 'Online products',
            statItem2: 'CA annuel HT',
            statItem3: 'Orders',
            statItem4: 'Online offers',
            statItem5: 'Current incidents',
            btnDownloadGuide: 'A question ? Download the seller\'s guide',
            problemQuestion: 'Are you having a problem or have a question?',
            contact: 'Contact Zawema',
          },
          accountPage: {
            title1: 'Compagny\'s informations',
            title2: 'General informations',
            title3: 'My informations',
            title4: 'banking information',
            companyName: 'Company name',
            companyNumber: 'number of SIRET',
            companyAdress: 'adress',
            companyMail: 'compagny\'s email',
            sellerIdentity: 'identity',
            shippingZone: 'delivery zone',
            aboutUs: 'who are we',
            aboutUsDesc: 'describe your business in a few words',
            shippingCondition: 'terms of delivery',
            shippingConditionDesc: 'describe your terms of delivery',
            returnCondition: 'refund conditions',
            returnConditionDesc: 'share with your customers your return policy',
            personalDataCondition: 'management of personal data',
            personalDataDesc: 'explain in a few sentences your customer data management policy',
            editMyInformation: 'Modify my information',
            succes: 'your information has been successfully saved.'
          },
          contactForm: {
            subject: 'send',
            send: 'send your request',
            cancel: 'cancel',
            isSent: 'thank you, you\'re message has been sent',
            order: 'order',
            catalog: 'catalog',
            imports: 'imports',
            logistic: 'logistic',
            account: 'my account',
            other: 'other',
            messageIsRequired: 'please enter your message',
            messageIsShort: 'your message is too short',
            editMyCompany: 'modify my compagny',
          },
          pagination: {
            showing: 'Showing',
            to: 'to',
            of: 'of',
            results: 'results'
          },
          loginPageTitle: 'SignIn',
          email: 'Email',
          password: 'Password',
          confirmPassword: 'Confirm password',
          emailRequired: 'Please enter your email',
          passwordRequired: 'Please enter your password',
          signUpSubmit: 'Sign Up',
          signUpPageTitle: 'Sign Up',
          confirmPasswordRequired: 'Please confirm your password',
          passwordMismatch: 'Passwords do not match',
          loginButton: 'Login',
          signUpButton: 'New ? Create an account !',
          logoutButton: 'Logout',
          homePageTitle: 'Home Page',
          requestCodeButton: 'Send',
          updateButton: 'Refresh my product list',
          emailNotValid: 'Email not valid',
          code: 'Code',
          submitNewPasswordButton: 'Confirm',
          codeRequired: 'Please enter your code',
          forgotPasswordPageTitle: 'Reset your Password',
          forgotPasswordButton: 'Password forgotten ?',
          firstLoginPageTitle: 'Almost there ! We need more informations to complete your subsribtion',
          updateUserError: 'An error occured.',
          firstName: 'Firstname',
          lastName: 'Lastname',
          updateUserSubmit: 'Done',
          firstNameRequired: 'Please enter your Firsname',
          lastNameRequired: 'Please enter your Lastname',
          search: 'Search…',
          refresh: 'Refresh',
          previous: 'Prévious',
          next: 'Next',
          validate: 'Validate',
          save: 'save',
          saveInProgress: 'Save in progress',
          price: 'Price',
          product: 'Product',
          seeDetail: 'See detail',
          quantityInStock: 'Quantity in stock',
          formDeposit: 'Form deposit :',
          searchFile: 'Load a file',
          fileName: "File's name",
          status: 'Status',
          errors: 'Errors',
          numberProducts: 'Number of ok products',
          errorsReport: 'Errors report',
          templateRetrieval: 'Template retrieval',
          download: 'Download',
          recover: 'Recover',
          detailedDesc: 'Detailed description',
          specifications: 'Specifications',
          ofProduct: 'of product',
          capacity: 'capacity',
          netWeight: 'Net weight',
          ingredient: 'Ingredients',
          NEW: 'New',
          ONGOING: 'Ongoing',
          ERROR: 'Error',
          DONE: 'Done',
          DONE_WITH_ERROR: 'Done with error',
          connect: 'Connect',
          logout: 'Logout',
          subscribe: "Register",
          step: 'Step ',
          selectCountry: "Select Country",
          selectNationality: "Select Nationality",
          goBackHome: "Back to home page",
          inConstruction: "This page is getting a little makeover !",
          error404: "WHOOPS ! The page was not found !",
          catalog: 'Catalog',
          orders: 'Orders',
          order: 'Order',
          logistics: 'logistics',
          myAccount: 'my account',
          goBackMarketplace: 'return to Zawema\'s marketplace',
          of: 'of',
          phoneNumber: 'phone number',
          birthDateNotAllowed: "the date of birth is not valid",
          uploadFileID: "Upload your ID",
          signupFailure: "Error: you have already tried to register with the same email address",
          adressFiles: "Upload your proof of address",
          company: 'compagny',
          close: 'close',
          errorMessage: 'error, please retry ',
          successMessage: 'saved with success'
        }
      },
      fr: {
        // APP
        translation: {
          signInForm: {
            signIn: 'Connectez vous à votre compte vendeur',
            nonsubscriber: 'Pas encore inscrit ? ',
            becomeSeller: 'Devenez vendeur Zawema',
            email: 'Adresse email',
            password: 'Mot de passe',
            rememberMe: 'Se souvenir de moi',
            forgottenPassword: 'Mot de passe oublié',
            connect: 'Se connecter',
            emailRequired: 'Veuillez saisir votre email',
            emailType: 'Veuillez renseigner un format E-mail valide',
            passwordRequired: 'Veuillez saisir votre mot de passe',
            passwordType: 'Veuillez saisir au moins 8 caractères, une lettre, un chiffre, une majuscule et un caractère spécial',
            isNotConfirmed: 'Ce compte est en cours de validation par l\'opérateur. Vous serez notifié par mail quand ce dernier sera actif.',
          },
          signUpForm: {
            title: 'Création du login et mot de passe',
            email: 'Email',
            password: 'Mot de passe',
            confirmPassword: 'Confirmation de mot de passe',
            isSoleTrader: 'Vous avez le statut AutoEntrepreneur (EIRL) ?',
            age: 'Âge',
            genre: 'Genre',
            newsletter: 'Newsletter',
            emailRequired: 'Veuillez saisir votre email',
            emailType: 'Entrez un email valide',
            passwordRequired: 'Veuillez saisir votre mot de passe',
            confirmPasswordRequired: 'Veuillez confirmer votre mot de passe',
            matchPassword: 'Les mots de passe doivent être identique',
            ageRequired: 'Veuillez saisir votre âge',
            genreRequired: 'Veuillez saisir votre sexe',
            next: 'Suivant',
            previous: 'Précédent',
            existingEmailAdress: 'Cet email est déjà utilisé'
          },
          forgotPassword: {
            title: "Mot de passe oublié ?",
            text: "Pas de panique ! \nComplétez le champ ci-dessous et nous vous enverrons par email les informations pour réinitialiser votre mot de passe.",
            submitSubtitle: "Renseignez le code reçu à l'adresse suivante :",
            submitSubtitle2: "et choisissez votre nouveau mot de passe.",
            emailValidation: 'Renseignez un email valide',
            emailRequired: 'Renseignez votre adresse email',
            explanation: 'Vous avez reçu un code de confirmation sur votre boîte mail. Merci de renseigner ce code dans le champ ci-dessous.'
          },
          signUpBusiness: {
            companyInfo: "Informations de l'entreprise",
            socialReason: 'Raison sociale',
            shopName: 'Nom commercial de votre entreprise',
            socialReasonRequired: 'Veuillez saisir la raison sociale de votre entreprise',
            shopNameRequired: 'Veuillez saisir le nom de votre boutique',
            email: 'E-mail de contact',
            emailRequired: 'Veuillez saisir votre email de contact',
            telephone: 'Numéro de téléphone',
            telephoneRequired: 'Veuillez saisir le numéro de téléphone de votre entreprise',
            telephoneFormat: 'Veuillez saisir un numéro de téléphone valide',
            adress: 'Adresse de la société',
            adressRequired: "Veuillez saisir l'adresse de votre entreprise",
            cp: 'CP',
            cpRequired: 'Veuillez saisir le code postal de votre entreprise',
            cpFormat: 'Veuillez saisir un code postal valide',
            city: 'Ville',
            cityRequired: 'Veuillez saisir la ville de votre entreprise',
            country: 'Pays',
            countryRequired: 'Veuillez saisir le pays de votre entreprise',
            region: 'Région',
            regionRequired: 'Veuillez saisir la région de votre entreprise',
            identificationNumber: "Numéro d'identification de la société",
            identificationNumberRequired: "Veuillez saisir le numéro d'identification de la société de votre entreprise",
            identificationNumberFormat: "Veuillez saisir un numéro d'identification valide",
            file: 'Dépôt des documents *',
            fileButton: 'Chercher un fichier',
            fileRequired: 'Veuillez fournir les pièces jointes demandées',
            numberOfFile: 'Veuillez retirer une pièce jointe',
            fileSize: 'Une pièce jointe dépasse la taille de 3Mo',
            fileToSmall: 'Le poids du fichier doit être supérieur à 32KB',
            activityType: 'Activité de votre entreprise',
            productsToSell: 'Produits que vous souhaitez vendre',
            website: 'Avez-vous un site Web ? (facultatif)',
            phoneNumber: 'Entrez un numéro de téléphone',
            websiteUrl: 'https://mywebsite.com',
            activityExample: 'Vente de soins capillaires',
            shampoo: 'Shampoings',
            mailExample: 'nomdemonentreprise@example.com',
            activityRequired: 'Veuillez saisir une activité',
            productRequired: 'Veuillez saisir les produits que vous souhaitez vendre',
            obligatory: 'Documents obligatoires',
            socialName: "Nom de l'entreprise + forme juridique (SAS, SARL, EIRL...)",
            uploadID: "Chargez la pièce d'identité du représentant légal",
            uploadAdressProof: "Chargez le justificatif d'adresse de l'entreprise",
            uploadRegistery: "Chargez l'extrait du registre des sociétés",
            uploadRegisterySoleTrader: 'Chargez votre avis de situation SIRENE ou extrait du registre de commerce de moins de 3 mois.',
            uploadStatutes: "Chargez les statuts de votre entreprise"
          },
          signUpBank: {
            title: 'Informations bancaires',
            owner: 'Nom du titulaire du compte',
            bankName: 'Nom de la banque',
            adress: 'L\'adresse du titulaire du compte bancaire',
            ownerNameRequired: 'Veuillez renseigner le nom du titulaire du compte',
            bankNameRequired: 'Renseignez le nom de votre banque',
            BICisRequired: 'Renseignez votre code BIC',
            IBANisRequired: 'Renseignez votre numéro de compte',
            adressRequired: "Renseignez l'adresse postale de votre banque",
            IBANcompletion: 'Indiquez votre numéro de compte : uniquement des nombres (et non pas l\'iban)',
            minIBAN: 'Minimum 7 numéros',
            maxIBAN: 'Maximum 27 numéros',
            BICcompletion: "Le code BIC ne doit comporter que des lettres de l'alphabet en majuscule",
            cpRequired: 'Renseignez le code postal du titulaire du compte',
            cityRequired: 'Renseignez la ville du titulaire du compte',
            countryRequired: 'Renseignez le pays du titulaire du compte',
            regionRequired: 'Renseignez la région du titulaire du compte',
            cgvCheckbox: "J'accepte les ",
            cgv: 'conditions générales de vente',
            cgvRequired: 'Veuillez cocher la case pour continuer',
            placeholderAdress: '165, rue des coquelicots',
          },
          signUpInfos: {
            legalRepresentantInfo: 'Informations du représentant légal',
            firstName: 'Prénom du réprésentant légal',
            lastName: "Nom du réprésentant légal",
            dateOfBirth: 'Date de naissance',
            dateOfBirthRequired: 'Veuillez saisir votre date de naissance',
            nationality: 'Nationalité',
            nationalityRequired: 'Veuillez saisir votre nationalité',
            birthCountry: 'Pays de naissance',
            birthCountryRequired: 'Veuillez saisir votre pays de naissance',
            birthCity: 'Ville de naissance',
            birthCityRequired: 'Veuillez saisir votre ville de naissance',
            adress: 'Adresse',
            adressRequired: 'Veuillez saisir votre adresse',
            cp: 'CP',
            cpRequired: 'Veuillez saisir votre code postal',
            city: 'Ville',
            cityRequired: 'Veuillez saisir votre ville',
            country: 'Pays',
            countryRequired: 'Veuillez saisir votre pays',
            region: 'Région',
            regionRequired: 'Veuillez saisir votre région',
            nationalityPlaceholder: 'Français',
            adressPlaceholder: '35, rue du marché',
            adress2Placeholder: 'Appartement 4',
            denomination: 'Nom et prénom',
            legalRepresentantIntro: "Ce compte doit être activé  par un dirigeant, un cadre supérieur ou une autre personne occupant un poste à responsabilités dans le contrôle et la gestion de votre entreprise. Si ce n’est pas votre cas, veuillez demander à la personne concernée de remplir ce formulaire.",
            legalRepresentantIntroSoleTrader: "Ce compte doit être activé par l'auto-entrepreneur lui même. Si ce n’est pas votre cas, veuillez demander à la personne concernée de remplir ce formulaire.",

            shareholder: "Je possède au moins 25% de l’entreprise.",
            administrator: "Je suis membre du conseil d’administraion de l’entreprise",
            uploadFile: "charger un fichier",
            obligatory: "Pièces à joindre obligatoirement",
            infoFile: 'Format PDF ou JPG ou PNG. Taille min 32ko, max 3mo.',
            tipFile: 'Astuce: Si votre fichier pèse moins de 32ko: vous pouvez l\'imprimer et le scanner pour augmenter son poids.'
          },
          signUpIntro: {
            information: 'Informations préalables',
            subTitle: 'Avant de procéder à votre inscription veuillez vous assurer d\'avoir les documents suivants:',
            fileAdressProof: 'Justificatif de domicile du représentant légal et des bénéficiaires effectifs (+25% des parts)',
            fileIDCard: 'Pièce d\'identité du représentant légal et des bénéficiares effectifs',
            fileCompAdressProof: 'Justificatif d\'adresse de l\'entreprise',
            fileRegistery: 'Extrait du registre des sociétés',
            fileStatute: 'Statuts de l\'entreprise',
            soleTraderInfo: 'Auto entrepreneur ? Uniquement: 1 pièce d\'identité + 1 avis SIRENE ou équivalent + 1 justificatif de domicile',
            formats: 'Formats autorisés : pdf, png, jpeg.',
            size: 'Poids mini : 32ko, maxi: 3mo.',
            cancel: 'Annuler',
            go: 'Commencer',
            needMoreInfo: 'Besoin de plus d\'informations ?',
            contactUs: 'Contactez nous sur :'
          },
          shareholderForm: {
            title: "Actionnaires de l'entreprise",
            intro: 'Nous sommes tenus de recueillir des informations sur tout bénéficiaire effectif possédant 25% (ou plus) des parts de votre entreprise.',
            shareholdersList: 'Veuillez indiquer tous les bénéficiaires effectifs des parts',
            addShareholderButton: 'Ajouter un autre bénéficiaire effectif',
            addShareholderForm: 'Ajouter un bénéficiaire effectif',
            add: 'Ajouter',
            goBack: 'Retour',
            shareholderDeclaration: 'Chargez la déclaration de bénéficiaire effectif',
            shareholderDeclarationDoc: 'Une déclaration de bénéficiaire effectif'
          },
          welcome: {
            seller: 'Devenez vendeur',
            onOperator: 'sur Zawema',
            introduction1: 'Zawema est une ',
            introduction2: 'marketplace dédiée aux cheveux bouclés, frisés et crépus.',
            introduction3: "Nous mettons en relation des professionnels et des particuliers pour permettre l'achat/vente de produits et services adaptés aux cheveux texturés.",
            introduction4: "En réunissant toute l'offre dédiée sur une plateforme digitale, Zawema apporte deux solutions :",
            introduction5: 'Aider les consommateurs à ',
            introduction5bold: "gagner du temps, de l’argent et avoir des cheveux en bonne santé",
            introduction6: 'Donner aux vendeurs une ',
            introduction6bold: "meilleure visibilité et l'accès à une clientèle ultra ciblée.",
            createAccount: 'Créez votre compte maintenant',
            see: 'Consultez nos ',
            legalConditions: 'cgu/cgv',
            alreadySignup: 'Vous possédez déjà un compte ?',
            marketplace: 'la marketplace',
            why: 'Pourquoi vendre sur Zawema ?',
            whyIntro: "L'accélérateur de votre croissance en ligne",
            featureName1: 'Un nouveau canal de vente',
            featureDescription1: 'Elargissez la visibilité de votre entreprise et profitez du rayonnement de la première Marketplace dédiée aux cheveux texturés. Que vous soyez déjà E-commerçant ou non, vous trouverez forcément une bonne raison de nous rejoindre.',
            featureName2: "L'accès à une clientèle ciblée",
            featureDescription2: "Dédiée intégralement aux cheveux texturés, profitez d'une cilentèle ultra ciblée, en quête de vos produits. Tous nos visiteurs sont des acheteurs potentiels pour votre marque.",
            featureName3: 'Des fonctionnalités pensées pour vous',
            featureDescription3: "Parce que nous avons à coeur de vous fournir une expérience de vente fluide, nous avons développé de nombreuses fonctionnalités pour vous permettre de gagner de l’argent sans perdre de temps inutilement.",
            featureName4: 'Des paiements sécurisés',
            featureDescription4: "Zawema propose par l’intermédiaire d’un monéticien reconnu, des échanges sécurisés. Pour vous c’est la garantie de n’avoir aucune mauvaises suprises. Vendez, livrez, soyez payé.",
            subtitle: 'Éléments nécessaires pour créer votre compte vendeur',
            docIntro: 'Parce que la légalité prévaut',
            docTitle1: 'Le listing des bénéficiaires effectifs',
            docDescription1: "Que vous soyez à votre compte ou associé(e), l'accès à la plateforme nécessite les informations des personnes détenant au moins 25% des parts de l'entreprise.",
            docTitle2: "Status certifiés de l'entreprise",
            docDescription2: "Pour devenir vendeur Zawema, vous devez avoir un « statut professionnel », c'est-à-dire être immatriculé.",
            docTitle3: 'Extrait du Registre des sociétés de moins de 3 mois',
            docDescription3: 'Préparer et présenter un extrait RCS récent (ou équivalent)',
            docTitle4: 'Garantir la livraison de ses commandes',
            docDescription4: 'Votre entreprise doit être en mesure de livrer elle-même les produits au client final. Vous êtes libre de sélectionner le prestataire de votre choix',
            docTitle5: 'Facturation et service clients',
            docDescription5: 'Pour chaque commande reçue et expédiée, vous devez fournir une facture à vos clients. Votre service client peut être géré en français.',
            docTitle6: 'Vos informations bancaires',
            docDescription6: 'Votre activité doit avoir un compte bancaire dédié et les informations de celui-ci doivent être partagées auprès de notre fournisseur de paiement.',
            legalTitle: 'légal & juridique',
            discover: 'Découvrez nos ',
            download: ' et téléchargez ',
            chart: 'la charte du vendeur'
          },
          shipping: {
            shippingZone: 'Zone de livraison',
            mode: {
              domicilestandard: 'Livraison à domicile - Standard',
              domicilelong: 'Livraison à domicile - Longue',
              domicileexpress: 'Livraison à domicile - Express',
              relaiscolis: 'Livraison en point relais - Mondial Relay'
            },
            europe: 'Europe (hors France)',
            metropolitanFrance: 'France métropolitaine',
            africa: 'Afrique',
            usa: 'États-Unis',
            latinAmerica: 'Amérique latine',
            free: 'Gratuit',
            notice: 'Pour offrir les frais de livraison sur un mode de livraison : activez le mode et laissez le champ prix vide.',
          },
          signUpConfirmation: {
            thanks: 'Merci !',
            formTransfered: 'Votre formulaire a été correctement complété et vos informations ont été transmises à Zawema.',
            confirmationEmail: 'Vous recevrez un mail après validation pour pouvoir accéder à nos services.',
            signupQuestions: "Des questions ? Contactez nous à l'adresse mail suivante :",
            goHome: "Retour à la page d'accueil"
          },
          modalBusinessForm: {
            documents: 'Documents obligatoires à transmettre :',
            identity1: ' Carte d’identité, ',
            identity2: 'passeport, permis de séjour ou permis de conduire pour la zone UE. Passeport ou permis de conduire pour le Royaume-Uni, les États-Unis et le Canada. Pour les autres nationalités, un passeport est requis. Dans le cas d’un utilisateur légal, ce document doit faire référence à la personne physique dûment habilitée à agir au nom de la personne morale. Carte d’identité : Recto ET Verso (Valide) OU Passeport (Valide).',
            status1: ' Statuts certifiés',
            status2: ' - acte formel établi par les entrepreneurs, dans lequel sont mentionnées les informations suivantes : raison sociale, activité, siège social, actionnariat…',
            registerExtract1: ' Extrait du Registre des Sociétés ',
            registerExtract2: 'délivré au cours des trois derniers mois Dans le cas d’une organisation ou d’une entreprise individuelle, il peut s’agir d’une preuve d’enregistrement de l’autorité officielle.',
            proofAdress1: '- Un justificatif de domicile. ',
            proofAdress2: 'Confirmation de résidence : Moins d’un an. Peut être : Formulaire d’enregistrement résidentiel, Facture eau/électricité/gaz/téléphone, Attestation fiscale, Assurance habitation, Confirmation de propriété immobilière.',
            identificationCard: "Pièce d'identité valide"
          },
          modalAcceptOrder: {
            doYouWantAccept: "Voulez-vous valider la commande",
            isAccept: "validée avec succès",
          },
          modalShippingOrder: {
            toValidate: "Pour valider l'envoi et l'expédition de",
            order: "la commande",
            pleaseFill: "veuillez remplir les informations de suivi de colis",
            goBack: "retour",
            alertNotification: "Un message notifiera votre client de l’envoi de sa commande",
            notificationConfirmation: "Un message a été notifié à votre client",
            shippingOf: "L'expédition de",
            isValidate: "a été pris en compte",
            sendWithoutTracking: "Expédier sans suivi de livraison"
          },
          modalCancelOrder: {
            doYouWantRefuse: "Voulez-vous vraiment refuser la commande",
            confirmeRefuse: "Oui je souhaite refuser la commande",
            order: "la commande",
            isRefused: "a bien été refusée",
            doYouWantCancel: "Voulez-vous vraiment annuler la commande",
            confirmCancel: "Oui je souhaite annuler la commande",
            isCanceled: "à bien été annulée",
            goBack: "retour",
            doYouWantDenyIncident: "Voulez-vous vraiment refuser l'incident de",
            confirmDeny: "Oui je souhaite refuser l'incident",
            incidentOf: "L'incident de",
            isDeny: "à bien été refusé",
            alertNotification: "En annulant la commande le client sera intégralement remboursé."
          },
          modalRefundOrder: {
            complete: "intégral",
            partial: "partiel",
            partialRefund: "Remboursement partiel",
            completeRefund: "Remboursement intégral",
            refund: "Remboursement",
            of: "de",
            order: "la commande",
            refundOrder: "Rembourser la commande",
            isRefund: "effectué avec succès"
          },
          orderPage: {
            waitingDelivery: "Livraison en cours",
            newOrders: "Nouvelles commandes",
            preparation: "A traiter",
            notShipped: "Non expédiées",
            delivered: "Livrées",
            incident: "incidents",
            orderNumber: "ID Commande",
            products: "Produits",
            quantity: "Qté",
            amountOrder: "Montant",
            customer: "Client",
            trackingNumber: 'N° de suivi',
            track: "suivre",
            details: "Détails",
            actions: "Actions",
            status: "Statut",
            paidPluriel: "Payées",
            paid: "Payée",
            refused: "Refusée",
            noActions: "pas d'action disponible",
            accept: "Accepter",
            refuse: "Refuser",
            send: "Envoyer",
            cancel: "Annuler",
            finalizePreparation: "Finaliser la préparation",
            windUp: "Clôturer",
            refund: "Rembourser",
            openIncident: "Ouvrir un incident",
            shippingCost: "Frais de livraison",
            totalOrder: "Total de la commande",
            acceptCommandForSee: "Veuillez accepter la commande pour pouvoir voir le contact du client.",
            lateDelivery: "Retard de livraison",
            currentIncident: "Incident(s) en cours",
            deliveryAddress: "Adresse de livraison",
            waitingAccept: "en attente d'acceptation",
            toShip: "a expédier",
            inDelivery: "en cours de livraison",
            rejected: "refusée",
            isDelivered: "commande livrée",
            noOrderToDisplay: "aucune commande à afficher",
            noOrderToPrepare: "vous n'avez aucune commande à traiter",
            noOrderToShip: "vous n'avez aucune commande à expédier",
            noOrderDelivered: "vous n'avez aucune commande livrée",
            noOrderWaitingDelivery: "vous n'avez aucune commande en cours de livraison",
            noOrderIncident: "vous n'avez aucun incident",
            noProduct: "vous n'avez aucun produit enregistré",
            importProduct: "importer des produits",
            summaryOfOrders: "récapitulatif des commandes",
            deliveryInfo: "Informations de livraison",
            newIncident: "à traiter",
            waitingRefundIncident: "en attente de remboursement",
            refundIncident: "cloturé: remboursement effectué",
            closedIncident: "cloturé",
          },
          contactPage: {
            contactUs: 'Contactez-nous',
            emailContact: 'email renseigné lors de votre inscription',
            subject: 'Objet',
            subjectMissing: 'Merci de renseigner le sujet de votre message',
            message: 'Message',
            messagePlaceholder: 'Votre message ici...',
            messageError: 'Aucun message à envoyer'
          },
          importsPage: {
            title: 'Mes produits importés',
            dragDrop: 'ou glissez déposer le fichier',
            dropHere: 'Déposez ici',
            fileSent: 'Votre fichier est envoyé !',
            importsGuide: "Un doute ? Téléchargez le guide !",
            importsMobile: "Téléchargez le guide !",
            errorTitle: "Afin de pouvoir importer vos produits, veuillez finir de configurer votre compte.",
            accountTitle: "Informations boutique",
            accountDetails: "editez les informations relatives à votre boutique en ligne dans l'espace \"Mon Compte\".",
            accountAccess: "editer maintenant",
            shippingTitle: "frais de livraison",
            shippingDetails: "choisissez vos zones d'expédition et configurez vos tarifs de livraison dans l'espace \"Logistique\".",
            shippingAccess: "configurer maintenant"
          },
          cataloguePage: {
            productName: 'Nom',
            disponibility: 'Disponibilité',
            price: "Prix(€)",
            noAttribut: 'Aucun attribut renseigné'
          },
          homePage: {
            title: 'Tableau de bord',
            waiting: ' en attente',
            statItem1: 'Produits en ligne',
            statItem2: 'CA annuel HT',
            statItem3: 'Commandes',
            statItem4: 'Offres en ligne',
            statItem5: 'Incidents en cours',
            btnDownloadGuide: 'Une question ? Téléchargez le guide vendeur',
            problemQuestion: 'Vous rencontrez un problème ou avez une question ?',
            contact: 'Contactez Zawema',
          },
          accountPage: {
            title1: 'informations de l\'entreprise',
            title2: 'informations générale',
            title3: 'mes informations',
            title4: 'informations bancaire',
            companyName: 'Dénomination sociale de l\'entreprise',
            companyNumber: 'n° de SIRET',
            companyAdress: 'adresse',
            companyMail: 'email de l\'entreprise',
            sellerIdentity: 'identité',
            shippingZone: 'zone de livraison',
            aboutUs: 'qui sommes-nous',
            aboutUsDesc: 'décrivez votre entreprise en quelques mots',
            shippingCondition: 'conditions de livraison',
            shippingConditionDesc: 'décrivez vos conditions de livraison',
            returnCondition: 'conditions de retour',
            returnConditionDesc: 'partagez avec vos clients votre politique de retour',
            personalDataCondition: 'gestion des données personnelles',
            personalDataDesc: 'expliquez en quelques phrases votre politique de gestion des données clients',
            editMyInformation: 'Modifier mes informations',
            succes: 'vos informations ont bien été enregistrées.'
          },
          contactForm: {
            subject: 'sujet',
            send: 'envoyer votre demande',
            cancel: 'annuler',
            isSent: 'merci votre message est bien envoyé',
            order: 'commande',
            catalog: 'catalogue',
            imports: 'imports',
            logistic: 'logistique',
            account: 'mon compte',
            other: 'autres',
            messageIsRequired: 'veuillez renseigner votre message',
            messageIsShort: 'votre message est trop court',
            editMyCompany: 'modifier mon entreprise',
          },
          pagination: {
            showing: 'Affichage de',
            to: 'à',
            of: 'sur',
            results: 'résultats'
          },
          loginPageTitle: 'Connexion',
          email: 'E-mail',
          password: 'Mot de Passe',
          confirmPassword: 'Confirmation mot de passe',
          emailRequired: 'Veuillez saisir votre email',
          passwordRequired: 'Veuillez saisir votre mot de passe',
          signUpSubmit: 'S\'inscrire',
          signUpPageTitle: 'Inscription',
          confirmPasswordRequired: 'Veuillez confirmer votre mot de passe',
          passwordMismatch: 'Les mots de passe ne correspondent pas',
          loginButton: 'Se Connecter',
          signUpButton: 'Créer mon compte',
          logoutButton: 'Se Deconnecter',
          homePageTitle: 'Page d\'acceuil',
          requestCodeButton: 'Envoyer',
          updateButton: 'Actualiser l\'affichage',
          emailNotValid: 'Email non valide',
          code: 'Code',
          submitNewPasswordButton: 'Confirmer',
          codeRequired: 'Veuillez renseigner votre code',
          forgotPasswordPageTitle: 'Reinitialisez votre mot de passe',
          sellerZawema: "Envie de devenir vendeur sur Zawema ?",
          forgotPasswordButton: 'Mot de passe oublié',
          firstLoginPageTitle: 'Vous y êtes presque ! Renseignez ces informations pour completer votre inscription',
          updateUserError: 'Une erreur est survenue',
          firstName: 'Prénom',
          lastName: 'Nom',
          updateUserSubmit: 'Terminer',
          firstNameRequired: 'Veuillez entrer votre prénom',
          lastNameRequired: 'Veuillez entrer votre nom',
          search: 'Rechercher…',
          refresh: 'Rafraîchir',
          previous: 'Précédent',
          next: 'Suivant',
          validate: 'Valider',
          save: 'enregistrer',
          saveInProgress: 'Enregistrement en cours',
          price: 'Prix',
          product: 'Produit',
          seeDetail: 'Voir le détail',
          quantityInStock: 'Quantité en stock',
          formDeposit: 'Dépôt du formulaire :',
          searchFile: 'Chercher un fichier',
          fileName: 'Nom du fichier',
          status: 'Statut',
          errors: 'Erreurs',
          numberProducts: 'Nombre de produits ok',
          errorsReport: "Rapport d'erreurs",
          templateRetrieval: 'Télécharger le modèle de fichier CSV',
          download: 'Télécharger',
          recover: 'Récupérer',
          detailedDesc: 'Description détaillée',
          specifications: 'Caractéristiques',
          ofProduct: 'du produit',
          capacity: 'contenance',
          netWeight: 'Poids net',
          ingredient: 'Ingrédients',
          NEW: 'Nouveau',
          ONGOING: 'En cours',
          ERROR: 'Erreur',
          DONE: 'Réalisé',
          DONE_WITH_ERROR: 'Réalisé avec des erreurs',
          connect: 'Se connecter',
          logout: 'Se déconnecter',
          subscribe: "S'inscrire",
          step: 'Étape ',
          selectCountry: 'Sélectionnez un pays',
          selectNationality: "Sélectionnez une nationalité",
          goBackHome: "Retour à la page d'accueil",
          inConstruction: "Cette page se refait une petite beauté !",
          error404: "OUPS ! La page n'a pas été trouvée !",
          catalog: 'Catalogue',
          orders: 'Commandes',
          order: 'Commande',
          logistics: 'logistique',
          myAccount: 'mon compte',
          goBackMarketplace: 'retourner sur la marketplace Zawema',
          of: 'du',
          phoneNumber: 'n° de telephone',
          birthDateNotAllowed: "La date de naissance n'est pas valide",
          uploadFileID: "Chargez votre pièce d'identité",
          signupFailure: "Erreur : vous avez déjà essayé de vous inscrire avec une même adresse mail",
          adressFiles: "Chargez votre justificatif de domicile",
          company: 'entreprise',
          close: 'fermer',
          errorMessage: 'une erreur est survenue, veuillez réessayer.',
          successMessage: 'enregistré avec succès.'
        }
      }
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

export default i18n;
