import React from 'react';
import { useTranslation } from "react-i18next";
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { useStyles } from "react-styles-hook";
import { PrinterIcon } from '@heroicons/react/solid';
import useFetchSellerOrders from '../../../hooks/useFetchSellerOrders';
import { capitalizeFirst } from '../../../utils/format';
import getStyles from "./styles";

export const StatusTabs = ({ to, text, numberOf, ...props }) => {
  const { t } = useTranslation();
  const classes = useStyles(getStyles());
  const { pathname } = useLocation();
  const print = () => {
    window.print();
  };

  const numberOfOrder = useFetchSellerOrders();
  const numberOfPrepare = useFetchSellerOrders("WAITING_ACCEPTANCE");
  const numberOfNotShipped = useFetchSellerOrders("WAITING_PREPARATION");
  const numberOfDelivered = useFetchSellerOrders("DELIVERED");
  const numberOfIncident = useFetchSellerOrders("INCIDENT");
  const numberOfWaitingDelivery = useFetchSellerOrders("WAITING_DELIVERY");

  return (
    <div className="flex justify-between">
      {numberOfOrder && numberOfPrepare && numberOfNotShipped && numberOfDelivered && numberOfIncident
        && (
          <>
            <div className="flex gap-x-8">
              <div className="relative">
                <Link
                  to="/orders"
                  className={`static text-center text-s md:text-xms ${(pathname === "/orders") ? "text-black-title font-medium" : "text-grey-middle font-normal"}`}
                >
                  {t("orders")}
                  {
                    (numberOfOrder?.length > 0)
                    && (
                      <svg className="absolute -top-2 -right-3 h-2 w-2 text-orange-dark" fill="currentColor" viewBox="0 0 8 8">
                        <circle cx={4} cy={4} r={3} />
                      </svg>
                    )
                  }
                </Link>
              </div>
              <div className="relative">
                <Link
                  to="/preparation-orders"
                  className={`static text-center text-s md:text-xms ${(pathname === "/preparation-orders") ? "text-black-title font-medium" : "text-grey-middle font-normal"}`}
                >
                  {t("orderPage.preparation")}
                  {
                    (numberOfPrepare?.length > 0)
                    && (
                      <svg className="absolute -top-2 -right-3 h-2 w-2 text-orange-dark" fill="currentColor" viewBox="0 0 8 8">
                        <circle cx={4} cy={4} r={3} />
                      </svg>
                    )
                  }
                </Link>
              </div>
              <div className="relative">
                <Link
                  to="/not-shipped"
                  className={`static text-center text-s md:text-xms ${(pathname === "/not-shipped") ? "text-black-title font-medium" : "text-grey-middle font-normal"}`}
                >
                  {t("orderPage.notShipped")}
                  {
                    (numberOfNotShipped?.length > 0)
                    && (
                      <svg className="absolute -top-2 -right-3 h-2 w-2 text-orange-dark" fill="currentColor" viewBox="0 0 8 8">
                        <circle cx={4} cy={4} r={3} />
                      </svg>
                    )
                  }
                </Link>
              </div>
              <div className="relative">
                <Link
                  to="/waiting-delivery"
                  className={`static text-center text-s md:text-xms ${(pathname === "/waiting-delivery") ? "text-black-title font-medium" : "text-grey-middle font-normal"}`}
                >
                  {t("orderPage.waitingDelivery")}
                  {
                    (numberOfWaitingDelivery?.length > 0)
                    && (
                      <svg className="absolute -top-2 -right-3 h-2 w-2 text-orange-dark" fill="currentColor" viewBox="0 0 8 8">
                        <circle cx={4} cy={4} r={3} />
                      </svg>
                    )
                  }
                </Link>
              </div>
              <div className="relative">
                <Link
                  to="/delivery"
                  className={`static text-center text-s md:text-xms ${(pathname === "/delivery") ? "text-black-title font-medium" : "text-grey-middle font-normal"}`}
                >
                  {t("orderPage.delivered")}
                  {
                    (numberOfDelivered?.length > 0)
                    && (
                      <svg className="absolute -top-2 -right-3 h-2 w-2 text-orange-dark" fill="currentColor" viewBox="0 0 8 8">
                        <circle cx={4} cy={4} r={3} />
                      </svg>
                    )
                  }
                </Link>
              </div>
              <div className="relative">
                <Link
                  to="/incident"
                  className={`static text-center text-s md:text-xms capitalize ${(pathname === "/incident") ? "text-black-title font-medium" : "text-grey-middle font-normal"}`}
                >
                  {t("orderPage.incident")}
                  {
                    (numberOfIncident?.length > 0)
                    && (
                      <svg className="absolute -top-2 -right-3 h-2 w-2 text-orange-dark" fill="currentColor" viewBox="0 0 8 8">
                        <circle cx={4} cy={4} r={3} />
                      </svg>
                    )
                  }
                </Link>
              </div>
            </div>
            {
              (pathname === "/not-shipped")
              && (
                <button
                  type="button"
                  onClick={print}
                  className="md:hidden lg:flex lg:text-xxxs xl:text-xxms lg:flex-row lg:space-x-1"
                >
                  <span className="underline">{capitalizeFirst(t("orderPage.summaryOfOrders"))}</span>

                  <PrinterIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              )
            }
          </>
        )}

    </div>

  );
};

export default StatusTabs;
