import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { Fragment } from 'react-styles-hook';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { capitalizeFirst } from '../../utils/format';
import { contactLaunched } from '../../store/reducer';

export const ContactForm = ({ subjectList, openForm, setOpenForm, hideModal, to, text, ...props }) => {
  const { t } = useTranslation();
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const [selected, setSelected] = useState(subjectList[0]);
  const [send, setSend] = useState(false);

  const dispatch = useDispatch();
  const { contact } = useSelector((state) => (
    {
      contact: state.getIn(['store', 'contact'])
    }
  ));

  // auto close modal or delete confirmation message after 3s ONLY if form is submitted
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return null;
    }
    let timer;
    if (hideModal) {
      timer = setTimeout(() => hideModal(), 3000);
    } else {
      timer = setTimeout(() => setOpenForm(false), 3000);
    }

    return () => { clearTimeout(timer); };
  }, [send]);

  // Form
  const [formValue, setFormValue] = useState({
    subject: selected,
    body: '',
  });
  const initialValues = {
    subject: formValue.subject,
    body: formValue.body,
  };

  const onSubmit = () => {
    dispatch(contactLaunched({ formValue }));
    setSend(true);
  };

  const validationSchema = Yup.object().shape({
    body: Yup.string()
      .min(5, capitalizeFirst(t("contactForm.messageIsShort")))
      .required(capitalizeFirst(t("contactForm.messageIsRequired")))
  });

  return (
    <>
      {
        (openForm)
        && (
          <div className="bg-white py-4 px-4 overflow-hidden sm:px-6">
            <div className="relative max-w-md mx-auto">
              <div className="mt-2">
                {
                  (!send)
                  && (
                    <>
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                      >
                        {({ errors, touched, values, handleChange }) => (
                          <Form>
                            <div className="">
                              <Listbox
                                id="subject"
                                name="subject"
                                value={values.subject}
                                onChange={(e) => {
                                  setSelected(e);
                                  handleChange(e);
                                  setFormValue((prevState) => ({
                                    ...prevState,
                                    subject: e
                                  }));
                                }}
                              >
                                {({ open }) => (
                                  <>
                                    <Listbox.Label className="block text-xxms font-medium text-gray-700 capitalize">{t("contactForm.subject")}</Listbox.Label>
                                    <div className="mt-1 relative">
                                      <Listbox.Button className={`relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default ${(subjectList.length > 1) ? "focus:outline-none focus:ring-1 focus:ring-orange-dark focus:border-orange-dark" : ""} `}>
                                        <span className="block truncate text-xs">{capitalizeFirst(selected)}</span>
                                        {(subjectList.length > 1)
                                          && (
                                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                              <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            </span>
                                          )}
                                      </Listbox.Button>
                                      {
                                        (subjectList.length > 1)
                                        && (
                                          <Transition
                                            show={open}
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                          >
                                            <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                              {subjectList.map((subject) => (
                                                <Listbox.Option
                                                  key={subject}
                                                  className={({ active }) => classNames(
                                                    active ? 'text-white bg-orange-chocolate' : 'text-gray-900',
                                                    'text-xs cursor-default select-none relative py-2 pl-8 pr-4'
                                                  )}
                                                  value={subject}
                                                >
                                                  {({ newSelected, active }) => (
                                                    <>
                                                      <span className={classNames(newSelected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                        {capitalizeFirst(subject)}
                                                      </span>

                                                      {newSelected ? (
                                                        <span
                                                          className={classNames(
                                                            active ? 'text-white' : 'text-indigo-600',
                                                            'absolute inset-y-0 left-0 flex items-center pl-1.5'
                                                          )}
                                                        >
                                                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                      ) : null}
                                                    </>
                                                  )}
                                                </Listbox.Option>
                                              ))}
                                            </Listbox.Options>
                                          </Transition>
                                        )
                                      }
                                    </div>
                                  </>
                                )}
                              </Listbox>
                            </div>
                            <div className="mt-4">
                              <label htmlFor="body" className="block text-xxms font-medium text-gray-700">
                                Message
                              </label>
                              <div className="mt-1">
                                <Field
                                  id="body"
                                  name="body"
                                  component="textarea"
                                  rows={4}
                                  value={values.body}
                                  placeholder={t("contactPage.messagePlaceholder")}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setFormValue((prevState) => ({
                                      ...prevState,
                                      [e.target.name]: e.target.value
                                    }));
                                  }}
                                  className="py-3 px-4 block w-full shadow-sm focus:ring-orange-dark focus:border-orange-dark border border-gray-300 rounded-md"
                                />
                                {errors.body && touched.body && (
                                  <p className="px-2 text-red text-center md:text-left">{errors.body}</p>
                                )}
                              </div>
                              <div className="mt-6 flex justify-center">
                                <button
                                  type="submit"
                                  className="w-full md:w-52 px-3 py-1.5 border border-transparent rounded-md shadow-sm text-xxms font-medium text-white bg-orange-dark hover:bg-orange-chocolate focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dark"
                                >
                                  {capitalizeFirst(t("contactForm.send"))}
                                </button>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                      <div className="mt-2 flex justify-center">
                        {(hideModal)
                          && (
                            <button
                              type="button"
                              onClick={() => hideModal()}
                              className=" w-full md:w-52 px-3 py-1.5 border border-grey-medium rounded-md shadow-sm text-xxms font-medium text-grey bg-white capitalize"
                            >
                              {t("contactForm.cancel")}
                            </button>
                          )}
                        {(!hideModal)
                          && (
                            <button
                              type="button"
                              onClick={() => setOpenForm(false)}
                              className=" w-full md:w-52 px-3 py-1.5 border border-grey-medium rounded-md shadow-sm text-xxms font-medium text-grey bg-white capitalize"
                            >
                              {t("contactForm.cancel")}
                            </button>
                          )}
                      </div>
                    </>
                  )
                }
              </div>
              {
                (send)
                && (
                  <div className="text-center">
                    {capitalizeFirst(t("contactForm.isSent"))}
                  </div>
                )
              }
            </div>
          </div>
        )
      }
    </>
  );
};

export default ContactForm;
