import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'formik';
import { CustomButton } from '../Button';

const RequestForm = (props) => {
  const { t } = useTranslation();
  const { requestCodeErrorMessage } = useSelector((state) => ({
    requestCodeErrorMessage: state.getIn(['app', 'requestCodeErrorMessage']),
    requestCodeLoading: state.getIn(['app', 'requestCodeLoading'])
  }));
  const {
    values,
    errors,
    onSubmit,
    handleBlur,
    handleChange,
    handleSubmit,
    initialValues
  } = props;
  const { email } = values;

  return (
    <Form className="container flex flex-col justify-center items-center w-full">
      <div className="w-full px-4 flex flex-col justify-center items-center">
        <div className="w-full flex flex-col md:flex-row items-center mb-6">
          <label className="text-s font-sans md:text-s mb-2 md:mb-0 w-full md:w-1/4">{t('email')}</label>
          <div>
            <Field
              id="Email"
              name="email"
              type="email"
              onChange={handleChange}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-white focus:outline-none focus:ring-orange-light focus:border-orange-dark sm:text-xxs"
            />
            {errors && errors.email === "emailNotValid" ? (
              <p className="text-red-600 text-center md:text-left">{t("forgotPassword.emailValidation")}</p>
            ) : null }
            {errors && errors.email === "emailRequired" ? (
              <p className="text-red-600 text-center md:text-left">{t("forgotPassword.emailRequired")}</p>
            ) : null }
          </div>
        </div>
      </div>
      <CustomButton
        type="submit"
        onclick={() => onSubmit}
        title={t('requestCodeButton')}
      />
      <p className="pt-4 text-red text-sm text-center">{requestCodeErrorMessage}</p>
    </Form>
  );
};

export default RequestForm;
