import React, { useState } from "react";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { ModalProductDetails } from '../Modal/ModalProductDetails';

const CatalogArray = ({ item }) => {
  // Mobile component
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const showModal = () => {
    setOpenModal(true);
  };
  const hideModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <div key={item?.id} className="pl-4 border-grey-light border-b lg:border-b-0">
        <div className="mb-4 pt-4">
          <div>
            <p className="text-xxms text-grey-lightDark font-semibold">ID</p>
          </div>
          <div className="pt-2">
            <p className="text-xxms space-y-2">{item.productId}</p>
          </div>
        </div>
        <div className="mb-4 pt-4">
          <div>
            <p className="text-xxms font-semibold text-grey-lightDark">{t("product")}</p>
          </div>
          <div>
            <div className="pt-2">
              <img src={item.images[0].url} className="h-20 w-16" alt="Creme" />
            </div>
            <div className="pt-2">
              <p className="text-xxms">{item.title.fr}</p>
            </div>
          </div>
        </div>
        <div className="mb-4">
          <div>
            <p className="text-xxms text-grey-lightDark font-semibold">GTIN</p>
          </div>
          <div className="pt-2">
            <p className="text-xxms space-y-2">{item.gtin}</p>
          </div>
        </div>
        <div className="mb-4">
          <div>
            <p className="text-xxms text-grey-lightDark font-semibold">SKU</p>
          </div>
          <div className="pt-2">
            <p className="text-xxms">{item.sellerSKU}</p>
          </div>
        </div>
        <div className="mb-4">
          <div>
            <p className="text-xxms text-grey-lightDark font-semibold">{t("price")}</p>
          </div>
          <div className="pt-2">
            <p className="text-xxms">{`${item.priceWithVAT ? item.priceWithVAT.toFixed(2) : ""} €`}</p>
          </div>
        </div>
        <div className="pt-2">
          <div className="mb-2">
            <p className="text-xxms text-grey-lightDark font-semibold">{t("cataloguePage.disponibility")}</p>
          </div>
          <div className="pt-2">
            <p className="text-xxms">
              {item.isAvailable
                ? (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-green-apple" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                )
                : (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                )}
            </p>
          </div>
        </div>
        <div className="pt-4 mb-8">
          <div>
            <p className="text-xxms text-grey-lightDark font-semibold">Actions</p>
          </div>
          <div className="pt-4">
            <button
              type="button"
              onClick={showModal}
              className="text-xxms underline"
              id="details"
            >
              {t("seeDetail")}
            </button>
          </div>
        </div>
        <ModalProductDetails
          showModal={openModal}
          hideModal={hideModal}
          item={item}
        />
      </div>
    </>
  );
};

export default CatalogArray;

CatalogArray.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object]).isRequired
};
