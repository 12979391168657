import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Yup from "yup";
import { Field, Formik, Form } from 'formik';
import { signupLaunched } from '../../App/reducer';
import countriesJSON from "../../../utils/countries.json";
import CguCgv from '../../../assets/documents/cgu-cgv.pdf';

const BankInfosForm = ({ step, setStep, formValue, setFormValue }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Form data
  const initialValues = {
    bankAccontOwner: formValue.bankAccontOwner,
    bankAdress1: formValue.bankAdress1,
    bankZipCode: formValue.bankZipCode,
    bankCity: formValue.bankCity,
    bankCountry: formValue.bankCountry,
    bic: formValue.bic,
    iban: formValue.iban,
    cgv: formValue.cgv
  };

  const signup = () => {
    dispatch(signupLaunched(formValue));
    setStep(step + 1);
    window.scrollTo(0, 0);
  };

  // sorts country select by name
  const countriesMapped = countriesJSON;

  // Form Validation Schema
  const ValidationSchema = Yup.object().shape({
    bankAccontOwner: Yup.string()
      .required(t("signUpBank.ownerNameRequired"))
      .nullable(),
    bankAdress1: Yup.string()
      .required(t("signUpBank.adressRequired"))
      .nullable(),
    bankZipCode: Yup.number()
      .required(t("signUpBank.cpRequired"))
      .positive()
      .integer()
      .nullable(),
    bankCity: Yup.string()
      .required(t("signUpBank.cityRequired"))
      .nullable(),
    bankCountry: Yup.string()
      .required(t("signUpBank.countryRequired"))
      .nullable(),
    bic: Yup.string()
      .required(t("signUpBank.BICisRequired"))
      .matches(
        /[A-Z\s]+/,
        t("signUpBank.BICcompletion")
      )
      .nullable(),
    iban: Yup.string()
      .required(t("signUpBank.IBANisRequired"))
      .matches(/^[0-9]+$/, t("signUpBank.IBANcompletion"))
      .min(7, t("signUpBank.minIBAN"))
      .max(35, t("signUpBank.maxIBAN"))
      .nullable(),
    cgv: Yup.bool()
      .oneOf([true], t("signUpBank.cgvRequired"))
  });

  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-lg">
      <h2 className="text-sm md:text-s font-bold w-full mx-auto lg:w-5/6 pb-2">{`${t("signUpBank.title")} *`}</h2>
      <div className="w-full mx-auto">
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema}
          onSubmit={signup}
        >
          {({ errors, touched, values, handleChange }) => (
            <Form className="mx-auto w-5/6 flex flex-col justify-center items-center">
              <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
                <div className="w-full">
                  <label className="block text-xxms font-medium">{`${t('signUpBank.owner')} *`}</label>
                  <Field
                    id="bankAccontOwner"
                    name="bankAccontOwner"
                    type="text"
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value
                      }));
                    }}
                    placeholder="ex : Dupont"
                    className="w-full border-grey-light rounded text-xxms text-gray-500"
                  />
                  {errors.bankAccontOwner && touched.bankAccontOwner ? (
                    <p className="text-red-600 text-center md:text-left">{errors.bankAccontOwner}</p>
                  ) : null}
                </div>
              </div>

              <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-2 md:mb-4">
                <div className="w-full">
                  <label className="block text-xxms font-medium">{`${t('signUpBank.adress')} *`}</label>
                  <Field
                    id="bankAdress1"
                    name="bankAdress1"
                    type="text"
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value
                      }));
                    }}
                    placeholder={`ex : ${t("signUpBank.placeholderAdress")}`}
                    className="w-full border-grey-light rounded text-xxms text-gray-500"
                  />
                  {errors.bankAdress1 && touched.bankAdress1 ? (
                    <p className="text-red-600 text-center md:text-left">{errors.bankAdress1}</p>
                  ) : null}
                </div>
              </div>

              <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-4 md:mb-6">
                <div className="w-full md:flex">
                  {/* ZipCode field */}
                  <div className="w-full md:1/5 md:pr-4">
                    <Field
                      id="bankZipCode"
                      name="bankZipCode"
                      type="number"
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: parseInt(e.target.value, 10)
                        }));
                      }}
                      placeholder="ex : 75000"
                      className="w-full border-grey-light rounded text-xxms text-gray-500"
                    />
                    {errors.bankZipCode && touched.bankZipCode ? (
                      <p className="text-red-600 text-center md:text-left">{errors.bankZipCode}</p>
                    ) : null}
                  </div>
                  {/* City field */}
                  <div className="w-full md:w-4/5 mb-4 md:mb-0 mt-4 md:mt-0">
                    <Field
                      id="bankCity"
                      name="bankCity"
                      type="text"
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value
                        }));
                      }}
                      placeholder="ex : Paris"
                      className="w-full border-grey-light rounded text-xxms text-gray-500"
                    />
                    {errors.bankCity && touched.bankCity ? (
                      <p className="text-red-600 text-center md:text-left">{errors.bankCity}</p>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
                <div className="w-full">
                  <select
                    id="bankCountry"
                    name="bankCountry"
                    defaultValue=""
                    type={values.bankCountry}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value
                      }));
                    }}
                    className="w-full border-grey-light rounded text-xxms text-gray-500"
                  >
                    <option value="" disabled label={t("selectCountry")} />
                    {
                      countriesMapped.map((country) => (
                        <option key={country.alpha_3_code} value={country.alpha_2_code}>
                          {country.en_short_name}
                        </option>
                      ))
                    }
                  </select>
                  {errors.bankCountry && touched.bankCountry ? (
                    <p className="text-red-600 text-center md:text-left">{errors.bankCountry}</p>
                  ) : null}
                </div>
              </div>

              <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
                <div className="w-full">
                  <label className="block text-xxms font-medium">BIC *</label>
                  <Field
                    id="bic"
                    name="bic"
                    type="text"
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value
                      }));
                    }}
                    placeholder="ex : CMCIFR2A"
                    className="w-full border-grey-light rounded text-xxms text-gray-500"
                  />
                  {errors.bic && touched.bic ? (
                    <p className="text-red-600 text-center md:text-left">{errors.bic}</p>
                  ) : null}
                </div>
              </div>

              <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
                <div className="w-full">
                  <label className="block text-xxms font-medium">Numéro de compte *</label>
                  <Field
                    id="iban"
                    name="iban"
                    type="text"
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value
                      }));
                    }}
                    placeholder="ex : 123456789"
                    className="w-full border-grey-light rounded text-xxms text-gray-500"
                  />
                  {errors.iban && touched.iban ? (
                    <p className="text-red-600 text-center md:text-left">{errors.iban}</p>
                  ) : null}
                </div>
              </div>

              <div className="w-full flex flex-col justify-center items-start md:items-center mb-4 md:mb-6">
                <div>
                  <Field
                    id="cgv"
                    name="cgv"
                    type="checkbox"
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: !values.cgv
                      }));
                    }}
                    className="border-grey-light focus:ring-orange-dark text-orange-dark mr-2"
                  />
                  <label htmlFor="cgv">
                    {t("signUpBank.cgvCheckbox")}
                    <a href={CguCgv} target="_blank" rel="noopener noreferrer" className="underline">{t("signUpBank.cgv")}</a>
                  </label>
                </div>
                {errors.cgv && touched.cgv ? (
                  <p className="text-red-600 text-center md:text-left">{errors.cgv}</p>
                ) : null}
              </div>

              <div className="w-full flex flex-col-reverse md:flex-row justify-center items-center my-6">
                <button
                  type="button"
                  className="border border-grey-light mt-4 md:mt-0 bg-white py-2 px-4 rounded-md shadow-sm text-xxms font-medium text-black hover:bg-orange-dark hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange"
                  onClick={() => {
                    setStep(step - 1);
                    window.scrollTo(0, 0);
                  }}
                >
                  {t("previous")}
                </button>
                <button
                  type="submit"
                  className="border border-grey-light md:ml-3 inline-flex justify-center py-2 px-4 shadow-sm text-xxms font-medium rounded-md text-black bg-white hover:bg-orange-dark hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange"
                >
                  {t("next")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default BankInfosForm;
