import React, { useState, useEffect, useRef } from 'react';
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { useStyles } from "react-styles-hook";
import { ThumbUpIcon, CheckIcon } from '@heroicons/react/solid';
import { useDispatch } from 'react-redux';
import { updateSellerOrderLaunched } from '../../../store/reducer';
import getStyles from "../styles";

export const ModalOrderShipping = ({ showModal, hideModal, infosOrder }) => {
  const classes = useStyles(getStyles());
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  // initalize Form SellerOrder if trackingUrl is updated
  const [formValue, setFormValue] = useState({
    id: null,
    sellerId: null,
    delivery: null,
    orderStatus: null,
  });

  const [sendWithoutLink, setSendWithoutLink] = useState(false);

  const [link, setLink] = useState("");
  const [linkIsValid, setLinkIsValid] = useState(false);
  useEffect(() => {
    if (new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(link)) {
      setLinkIsValid(true);
      setSendWithoutLink(false);
    } else {
      setLinkIsValid(false);
    }
    setFormValue({
      id: infosOrder.id,
      sellerId: infosOrder.sellerId,
      delivery: {
        date: infosOrder.delivery.deliveryDate,
        trackingUrl: link,
        price: infosOrder.delivery.deliveryPrice,
        deliveryModeId: infosOrder.delivery.deliveryModeId
      },
      orderStatus: "WAITING_DELIVERY",
      shipmentDate: new Date()
    });
  }, [link]);

  const [validate, setValidate] = useState(false);
  // auto close modal after 3s ONLY if shipping link is validated
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return null;
    }
    const timer = setTimeout(() => hideModal(), 3000);
    return () => { clearTimeout(timer); };
  }, [validate]);

  // update SellerOrder with formValue
  const submit = () => {
    dispatch(updateSellerOrderLaunched({ formValue }));
  };

  return (
    showModal
    && (
      <button
        type="button"
        className="cursor-auto absolute w-screen h-screen top-0 pt-0 inset-x-0 flex justify-center items-center md:items-center"
        style={classes.modalBackdrop}
        // close modal if anything outside modal content is clicked
        onClick={() => { hideModal(); setLink(""); setSendWithoutLink(false); }}
      >
        <div
          aria-hidden="true"
          className="relative bg-white h-fit w-11/12 md:w-3/4 lg:w-4/6 xl:w-1/2 flex flex-col justify-center items-center px-4 md:px-12 py-3 md:py-8"
          onClick={(e) => {
            // don't close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}
        >
          <div className="w-3/4 md:w-full flex flex-col items-center justify-center my-4 md:my-16 md:h-36 md:min-h-full">
            {
              (validate === false)
              && (
                <>
                  <h3 className="text-s md:text-xss text-center my-6 md:pt-6">
                    <p>{t("modalShippingOrder.toValidate")}</p>
                    <p className="font-semibold space-x-1">
                      <span>{t("modalShippingOrder.order")}</span>
                      <span>{infosOrder.orderNumber}</span>
                    </p>
                    <p>{t("modalShippingOrder.pleaseFill")}</p>
                  </h3>
                  <form className="w-full flex flex-col items-center">
                    <div className="mt-1 relative rounded-md shadow-sm w-full md:w-5/6 2xl:w-2/3">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-5 w-5">
                          <path d="M17.07 2.93L15.66 4.34C17.1 5.79 18 7.79 18 10C18 14.42 14.42 18 10 18C5.58 18 2 14.42 2 10C2 5.92 5.05 2.56 9 2.07V4.09C6.16 4.57 4 7.03 4 10C4 13.31 6.69 16 10 16C13.31 16 16 13.31 16 10C16 8.34 15.33 6.84 14.24 5.76L12.83 7.17C13.55 7.9 14 8.9 14 10C14 12.21 12.21 14 10 14C7.79 14 6 12.21 6 10C6 8.14 7.28 6.59 9 6.14V8.28C8.4 8.63 8 9.26 8 10C8 11.1 8.9 12 10 12C11.1 12 12 11.1 12 10C12 9.26 11.6 8.62 11 8.28V0H10C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 7.24 18.88 4.74 17.07 2.93Z" fill={`${(linkIsValid === true) ? "#1E2134" : "#8F9099"}`} />
                        </svg>
                      </div>
                      <input
                        type="text"
                        name="trackingLink"
                        id="trackingLink"
                        className="focus:ring-orange-dark focus:border-orange-dark block w-full px-10 text-xxs border-gray-300 rounded-md"
                        placeholder="Indiquez l'URL de suivi"
                        onChange={(e) => setLink(e.target.value)}
                      />
                      <div className={`absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none ${(linkIsValid === true) ? "" : "hidden"}`}>
                        <CheckIcon className="h-5 w-5 text-green-apple" />
                      </div>
                    </div>
                    <div className="relative flex flex-row items-center mt-4">
                      <div className="flex h-5 items-center">
                        <input id="comments" onChange={() => { setLink(""); setSendWithoutLink(!sendWithoutLink); }} checked={sendWithoutLink} aria-describedby="comments-description" name="comments" type="checkbox" className="h-4 w-4 rounded border-orange-dark text-orange-chocolate focus:ring-orange-chocolate" />
                      </div>
                      <div className="ml-3 text-s md:text-xss">
                        <label htmlFor="comments" className="font-medium text-gray-700">
                          {t("modalShippingOrder.sendWithoutTracking")}
                        </label>
                      </div>
                    </div>
                    {
                      (linkIsValid === true || sendWithoutLink === true)
                      && (
                        <button
                          type="button"
                          className="text-xxs text-white rounded-md mt-2 px-6 py-2 bg-green-apple"
                          onClick={() => { submit(); setValidate(true); }}
                        >
                          {t("orderPage.send")}
                        </button>
                      )
                    }
                    {
                      (linkIsValid === false && sendWithoutLink === false)
                      && (
                        <div
                          className="text-xxs text-white rounded-md mt-2 px-6 py-2 bg-grey-light"
                        >
                          {t("orderPage.send")}
                        </div>
                      )
                    }
                  </form>
                  <button className="text-xxs px-4 py-2 capitalize" type="button" onClick={() => { hideModal(); setLink(""); setSendWithoutLink(false); }}>{t("modalShippingOrder.goBack")}</button>
                  <p className="pt-2 text-xxxs">{t("modalShippingOrder.alertNotification")}</p>
                </>
              )
            }
            {
              (validate === true)
              && (
                <>
                  <h3 className="text-s md:text-xss text-center my-6 space-y-0.5">
                    <p className="space-x-1">
                      <span>{t("modalShippingOrder.shippingOf")}</span>
                      <span>{t("modalShippingOrder.order")}</span>
                    </p>
                    <p className="font-semibold pl-2">{infosOrder.orderNumber}</p>
                    <p>{t("modalShippingOrder.isValidate")}</p>
                  </h3>
                  <p className="bottom-0 text-xxxs">{t("modalShippingOrder.notificationConfirmation")}</p>
                </>
              )
            }
          </div>
          <div className={`absolute w-full top-1/4 flex justify-center ${(validate === true) ? "" : "hidden"}`}>
            <ThumbUpIcon className="w-28 height-auto text-green-apple opacity-10" />
          </div>
        </div>
      </button>
    )
  );
};

export default ModalOrderShipping;

ModalOrderShipping.propTypes = {
  showModal: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  infosOrder: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
