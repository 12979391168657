import React, { useState } from 'react';
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useStyles } from "react-styles-hook";
import getStyles from "../styles";
import { ContactForm } from '../../ContactForm';

export const ModalContact = ({ showModal, hideModal, subjectList }) => {
  const classes = useStyles(getStyles());
  const { t } = useTranslation();

  const [openForm, setOpenForm] = useState(true);

  return (
    showModal
    && (
      <div
        role="button"
        className="cursor-auto absolute w-screen h-screen top-0 pt-0 inset-x-0 flex justify-center items-center md:items-center"
        style={classes.modalBackdrop}
        // close modal if anything outside modal content is clicked
        onClick={hideModal}
        onKeyPress={hideModal}
        tabIndex="0"
      >
        <div
          aria-hidden="true"
          className="relative bg-white w-11/12 md:w-3/4 lg:w-4/6 xl:w-1/2 flex flex-col justify-center items-center px-4 md:px-12 py-3 md:py-8"
          // don't close modal if anything inside modal content is clicked or any key is pressed
          onClick={(e) => { e.stopPropagation(); }}
          onKeyPress={(e) => { e.stopPropagation(); }}
        >
          <div className="w-3/4 md:w-full my-2">
            <ContactForm subjectList={subjectList} openForm={openForm} setOpenForm={setOpenForm} hideModal={hideModal} />
          </div>
        </div>
      </div>
    )
  );
};

export default ModalContact;

ModalContact.propTypes = {
  subjectList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  showModal: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
};
