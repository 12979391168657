import { React, Fragment, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const CustomSelect = ({ label, deliveryZones, zoneSelected, setZoneSelected }) => {
  const { t } = useTranslation();

  const translateZone = (zone) => {
    switch (zone) {
      case "AFRICA":
        return (t("shipping.africa"));
      case "LATIN AMERICA":
        return (t("shipping.latinAmerica"));
      case "METROPOLITAN FRANCE":
        return (t("shipping.metropolitanFrance"));
      case "AMERICA":
        return (t("shipping.usa"));
      case "EU (EXCEPT FRANCE)":
        return (t("shipping.europe"));
      case "DOM-TOM":
        return "DOM-TOM";
      default:
        return zone;
    }
  };

  return (
    <Listbox
      value={zoneSelected}
      onChange={setZoneSelected}
    >
      {({ open }) => (
        <>
          <div className="flex flex-col md:flex-row md:items-center">
            <Listbox.Label className="block text-sm font-medium text-black-title">{label}</Listbox.Label>
            <div className="mt-1 relative md:w-80 md:ml-10">
              <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-orange focus:border-orange sm:text-sm">
                <span className="block truncate">{translateZone(zoneSelected)}</span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {deliveryZones && deliveryZones.map((edges) => (
                    <Listbox.Option
                      key={edges.id}
                      className={({ active }) => classNames(
                        active ? 'text-gray-900 bg-orange' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-3 pr-9'
                      )}
                      value={edges.zone}
                    >
                      {({ newSelected, active }) => (
                        <>
                          <span className={classNames(newSelected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                            {translateZone(edges.zone)}
                          </span>
                          {newSelected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </div>

        </>
      )}
    </Listbox>
  );
};

export default CustomSelect;

CustomSelect.propTypes = {
  label: PropTypes.string.isRequired
};
