import React from 'react';
import { useTranslation } from "react-i18next";

export const ModalFormBusiness = ({ showModal, hideModal, simple, shareholder, isSoleTrader }) => {
  const { t } = useTranslation();

  const limitedParagraph = () => {
    if (shareholder) {
      return (
        <>
          <p className="text-s md:text-sm text-left mb-4 font-bold">
            {t("modalBusinessForm.documents")}
          </p>
          <p className="text-s md:text-sm text-left">
            {t("modalBusinessForm.proofAdress1")}
          </p>
          <p className="text-s md:text-sm text-left mb-4">
            <span>-</span>
            <span> </span>
            {t("shareholderForm.shareholderDeclarationDoc")}
          </p>
        </>
      );
    }
    return (
      <>
        <p className="text-s md:text-sm text-left mb-4 font-bold">
          {t("modalBusinessForm.documents")}
        </p>
        <p className="text-s md:text-sm text-left font-bold mb-4">
          {t("modalBusinessForm.proofAdress1")}
        </p>
        <p className="text-s md:text-sm text-left font-bold">
          <span>-</span>
          <span> </span>
          {t("modalBusinessForm.identificationCard")}
        </p>
        <p className="text-s md:text-sm text-left mb-4">
          <span>{t("modalBusinessForm.identity1")}</span>
          <span>{t("modalBusinessForm.identity2")}</span>
        </p>

      </>
    );
  };

  const paragraph = (
    <>
      <p className="text-s md:text-sm text-left mb-4 font-bold">
        {t("modalBusinessForm.documents")}
      </p>
      {isSoleTrader
        ? null
        : (
          <>
            <p className="text-s md:text-sm text-left mb-4">
              -
              <span className="font-bold">
                {t("modalBusinessForm.status1")}
              </span>
              {t("modalBusinessForm.status2")}
            </p>
            <p className="text-s md:text-sm text-left">
              <span className="font-bold">
                {t("modalBusinessForm.proofAdress1")}
              </span>
              {t("modalBusinessForm.proofAdress2")}
            </p>
          </>
        )}
      <p className="text-s md:text-sm text-left mb-4">
        -
        <span className="font-bold">
          {t("modalBusinessForm.registerExtract1")}
        </span>
        {t("modalBusinessForm.registerExtract2")}
      </p>
    </>
  );

  return (
    showModal
    && (
      <div
        className="bg-opacity-25 bg-grey-medium cursor-auto absolute w-screen h-screen inset-x-0 bottom-0 flex justify-center items-start md:items-center"
      >
        <div className="bg-white w-full md:w-1/2 px-12 py-6 pointer-events-auto shadow-2xl rounded">
          <div className="w-full flex items-center justify-end mb-6">
            <button
              type="button"
              onClick={hideModal}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="#F00">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={4} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          {simple ? limitedParagraph() : paragraph}
        </div>
      </div>
    )
  );
};

export default ModalFormBusiness;
