import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'formik';
import clsx from 'clsx';

const LoginForm = ({
  values,
  errors,
  handleChange,
  setFormValue,
  setFormEmail,
  setErrorMessage,
  errorMessage }) => {
  const { t } = useTranslation();

  return (
    <div className="px-0 py-0 md:px-8 sm:py-4">
      <h2 className="mt-6 text-s font-bold text-gray-900 pl-12">{t("signInForm.signIn")}</h2>
      {errorMessage === null && (
        <p className="px-6 sm:px-0 sm:pl-12 mt-2 text-xxs text-gray-600">
          {t("signInForm.nonsubscriber")}
          <Link to="/signup" className="font-medium text-orange-dark hover:text-orange-chocolate">
            {t("signInForm.becomeSeller")}
          </Link>
        </p>
      )}
      <div className="bg-white border border-white py-8 px-12 sm:px-10">
        <label className="block text-xxs font-medium text-gray-700">{t("signInForm.email")}</label>
        <div className="mt-1">
          <Field
            id="email"
            name="email"
            type="email"
            value={values.email}
            onChange={(e) => {
              handleChange(e);
              setFormValue((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value.toLowerCase()
              }));
              setFormEmail(e.target.value.toLowerCase());
              if (errorMessage) {
                setErrorMessage(null);
              }
            }}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-white focus:outline-none focus:ring-orange-light focus:border-orange-dark sm:text-xxs"
          />
          {errors.email ? (
            <p className="text-red-600 text-center md:text-left">{errors.email}</p>
          ) : null}
        </div>
      </div>
      <div className="bg-white border border-white pb-4 px-12 sm:px-10">
        <label className="block text-xxs font-medium text-gray-700">{t("signInForm.password")}</label>
        <div>
          <Field
            id="password"
            name="password"
            type="password"
            value={values.password}
            onChange={(e) => {
              handleChange(e);
              setFormValue((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
              }));
            }}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-white focus:outline-none focus:ring-orange-light focus:border-orange-dark sm:text-xxs"
          />
          {errors.password ? (
            <p className="text-red-600 text-center md:text-left">{errors.password}</p>
          ) : null}
        </div>
      </div>
      <div className="flex items-center justify-between bg-white py-2 px-4 sm:px-10">
        <div className="flex items-center">
          <input
            id="remember-me"
            name="remember-me"
            type="checkbox"
            className="h-4 w-4 text-orange-dark focus:ring-orange-dark border-gray-300 rounded"
          />
          <label htmlFor="remember-me" className="ml-2 block text-xxms font-normal text-gray-900">
            {t("signInForm.rememberMe")}
          </label>
        </div>

        <div className="text-xxms">
          <Link to="/password" className="font-medium text-orange-dark hover:text-orange-chocolate">
            {t("signInForm.forgottenPassword")}
          </Link>
        </div>
      </div>
      {errorMessage !== null && (
        <p className="px-6 sm:px-12 mt-2 text-xs">{errorMessage}</p>
      )}
      <div className="bg-white px-4 py-2 pb-10 sm:px-10">
        <button
          type="submit"
          className={clsx("w-full flex justify-center py-2 px-4 border rounded-md shadow-sm text-xxms font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange", errors.password || errors.email ? "text-orange-dark bg-white hover:text-orange-chocolate hover:border-orange-chocolage border-orange-dark" : "text-white bg-orange-dark")}
        >
          {t("signInForm.connect")}
        </button>
      </div>
    </div>
  );
};

export default LoginForm;
