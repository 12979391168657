import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PlusCircleIcon } from '@heroicons/react/solid';
import { useStyles } from 'react-styles-hook';
import getStyles from './styles';
import InfosForm from '../../components/SignUpForm/InfosForm';
import ShareHolderForm1 from '../../components/SignUpForm/ShareHolderForm1';
import ShareHolderForm2 from '../../components/SignUpForm/ShareHolderForm2';
import ShareHolderForm3 from '../../components/SignUpForm/ShareHolderForm3';
import BusinessInfosForm from '../../components/SignUpForm/BusinessInfosForm';
import BankInfosForm from '../../components/SignUpForm/BankInfosForm';
import SignUpForm from '../../components/SignUpForm';
import SignUpConfirmation from '../../components/SignUpForm/SignUpConfirmation';
import { Stepper } from '../../components/Stepper';
import { ModalFormBusiness } from "../../components/Modal/ModalFormBusiness";
import SignUpInfo from '../../components/SignUpForm/Informations';

// s'inscrire
export const SignUpPage = () => {
  const classes = useStyles(getStyles());
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);

  const hideModal = () => {
    setOpenModal(false);
  };

  const signup = () => {
    setStep(step + 1);
    window.scrollTo(0, 0);
  };

  // states to show forms on step 3 :
  const [form1, setForm1] = useState(false);
  const [form2, setForm2] = useState(false);
  const [form3, setForm3] = useState(false);

  const [step, setStep] = useState(1);

  const [formValue, setFormValue] = useState({
    // login informations (step 1)
    email: null,
    password: null,
    isSoleTrader: false,
    // legal representant (step 2)
    lastName: null,
    firstName: null,
    birthday: null,
    nationality: null,
    birthCountry: null,
    birthCity: null,
    emailLegalRepresentant: null,
    adress1LegalRepresentant: null,
    zipCodeLegalRepresentant: null,
    cityLegalRepresentant: null,
    countryLegalRepresentant: null,
    enabledAdministrator: false,
    enabledShareholder: false,
    adressFiles: [],
    // first shareholder (step 3)
    shareholder1email: null,
    shareholder1lastName: null,
    shareholder1firstName: null,
    shareholder1birthday: null,
    shareholder1nationality: null,
    shareholder1birthCountry: null,
    shareholder1birthCity: null,
    shareholder1adress: null,
    shareholder1zipCode: null,
    shareholder1city: null,
    shareholder1country: null,
    // shareholder1identityFiles: [],
    shareholder1adressFiles: [],
    shareholder1declaration: [],
    enabledAdministrator1: false,
    enabledShareholder1: false,
    // second shareholder (step 3)
    shareholder2email: null,
    shareholder2lastName: null,
    shareholder2firstName: null,
    shareholder2birthday: null,
    shareholder2nationality: null,
    shareholder2birthCountry: null,
    shareholder2birthCity: null,
    shareholder2adress: null,
    shareholder2zipCode: null,
    shareholder2city: null,
    shareholder2country: null,
    // shareholder2identityFiles: [],
    shareholder2adressFiles: [],
    shareholder2declaration: [],
    enabledAdministrator2: false,
    enabledShareholder2: false,
    // third and last shareholder (step 3)
    shareholder3email: null,
    shareholder3lastName: null,
    shareholder3firstName: null,
    shareholder3birthday: null,
    shareholder3nationality: null,
    shareholder3birthCountry: null,
    shareholder3birthCity: null,
    shareholder3adress: null,
    shareholder3zipCode: null,
    shareholder3city: null,
    shareholder3country: null,
    // shareholder3identityFiles: [],
    shareholder3adressFiles: [],
    shareholder3declaration: [],
    enabledAdministrator3: false,
    enabledShareholder3: false,
    // company - business informations (step 4)
    socialReason: null,
    companyEmail: null,
    companyTelephone: undefined,
    companyAdress: null,
    companyZipCode: null,
    companyCity: null,
    companyCountry: null,
    companyId: null,
    companyWebsite: null,
    representantIdFiles: [],
    businessAdressFiles: [],
    registrationFiles: [],
    statutesFiles: [],
    // bank informations (step 5)
    bankAccontOwner: null,
    bankAdress1: null,
    bankZipCode: null,
    bankCity: null,
    bankCountry: null,
    bic: null,
    iban: null,
    cgv: false,
  });

  const [showInfo, setShowInfo] = useState(true);

  return (
    <div>
      {
        (step > 1 && step < 6)
        && (
          <div className="w-5/6 mx-auto flex justify-center">
            <Stepper step={step} isSoleTrader={formValue.isSoleTrader} />
          </div>
        )
      }
      {
        step === 1
        && (
          <div className="bg-palms bg-cover w-full brightness-75">
            <div className="min-h-full flex flex-col justify-center sm:px-6 lg:px-8">
              <div className="flex flex-col">
                <div className="w-1/2 mx-auto flex justify-center">
                  <Stepper step={step} isSoleTrader={formValue.isSoleTrader} />
                </div>
                <div className="min-h-full flex flex-col justify-center pt-2 pb-8 sm:px-6 lg:px-8">
                  {showInfo ? <SignUpInfo setShowInfo={() => setShowInfo()} />
                    : (
                      <SignUpForm
                        step={step}
                        setStep={setStep}
                        formValue={formValue}
                        setFormValue={setFormValue}
                        previous={() => setShowInfo(true)}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        )
      }
      <div className="w-full px-4 flex flex-col">
        {
          step === 2
          && (
            <div className="min-h-full flex flex-col justify-center sm:px-6 lg:px-8">
              <InfosForm step={step} setStep={setStep} formValue={formValue} setFormValue={setFormValue} />
            </div>
          )
        }
        {
          step === 3
          && (
            <div className="sm:mx-auto sm:w-full sm:max-w-lg">
              {/* hiding introduction text when adding a new shareholder */}
              {(form1 || form2 || form3) === false
                && (
                  <>
                    <h2 className="text-sm md:text-s font-bold mx-auto pb-2">{t("shareholderForm.title")}</h2>
                    <p className="text-gray-500 text-xxxs pb-12">{t("shareholderForm.intro")}</p>
                    <p className="text-xxms font-medium pb-12">{t("shareholderForm.shareholdersList")}</p>
                  </>
                )}
              <div className="grid grid-cols-1 sm:grid-cols-1">
                {/* conditional rendering of box with name and email informations if form is completed for one or more shareholders */}
                {formValue.firstName !== null && (form1 || form2 || form3) === false
                  && (
                    <div className="mb-6 border border-grey-extraLight bg-white px-6 py-5 shadow-sm flex flex-col items-start hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-orange-chocolate">
                      <span>
                        {formValue.firstName}
                        <span> </span>
                        {formValue.lastName}
                      </span>
                      <span className="text-gray-500">{formValue.emailLegalRepresentant}</span>
                    </div>
                  )}
                {formValue.firstName !== null && formValue.shareholder1firstName !== null && (form1 || form2 || form3) === false
                  && (
                    <div className="mb-6 border border-grey-extraLight bg-white px-6 py-5 shadow-sm flex flex-col items-start hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-orange-chocolate">
                      <span>
                        {formValue.shareholder1firstName}
                        <span> </span>
                        {formValue.shareholder1lastName}
                      </span>
                      <span className="text-gray-500">{formValue.shareholder1email}</span>
                    </div>
                  )}
                {formValue.firstName !== null && formValue.shareholder1firstName !== null && formValue.shareholder2firstName !== null && (form1 || form2 || form3) === false
                  && (
                    <div className="mb-6 border border-grey-extraLight bg-white px-6 py-5 shadow-sm flex flex-col items-start hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-orange-chocolate">
                      <span>
                        {formValue.shareholder2firstName}
                        <span> </span>
                        {formValue.shareholder2lastName}
                      </span>
                      <span className="text-gray-500">{formValue.shareholder2email}</span>
                    </div>
                  )}
                {formValue.firstName !== null && formValue.shareholder1firstName !== null && formValue.shareholder2firstName !== null && formValue.shareholder3firstName !== null && (form1 || form2 || form3) === false
                  && (
                    <div className="mb-6 border border-grey-extraLight bg-white px-6 py-5 shadow-sm flex flex-col items-start hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-orange-chocolate">
                      <span>
                        {formValue.shareholder3firstName}
                        <span> </span>
                        {formValue.shareholder3lastName}
                      </span>
                      <span className="text-gray-500">{formValue.shareholder3email}</span>
                    </div>
                  )}
                {/* end of conditional rendering of box with name and email informations */}
                {
                  form1 === false && formValue.shareholder1email === null
                  && (
                    <button
                      type="button"
                      onClick={() => setForm1(true)}
                      className="inline-flex items-center px-4 py-2 border border-gray-500 shadow-sm text-xxms font-medium rounded-md text-grey-extraDark bg-transparent hover:bg-orange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange"
                    >
                      <PlusCircleIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                      {t("shareholderForm.addShareholderButton")}
                    </button>
                  )
                }
                {
                  form2 === false && formValue.shareholder2email === null && formValue.shareholder1email !== null
                  && (
                    <button
                      type="button"
                      onClick={() => setForm2(true)}
                      className="inline-flex items-center px-4 py-2 border border-gray-500 shadow-sm text-xxms font-medium rounded-md text-grey-extraDark bg-transparent hover:bg-orange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange"
                    >
                      <PlusCircleIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                      {t("shareholderForm.addShareholderButton")}
                    </button>
                  )
                }
                {
                  form3 === false && formValue.shareholder3email === null && formValue.shareholder1email !== null && formValue.shareholder2email !== null
                  && (
                    <button
                      type="button"
                      onClick={() => setForm3(true)}
                      className="inline-flex items-center px-4 py-2 border border-gray-500 shadow-sm text-xxms font-medium rounded-md text-grey-extraDark bg-transparent hover:bg-orange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange"
                    >
                      <PlusCircleIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                      {t("shareholderForm.addShareholderButton")}
                    </button>
                  )
                }
              </div>
              {
                form1
                && (
                  <ShareHolderForm1 validate={setForm1} formValue={formValue} setFormValue={setFormValue} />
                )
              }
              {
                form2
                && (
                  <ShareHolderForm2 validate={setForm2} formValue={formValue} setFormValue={setFormValue} />
                )
              }
              {
                form3
                && (
                  <ShareHolderForm3 validate={setForm3} formValue={formValue} setFormValue={setFormValue} />
                )
              }
              {(form1 || form2 || form3) === false
                && (
                  <div className="w-full flex flex-col-reverse md:flex-row justify-center items-center my-6">
                    <button
                      type="button"
                      className="border border-grey-light bg-white mt-4 md:mt-0 py-2 px-4 rounded-md shadow-sm text-xxms font-medium text-black hover:bg-orange-dark hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange"
                      onClick={() => {
                        setStep(step - 1);
                        window.scrollTo(0, 0);
                      }}
                    >
                      {t("previous")}
                    </button>
                    <button
                      onClick={() => {
                        setStep(step + 1);
                        window.scrollTo(0, 0);
                      }}
                      type="button"
                      className="border border-grey-light md:ml-3 inline-flex justify-center py-2 px-4 shadow-sm text-xxms font-medium rounded-md text-black bg-white hover:bg-orange-dark hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange"
                    >
                      {t("next")}
                    </button>
                  </div>
                )}
            </div>
          )
        }
        {
          step === 4
          && <BusinessInfosForm step={step} setStep={setStep} formValue={formValue} setFormValue={setFormValue} />
        }
        {
          step === 5
          && <BankInfosForm step={step} setStep={setStep} formValue={formValue} setFormValue={setFormValue} />
        }
        {
          step === 6
          && <SignUpConfirmation />
        }
      </div>
      <ModalFormBusiness showModal={openModal} hideModal={hideModal} />
    </div>
  );
};

export default SignUpPage;
