import React from 'react';
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { useStyles } from "react-styles-hook";
import { formatDateToFrenchFormat } from '../../../utils/format';
import getStyles from "../styles";

export const ModalOrderDetails = ({ showModal, hideModal, infosOrder }) => {
  const classes = useStyles(getStyles());
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    showModal
    && (
      <div
        role="button"
        className="overflow-y-auto cursor-auto absolute w-screen h-screen top-0 pt-6 md:pt-20 inset-x-0 flex justify-center items-start"
        style={classes.modalBackdrop}
        // close modal if anything outside modal content is clicked
        onClick={hideModal}
        onKeyPress={hideModal}
        tabIndex="0"
      >
        <div
          aria-hidden="true"
          className="bg-white h-fit w-11/12 flex flex-col justify-center items-start px-0 sm:px-4 md:px-12 py-2 md:py-6 md:shadow-md"
          onClick={(e) => {
            // do not close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}
        >
          <div className="hidden md:flex w-full items-center justify-end mb-6">
            <button
              type="button"
              onClick={hideModal}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="#F00">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={4} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <div className="hidden md:flex w-full flex-col items-start justify-center mb-10">
            <h3 className="font-normal text-xms text-left text-black-title mb-4 space-x-1.5">
              <span>{t("order")}</span>
              <span className="font-semibold">{infosOrder.orderNumber}</span>
              <span>{t("of")}</span>
              <span>{formatDateToFrenchFormat(infosOrder.date)}</span>
            </h3>
          </div>

          <div className="hidden md:flex w-full">
            {/* Order details */}
            <div className="w-3/5 grid grid-cols-6 mb-10 pr-10">
              <div className="col-span-2 text-xxxs lg:text-xxs xl:text-xms text-black-primary font-medium pb-4 border-b">{t("orderPage.orderNumber")}</div>
              <div className="col-span-2 text-xxxs lg:text-xxs xl:text-xms text-black-primary text-left font-medium pb-4 border-b">{t("product")}</div>
              <div className="text-xxxs lg:text-xxs xl:text-xms text-black-primary font-medium pb-4 border-b">{t("orderPage.quantity")}</div>
              <div className="text-xxxs lg:text-xxs xl:text-xms text-black-primary font-medium pb-4 border-b">Total</div>

              <div className="col-span-6">
                {
                  infosOrder.products.map((product) => (
                    <div key={product.sku} className="grid grid-cols-6 items-center border-b">
                      <div className="col-span-2 text-xxxs lg:text-xxms">{infosOrder.orderNumber}</div>
                      <div className="col-span-2 flex items-center justify-start">
                        {(product.img.length > 0 && product.img[0].url !== "")
                          && (
                            <img src={product.img[0].url} alt={product.title} className="w-1/4" />
                          )}
                        <div className="flex flex-col justify-center items-start text-black">
                          <p className="text-xxxs lg:text-xxms text-left">{product.title}</p>
                          <p className="text-xxxs lg:text-xxms text-left">{`Sku : ${product.sku}`}</p>
                        </div>
                      </div>
                      <div className="justify-self-center">
                        <p className="px-10 py-2 text-xxms lg:text-xms">{product.quantity}</p>
                      </div>
                      <div className="text-xxms lg:text-xms">{`${(product.quantity * product.price).toFixed(2)} €`}</div>
                      {/* Incident details */}
                      {
                        (product.incident[0].hasIncident)
                        && (
                          <div className="col-span-6 py-1 px-4">
                            <p className="text-xxs lg:text-xs space-x-0.5 text-orange-chocolate">
                              <span>Incident</span>
                              {(product.incident[0].status === "NEW")
                                && <span>{t("orderPage.newIncident")}</span>}
                              {(product.incident[0].status === "WAITING_FOR_REFUND")
                                && <span>{t("orderPage.waitingRefundIncident")}</span>}
                              {(product.incident[0].status === "REFUNDED")
                                && (
                                  <span>
                                    {t("orderPage.refundIncident")}
                                    {` (${product.incident[0].amountWithVAT}€TTC)`}
                                  </span>
                                )}
                              {(product.incident[0].status === "CLOSED")
                                && <span>{t("orderPage.closedIncident")}</span>}
                            </p>
                            <p className="space-x-0.5">
                              <span> Message :</span>
                              <span className="break-words">{product.incident[0].message}</span>
                            </p>
                          </div>
                        )
                      }
                    </div>
                  ))
                }
              </div>

              <div className="col-span-6 grid grid-cols-6 items-center text-black-primary mt-4">
                <div className="col-span-5 justify-self-end pr-6">
                  <p className="text-xxs md:text-xxms font-medium">{`${t("orderPage.shippingCost")} : ${infosOrder.delivery.country}`}</p>
                </div>
                <div className="justify-self-center">
                  <p className="text-xxxs md:text-xxs">{`${infosOrder.totalDeliveryPrice.toFixed(2)} €`}</p>
                </div>
              </div>

              <div className="col-span-6 grid grid-cols-6 items-center text-black-primary mt-3">
                <div className="col-span-5 justify-self-end pr-6">
                  <p className="text-xxs md:text-xxms font-medium">{t("orderPage.totalOrder")}</p>
                </div>
                <div className="justify-self-center">
                  <p className="text-xxs md:text-xxms font-semibold">{`${(Number(infosOrder.totalOfferPrice) + Number(infosOrder.totalDeliveryPrice)).toFixed(2)} €`}</p>
                </div>
              </div>
            </div>

            {/* Delivery informations */}
            <div className="w-2/5 pl-16">
              <p className="text-left text-xxms lg:text-xs xl:text-xms text-black-primary font-medium mb-2">{t("orderPage.deliveryAddress")}</p>
              {
                (infosOrder.status === "WAITING_ACCEPTANCE")
                && <p className="text-left text-xxms xl:text-xms text-black-primary font-normal">{t("orderPage.acceptCommandForSee")}</p>
              }
              {
                (infosOrder.status !== "WAITING_ACCEPTANCE")
                && (
                  <>
                    <p className="text-left text-xss text-black-primary font-medium mb-6">
                      Mode:
                      {infosOrder.delivery.deliveryModeId}
                    </p>
                    <div className="grid grid-cols-2 justify-items-start content-start items-center mb-6">
                      <p className="text-left text-xxms lg:text-xs xl:text-s font-normal text-black-light pb-1">{t("lastName")}</p>
                      <p className="text-left text-xxms lg:text-xs xl:text-s font-medium">{infosOrder.delivery.lastName}</p>
                      <p className="text-left text-xxms lg:text-xs xl:text-s font-normal text-black-light pb-1">{t("firstName")}</p>
                      <p className="text-left text-xxms lg:text-xs xl:text-s font-medium">{infosOrder.delivery.firstName}</p>
                      <p className={`text-left text-xxms lg:text-xs xl:text-s font-normal text-black-light pb-1 capitalize ${(infosOrder.delivery.relayPointId) ? "" : "hidden"}`}>
                        ID point relais
                      </p>
                      <p className={`text-left text-xxms lg:text-xs xl:text-s font-medium ${(infosOrder.delivery.relayPointId) ? "" : "hidden"}`}>{infosOrder.delivery.relayPointId}</p>
                      <p className={`text-left text-xxms lg:text-xs xl:text-s font-normal text-black-light pb-1 capitalize ${(infosOrder.delivery.company) ? "" : "hidden"}`}>
                        {(infosOrder.delivery.deliveryModeId !== "relais-colis") ? (t("company")) : "Point relais"}
                      </p>
                      <p className={`text-left text-xxms lg:text-xs xl:text-s font-medium ${(infosOrder.delivery.company) ? "" : "hidden"}`}>{infosOrder.delivery.company}</p>
                      <p className="text-left text-xxms lg:text-xs xl:text-s font-normal text-black-light pb-1">{t("signUpInfos.adress")}</p>
                      <p className="text-left text-xxms lg:text-xs xl:text-s font-medium">{infosOrder.delivery.address}</p>
                      {(infosOrder.delivery.zipcode !== undefined && infosOrder.delivery.zipcode !== null) && (
                        <>
                          <p className="text-left text-xxms lg:text-xs xl:text-s font-normal text-black-light pb-1">{t("signUpInfos.cp")}</p>
                          <p className="text-left text-xxms lg:text-xs xl:text-s font-medium">{infosOrder.delivery.zipcode}</p>
                        </>
                      )}
                      <p className="text-left text-xxms lg:text-xs xl:text-s font-normal text-black-light pb-1">{t("signUpInfos.city")}</p>
                      <p className="text-left text-xxms lg:text-xs xl:text-s font-medium">{infosOrder.delivery.city}</p>
                      <p className="text-left text-xxms lg:text-xs xl:text-s font-normal text-black-light pb-1">{t("signUpInfos.country")}</p>
                      <p className="text-left text-xxms lg:text-xs xl:text-s font-medium">{infosOrder.delivery.country}</p>
                      <p className="text-left text-xxms lg:text-xs xl:text-s font-normal text-black-light pb-1 capitalize">{t("phoneNumber")}</p>
                      <p className="text-left text-xxms lg:text-xs xl:text-s font-medium">{infosOrder.delivery.telephone}</p>
                    </div>
                    {infosOrder?.delivery?.personnalAddress && (
                      <>
                        <p className="text-left text-xss text-black-primary font-medium mt-2 mb-1">
                          Adresse personnelle du client:
                        </p>
                        <div className="grid grid-cols-2 justify-items-start content-start items-center mb-6">
                          <p className="text-left text-xxxs lg:text-xxms xl:text-xss font-normal text-black-light pb-1 capitalize">{t("signUpInfos.adress")}</p>
                          <p className="text-left text-xxxs lg:text-xxms xl:text-xss font-medium">{infosOrder.delivery.personnalAddress}</p>
                          <p className="text-left text-xxxs lg:text-xxms xl:text-xss font-normal text-black-light pb-1 capitalize">{t("signUpInfos.cp")}</p>
                          <p className="text-left text-xxxs lg:text-xxms xl:text-xss font-medium">{infosOrder.delivery.personnalZipcode}</p>
                          <p className="text-left text-xxxs lg:text-xxms xl:text-xss font-normal text-black-light pb-1 capitalize">{t("signUpInfos.city")}</p>
                          <p className="text-left text-xxxs lg:text-xxms xl:text-xss font-medium">{infosOrder.delivery.personnalCity}</p>
                          <p className="text-left text-xxxs lg:text-xxms xl:text-xss font-normal text-black-light pb-1 capitalize">{t("signUpInfos.country")}</p>
                          <p className="text-left text-xxxs lg:text-xxms xl:text-xss font-medium">{infosOrder.delivery.personnalCountry}</p>
                        </div>
                      </>
                    )}
                  </>
                )
              }
            </div>
          </div>
          {/* Modal mobile */}
          <div className="w-full md:hidden flex flex-col items-start my-2 pb-2 h-5/6 min-h-full text-left">
            <div className="w-full flex flex-row pl-4 py-3 border-b border-grey-light border-opacity-50">
              <div className="w-2/5 font-normal">{t("orderPage.orderNumber")}</div>
              <div className="print:font-medium print:text-xxms w-3/5 font-normal">{infosOrder.orderNumber}</div>
            </div>
            <div className="w-full flex flex-row pl-4 py-3 border-b border-grey-light border-opacity-50">
              <div className="flex flex-col justify-center w-2/5 font-normal">{t("orderPage.products")}</div>
              <div className="w-3/5 space-y-6 font-normal text-xxxs">
                {infosOrder.products.map((product) => (
                  <div key={product.sku} className="flex flex-col">
                    <span className="capitalize">{product.title}</span>
                    <span>{`SKU: ${product.sku}`}</span>
                    <span className="font-normal text-xxs mt-1">{`${t("orderPage.quantity")} : ${product.quantity}`}</span>
                    <span>{`${t("orderPage.amountOrder")} : ${(product.quantity * product.price).toFixed(2)} €`}</span>
                    {(product.incident[0].hasIncident)
                      && (
                        <>
                          <div className="text-xxs lg:text-xs space-x-0.5 text-orange-chocolate">
                            <span>Incident</span>
                            {(product.incident[0].status === "NEW")
                              && <span>{t("orderPage.newIncident")}</span>}
                            {(product.incident[0].status === "WAITING_FOR_REFUND")
                              && <span>{t("orderPage.waitingRefundIncident")}</span>}
                            {(product.incident[0].status === "REFUNDED")
                              && (
                                <span>
                                  {t("orderPage.refundIncident")}
                                  {` (${product.incident[0].amountWithVAT}€TTC)`}
                                </span>
                              )}
                            {(product.incident[0].status === "CLOSED")
                              && <span>{t("orderPage.closedIncident")}</span>}
                          </div>
                          <p className="text-xxs lg:text-xs space-x-0.5">
                            <span>Message :</span>
                            <span className="break-words">{product.incident[0].message}</span>
                          </p>
                        </>
                      )}
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full flex flex-row pl-4 py-3">
              <div className="w-2/5 font-normal pr-2">{`${t("orderPage.shippingCost")} : ${infosOrder.delivery.country}`}</div>
              <div className="w-3/5 font-normal text-xxxs">{`${infosOrder.totalDeliveryPrice.toFixed(2)} €`}</div>
            </div>
            <div className="w-full flex flex-row pl-4 pt-1 pb-4">
              <div className="w-2/5 font-normal">Total</div>
              <div className="w-3/5 font-medium text-xxs">{`${(Number(infosOrder.totalOfferPrice) + Number(infosOrder.totalDeliveryPrice)).toFixed(2)} €`}</div>
            </div>
            <div className="w-full pl-4 pb-2 font-medium">
              {t("orderPage.deliveryAddress")}
            </div>
            {
              (infosOrder.status === "WAITING_ACCEPTANCE")
              && <p className="px-4 text-left text-xxms text-black-primary font-normal">{t("orderPage.acceptCommandForSee")}</p>
            }
            {
              (infosOrder.status !== "WAITING_ACCEPTANCE")
              && (
                <>
                  <div className="w-full pl-4 pb-2 font-medium">
                    Mode:
                    {infosOrder.delivery.deliveryModeId}
                  </div>
                  <div className="w-full flex flex-row pl-4 py-0">
                    <div className="w-2/5 font-normal text-grey-middle capitalize">{t("lastName")}</div>
                    <div className="w-3/5 font-medium">{infosOrder.delivery.lastName}</div>
                  </div>
                  <div className="w-full flex flex-row pl-4 py-0">
                    <div className="w-2/5 font-normal text-grey-middle capitalize">{t("firstName")}</div>
                    <div className="w-3/5 font-medium">{infosOrder.delivery.firstName}</div>
                  </div>
                  <div className={`w-full flex flex-row pl-4 py-0  ${(infosOrder.delivery.relayPointId) ? "" : "hidden"}`}>
                    <div className="w-2/5 font-normal text-grey-middle capitalize">ID point relais</div>
                    <div className="w-3/5 font-medium">{infosOrder.delivery.relayPointId}</div>
                  </div>
                  <div className={`w-full flex flex-row pl-4 py-0  ${(infosOrder.delivery.company) ? "" : "hidden"}`}>
                    <div className="w-2/5 font-normal text-grey-middle capitalize">{(infosOrder.delivery.deliveryModeId !== "relais-colis") ? (t("company")) : "Point relais"}</div>
                    <div className="w-3/5 font-medium">{infosOrder.delivery.company}</div>
                  </div>
                  <div className="w-full flex flex-row pl-4 py-0">
                    <div className="w-2/5 font-normal text-grey-middle capitalize">{t("signUpInfos.adress")}</div>
                    <div className="w-3/5 font-medium">{infosOrder.delivery.address}</div>
                  </div>
                  {(infosOrder.delivery.zipcode !== undefined && infosOrder.delivery.zipcode !== null) && (
                    <>
                      <div className="w-full flex flex-row pl-4 py-0">
                        <div className="w-2/5 font-normal text-grey-middle capitalize">{t("signUpInfos.cp")}</div>
                        <div className="w-3/5 font-medium">{infosOrder.delivery.zipcode}</div>
                      </div>
                    </>
                  )}
                  <div className="w-full flex flex-row pl-4 py-0">
                    <div className="w-2/5 font-normal text-grey-middle capitalize">{t("signUpInfos.city")}</div>
                    <div className="w-3/5 font-medium">{infosOrder.delivery.city}</div>
                  </div>
                  <div className="w-full flex flex-row pl-4 py-0">
                    <div className="w-2/5 font-normal text-grey-middle capitalize">{t("signUpInfos.country")}</div>
                    <div className="w-3/5 font-medium">{infosOrder.delivery.country}</div>
                  </div>
                  <div className="w-full flex flex-row pl-4 py-0">
                    <div className="w-2/5 font-normal text-grey-middle capitalize">{t("phoneNumber")}</div>
                    <div className="w-3/5 font-medium">{infosOrder.delivery.telephone}</div>
                  </div>
                  {infosOrder.delivery.personnalAddress && (
                    <>
                      <div className="w-full pt-4 pl-4 pb-2 font-medium">
                        Détails Client:
                      </div>
                      <div className="w-full flex flex-row pl-4 py-0">
                        <div className="w-2/5 font-normal text-grey-middle capitalize">{t("signUpInfos.adress")}</div>
                        <div className="w-3/5 font-medium">{infosOrder.delivery.personnalAddress}</div>
                      </div>
                      <div className="w-full flex flex-row pl-4 py-0">
                        <div className="w-2/5 font-normal text-grey-middle capitalize">{t("signUpInfos.cp")}</div>
                        <div className="w-3/5 font-medium">{infosOrder.delivery.personnalZipcode}</div>
                      </div>
                      <div className="w-full flex flex-row pl-4 py-0">
                        <div className="w-2/5 font-normal text-grey-middle capitalize">{t("signUpInfos.city")}</div>
                        <div className="w-3/5 font-medium">{infosOrder.delivery.personnalCity}</div>
                      </div>
                      <div className="w-full flex flex-row pl-4 py-0">
                        <div className="w-2/5 font-normal text-grey-middle capitalize">{t("signUpInfos.country")}</div>
                        <div className="w-3/5 font-medium">{infosOrder.delivery.personnalCountry}</div>
                      </div>
                    </>
                  )}

                </>
              )
            }
          </div>
        </div>
      </div>

    )
  );
};

export default ModalOrderDetails;

ModalOrderDetails.propTypes = {
  showModal: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  infosOrder: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
