import React from 'react';
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { useStyles } from "react-styles-hook";
import getStyles from "../styles";

export const ModalOrderIncident = ({ showModal, hideModal, infosOrder }) => {
  const classes = useStyles(getStyles());
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    showModal
    && (
      <button
        type="button"
        className="cursor-auto absolute w-screen h-screen top-0 pt-0 inset-x-0 flex justify-center items-center md:items-center"
      >
        <div className="bg-orange w-full md:w-1/3 flex flex-col justify-center items-center px-4 md:px-12 py-2 md:py-6">
          <div className="w-full flex items-center justify-end md:mb-6">
            <button
              type="button"
              onClick={hideModal}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="#F00">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={4} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <div className="w-3/4 md:w-full flex flex-col items-center justify-center mb-6">
            <h3 className="text-s md:text-m text-left md:text-center mb-4">Veuillez indiquer la raison de l&lsquo;incident :</h3>
            <form className="w-full flex flex-col items-center">
              <textarea className="w-full mb-6" rows={6} />
              <button className="self-end text-xs border border-grey bg-white px-4 py-2" type="button">Valider</button>
            </form>
          </div>
        </div>
      </button>
    )
  );
};

export default ModalOrderIncident;

ModalOrderIncident.propTypes = {
  showModal: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  infosOrder: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
