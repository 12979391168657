import React from 'react';
import PropTypes from 'prop-types';
import { deleteFileS3 } from '../../utils/awsLib';

export const UploadFile = ({ eventToReset, arrayValues, identificator, label, onInput, errors, setFormValue, setFieldValue, touched }) => (
  <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-2">
    <div className="w-full md:w-4/6 lg:w-full">
      <div className="w-full md:w-auto flex flex-row-reverse items-center justify-end">
        <label htmlFor={identificator} className="w-full md:w-auto px-4 py-2 text-xxms font-medium text-center font-sans cursor-pointer">{label}</label>
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 15C0 14.4477 0.44772 14 1 14H13C13.5523 14 14 14.4477 14 15C14 15.5523 13.5523 16 13 16H1C0.44772 16 0 15.5523 0 15ZM3.29289 4.70711C2.90237 4.31658 2.90237 3.68342 3.29289 3.29289L6.29289 0.29289C6.48043 0.10536 6.73478 0 7 0C7.2652 0 7.5196 0.10536 7.7071 0.29289L10.7071 3.29289C11.0976 3.68342 11.0976 4.31658 10.7071 4.70711C10.3166 5.09763 9.6834 5.09763 9.2929 4.70711L8 3.41421V11C8 11.5523 7.5523 12 7 12C6.44771 12 6 11.5523 6 11V3.41421L4.70711 4.70711C4.31658 5.09763 3.68342 5.09763 3.29289 4.70711Z"
            fill="#0F0F0F"
          />
        </svg>
        <input
          id={identificator}
          name={identificator}
          type="file"
          accept="image/png, image/gif, image/jpeg, .pdf"
          onInput={onInput}
          className="sr-only"
        />
      </div>
      {
        arrayValues
        && arrayValues.map((file) => (
          <p key={file.name} className={`flex w-3/4 items-center justify-around mt-2 break-all text-xxms font-medium rounded-md ${(errors && touched) ? "bg-red-light text-red-800" : "bg-green-light text-green-800"}`}>
            {errors && touched ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
              </svg>
            ) : (
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.7071 6.70711C12.0976 6.31658 12.0976 5.68342 11.7071 5.29289C11.3166 4.90237 10.6834 4.90237 10.2929 5.29289L7 8.58579L5.70711 7.29289C5.31658 6.90237 4.68342 6.90237 4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711L6.29289 10.7071C6.68342 11.0976 7.31658 11.0976 7.70711 10.7071L11.7071 6.70711Z"
                  fill="#34D399"
                />
              </svg>
            )}
            {`${file.name} - `}
            {file.size > 999999 ? `${Math.round(file.size / 100000) / 10} Mo` : `${Math.round(file.size / 100) / 10} Ko`}
            <button
              type="button"
              className="ml-2"
              onClick={() => {
                const filesArray = arrayValues;
                eventToReset.value = null; // reset event value, (if user delete his file X and upload the same file)
                setFieldValue(identificator, filesArray.filter((element) => element.name !== file.name));
                setFormValue((prevState) => ({
                  ...prevState,
                  [identificator]: arrayValues.filter((element) => element.name !== file.name)
                }));
                deleteFileS3(file.fileData); // file.fileData = s3 key of current file
              }}
            >
              {errors && touched ? (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" viewBox="0 0 20 20" fill="#10B981">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              )}
            </button>
          </p>
        ))
      }
      {errors && touched ? (
        <p className="text-red-600 text-center md:text-left mt-2">{errors}</p>
      ) : null}
    </div>
  </div>
);

export default UploadFile;

UploadFile.propTypes = {
  arrayValues: PropTypes.oneOfType([PropTypes.array]).isRequired,
  errors: PropTypes.string,
  identificator: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onInput: PropTypes.func.isRequired,
  touched: PropTypes.oneOfType([PropTypes.array]),
  setFormValue: PropTypes.func,
  setFieldValue: PropTypes.func
};

UploadFile.defaultProps = {
  errors: null,
  touched: [],
  setFormValue: undefined,
  setFieldValue: undefined
};
