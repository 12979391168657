import React from 'react';
import { Redirect, Route } from 'react-router';
import { useSelector } from 'react-redux';

const privateRender = (Component, isAuthenticated, isAuthenticating, location, userDynamo, ...rest) => (props) => {
  let result = null;
  if (isAuthenticating || isAuthenticating === undefined) {
    // DO NOTHING
  } else if (isAuthenticated === true && userDynamo?.seller?.isConfirmedByOperator === false) {
    result = (
      <Redirect
        to={{
          pathname: '/waitingForValidation',
          state: {
            from: location.pathname
          }
        }}
      />
    );
  } else if (isAuthenticated === true) {
    // User Authentified
    return (
      <div>
        <Component {...props} />
      </div>
    );
  } else {
    result = (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            from: location.pathname
          }
        }}
      />
    );
  }
  return result;
};

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.getIn(['app', 'isAuthenticated']), null);
  const isAuthenticating = useSelector((state) => state.getIn(['app', 'isAuthenticating']), null);
  const userDynamo = useSelector((state) => state.getIn(['app', 'userDynamo']), null);
  return <Route {...rest} render={privateRender(Component, isAuthenticated, isAuthenticating, location, userDynamo, rest)} />;
};

export default PrivateRoute;
