import React, { useState, useEffect, useRef } from 'react';
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { useStyles } from "react-styles-hook";
import { ShoppingCartIcon } from '@heroicons/react/solid';
import { useDispatch, useSelector } from 'react-redux';
import { updateSellerOrderLaunched } from '../../../store/reducer';
import getStyles from "../styles";

export const ModalOrderValidation = ({ showModal, hideModal, infosOrder }) => {
  const classes = useStyles(getStyles());
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [validate, setValidate] = useState(false);

  // auto close modal after 3s ONLY if order is validated
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return null;
    }
    const timer = setTimeout(() => hideModal(), 3000);
    return () => { clearTimeout(timer); };
  }, [validate]);

  // Form sellerOrder is accepted
  const [formValue, setFormValue] = useState({
    id: infosOrder.id,
    sellerId: infosOrder.sellerId,
    orderStatus: "WAITING_PREPARATION",
    decisionDate: new Date(),
  });
  const submit = () => {
    dispatch(updateSellerOrderLaunched({ formValue }));
  };
  return (
    showModal
    && (
      <button
        type="button"
        className="cursor-auto absolute w-screen h-screen top-0 pt-0 inset-x-0 flex justify-center items-center md:items-center"
        style={classes.modalBackdrop}
        // close modal if anything outside modal content is clicked
        onClick={hideModal}
      >
        <div
          aria-hidden="true"
          className="relative bg-white w-11/12 md:w-3/4 lg:w-4/6 xl:w-1/2 flex flex-col justify-center items-center px-4 md:px-12 py-3 md:py-8"
          onClick={(e) => {
            // don't close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}
        >
          <div className="w-3/4 md:w-full flex flex-col items-center justify-center my-14 h-36 min-h-full">
            {
              (validate === false)
              && (
                <>
                  <h3 className="text-s md:text-xss text-center my-6">
                    <p>{t("modalAcceptOrder.doYouWantAccept")}</p>
                    <p className="font-semibold pl-2">{infosOrder.orderNumber}</p>
                  </h3>
                  <form className="w-full flex flex-col items-center">
                    <button
                      type="button"
                      className="text-xxs text-white bg-green-apple rounded-md px-6 py-2"
                      onClick={() => { submit(); setValidate(true); }}
                    >
                      {t("validate")}
                    </button>
                  </form>
                  <button className="text-xxs px-4 py-2" type="button" onClick={hideModal}>{t("orderPage.cancel")}</button>
                </>
              )
            }
            {
              (validate === true)
              && (
                <>
                  <h3 className="text-s md:text-xss text-center my-6 space-y-0.5">
                    <p className="capitalize">{t("order")}</p>
                    <p className="font-semibold pl-2">{infosOrder.orderNumber}</p>
                    <p>{t("modalAcceptOrder.isAccept")}</p>
                  </h3>
                </>
              )
            }
          </div>
          <div className="absolute w-full top-1/4 flex justify-center">
            <ShoppingCartIcon className="w-14 height-auto text-green-apple opacity-10" />
            {/* <button
              type="button"
              onClick={hideModal}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="#F00">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={4} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button> */}
          </div>
        </div>
      </button>
    )
  );
};

export default ModalOrderValidation;

ModalOrderValidation.propTypes = {
  showModal: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  infosOrder: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
