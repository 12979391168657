import React from "react";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";

const DropFile = ({ setFieldValue }) => {
  const { t } = useTranslation();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "text/csv",
    onDrop: (acceptedFiles) => {
      setFieldValue("file", acceptedFiles);
    }
  });

  return (
    <div {...getRootProps({ className: 'md:flex md:pr-8 md:mr-0 mr-8 ml-1' })}>
      <div className={`max-w-lg flex justify-center px-6 pt-5 pb-6 border-dashed rounded-md ${(isDragActive === true) ? "border-2 border-grey-extraDark" : "border-2 border-gray-300"}`}>
        <div className="space-y-1 text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="flex text-sm text-gray-600">
            <input {...getInputProps()} />
            {isDragActive ? (
              <p className="md:w-80 md:min-w-min">{t("importsPage.dropHere")}</p>
            ) : (
              <>
                <p className="relative cursor-pointer rounded-md hover:text-orange-chocolate focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-orange-chocolate font-medium text-orange-dark">
                  {t("searchFile")}
                </p>
                <p className="pl-1 hidden md:block">{t("importsPage.dragDrop")}</p>
              </>
            )}
          </div>
          <p className="text-xs text-gray-500">CSV</p>
        </div>
      </div>
    </div>
  );
};

export default DropFile;
