import { React, useState, useEffect } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { GiftIcon } from '@heroicons/react/outline';
import { useTranslation } from "react-i18next";
import { Field } from 'formik';
import { useStyles } from "react-styles-hook";
import getStyles from "./styles";

export const ShippingField = ({
  index,
  deliveryZones,
  idZoneSelected,
  setIdZoneSelected,
  zoneSelected,
  currentMode,
  arrayDeliveryModes,
  handleChange,
  errors,
  touched,
  values,
  setFieldValue,
  seller,
  shippingConfiguration,
  refresh,
  isSubmit }) => {
  const { t } = useTranslation();
  const classes = useStyles(getStyles());
  const [focused, setFocused] = useState(false);

  const translateMode = (deliveryMode) => {
    const deliveryModeToTranslate = deliveryMode.replace('-', '').replace(' ', '');
    return t(`shipping.mode.${deliveryModeToTranslate}`);
    // For example. Input from back : domicile-standard, Output : shipping.mode.domicilestandard translation
  };

  // Checking if current delivery mode is available with current delivery zone
  const [available, setAvailable] = useState(false);
  let dataZones;

  useEffect(() => {
    setAvailable(false);
    arrayDeliveryModes.forEach((deliveryMode) => {
      if (deliveryMode.id === currentMode) {
        dataZones = deliveryMode.deliveryZones;
        dataZones.forEach((zone) => {
          if (zone === idZoneSelected) {
            setAvailable(true);
          }
        });
      }
    });
  }, [idZoneSelected, currentMode]);

  // Set Form Value
  const { id, sellerId, deliveryZoneId, deliveryModeId, pricePerOfferWithVAT, isActive } = values;
  let arrayShippingConfiguration;

  if (shippingConfiguration) {
    arrayShippingConfiguration = Array.from(shippingConfiguration);
  }

  useEffect(() => {
    // Set this current ShippingField with default value
    deliveryZones.forEach((deliveryZone) => {
      if (deliveryZone.zone === zoneSelected) {
        setIdZoneSelected(deliveryZone.id);
      }
    });
    setFieldValue(`${index}.id`, null);
    setFieldValue(`${index}.deliveryZoneId`, idZoneSelected);
    setFieldValue(`${index}.deliveryModeId`, currentMode);
    setFieldValue(`${index}.sellerId`, seller);
    setFieldValue(`${index}.isActive`, false);
    setFieldValue(`${index}.pricePerOfferWithVAT`, 0);

    // Set this current ShippingField if is already configured
    if (arrayShippingConfiguration) {
      arrayShippingConfiguration.forEach((configuration) => {
        if (configuration.deliveryModeId === currentMode && configuration.deliveryZoneId === idZoneSelected) {
          setFieldValue(`${index}.id`, configuration.id);
          setFieldValue(`${index}.isActive`, configuration.isActive);
          setFieldValue(`${index}.pricePerOfferWithVAT`, configuration.pricePerOfferWithVAT);
          setFieldValue(`${index}.deliveryZoneId`, configuration.deliveryZoneId);
          setFieldValue(`${index}.deliveryModeId`, configuration.deliveryModeId);
          setFieldValue(`${index}.sellerId`, configuration.sellerId);
        }
      });
    }
  }, [idZoneSelected, zoneSelected, shippingConfiguration, refresh, isSubmit]);

  return (
    <fieldset
      name={`${index}`}
      className={`space-y-5 border p-4  ${(focused === true) ? "bg-orange border-orange-dark" : "bg-white"}`}
      aria-hidden="true"
      onFocus={() => {
        setFocused(true);
      }}
      onClick={() => {
        if (available) {
          setFocused(true);
        }
      }}
    >
      <div className="relative flex items-start pt-2">
        <div className="flex items-center h-5 pt-3 cursor-pointer">
          <Field
            id={`${index}.isActive`}
            type="checkbox"
            name={`${index}.isActive`}
            value={isActive}
            disabled={!available}
            className={`w-5 h-5 rounded-full text-orange-chocolate ${(focused) ? "focus:ring-orange-chocolate" : ""} ${(available) ? "border-gray-400" : "border-gray-100"}`}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </div>
        <div className="ml-3 text-xxs sm:text-xs md:text-sm flex-1">
          <label
            aria-hidden="true"
            htmlFor={`${index}.isActive`}
            className={`font-normal pl-2 ${(available) ? "text-gray-900" : "text-gray-300"}`}
          >
            <>
              <span>{translateMode(currentMode)}</span>
              {values[index]?.isActive
                && (<span className="italic text-xxs">(Active)</span>)}
            </>
          </label>
          {(available && values[index]?.isActive && (values[index]?.pricePerOfferWithVAT === 0 || values[index]?.pricePerOfferWithVAT === ''))
            && (
              <p className="pl-2 font-medium flex">
                <GiftIcon className="w-5 h-5 mr-1" />
                {t("shipping.free")}
              </p>
            )}
        </div>
        {available && !focused
          && (
            <div className="ml-auto"><ChevronDownIcon className="h-5 w-5" /></div>
          )}
      </div>
      <div
        className={`ml-10 w-52 text-sm ${(focused === true) ? "" : "hidden"}`}
      >
        <>
          <label htmlFor={`${index}.pricePerOfferWithVAT`} className="hidden">
            Prix
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm">€</span>
            </div>
            <Field
              id={`${index}.pricePerOfferWithVAT`}
              name={`${index}.pricePerOfferWithVAT`}
              type="number"
              step="0.01"
              min="0"
              disabled={!available}
              value={values[index]?.pricePerOfferWithVAT || ''}
              placeholder="0,00"
              style={classes.inputWithoutWebkit}
              className="focus:ring-orange focus:border-orange block w-full pl-10 pr-15 text-gray-simple font-normal sm:text-sm border-gray-300 rounded-md"
              aria-describedby="price-currency"
              onChange={(e) => {
                handleChange(e);
              }}
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span className="text-gray-500 text-xs sm:text-sm" id="price-currency">
                Euros
              </span>
            </div>
          </div>
        </>
      </div>
    </fieldset>
  );
};

export default ShippingField;
