import { useMemo } from 'react';

// This custom hooks returns array with PageSize elements based on initial currentData
// He's refresh when the currentPage or currentData is updated

const usePagination = ({ currentData, PageSize, currentPage }) => {
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return currentData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, currentData]);

  return currentTableData;
};

export default usePagination;
