import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { RequestForm, SubmitForm } from '../../components/ForgotPasswordForm';
import { requestPasswordCodeLaunched, submitNewPasswordLaunched } from '../../components/App/reducer';

export const ForgotPassword = () => {
  const dispatch = useDispatch();
  const { forgotPasswordStep } = useSelector((state) => ({
    forgotPasswordStep: state.getIn(['app', 'forgotPasswordStep'])
  }));
  const { t } = useTranslation();
  const [email, setEmail] = useState('');

  //  Forms Data
  const RequestInitialValues = {
    email: ''
  };

  const SubmissionInitialValues = {
    code: '',
    password: '',
    confirmPassword: ''
  };

  // Forms Submitting Functions
  const requestCode = (payload) => {
    setEmail(payload.email);
    dispatch(requestPasswordCodeLaunched(payload));
  };

  const submitNewPassword = (payload) => {
    const data = { username: email.toLowerCase(), ...payload };
    dispatch(submitNewPasswordLaunched(data));
  };

  // Forms Validation Schemas
  const RequestValidationSchema = Yup.object().shape({
    email: Yup
      .string()
      .email('emailNotValid')
      .required('emailRequired')
  });

  const SubmissionValidationSchema = Yup.object().shape({
    code: Yup
      .string()
      .required('codeRequired'),
    password: Yup
      .string()
      .min(8, t("signInForm.passwordType"))
      .required('passwordRequired'),
    confirmPassword: Yup
      .string()
      .oneOf([Yup.ref('password'), null], t("passwordMismatch"))
      .required('confirmPasswordRequired')
  });

  const history = useHistory();
  const { submitPasswordSuccessMessage } = useSelector((state) => ({
    submitPasswordSuccessMessage: state.getIn(['app', 'submitPasswordSuccessMessage'])
  }));

  if (submitPasswordSuccessMessage) {
    setTimeout(() => history.push('/login'), 5000);
  }

  const renderForms = () => {
    if (forgotPasswordStep === 1) {
      return (
        // Requesting Code
        <>
          <div>
            <div className="my-5">
              <h2 className="mt-1 block text-s text-center tracking-tight font-semibold">
                {t('forgotPassword.text')}
              </h2>
            </div>
          </div>
          <Formik
            render={(props) => <RequestForm {...props} />}
            initialValues={RequestInitialValues}
            validationSchema={RequestValidationSchema}
            onSubmit={requestCode}
          />
        </>
      );
    }
    return (
      // Sending New Password
      <Formik
        render={(props) => <SubmitForm {...props} />}
        initialValues={SubmissionInitialValues}
        validationSchema={SubmissionValidationSchema}
        onSubmit={submitNewPassword}
      />
    );
  };

  return (
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md sm:py-24">
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <h2 className="mt-1 block text-l tracking-tight font-bold sm:text-xxl text-orange-chocolate text-center">
          {t('forgotPassword.title')}
        </h2>
        {renderForms()}
      </div>
    </div>
  );
};

export default ForgotPassword;
