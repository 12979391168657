import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { getDeliveryZonesLaunched, getDeliveryModesLaunched, shippingConfigurationLaunched, getShippingConfigurationLaunched } from '../../store/reducer';
import { CustomSelect } from '../../components/CustomSelect';
import { CustomButton } from '../../components/Button';
import { ShippingField } from '../../components/Inputs/ShippingField';
import { capitalizeFirst } from '../../utils/format';
import { SpinnerLoader } from '../../components/Loader';

export const ShippingModeConfigurationsPage = (props) => {
  const { t } = useTranslation();

  const [refresh, setRefresh] = useState(false);
  const [zoneSelected, setZoneSelected] = useState(null);

  const dispatch = useDispatch();
  const { deliveryZones, deliveryModes, userDynamo, shippingConfiguration, getShippingConfigurationLoading, shippingLoading, shippingSuccess } = useSelector((state) => (
    {
      deliveryZones: state.getIn(['store', 'deliveryZones']),
      deliveryModes: state.getIn(['store', 'deliveryModes']),
      userDynamo: state.getIn(['app', 'userDynamo']),
      shippingConfiguration: state.getIn(['store', 'shippingConfiguration']),
      getShippingConfigurationLoading: state.getIn(['store', 'getShippingConfigurationLoading']),
      shippingLoading: state.getIn(['store', 'shippingLoading']),
      shippingSuccess: state.getIn(['store', 'shippingSuccess']),
    }
  ));

  useEffect(() => {
    dispatch(getDeliveryZonesLaunched());
    dispatch(getDeliveryModesLaunched());
  }, []);

  const seller = userDynamo?.seller?.id;

  // After submit : get ShippingModeConfiguration updated
  useEffect(() => {
    dispatch(getShippingConfigurationLaunched());
  }, [refresh, zoneSelected, shippingLoading]);

  // DEFINE DEFAULT DELIVERY ZONE TO DISPLAY
  const [idZoneSelected, setIdZoneSelected] = useState(null);
  useEffect(() => {
    if (deliveryZones) {
      setZoneSelected(deliveryZones[2].zone);
      setIdZoneSelected(deliveryZones[2].id);
    }
  }, [deliveryZones]);

  // FORM
  const initialValues = {
    // id: null, (AUTOGENERATE)
    sellerId: null,
    deliveryZoneId: null,
    deliveryModeId: null,
    pricePerOfferWithVAT: null,
    isActive: null,
  };

  const validationSchema = Yup.object({
    pricePerOfferWithVAT: Yup.number()
      .positive()
  });

  const submitForm = (values) => {
    dispatch(shippingConfigurationLaunched(values));
  };

  // Auto hide confirmation message if form is submitted
  const [isSubmit, setIsSubmit] = useState(false);
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return null;
    }
    const timer = setTimeout(() => setIsSubmit(false), 3000);
    return () => { clearTimeout(timer); };
  }, [refresh]);

  // Set Success & error message
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (!shippingLoading && shippingSuccess) {
      setSuccessMessage(t("successMessage"));
    } else if (!shippingLoading && !shippingSuccess) {
      setErrorMessage(t("errorMessage"));
    }
  }, [shippingLoading, shippingSuccess]);

  useEffect(() => {
    setSuccessMessage(null);
    setErrorMessage(null);
  }, [idZoneSelected]);

  return (
    <>
      <div className="w-full md:w-3/4 px-4 md:mx-auto pt-8">
        {(!getShippingConfigurationLoading && shippingConfiguration)
          && (
            <>
              <CustomSelect
                label={t("shipping.shippingZone")}
                deliveryZones={deliveryZones}
                zoneSelected={zoneSelected}
                setZoneSelected={setZoneSelected}
              />
              <p className="pt-4 flex text-xxms sm:text-xms md:text-xss">
                <InformationCircleIcon className="w-10 h-10 sm:w-6 sm:h-6 mr-1" />
                {t("shipping.notice")}
              </p>
              {
                (deliveryZones && deliveryModes)
                && (
                  <div className="w-full 2xl:w-3/4 pt-4">
                    <Formik
                      initialValues={[initialValues]}
                      validationSchema={validationSchema}
                      onSubmit={(values, { resetForm }) => {
                        submitForm(values);
                        setRefresh(!refresh);
                        resetForm();
                        setIsSubmit(true);
                      }}
                    >
                      {({ errors, touched, values, setFieldValue, handleChange }) => (
                        <Form>
                          <FieldArray
                            name=""
                            render={(arrayHelpers) => (
                              <div>
                                {deliveryZones.map((zones) => (
                                  deliveryModes.map((deliveryMode, index) => (
                                    (zones.zone === zoneSelected)
                                    && (
                                      <ShippingField
                                        key={zones.id + deliveryMode.id}
                                        index={index}
                                        deliveryZones={deliveryZones}
                                        idZoneSelected={idZoneSelected}
                                        setIdZoneSelected={setIdZoneSelected}
                                        zoneSelected={zoneSelected}
                                        currentMode={deliveryMode.id}
                                        arrayDeliveryModes={deliveryModes}
                                        handleChange={handleChange}
                                        errors={errors}
                                        touched={touched}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        seller={seller}
                                        shippingConfiguration={shippingConfiguration}
                                        refresh={refresh}
                                        isSubmit={isSubmit}
                                      />
                                    )
                                  ))
                                ))}
                                {successMessage && (
                                  <div className="pt-6 flex justify-center text-green-apple">
                                    <p>{capitalizeFirst(successMessage)}</p>
                                  </div>
                                )}
                                {errorMessage && (
                                  <div className="pt-6 flex justify-center text-red">
                                    <p>{capitalizeFirst(errorMessage)}</p>
                                  </div>
                                )}
                                <div className="pt-8 flex justify-center">
                                  <CustomButton title={shippingLoading ? capitalizeFirst(t("saveInProgress")) : capitalizeFirst(t("save"))} disabled={shippingLoading} />
                                </div>
                              </div>
                            )}
                          />
                        </Form>
                      )}
                    </Formik>
                  </div>
                )
              }
            </>
          )}
        {getShippingConfigurationLoading
          && (
            <SpinnerLoader />
          )}
      </div>

    </>
  );
};

export default ShippingModeConfigurationsPage;
