import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import CatalogArray from '../../components/CatalogArray';
import { getProductSheetsLaunched } from '../../store/reducer';
import DesktopCatalogArray from '../../components/CatalogArray/desktopCatalogArray';
import usePagination from "../../hooks/usePagination";
import { Pagination } from "../../components/Pagination";
import { capitalizeFirst } from '../../utils/format';
import { SpinnerLoader } from '../../components/Loader';

export const ProductsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(false);

  const { productSheets, getProductSheetsLoading } = useSelector((state) => (
    {
      productSheets: state.getIn(['store', 'productSheets']),
      getProductSheetsLoading: state.getIn(['store', 'getProductSheetsLoading'])
    }
  ));

  useEffect(() => {
    dispatch(getProductSheetsLaunched({ productSheets }));
  }, [dispatch, refresh]);

  // Pagination
  const PageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  useEffect(() => {
    if (productSheets) {
      setCurrentData(productSheets);
    }
  }, [productSheets]);

  const currentTableData = usePagination({ currentData, PageSize, currentPage });

  return (
    <>
      {getProductSheetsLoading && (
        <div className="pt-10">
          <SpinnerLoader />
        </div>
      )}
      {/* Mobile */}
      {!getProductSheetsLoading && productSheets && currentTableData.map((item) => (
        <div className="block lg:hidden" key={item.id}>
          <CatalogArray
            arrayContent={currentTableData}
            item={item}
          />
        </div>
      ))}
      {!getProductSheetsLoading && productSheets
        && (
          <div className="block lg:hidden">
            <Pagination
              currentPage={currentPage}
              totalCount={productSheets.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      {productSheets?.length === 0
        && (
          <div className="flex flex-col lg:hidden pt-2">
            <p className="ml-4 mt-5 mb-2 text-s font-medium capitalize">{t("orderPage.products")}</p>
            <div className="text-grey-middle text-xxms font-normal mt-2 pl-5">{capitalizeFirst(t("orderPage.noProduct"))}</div>
            <a href="/imports" className="self-center mt-10 py-2 px-6 text-xxs text-white font-normal rounded bg-orange-dark hover:bg-orange-chocolate">
              {capitalizeFirst(t("orderPage.importProduct"))}
            </a>
          </div>
        )}
      {/* Desktop */}
      <div className="hidden lg:block w-5/6 mx-auto">
        {!getProductSheetsLoading && productSheets
          && (
            <>
              <DesktopCatalogArray
                arrayContent={currentTableData}
                setRefresh={setRefresh}
                refresh={refresh}
              />
              <Pagination
                currentPage={currentPage}
                totalCount={productSheets.length}
                pageSize={PageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </>
          )}
      </div>
    </>
  );
};

export default ProductsPage;
