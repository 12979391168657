import { React } from "react";
import { useStyles } from "react-styles-hook";
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { CustomNavLink } from '../NavLink';
import getStyles from "./styles";

export const NavBar = () => {
  const classes = useStyles(getStyles());
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <div className="hidden lg:block w-5/6 mx-auto py-8">
      <nav className="bg-white">
        <ul className="w-full flex justify-center items-center">
          <li className="pr-8 flex">
            <CustomNavLink
              to="/home"
              text="Dashboard"
              icon={(
                <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 pr-2 pb-2" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11.707 4.707a1 1 0 00-1.414-1.414L10 9.586 8.707 8.293a1 1 0 00-1.414 0l-2 2a1 1 0 101.414 1.414L8 10.414l1.293 1.293a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
              )}
            />
          </li>
          <li className="pr-8 flex">
            <CustomNavLink
              to="/orders"
              text="Commandes"
              icon={(
                <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 pr-2" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                </svg>
              )}
              isActive={() => ['/orders', '/preparation-orders', '/not-shipped', '/delivery', '/incident'].includes(pathname)}
            />
          </li>
          <li className="pr-8 flex">
            <CustomNavLink
              to="/products"
              text="Catalogue"
              icon={(
                <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 pr-2 pb-2" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                  <path fillRule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clipRule="evenodd" />
                </svg>
              )}
            />
          </li>
          <li className="pr-8 flex">
            <CustomNavLink
              to="/imports"
              text="Imports"
              icon={(
                <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 pr-2 pb-2" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z" clipRule="evenodd" />
                </svg>
              )}
            />
          </li>
          <li className="pr-8 flex">
            <CustomNavLink
              to="/shipping-mode"
              text="Logistique"
              icon={(
                <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 pr-2 pb-2" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M8 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM15 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
                  <path d="M3 4a1 1 0 00-1 1v10a1 1 0 001 1h1.05a2.5 2.5 0 014.9 0H10a1 1 0 001-1V5a1 1 0 00-1-1H3zM14 7a1 1 0 00-1 1v6.05A2.5 2.5 0 0115.95 16H17a1 1 0 001-1v-5a1 1 0 00-.293-.707l-2-2A1 1 0 0015 7h-1z" />
                </svg>
              )}
            />
          </li>
          <li className="pr-8 flex">
            <CustomNavLink
              to="/account"
              text={t("myAccount")}
              icon={(
                <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 pr-2 pb-2" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd" />
                </svg>
              )}
            />
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default NavBar;
