import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useStyles } from "react-styles-hook";
import { Link, useLocation } from 'react-router-dom';
import { XIcon, CurrencyEuroIcon } from '@heroicons/react/solid';
import { formatDateToFrenchFormat } from '../../../../utils/format';
import { ModalOrderDetails } from '../../../Modal/ModalOrderDetails';
import { ModalOrderIncident } from '../../../Modal/ModalOrderIncident';
import { ModalOrderValidation } from '../../../Modal/ModalOrderValidation';
import { ModalOrderShipping } from '../../../Modal/ModalOrderShipping';
import { ModalOrderCancel } from '../../../Modal/ModalOrderCancel';
import { ModalOrderRefund } from '../../../Modal/ModalOrderRefund';
import getStyles from "../styles";
import blurEmail from "../../../../assets/img/blur_email.png";
import blurCustomer from "../../../../assets/img/blur_customer.png";

export const FilterResultMobile = ({ infoOrder, incidentModal, incident, validationModal, shippingModal, cancelModal, refundModal }) => {
  const classes = useStyles(getStyles());
  const { t } = useTranslation();
  const [openModalDetails, setOpenModalDetails] = useState(false);
  const [openModalIncident, setOpenModalIncident] = useState(false);
  const [openModalValidation, setOpenModalValidation] = useState(false);
  const [openModalShipping, setOpenModalShipping] = useState(false);
  const [openModalCancel, setOpenModalCancel] = useState(false);
  const [openModalRefund, setOpenModalRefund] = useState(false);

  const showModal = (e) => {
    switch (e.target.id) {
      case "incident":
        setOpenModalIncident(true);
        break;
      case "details":
        setOpenModalDetails(true);
        break;
      case "validation":
        setOpenModalValidation(true);
        break;
      case "shipping":
        setOpenModalShipping(true);
        break;
      case "cancel":
        setOpenModalCancel(true);
        break;
      case "refund":
        setOpenModalRefund(true);
        break;
      default:
        break;
    }
  };

  const hideModal = () => {
    setOpenModalDetails(false);
    setOpenModalIncident(false);
    setOpenModalValidation(false);
    setOpenModalShipping(false);
    setOpenModalCancel(false);
    setOpenModalRefund(false);
  };

  const { pathname } = useLocation();
  // incident dans ModalOrderIncident

  return (
    <div key={infoOrder.id}>
      <div className="flex flex-row print:py-1 pl-4 py-4 border-b border-grey-light border-opacity-50">
        <div className="w-2/5 font-medium">{t("orderPage.orderNumber")}</div>
        <div className="print:font-medium print:text-xxms w-3/5 font-normal">{infoOrder.orderNumber}</div>
      </div>
      <div className="flex flex-row print:py-1 pl-4 py-4 border-b border-grey-light border-opacity-50">
        <div className="w-2/5 font-medium">Date</div>
        <div className="w-3/5 font-normal text-xxxs">{formatDateToFrenchFormat(infoOrder.date)}</div>
      </div>
      <div className="flex flex-row print:py-2 pl-4 py-4 border-b border-grey-light border-opacity-50">
        <div className="flex flex-col justify-center w-2/5 font-medium">{t("orderPage.products")}</div>
        <div className="w-3/5 space-y-4 font-normal text-xxxs truncate">
          {infoOrder.products.map((product) => (
            <div key={product.title + product.sku} className="flex flex-col">
              <span className="capitalize">{product.title}</span>
              <span>{`SKU: ${product.sku}`}</span>
              <span className="font-medium text-xxs mt-1">{`${t("orderPage.quantity")} : ${product.quantity}`}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-row print:py-1 pl-4 py-4 border-b border-grey-light border-opacity-50">
        <div className="w-2/5 font-medium">{t("orderPage.amountOrder")}</div>
        <div className="w-3/5 font-normal text-xxxs">{`${infoOrder.totalOfferPrice.toFixed(2)} €`}</div>
      </div>
      <div className="flex flex-row print:py-1 pl-4 py-4 border-b border-grey-light border-opacity-50">
        <div className="w-2/5 font-medium">{t("orderPage.customer")}</div>
        <div className="w-3/5 font-normal text-xxxs truncate">
          {
            (infoOrder.status === "WAITING_ACCEPTANCE")
            && (
              <img className="w-20" src={blurCustomer} alt="Customer" />
            )
          }
          {
            (infoOrder.status !== "WAITING_ACCEPTANCE")
            && (
              <>
                <span>
                  {infoOrder.customer.firstname}
                </span>
                <span className="pl-1">
                  {infoOrder.customer.lastname}
                </span>
              </>
            )
          }
        </div>
      </div>
      <div className="flex flex-row print:py-1 pl-4 py-4 border-b border-grey-light border-opacity-50">
        <div className="w-2/5 font-medium">{t("email")}</div>
        <div className="w-3/5 font-normal text-xxxs truncate">
          {
            (infoOrder.status === "WAITING_ACCEPTANCE")
            && (
              <img className="w-36" src={blurEmail} alt="Customer Email" />
            )
          }
          {
            (infoOrder.status !== "WAITING_ACCEPTANCE")
            && (
              infoOrder.customer.email
            )
          }
        </div>
      </div>
      <div className={`flex flex-row print:py-1 pl-4 py-4 border-b border-grey-light border-opacity-50 print:hidden ${(pathname === "/incident" || pathname === "/delivery" || pathname === "/waiting-delivery") ? "" : "hidden"}`}>
        <div className="w-2/5 font-medium">{t("orderPage.trackingNumber")}</div>
        <div className="w-3/5 font-normal text-xxxs">
          {(infoOrder.trackingUrl !== "" && infoOrder.trackingUrl !== null) ? <a href={infoOrder.trackingUrl} className="capitalize">{t("orderPage.track")}</a> : 'Aucun'}
        </div>
      </div>
      <div className="flex flex-row print:py-1 pl-4 py-4 border-b border-grey-light border-opacity-50 print:hidden">
        <div className="w-2/5 font-medium">{t("orderPage.status")}</div>
        <div className="w-3/5 font-normal text-xxxs truncate">
          {
            (infoOrder.status === "WAITING_ACCEPTANCE")
            && (
              <span className="inline-flex items-center px-3 py-0.5 rounded-full font-normal bg-grey-lightDark text-white capitalize">
                {t("orderPage.waitingAccept")}
              </span>
            )
          }
          {
            (infoOrder.status === "WAITING_PREPARATION")
            && (
              <span className="inline-flex items-center px-3 py-0.5 rounded-full font-normal bg-orange-lightDark text-white capitalize">
                {t("orderPage.toShip")}
              </span>
            )
          }
          {
            (infoOrder.status === "WAITING_DELIVERY")
            && (
              <span className="inline-flex items-center px-3 py-0.5 rounded-full font-normal bg-blue text-white capitalize">
                {t("orderPage.inDelivery")}
              </span>
            )
          }
          {
            (infoOrder.status === "DELIVERED")
            && (
              <span className="inline-flex items-center px-3 py-0.5 rounded-full font-normal bg-blue-aqua text-white capitalize">
                {t("orderPage.isDelivered")}
              </span>
            )
          }
          {
            (infoOrder.status === "INCIDENT")
            && (
              <span className="inline-flex items-center px-3 py-0.5 rounded-full font-normal bg-orange-chocolate text-white capitalize">
                Incident
              </span>
            )
          }
          {
            (infoOrder.status === "PAID")
            && (
              <span className="inline-flex items-center px-3 py-0.5 rounded-full font-normal bg-green-apple text-white capitalize">
                {t("orderPage.paid")}
              </span>
            )
          }
          {
            (infoOrder.status === "REFUSED")
            && (
              <span className="inline-flex items-center px-3 py-0.5 rounded-full font-normal bg-red-900 text-white capitalize">
                {t("orderPage.refused")}
              </span>
            )
          }
        </div>
      </div>
      <div className="flex flex-row print:py-1 pl-4 py-4 border-b border-grey-light border-opacity-50 mb-12 print:hidden">
        <div className="flex flex-col justify-center w-2/5 font-medium">{t("orderPage.actions")}</div>
        <div className="flex flex-col items-left w-3/5 font-normal text-xxs py-1 gap-y-3">
          {
            (pathname === "/preparation-orders")
            && (
              <>
                {validationModal
                  && (
                    <button onClick={showModal} id="validation" type="button" className="w-28 text-green-apple border rounded border-green-apple py-1 px-4">{t("orderPage.accept")}</button>
                  )}
                {cancelModal
                  && (
                    <button onClick={showModal} id="cancel" type="button" className="w-28 text-orange-chocolate border rounded border-orange-chocolate py-1 px-4">{t("orderPage.refuse")}</button>
                  )}
              </>
            )
          }
          {
            (pathname === "/not-shipped")
            && (
              <>
                {shippingModal
                  && (
                    <button onClick={showModal} id="shipping" type="button" className="w-28 text-green-apple border rounded border-green-apple py-1 px-4">{t("orderPage.send")}</button>
                  )}
                {cancelModal
                  && (
                    <button onClick={showModal} id="cancel" type="button" className="w-28 text-orange-chocolate border rounded border-orange-chocolate py-1 px-4">{t("orderPage.cancel")}</button>
                  )}
              </>
            )
          }
          {
            (pathname === "/incident")
            && (
              <>
                {cancelModal
                  && (
                    <button onClick={showModal} id="cancel" type="button" className="flex text-orange-chocolate">
                      <XIcon className="w-5 h-5 mr-1" />
                      {t("orderPage.refuse")}
                    </button>
                  )}
                {/* Feature unavailable <button type="button" className="">{t("orderPage.windUp")}</button> */}
                {refundModal
                  && (
                    <button onClick={showModal} id="refund" type="button" className="flex text-yellow">
                      <CurrencyEuroIcon className="w-5 h-5 mr-1" />
                      {t("orderPage.refund")}
                    </button>
                  )}
              </>
            )
          }
          {/* Feature unavailable {incidentModal && <button onClick={showModal} id="incident" type="button" className="">{t("orderPage.openIncident")}</button>} */}
          <button onClick={showModal} id="details" type="button" className="w-28 border rounded border-black-primary text-black-primary py-1 px-2">{t("seeDetail")}</button>
        </div>
      </div>
      {/* Start only print */}
      {
        (pathname === "/not-shipped")
        && (
          <>
            <div className="print:flex hidden flex-row print:py-1 pl-4 pt-3 pb-2 border-b border-grey-light border-opacity-50 font-medium">
              {t("orderPage.deliveryInfo")}
            </div>
            <div className="print:flex hidden flex-row print:py-1 pl-4 py-4 border-b border-grey-light border-opacity-50">
              <div className="w-2/5 font-medium">{`${t("lastName")} ${t("firstName")}`}</div>
              <div className="w-3/5 flex font-normal text-xxxs space-x-0.5">
                <span>{infoOrder.delivery.firstname}</span>
                <span>{infoOrder.delivery.lastname}</span>
              </div>
            </div>
            <div className={`hidden flex-row print:py-1 pl-4 py-4 border-b border-grey-light border-opacity-50 ${(infoOrder.delivery.company) ? "print:flex" : "print:hidden"}`}>
              <div className="w-2/5 font-medium capitalize">Entreprise</div>
              <div className="w-3/5 font-normal text-xxxs">{infoOrder.delivery.company}</div>
            </div>
            <div className="print:flex hidden flex-row print:py-1 pl-4 py-4 border-b border-grey-light border-opacity-50">
              <div className="w-2/5 font-medium">{t("signUpInfos.adress")}</div>
              <div className="w-3/5 font-normal text-xxxs">{infoOrder.delivery.address}</div>
            </div>
            <div className="print:flex hidden flex-row print:py-1 pl-4 py-4 border-b border-grey-light border-opacity-50">
              <div className="w-2/5 font-medium">{t("signUpInfos.cp")}</div>
              <div className="w-3/5 font-normal text-xxxs">{infoOrder.delivery.zipcode}</div>
            </div>
            <div className="print:flex hidden flex-row print:py-1 pl-4 py-4 border-b border-grey-light border-opacity-50">
              <div className="w-2/5 font-medium">{t("signUpInfos.city")}</div>
              <div className="w-3/5 font-normal text-xxxs">{infoOrder.delivery.city}</div>
            </div>
            <div className="print:flex hidden flex-row print:py-1 pl-4 py-4 border-b border-grey-light border-opacity-50">
              <div className="w-2/5 font-medium">{t("signUpInfos.country")}</div>
              <div className="w-3/5 font-normal text-xxxs">{infoOrder.delivery.country}</div>
            </div>
            <div className="print:flex print:mb-6 hidden flex-row print:py-1 pl-4 py-4 border-b border-grey-light border-opacity-50">
              <div className="w-2/5 font-medium capitalize">{t("phoneNumber")}</div>
              <div className="w-3/5 font-normal text-xxxs">{infoOrder.delivery.telephone}</div>
            </div>
          </>
        )
      }
      {/* End only print */}
      <ModalOrderDetails showModal={openModalDetails} hideModal={hideModal} infosOrder={infoOrder} />
      <ModalOrderIncident showModal={openModalIncident} hideModal={hideModal} infosOrder={infoOrder} />
      <ModalOrderValidation showModal={openModalValidation} hideModal={hideModal} infosOrder={infoOrder} />
      <ModalOrderShipping showModal={openModalShipping} hideModal={hideModal} infosOrder={infoOrder} />
      <ModalOrderCancel showModal={openModalCancel} hideModal={hideModal} infosOrder={infoOrder} />
      <ModalOrderRefund showModal={openModalRefund} hideModal={hideModal} infosOrder={infoOrder} />
    </div>
  );
};

export default FilterResultMobile;
