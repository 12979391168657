import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomOrdersLaunched, getOffersLaunched, getDeliverySimulationsLaunched, getUserLaunched } from '../../store/reducer';
import { OrdersNavigation } from "../../components/OrdersNavigation";
import creme2 from "../../assets/img/creme2.jpg";

export const PaidOrdersPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { customOrders, offers, users, deliverySimulations } = useSelector((state) => (
    {
      customOrders: state.getIn(['store', 'customOrders']),
      offers: state.getIn(['store', 'offers']),
      users: state.getIn(['store', 'user']),
      deliverySimulations: state.getIn(['store', 'deliverySimulations']),
    }
  ));

  const sellerID = "7e1c7bba-4d5a-450b-acd0-d5c31d77c30d";

  useEffect(() => {
    dispatch(getCustomOrdersLaunched({ customerOrders: id }));
    dispatch(getOffersLaunched({ offers: id }));
    dispatch(getDeliverySimulationsLaunched({ deliverySimulations: id }));
    dispatch(getUserLaunched({ users: id }));
  }, []);

  let dataCustomOrders;
  let dataOffers;
  let dataDeliverySimulations;
  let dataUsers;
  const currentInfosOrder = [];
  if (customOrders && offers && deliverySimulations && users) {
    dataCustomOrders = Array.from(customOrders);
    dataOffers = Array.from(offers);
    dataDeliverySimulations = Array.from(deliverySimulations);
    dataUsers = Array.from(users);

    dataDeliverySimulations.forEach((item) => {
      const currentCustomOrder = dataCustomOrders.filter((data) => data.deliverySimulationId === item.id);
      const currentEmailUser = dataUsers.filter((data) => data.id === item.customerId);
      const currentOffers = [];
      item.sellerOrders.forEach((order) => {
        if (order.sellerId === sellerID) {
          order.cartItems.forEach((cart) => {
            const offer = dataOffers.filter((data) => data.id === cart.offerId);
            currentOffers.push({
              img: creme2,
              title: offer[0].name,
              sku: offer[0].sellerSKU,
              quantity: cart.quantity,
              price: offer[0].priceWithVAT
            });
          });
        }
      });
      let totalPrice = 0;
      currentOffers.forEach((offer) => {
        totalPrice += (offer.price * offer.quantity);
      });

      currentInfosOrder.push({
        orderNumber: currentCustomOrder[0].orderNumber,
        date: currentCustomOrder[0].createdDate,
        mail: currentEmailUser[0].email,
        totalPrice,
        products: currentOffers,
        delivery: {
          lastname: currentEmailUser[0].lastName,
          firstname: currentEmailUser[0].firstName,
          telephone: "+33612345789",
          adress: item.delivery.address,
          zipCode: item.delivery.zipCode,
          city: item.delivery.city,
          country: item.delivery.country
        }
      });
    });
  }

  return (
    <>
      <div className="md:w-3/4 md:px-4 md:mx-auto pt-2">
        <OrdersNavigation infosOrder={currentInfosOrder} />
      </div>
    </>
  );
};

export default PaidOrdersPage;
