import React from 'react';
import { useStyles } from "react-styles-hook";
import getStyles from "./styles";

export const StatArray = ({ stats }) => {
  const classes = useStyles(getStyles());
  return (
    <dl className="mt-5 grid gap-5 grid-cols-2 sm:grid-cols-4 lg:grid-cols-6">
      {stats.map((item) => (
        <div
          key={item.id}
          className={`relative bg-white pt-5 px-4 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden col-span-2 ${(item.id > 3) ? "lg:col-span-3" : "lg:col-span-2"} ${(item.id > 4) ? "sm:col-span-4 lg:col-span-3" : "lg:col-span-2"}`}
        >
          <dt>
            <div className="absolute bg-orange-dark rounded-md p-3">
              <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
            </div>
            <p className="ml-16 text-xxms font-normal text-gray-500 truncate">{item.name}</p>
          </dt>
          <dd className="ml-16 pt-2 pb-6 flex items-baseline sm:pb-7">
            <p className="text-m font-semibold text-gray-700">{item.stat}</p>
          </dd>
        </div>
      ))}
    </dl>
  );
};

export default StatArray;
